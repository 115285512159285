import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashDifference from 'lodash/difference';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import format from 'date-fns/format';
import converter from '../../../utils/converter.js'; //permet de transformer les données pour les adapter aux entités de l'API web et inversement

import { ChangeType, ReducerType } from '../../../models/changeAction.js';
import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import { SatimageHelper } from '../../../utils/satimageHelper.js';
import ConstantsProvidersSatellite from '../../../utils/constantsProvidersSatellite.js';
import DateHelper from '../../../utils/dateHelper.js';
import sendError from '../../../utils/errorService.js';
import ConstantsLayers from '../../../utils/constantsOfLayers.js';
import { ChoiceProvidersSatellite } from '../../../utils/constantsProvidersSatellite.js';
import {
    ActionLoadingGlobalLayer,
    ActionGlobalLayerLoaded,
    ActionSetValueNewImageShowedOnMap,
    ActionFitMapToBounds
} from '../../../redux/actions/contextApp.js';

import MapButtonsPaneModulation from './MapButtonsPaneModulation.jsx';
import country from '../../../datas/country.json';

import '../../../assets/css/rm-render_maps.css';

import getTheme from "../../../themes/index.js";
let theme = getTheme();


/* ↓↓ Définition des différents styles à appliquer sur un polygone ↓↓ */
const stylePolygon = {
    default: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0.3, opacity: 1},
    selection: { fillColor: theme.palette.contour.selection, color: theme.palette.contour.selection, fillOpacity: 0.3, opacity: 1},
    new: { fillColor: theme.palette.contour.new, color: theme.palette.contour.new, fillOpacity: 0.3, opacity: 1},
    transparent: { fillOpacity: 0, opacity: 0 },
    defaultContour: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0, opacity: 1},
    selectionContour: { fillColor: theme.palette.contour.selection, color: theme.palette.contour.selection, fillOpacity: 0, opacity: 1},
    newContour: { fillColor: theme.palette.contour.new, color: theme.palette.contour.new, fillOpacity: 0, opacity: 1},
    fill_off: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0, opacity: 1 },
    fill_on: { fillOpacity: 0.3, opacity: 1},
    selected: {color: theme.palette.contour.selection, opacity: 1},
    unselected: {color: theme.palette.primary.main, opacity: 1}
}

/* ↓↓ Définition des différentes options à appliquer lors de l'utilisation du fitBounds ↓↓ */
const fitBoundsOptions = {
    controlOpened: { paddingTopLeft: [479, 0], paddingBottomRight: [0, 70] }, // permet de déplacer l'image de la parcelle avec un padding de 70px du bas et 0px depuis la droite (boutons de controle de droite) et un padding de 479px sur gauche pour éviter de cacher partiellement la parcelle par le panneau flottant
}

///////////////////////////////////////////////////////////////////////////
// Composant détenant l'affichage de notre carte.
///////////////////////////////////////////////////////////////////////////
class Maps extends React.Component { //@@a renommer en "MapsModulation" et 'MapsModulation.jsx'

    /**
     * constructeur
     */
    constructor(props) {
        super(props);

        this.state = {
            mapIsReady: false, // donnée en lien avec les composants enfants/soeurs
        }

        this.map = null; //object leaflet
        this.mapId = 'mainMapModulation'; //ID de la carte
        
        //↓↓ LAYERS - fond de carte ↓↓
        this.currentBaseLayerOnMap = null; // object Leaflet actuellement appliqué sur la carte
        this.streetBaseMaps = null; //object leaflet représentant le layer de base street
        this.satelliteBaseMaps = null; //object leaflet représentant le layer de base satellite
        this.satelliteOverlayMaps  = null; //object leaflet lors de l'ajout de layer - pas besoin d'utiliser les fonctionnalités State de React
        this.sentinelOverlayMaps = null; //object leaflet lors de l'ajout de layer - pas besoin d'utiliser les fonctionnalités State de React

        this.timeForOverlayMaps = new Date(); //date à utiliser pour obtenir une vue globale Sentinel...
        
        //↓↓ GROUPE LAYER - layers parcelles et images ↓↓
        this.parcelPolygonLayerGroup = null; //object leaflet qui sert à afficher les parcelles (layer)
        this.parcelImageLayerGroup = null; //object leaflet qui sert à afficher les images (ImageLayer)
       
        //↓↓ CONTEXTE - Dico de données de contexte liés seulement à la carte ↓↓
        this.parcelPolygonLayerDico = {}; // Dico contenant les couples parcelId - layer
        this.parcelImageLayerDico = {}; // Dico contenant les couples parcelId-{typeLayer,layer}

        //↓↓ BIND - fonctions déclarées ↓↓
        this.addNewParcelPolygonToMap = this.addNewParcelPolygonToMap.bind(this);
        this.removeParcelPolygonFromMap = this.removeParcelPolygonFromMap.bind(this);
        this.addNewParcelImageToMap = this.addNewParcelImageToMap.bind(this);
        this.removeParcelImageFromMap = this.removeParcelImageFromMap.bind(this);
        this.addFilteredParcelsToMap = this.addFilteredParcelsToMap.bind(this);
        this.selectParcel = this.selectParcel.bind(this);
        this.zoomToParcel = this.zoomToParcel.bind(this);
        this.addBaseLayerOnMap = this.addBaseLayerOnMap.bind(this);
    }

    /**
     * fonction cycle de vie 
     */
    componentDidMount() {
        const { parcelIdSelected, forewardLayerSelected, codeCountry } = this.props;

        // ↓ récupération du code pays pour zoomer sur le pays en question lors de la vue de la carte ↓
        try {
            this.latlng = country[codeCountry].geo.latlng;
            this.zoom = country[codeCountry].geo.zoom;
        } catch (error) {
            this.latlng = country['FR'].geo.latlng;
            this.zoom = country['FR'].geo.zoom;
        }

        // ↓ CREATION - carte munie de son paramètrage ↓
        this.map = L.map(
            this.mapId, 
            {
                crs: L.CRS.EPSG3857, //définition de la projection
                center: this.latlng,
                zoom: this.zoom,
                minZoom: 6, // en dessous de 7 => les layers Sentinel ne sont pas disponibles
                maxZoom: 18, // au dessus le rendu est flou
                zoomControl:false, // on n'affiche pas les boutons de zoom natifs
                attributionControl: false,
            }
        );
        this.map.doubleClickZoom.disable(); // ← suppression double click ←

        //↓↓ CREATION ET INSERTION des différents layers de fond de carte ↓↓
        this.streetBaseMaps = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {}); // layer - vue street
        this.satelliteBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Esri-visible' }); // layer - vue satellite
        this.placesBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}', {}); // layer - délimitations des villes (en combinaision avac la vue satellite)
        this.addBaseLayerOnMap();

        //↓↓ INSERTION du groupe de layer représentant les polygones ↓↓
        this.parcelPolygonLayerGroup = L.geoJSON();
        this.map.addLayer(this.parcelPolygonLayerGroup);

        //↓↓ INSERTION du groupe de layer représentant les images ↓↓
        this.parcelImageLayerGroup = L.layerGroup();
        if ((!forewardLayerSelected) || 
            (forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {
            this.map.addLayer(this.parcelImageLayerGroup);
        } else {//C'est que l'on est en vue globale !
            this.applyGlobalLayer(forewardLayerSelected);
        }

        //↓↓ INSERTION des parcelles et de leur image courante respective ↓↓
        this.addFilteredParcelsToMap();
        this.addAllCurrentImageToMap();

        //↓↓ SELECTION parcelle/parcellaire ↓↓
        if (parcelIdSelected > 0) {
            
            this.selectParcel(parcelIdSelected); // sélection
            this.zoomToParcel(parcelIdSelected); // + zoom
        }
       
        //↓↓ A partir de ce point, les composants enfants/soeurs peuvent être créés 'mounted' (certains utilisent la donnée this.map au chargement => il faut que cette donnée soit disponible) ↓↓
        this.setState({ mapIsReady: true });

    }

    /**
     * fonction permettant d'appliquer le layer fond de carte sur le carte suivant les données du settings
     */
    addBaseLayerOnMap() {
        const { baseLayerSelected } = this.props; 
        
        if (baseLayerSelected === ConstantsLayers.RoadBaseLayerName) {
            if (this.currentBaseLayerOnMap !== this.streetBaseMaps) {
                if (this.currentBaseLayerOnMap &&this.map.hasLayer(this.currentBaseLayerOnMap)) {
                    this.map.removeLayer(this.currentBaseLayerOnMap); // on retire le précèdent si ce n'est pas le même que celui demandé
                }
                if (this.placesBaseMaps && this.map.hasLayer(this.placesBaseMaps)) {
                    this.map.removeLayer(this.placesBaseMaps); // on retire également (si présent) le layer de délimitation de ville
                }
                this.map.addLayer(this.streetBaseMaps); // on ajoute le nouveau layer demandé
            }
            this.currentBaseLayerOnMap = this.streetBaseMaps; // on garde l'information pour la prochaine demande 
        }
        else {
            if (this.currentBaseLayerOnMap !== this.satelliteBaseMaps) {
                if (this.currentBaseLayerOnMap && this.map.hasLayer(this.currentBaseLayerOnMap)) {
                    this.map.removeLayer(this.currentBaseLayerOnMap); // on retire le précèdent si ce n'est pas le même que celui demandé
                }
                this.map.addLayer(this.satelliteBaseMaps); // on ajoute le nouveau layer demandé
                this.map.addLayer(this.placesBaseMaps);
            }
            this.currentBaseLayerOnMap = this.satelliteBaseMaps;
        }
    }

    /**
     * fonction permettant de sélectionner une parcelle (nouveau style + zoom sur la parcelle) - second paramètre: l'ancienne sélection (parcelId)
     */
    selectParcel(parcelId, previousSelectedParcelId = -1) {
        // on retire la sélection sur la précèdente parcelle sélectionnée

        let selectedParcel = this.parcelPolygonLayerDico[parcelId];
        this.removeParcelPolygonFromMap(previousSelectedParcelId);
        this.removeParcelImageFromMap(previousSelectedParcelId);
        
        this.props.parcelIdsSelected
            .forEach(currentParcelId => {
                let currentParcel = this.parcelPolygonLayerDico[currentParcelId]
                if (currentParcel !== selectedParcel) {
                    currentParcel.setStyle(stylePolygon.transparent);
                } else {
                    currentParcel.setStyle(stylePolygon.selected)
                }
            });
        
        let isPreviousSelectedPolygonLayerOnMap = ((previousSelectedParcelId > 0) && this.parcelPolygonLayerDico[previousSelectedParcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[previousSelectedParcelId]));
        if (isPreviousSelectedPolygonLayerOnMap)
            this.parcelPolygonLayerDico[previousSelectedParcelId].setStyle(stylePolygon.unselected);

    }

    /**
     * fonction permettant de zoomer sur une parcelle sélectionnée - VUE PARCELLE
     */
    zoomToParcel(parcelId) {
        const layerOfPcl = this.parcelPolygonLayerDico[parcelId];
        if (layerOfPcl && this.parcelPolygonLayerGroup.hasLayer(layerOfPcl)) {
            let bounds = layerOfPcl.getBounds();
            if (bounds && bounds.isValid()) {
                this.map.fitBounds(bounds, fitBoundsOptions.controlOpened);
            }
        }
    }

    /**
     * fonction permettant d'ajouter pour chaque parcelle l'image associé
     * l'image associée est en lien avec la propriété currentSatimageId d'une entité Parcel 
     */
    addAllCurrentImageToMap() {
        const { parcelDico, parcelIdSelected, satimageByParcelDico, forewardLayerSelected } = this.props;
        if (!parcelIdSelected || (parcelIdSelected <= 0)) return;

        if ((forewardLayerSelected) && 
            (forewardLayerSelected !== ConstantsLayers.VisibleParcelLayerName) && (forewardLayerSelected !== ConstantsLayers.NdviParcelLayerName)) return;
                // 1- récupération de la parcelle
                let parcel = parcelDico[parcelIdSelected];

                //↓↓ 2- je récupère l'image associée ↓↓ 
                const satimageId = parcel.currentSatimageId;
                const layerType = (parcel.currentLayerType) ? parcel.currentLayerType : ConstantsLayers.NdviParcelLayerName;
                let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelIdSelected, satimageId, layerType);

                //↓↓ 3- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
                if (imageURL) {
                    /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
                    let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
                    // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
                    let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
                    //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
                    const so = L.latLng(parcel.ymin, parcel.xmin);
                    const ne = L.latLng(parcel.ymax, parcel.xmax);
                    const bounds = L.latLngBounds(so, ne);
                    const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelIdSelected}`); // création layer image
                    if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelIdSelected}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
                    if (imageLayer) {
                        this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                        this.parcelImageLayerDico[parcelIdSelected] = imageLayer; // ajout dans la liste représentant le contexte du composant
                    }
                }

                //↓↓ 4- CAS: est-ce une NOUVELLE IMAGE que l'utilisateur n'a pas encore été eue/vue ?  ↓↓
                const isNewSatimage = SatimageHelper.isNewSatimage(satimageByParcelDico, parcelIdSelected, satimageId);
                if (isNewSatimage) {
                    const layer = this.parcelPolygonLayerDico[parcelIdSelected]; // un layer est il associé ?
                    if (layer) layer.setStyle(stylePolygon.newContour);
                    SatimageHelper.setIsNewSatimage(this.props.parcelDico, this.props.satimageByParcelDico, parcelIdSelected, false);
                    this.props.setValueNewImageShowedOnMap(true); // on met à jour la valeur qui exprime le fait qu'il y a des nouvelles images en cours de visualisation sur la carte (Mapinfo va lire cette valeur)
                }
        
    }

    /**
     * fonction permettant d'ajouter toutes les parcelles contenues dans la liste des parcelles filtrées dans la carte
     */
    addFilteredParcelsToMap() {
        const { parcelIdsSelected } = this.props;
        if (!parcelIdsSelected || (parcelIdsSelected.length <= 0)) return;
        
        // - pour chaque id de parcelle selectionés
        parcelIdsSelected.forEach( parcelId => this.addNewParcelPolygonToMap(parcelId) );

        // 6- une fois les ajouts terminés - on recentre la carte sur le parcellaire
        if (this.parcelPolygonLayerGroup) {
            let bounds = this.parcelPolygonLayerGroup.getBounds();
            if (bounds && bounds.isValid())
                this.map.fitBounds(bounds, fitBoundsOptions.controlOpened);
        }
    }

    /**
     * fonction permettant de forcer, pour chaque parcelle, 
     * le retour à leur dernière date d'image dispo et de leur appliquer la couche NDVI à la parcelle !
     */
    applyLastDateAndNdviLayerForAllParcels() {
        const { parcelDico, parcelDicoCounter } = this.props;
        if ((!parcelDico) || (parcelDicoCounter <= 0)) return;
        
        //vire les vues globales (Visible et Ndvi):
        if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
            this.map.removeLayer(this.satelliteOverlayMaps);
        }
        if (this.sentinelOverlayMaps && this.map.hasLayer(this.sentinelOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
            this.map.removeLayer(this.sentinelOverlayMaps);
        }
        
        const layerType = ConstantsLayers.NdviParcelLayerName;
        // - pour chaque id de parcelle
        for (const key in parcelDico) {
            const parcel = parcelDico[key];
            if (parcel) {
                const lastDate = (parcel.firstSatimageDate) ? parcel.firstSatimageDate : undefined; // donc dernière dispo !
                this.updateImageForParcel(parcel.id, undefined, lastDate, layerType, false); 
            }
        }
    }
    
    /**
     * fonction permettant de forcer une vue globale (Visible ou NDVI) sur la carte:
     */
    applyGlobalLayer(forewardLayertype, satimageDate = undefined) { //ICI, on a toujours une parcelle de séletionnée (pas forcément le cas dans la carto générale) !
        //Retrait du layer d'image de chaque parcelle => donc, on retire le groupe de layers directement !
        if (this.parcelImageLayerGroup && (this.map.hasLayer(this.parcelImageLayerGroup))) this.map.removeLayer(this.parcelImageLayerGroup);

        //Met en forme la date (si fournie):
        let timeValue = undefined;
        if (satimageDate) {
            if (satimageDate && (satimageDate instanceof Date)) {
                timeValue = satimageDate;
            } else if (satimageDate) {
                try {
                    timeValue = new Date(satimageDate);
                }
                catch(err) {}
            } else {
                timeValue = new Date();
            }
        }

        const { satimageByParcelDico, parcelIdSelected, satimageIdSelectedOfParcel } = this.props;

        let sourceProvider = ConstantsProvidersSatellite.SatImageSource.SentinelL2;

        let satImageSelected = satimageByParcelDico[parcelIdSelected];
        if (satImageSelected !== undefined && satImageSelected !== null) {
            if (satImageSelected.satimageDico !== undefined && satImageSelected.satimageDico !== null) {
                sourceProvider = satimageByParcelDico[parcelIdSelected].satimageDico[satimageIdSelectedOfParcel].sourceProvider;
            }
        }

        //gestion des vues globales:
        if (forewardLayertype === ConstantsLayers.VisibleGlobalLayerName) {
            //vire la vue globale Ndvi:
            if (this.sentinelOverlayMaps && this.map.hasLayer(this.sentinelOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                this.map.removeLayer(this.sentinelOverlayMaps);
            }

            //cré (si besoin) et affiche la vue globale Visible:
            if (!this.satelliteOverlayMaps) {
                this.createSatelliteLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.satelliteOverlayMaps);
                this.createSatelliteLayer(sourceProvider, satimageDate); // si non existant alos on le crée
            } else { //si le layer existe déjà, on actualise la date...
                if (timeValue) {
                    this.satelliteOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), });
                } else {
                    this.satelliteOverlayMaps.setParams({ time: undefined, });
                }
            }

            this.map.addLayer(this.satelliteOverlayMaps);
        } else if (forewardLayertype === ConstantsLayers.NdviGlobalLayerName) {
            //vire la vue globale Visible:
            if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                this.map.removeLayer(this.satelliteOverlayMaps);
            }

            //cré (si besoin) et affiche la vue globale Ndvi:
            if (!this.sentinelOverlayMaps) {
                this.createSatelliteNdviLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.sentinelOverlayMaps);
                this.createSatelliteNdviLayer(sourceProvider, satimageDate); // si non existant alos on le crée
            } else { //si le layer existe déjà, on actualise la date...
                if (timeValue) {
                    this.sentinelOverlayMaps.setParams({ time: format(timeValue,'yyyy-MM-dd'), });
                } else {
                    this.sentinelOverlayMaps.setParams({ time: undefined, });
                }
            }

            this.map.addLayer(this.sentinelOverlayMaps);
        }
        //else, ce n'est pas un layer 'global' !
    }
    
    /**
     * fonction permettant d'actualiser la date du cliché de la vue globale (Visible ou NDVI) sur la carte:
     */
    updateGlobalLayer(forewardLayertype, specificDate) { //ICI, on a toujours une parcelle de séletionnée (pas forcément le cas dans la carto générale) !
        //Retrait du layer d'image de chaque parcelle => donc, on retire le groupe de layers directement !
        if (this.parcelImageLayerGroup && (this.map.hasLayer(this.parcelImageLayerGroup))) this.map.removeLayer(this.parcelImageLayerGroup);

        //Met en forme la date (si fournie):
        let timeValue = undefined;
        if (specificDate) {
            if (specificDate && (specificDate instanceof Date)) {
                timeValue = specificDate;
            } else if (specificDate) {
                try {
                    timeValue = new Date(specificDate);
                }
                catch(err) {}
            } else {
                timeValue = new Date();
            }
        }

        const { satimageByParcelDico, parcelIdSelected, satimageIdSelectedOfParcel } = this.props;
        let sourceProvider = ConstantsProvidersSatellite.SatImageSource.SentinelL2;

        let satImageSelected = satimageByParcelDico[parcelIdSelected];
        if (satImageSelected !== undefined && satImageSelected !== null) {
            if (satImageSelected.satimageDico !== undefined && satImageSelected.satimageDico !== null) {
                sourceProvider = satimageByParcelDico[parcelIdSelected].satimageDico[satimageIdSelectedOfParcel].sourceProvider;
            }
        }

        //gestion des vues globales: 
        if (forewardLayertype === ConstantsLayers.VisibleGlobalLayerName) {
            //cré (si besoin MAIS normalement il est censé déjà exister) et actualise la vue globale Visible:
            if (!this.satelliteOverlayMaps) {
                this.createSatelliteLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteOverlayMaps);
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.satelliteOverlayMaps);
                this.createSatelliteLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteOverlayMaps);
            } else {
                // en rapport avec le message de chargement depuis le composant MapInfo
                if (this.props.loadingGlobalLayer) {
                    this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
                }

                this.satelliteOverlayMaps.on('load', () => {
                    if (this.props.globalLayerLoaded) {
                        this.props.globalLayerLoaded();
                    }

                    this.satelliteOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
                });

                if (timeValue)
                    this.satelliteOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), }); // '2020-02-07', }); // 
            }
        } else if (forewardLayertype === ConstantsLayers.NdviGlobalLayerName) {           
            //cré (si besoin MAIS normalement il est censé déjà exister) et actualise la vue globale Ndvi:
            if (!this.sentinelOverlayMaps) {
                this.createSatelliteNdviLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.sentinelOverlayMaps);
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.sentinelOverlayMaps);
                this.createSatelliteNdviLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.sentinelOverlayMaps);
            } else {             
                // en rapport avec le message de chargement depuis le composant MapInfo
                if (this.props.loadingGlobalLayer) {
                    this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
                }
                
                this.sentinelOverlayMaps.on('load', () => { 
                    if (this.props.globalLayerLoaded) {
                        this.props.globalLayerLoaded();
                    }
    
                    this.sentinelOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
                });

                if (timeValue)
                    this.sentinelOverlayMaps.setParams({ time: format(timeValue,'yyyy-MM-dd'), }); // '2020-02-07', }); // 
            }
        }
        //else, ce n'est pas un layer 'global' !
    }    

    ///////////////////////////////////////////////////////////////////////////
    // fonction appelée pour créer la couche satellite
    ///////////////////////////////////////////////////////////////////////////
    createSatelliteLayer(sourceProvider, specificDate = undefined) { // Création de l'objet leaflet représentant la couche Sentinel Global du visible !
        let timeValue = undefined;
        let withSpecificDate = false;
        if (specificDate && (specificDate instanceof Date)) {
            timeValue = specificDate;
            withSpecificDate = true;
        } else if (specificDate) {
            timeValue = new Date(specificDate);
            withSpecificDate = true;
        } else {
            timeValue = new Date();
        }

        let urlWms = ChoiceProvidersSatellite(sourceProvider);
        let optionsWms = {};
        if (withSpecificDate) {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 100, //100% pour être sûre d'avoir la carte à la date demandée !
                // showLogo: false,
                layers: "VISIBLE,DATE", 
                //time:"2017-03-28/2018-05-09",
                time: format(timeValue,'yyyy-MM-dd'),
                format: 'image/png', 
                transparent: false,
                showDates:true
            };
        } else  {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 80, 
                // showLogo: false,
                layers: "VISIBLE,DATE", 
                //time:"2017-03-28/2018-05-09",
                format: 'image/png', 
                transparent: false,
                showDates:true
            };
        }

        this.satelliteOverlayMaps = L.tileLayer.wms(urlWms, optionsWms);
        
        // event trigger
        this.satelliteOverlayMaps.on('add', () => {             
            // en rapport avec le message de chargement depuis le composant MapInfo
            if (this.props.loadingGlobalLayer) {
                this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
            }
            
            this.satelliteOverlayMaps.on('load', () => { 
                if (this.props.globalLayerLoaded) {
                    this.props.globalLayerLoaded();
                }

                this.satelliteOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
            });
        });

        this.satelliteOverlayMaps.on('remove', () => { /*RAS*/ }); //@@a voir si besoin d'y faire quelque chose...
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction appelée pour créer la couche satellite Sentinel ou Landsat
    ///////////////////////////////////////////////////////////////////////////
    createSatelliteNdviLayer(sourceProvider, specificDate = undefined) { // Création de l'objet leaflet représentant la couche Sentinel Global del'indice de végétation (NDVI) !
        let timeValue = undefined;
        let withSpecificDate = false;
        if (specificDate && (specificDate instanceof Date)) {
            timeValue = specificDate;
            withSpecificDate = true;
        } else if (specificDate) {
            timeValue = new Date(specificDate);
            withSpecificDate = true;
        } else {
            timeValue = new Date();
        }

        let urlWms = ChoiceProvidersSatellite(sourceProvider, true);
        let optionsWms = {};
        if (withSpecificDate) {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 100, //100% pour être sûre d'avoir la carte à la date demandée !
                // showLogo: false,
                layers: "INDICE-DE-VEGETATION,DATE", // ⚠️- DATE permet l'ajout de la date dans les différents clichés
                //time:"2017-03-28/2018-05-09",
                time: format(timeValue,'yyyy-MM-dd'),
                format: 'image/png', 
                transparent: false,
                showDates:true
            };
        } else  {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 80, 
                // showLogo: false,
                layers: "INDICE-DE-VEGETATION,DATE", // ⚠️- DATE permet l'ajout de la date dans les différents clichés
                //time:"2017-03-28/2018-05-09",
                format: 'image/png', 
                transparent: false,
                showDates:true
            };
        }

        this.sentinelOverlayMaps = L.tileLayer.wms(urlWms, optionsWms);
        
        // event trigger
        this.sentinelOverlayMaps.on('add', () => {             
            // en rapport avec le message de chargement depuis le composant MapInfo
            if (this.props.loadingGlobalLayer) {
                this.props.loadingGlobalLayer(); // => StringTranslate.indvegecharge
            }
            
            this.sentinelOverlayMaps.on('load', () => { 
                if (this.props.globalLayerLoaded) {
                    this.props.globalLayerLoaded();
                }

                this.sentinelOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
            });
        });

        this.sentinelOverlayMaps.on('remove', () => { /*RAS*/ }); //@@a voir si besoin d'y faire quelque chose...
    }

    /**
     * fonction permettant d'ajouter l'image d'une parcelle suivant l'id de cette parcelle et les données de satImage
     */
    addNewParcelImageToMap(parcelId) {
        if (parcelId <= 0) return;

        // 1- récupération de la parcelle
        const { parcelDico, satimageByParcelDico } = this.props;
        let parcel = parcelDico[parcelId];

        //↓↓ 2- je récupère l'image associée ↓↓ 
        const satimageId = parcel.currentSatimageId;
        const layerType = (parcel.currentLayerType) ? parcel.currentLayerType : ConstantsLayers.NdviParcelLayerName;
        let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType);

        //↓↓ 3- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
        if (imageURL) {
            /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
            let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
            // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
            //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
            const so = L.latLng(parcel.ymin, parcel.xmin);
            const ne = L.latLng(parcel.ymax, parcel.xmax);
            const bounds = L.latLngBounds(so, ne);
            const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
            if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
            if (imageLayer) {
                this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
            }
        }
    }

    /**
     * fonction permettant d'ajouter le contour d'une parcelle suivant l'id de cette parcelle
     */
    addNewParcelPolygonToMap(parcelId) {
        if (parcelId <= 0) return;
        const { parcelDico } = this.props;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        let isLayerOnMap = (this.parcelPolygonLayerDico[parcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[parcelId]));

        if (!isLayerOnMap) {
            // - récupération de la parcelle
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);

            // 1- convertion d'une parcelle vers une entité geojson
            let geojson = (parcel) ? converter.databaseParcelTogeojson(parcel) : null;

            // 2- création d'un layer
            let layer = (geojson) ? L.geoJson(geojson) : null;

            // 3- customisation du layer avant insertion dans la carte
            if (layer) {
                // layer.setStyle({ className: 'rm-parcel-layer-loading' }); //ajout du style qui donne un effet zoom...
                layer.setStyle(stylePolygon.fill_off); //ajout du style qui donne un effet zoom...
                
               
                // ↓ Cablage événement ↓
                layer.on('tooltipopen', (e) => {
                    const { forewardLayerSelected } = this.props;
                    if ((forewardLayerSelected === ConstantsLayers.NdviGlobalLayerName) || (forewardLayerSelected === ConstantsLayers.VisibleGlobalLayerName)) // non affichage de la tooltip dans ce cas d'un layer globale
                        if (e && e.target) e.target.closeTooltip();
                    
                    // e.target.openTooltip(L.latLngs);
                    setTimeout(() => (e && e.target) ? e.target.closeTooltip() : null, 1500); // fermeture de la tooltip au bout d'une 1,5 secondes
                });

                // ↓ ajout Tooltip lors du survol sur cette parcelle ↓
                layer.bindTooltip(this.getParcelPolygonTooltip(parcelId), { className: 'rm-parcelPolygon-tooltip', sticky: true });

                // 4- ajout du layer dans le groupe de layer
                this.parcelPolygonLayerGroup.addLayer(layer);

                // 5- ajout dans un dico référençant les layers par parcelId
                this.parcelPolygonLayerDico[parcelId] = layer;
            }
        };
    }

    /**
     * Fonction permetttant de renvoyer un contenu HTML (tooltip) affichant la date de l'image en cours de survol
     */
    getParcelPolygonTooltip = (parcelId) => (layer) => {
        let parcel = ParcelsHelper.selectParcelFromDicoById(this.props.parcelDico, parcelId);
        let currentSatimageDate = (parcel.currentSatimageDate) ? new Date(parcel.currentSatimageDate) : null;
        let date = (currentSatimageDate instanceof Date) ? DateHelper.formati18n(currentSatimageDate, 'P') : '';
        return date;
    }


    /**
     * fonction permettant de supprimer une parcelle de la carte (polygone + image)
     */
    removeParcelPolygonFromMap(parcelId) {
        if (parcelId <= 0) return;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        const layerParcelSelected = this.parcelPolygonLayerDico[parcelId];
        let isLayerOnMap = (layerParcelSelected && this.parcelPolygonLayerGroup.hasLayer(layerParcelSelected));

        if (isLayerOnMap) {
            // - si présent alors on le retire
            this.parcelPolygonLayerGroup.removeLayer(layerParcelSelected);
            delete this.parcelPolygonLayerDico[parcelId];
        }
    }

    /**
     * fonction permettant de supprimer le layer image d'une parcelle
     */
    removeParcelImageFromMap(parcelId) {
        if (parcelId <= 0) return;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        const layerParcelSelected = this.parcelImageLayerDico[parcelId];
        let isLayerOnMap = (layerParcelSelected && this.parcelImageLayerGroup.hasLayer(layerParcelSelected));

        if (isLayerOnMap) {
            // - si présent alors on le retire
            this.parcelImageLayerGroup.removeLayer(layerParcelSelected);
            delete this.parcelImageLayerDico[parcelId];
        }
    }


    /**
     * A commenter
     */
    updateImageForParcel(parcelId, idOfImag, dateOfImag, layerType, removeGlobalLayers = true) {
        const { satimageByParcelDico, parcelDico, } = this.props;
        if (!satimageByParcelDico) return;

        // Choix de la couche (Visible ou Ndvi):
        let newLayertype = layerType; //couche demandée OU celle définit actuellement pour la parcelle...
        if (!layerType) {
            newLayertype = lodashGet( parcelDico, `[${parcelId}].currentLayerType`, ConstantsLayers.NdviParcelLayerName);
        }

        //↓↓ 2- je récupère l'image associée (suivant la date) ↓↓ 
        let satimage = undefined;
        if (idOfImag && (idOfImag > 0)) { //id d'image spécifié !
            satimage = lodashGet( satimageByParcelDico, `[${parcelId}].satimageDico[${idOfImag}]`, null);
        } else if (dateOfImag) { //date demandée:
            let dateInstance = dateOfImag;
            if (!(dateOfImag instanceof Date)) {
                dateInstance = new Date(dateOfImag);
            }
            const satimagesDico = lodashGet( satimageByParcelDico, `[${parcelId}].satimageDico`, null);
            satimage = SatimageHelper.getSatimageFromListByDate(satimagesDico, dateInstance);
        } else { //sinon, la plus récente:
            const satimagesDico = lodashGet( satimageByParcelDico, `[${parcelId}].satimageDico`, null);
            satimage = SatimageHelper.searchMostRecentSatimageFromList(satimagesDico);
        }
        let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, (satimage) ? satimage.id : -1, newLayertype);

        //↓↓ 3- on vérifie que le groupe de layers d'image est ajouté, sinon on l'ajoute ↓↓                
        if (this.parcelImageLayerGroup && (!this.map.hasLayer(this.parcelImageLayerGroup))) this.map.addLayer(this.parcelImageLayerGroup);
        //↓↓ 3(bis)- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
        if (imageURL) {
            if (removeGlobalLayers) {
                //vire les vues globales (Visible et Ndvi):
                if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                    this.map.removeLayer(this.satelliteOverlayMaps);
                }
                if (this.sentinelOverlayMaps && this.map.hasLayer(this.sentinelOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                    this.map.removeLayer(this.sentinelOverlayMaps);
                }
            }

            /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
            let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
            // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
            //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
            const so = L.latLng(parcel.ymin, parcel.xmin);
            const ne = L.latLng(parcel.ymax, parcel.xmax);
            const bounds = L.latLngBounds(so, ne);
            const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
            if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);

            const oldLayerImage = this.parcelImageLayerDico[parcelId];
            if (oldLayerImage && this.parcelImageLayerGroup.hasLayer(oldLayerImage)) this.parcelImageLayerGroup.removeLayer(oldLayerImage); // retrait, au sein groupe de layer d'images, de l'ancienne image

            if (imageLayer) { 
                this.parcelImageLayerGroup.addLayer(imageLayer); // ajout, dans le groupe de layer d'images, de la nouvelle image
                this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
            }
        } //else 'image' peut valloir null si on lui demande d'afficher une vue Global !
    }

    /**
     * fonction cycle de vie 
     */
    componentDidUpdate(prevProps, prevState) {
        const { parcelDico, parcelIdFilteredList, parcelIdFilteredListCounter, lastAction, satimageByParcelDico,
            parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, baseLayerSelected, forewardLayerSelected, fitMapToBounds } = this.props;
        
        //↓↓ CAS - AJOUT NOUVELLES PARCELLES (Import, récupération asynchrone des parcelles) ↓↓
        if (((!prevProps.parcelIdFilteredList) || (prevProps.parcelIdFilteredListCounter <= 0)) && (parcelIdFilteredList && (parcelIdFilteredListCounter > 0))) {
            this.addFilteredParcelsToMap();
        }

        //↓↓ CAS - MODIFICATION LISTE DES PARCELLES  (suite à un ajout, suppression, filtre, ...) ↓↓
        if (prevProps.parcelIdFilteredList !== parcelIdFilteredList) {

            let differenceListToDelete = lodashDifference( prevProps.parcelIdFilteredList, parcelIdFilteredList ); //⚠️- SUPPRESSION - Pour tous parcelId non présents dans la nouvelle liste de parcelId
            differenceListToDelete.forEach( parcelId => { 
                this.removeParcelPolygonFromMap(parcelId);
                this.removeParcelImageFromMap(parcelId);
            });

            let differenceListToAdd = lodashDifference( parcelIdFilteredList, prevProps.parcelIdFilteredList) ;//⚠️- AJOUT - Pour tous parcelId non présents dans l'ancienne liste de parcelId
            differenceListToAdd.forEach( parcelId => {
                this.addNewParcelPolygonToMap(parcelId);
                this.addNewParcelImageToMap(parcelId);
            });

        }

        //↓↓ CAS - OPERATIONS faites sur les différents Reducer ↓↓
        if ((prevProps.lastAction !== lastAction) && lastAction) { // pour toute nouvelle action
            if (lastAction.reducer === ReducerType.PARCEL) { // reducer parcels
                if (lastAction.type === ChangeType.ADD) { // type ajout
                    //↓↓ 1-je récupère l'image suivant le type demandé (ndvi, visible) ↓↓ 
                    const satimageId = lastAction.params.satimageId;
                    const parcelId = lastAction.params.parcelId;
                    const layerType = lastAction.params.layerType;
                    let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType);

                    //↓↓ 2- on vérifie que le groupe de layers d'image est ajouté, sinon on l'ajoute ↓↓ 
                    if ((forewardLayerSelected) && (
                        (forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) && 
                        this.parcelImageLayerGroup && (!this.map.hasLayer(this.parcelImageLayerGroup))) this.map.addLayer(this.parcelImageLayerGroup);
                    //↓↓ 2(bis)- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
                    if (imageURL) {
                        /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
                        let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
                        // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
                        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
                        //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
                        const so = L.latLng(parcel.ymin, parcel.xmin);
                        const ne = L.latLng(parcel.ymax, parcel.xmax);
                        const bounds = L.latLngBounds(so, ne);
                        const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
                        if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
                        if (imageLayer) {
                            this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                            this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
                        }
                    }

                    //↓↓ 4- CAS: est-ce une NOUVELLE IMAGE que l'utilisateur n'a pas encore été eue/vue ?  ↓↓
                    const isNewSatimage = SatimageHelper.isNewSatimage(satimageByParcelDico, parcelId, satimageId);
                    if (isNewSatimage) {
                        const layer = this.parcelPolygonLayerDico[parcelId]; // un layer est il associé ?
                        if (layer) layer.setStyle(stylePolygon.newContour);
                        SatimageHelper.setIsNewSatimage(parcelDico, this.props.satimageByParcelDico, parcelId, false); // on remet la valeur a false
                        this.props.setValueNewImageShowedOnMap(true); // on met à jour la valeur qui exprime le fait qu'il y a des nouvelles images en cours de visualisation sur la carte (Mapinfo va lire cette valeur)
                    }
                }
            }
        }

        //↓↓ CAS - CHANGEMENT de parcelle sélectionnée ↓↓
        if (prevProps.parcelIdSelected !== parcelIdSelected) {
            // Soit SELECTION (dit 'vue parcelle') ; Soit PAS DE SELECTION (dit 'vue parcellaire')
            
            if (parcelIdSelected > 0) { //on passe de la vue parcellaire à la vue d'une parcelle OU changement de parcelle sélectionnée !
                this.selectParcel( parcelIdSelected, prevProps.parcelIdSelected);
                this.zoomToParcel( parcelIdSelected )
            } 
        } 

        //↓↓ CAS - CHANGEMENT d'image sur la parcelle couramment sélectionnée ↓↓
        if (parcelIdSelected && (parcelIdSelected > 0) && (
            (prevProps.satimageIdSelectedOfParcel !== satimageIdSelectedOfParcel) || (prevProps.satimageDateSelectedOfParcel !== satimageDateSelectedOfParcel))) {
            // Soit on est sur un layer 'à la parcelle' => on actualise l'image Visible ou Ndvi sur le dessin de celle-ci:
            // Soit on est sur une vue 'globale' => on actualise la date de la carte entière en respectant la date du cliché de cette parcelle:
            if ((forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {                
                // utilise l'id et la date de l'image sélectionnée, MAISle layer défini sur cette parcelle (pas le layer défini sur le panneau des layers)
                this.updateImageForParcel(parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, undefined);
            } else { 
                this.updateGlobalLayer(forewardLayerSelected, satimageDateSelectedOfParcel);
            }
        }
        //else //On ne peut pas changer de date si on n'a pas une parcelle de sélectionnée !

        //↓↓ CAS - CHANGEMENT LAYER FOND DE CARTE ↓↓
        if (prevProps.baseLayerSelected !== baseLayerSelected) {
            this.addBaseLayerOnMap()
        }
        
        //↓↓ CAS - CHANGEMENT LAYER visuel... ↓↓
        if (prevProps.forewardLayerSelected !== forewardLayerSelected) {
            // Soit on est sur un layer 'à la parcelle' => 
            //   cas1 - une parcelle est sélectionnée: on actualise l'image Visible ou Ndvi sur le dessin de celle-ci (cette parcelle).
            //   cas2 - aucune parcelle sélectionnée: on force la dernière image Ndvi sur chaque parcelle.
            if ((forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {                
                if (parcelIdSelected && (parcelIdSelected > 0)) {
                    // utilise l'id et la date de l'image sélectionnée, ET on peut utiliser le layer défini car = à celle sur la parcelle
                    this.updateImageForParcel(parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, forewardLayerSelected);
                } else {
                    this.applyLastDateAndNdviLayerForAllParcels();
                }
            }
            // Soit on est sur une vue 'globale' => on actualise la date de la carte entière en utilisant la date  
            //   cas1 - une parcelle est sélectionnée: date de l'image actuellement sélectionnée sur cette parcelle.
    
            else { //qu'il y ai une parcelle de sélectionnée ou pas !
                if (parcelIdSelected && (parcelIdSelected > 0)) {
                    this.applyGlobalLayer(forewardLayerSelected, satimageDateSelectedOfParcel);
                }
            }
        }

        //↓↓ CAS - EVENEMENT BOUTON - RECENTRAGE CARTE PARCELLE/PARECLLAIRE ↓↓
        if (prevProps.fitMapToBounds !== fitMapToBounds && parcelIdSelected > 0) {
            this.zoomToParcel(parcelIdSelected);
        }

    }
    
    /**
     * fonction cycle de vie 
     */
    render() {
        return (
            <React.Fragment>
                {/* ↓↓ composant carte ↓↓ */}
                <div className="mapMain" id={this.mapId}></div>
                {(this.state.mapIsReady) && <MapButtonsPaneModulation {...this.props} renderMapsCmp={this} />}
            </React.Fragment>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
        //Infos provenant du reducer 'clientUser':
        clientDatas: state.clientUserData.clientDatas,
        maxParcelTypoClient: (state.clientUserData.clientDatas && (state.clientUserData.clientDatas.maxParcelTypoClient > 0)) ? state.clientUserData.clientDatas.maxParcelTypoClient : Number.MAX_SAFE_INTEGER,

        //Infos provenant du reducer 'parcel':
        parcelDico: state.parcelsData.parcelDico,
        lastAction: state.parcelsData.lastParcelDicoAction,
        parcelIdFilteredList: state.parcelsData.parcelIdFilteredList,
        parcelIdFilteredListCounter: state.parcelsData.parcelIdFilteredListCounter,
        parcelDicoCounter: state.parcelsData.parcelDicoCounter,

        //Infos provenant du reducer 'satimage':
        satimageByParcelDico: state.satimageData.satimagesByParcelDico,
        
        //Infos provenant du reducer 'contextApp':
        parcelIdSelected: state.contextAppData.parcelIdSelected,
        satimageIdSelectedOfParcel: state.contextAppData.satimageIdSelectedOfParcel,
        satimageDateSelectedOfParcel: state.contextAppData.satimageDateSelectedOfParcel, 
        providerSrcImageSelectedOfParcel: state.contextAppData.providerSrcImageSelectedOfParcel,
        showInviteToPremium: (state && state.contextAppData) ? state.contextAppData.showInviteToPremium : false, 
        fitMapToBounds: state.contextAppData.fitMapToBounds,

        //Infos provenant du reducer 'modulation':
        baseLayerSelected: (state && state.modulationsData) ? state.modulationsData.baseLayerSelectedForModulation : ConstantsLayers.VisibleBaseLayerName,
        forewardLayerSelected: (state && state.modulationsData) ? state.modulationsData.forewardLayerSelectedForModulation : ConstantsLayers.NdviParcelLayerName,
        parcelIdsSelected: state.modulationsData.parcelIdsSelected,

        //Infos provenant du reducer 'settings':
        language: state.settingsData.settings.language,
        codeCountry: state.settingsData.settings.codeCountry,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    loadingGlobalLayer: () => dispatch(ActionLoadingGlobalLayer()),
    globalLayerLoaded: () => dispatch(ActionGlobalLayerLoaded()),
    setValueNewImageShowedOnMap: (bool) => dispatch( ActionSetValueNewImageShowedOnMap(bool) ),
    fitMapToBounds: (parcelId) => dispatch(ActionFitMapToBounds(parcelId)),
})

export default connect( mapStateToProps, mapDispatchToProps )(Maps);