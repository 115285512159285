import ConfigApi from "./configApi";

const BadgeSentinel = "S"; //Représente les images issues de Sentinel
const BadgeLandSat = "L"; //Représente les images issues de LandSat
const BadgeModelisation = "M"; //Représsente les images issues de la modélisation
const BadgeRepaired = "R"; //Repreésente les images issues d'une réparation

const SatImageSourceProviderUnknown = 0;
const SatImageSourceProviderSentinelL2 = 1;
const SatImageSourceProviderLandSat8 = 2;
const SatImageSourceProviderModelisation = 3;
const SatImageSourceProviderRepaired = 4;

export const SatImageSourceProvider = {
    //origine de l'image satellite inconnue
    Unkown: 0,
    //image satellite est issue du système Sentinel L2A.
    SentinelL2: 1,
    //image satellite est issue du système LandSat 8.
    LandSat8: 2,
    //image issues de la modélisation
    Modelisation: 3,
    // image issues d'une réparation d'une image réelle
    Repaired: 4
};

export const ConstantsProvidersSatellite = {
    BadgeSentinel: BadgeSentinel,
    BadgeLandSat8: BadgeLandSat,
    BadgeModelisation: BadgeModelisation,
    BadgeRepaired: BadgeRepaired,

    SatImageSource: {
        Unkown: SatImageSourceProviderUnknown,
        SentinelL2: SatImageSourceProviderSentinelL2,
        LandSat8: SatImageSourceProviderLandSat8,
        Modelisation: SatImageSourceProviderModelisation,
        Repaired: SatImageSourceProviderRepaired,
    }
}

// Méthode permettant de centralisé le choix de l'image qui sera affiché pour le visuel des nuages sur les différentes cartes
export function ChoiceProvidersSatellite(sourceProvider, isNdvi = false) {
    let urlWms;
    if (isNdvi === false) {
        switch (sourceProvider) {   
            case ConstantsProvidersSatellite.SatImageSource.LandSat8:
                urlWms = ConfigApi.ConstAndDefault.UrlLandsatVisibleWms;
                break;

            case ConstantsProvidersSatellite.SatImageSource.Modelisation: //=> on prend la valeur de sentinel
            case ConstantsProvidersSatellite.SatImageSource.Repaired: //=> on prend la valeur de sentinel
                urlWms = ConfigApi.ConstAndDefault.UrlSentinelVisibleWms;
                break;

            // Par défaut on prend la valeur de sentinel
            default:
                urlWms = ConfigApi.ConstAndDefault.UrlSentinelVisibleWms;
                break;
        }
    } else {
        switch (sourceProvider) {
            case ConstantsProvidersSatellite.SatImageSource.LandSat8:
                urlWms = ConfigApi.ConstAndDefault.UrlLandsatNdviWms;
                break;

            case ConstantsProvidersSatellite.SatImageSource.Modelisation: //=> on prend la valeur de sentinel
            case ConstantsProvidersSatellite.SatImageSource.Repaired: //=> on prend la valeur de sentinel
                urlWms = ConfigApi.ConstAndDefault.UrlSentinelNdviWms;
                break;

            // Par défaut on prend la valeur de sentinel
            default:
                urlWms = ConfigApi.ConstAndDefault.UrlSentinelNdviWms;
                break;
        }
    }
    

    return urlWms;
}

export default ConstantsProvidersSatellite;