import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { Typography, Box, /*Link,*/ Grid } from "@mui/material";
import StringTranslate from "../../assets/i18n/stringLanguage";
import PlanCard from '../stripe/planCard';
import ConstantsContact from '../../utils/constantsContact.js';
import { ActionSelectPlan, ActionGetPlansSubscriptions, ActionAddDiscountedPlan} from "../../redux/actions/clientUser";


import '../../assets/css/ssp-stripe_subscribe_process.css'
import '../../assets/css/profil.css'
import { TypoClientEnum } from "../../redux/actions/contextApp";


class AbonnementFormule extends React.Component {
    constructor(props) {
        super(props);
        this.hasSubscription = false;
        this.checkHasSubscription = this.checkHasSubscription.bind(this);

    }

    /* fonction - présence d'au moins une souscription à un plan ?  */
    checkHasSubscription() {
        if (this.props.planSubscriptionDico) {
            Object.values(this.props.planSubscriptionDico).forEach( planSubscription => {
                if (planSubscription && planSubscription.subscription && planSubscription.subscription.id)
                    this.hasSubscription = true;
            });
        }
    }

    componentDidUpdate(prevProps) {
        // ↓ CAS: CHARGEMENT APPLICATION - Récupération des plans munis de leur souscription si on vient juste de récupérer les données du client (clientId notamment) ↓
        if ((prevProps.clientId !== this.props.clientId) && (!this.props.planSubscriptionDico || (Object.values(this.props.planSubscriptionDico).length <= 0)))
            this.props.getPlansSubscriptions();
        
        if (prevProps.planSubscriptionDico !== this.props.planSubscriptionDico) {
            this.checkHasSubscription();
        }
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        // ↓ Récupération des plans munis de leur souscription (si cela n'a pas déjà été demandé) ↓
        if (!this.props.planSubscriptionDico || (Object.values(this.props.planSubscriptionDico).length <= 0))
            this.props.getPlansSubscriptions();
    }

    renderFormules = () => {
        const { 
            hasSubscription, planSubscriptionDico
        } = this.props;
        
        let cultureValue = StringTranslate.getLanguage();

        return (
            <Grid container spacing={2}>
                {/* ↓ partie - AFFICHAGE des plans ↓ */}
                <Grid item xs={12} container spacing={2}>
                    {(planSubscriptionDico) && Object.values(planSubscriptionDico).map((planDatas, index, array) => (
                        (!planDatas.active) ? <div/> : 
                            <PlanCard 
                                key={index}
                                planDatas={planDatas}
                                length={array.length}
                                {...this.props}
                            />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Box 
                        id='div_stripe_plan_view'
                        sx={{ display:"block" }}
                    >
                        {/* ↓ partie - AFFICHAGE des liens de CLUF et CGV ↓ */}
                        <Box sx={{ display:"block" }}>
                            {/* <Link target='_blank' href={ConstantsContact.UrlPGD}>{StringTranslate.pgdlink}</Link> */}
                            <Typography
                                variant="subtitle1" classes={{ subtitle1:'ssp-plan-view-typo' }} sx={{ cursor:"pointer" }}
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={ConstantsContact.UrlCGV}>
                                {StringTranslate.cgvlink}
                            </Typography>
                        </Box>
                        <Box sx={{ display:"block", paddingBottom: '5px' }}>
                            {/* <Link target='_blank' href={ConstantsContact.UrlCGV}>{StringTranslate.cgvlink}</Link> */}
                            <Typography
                                variant="subtitle1" classes={{ subtitle1:'ssp-plan-view-typo' }} sx={{ cursor:"pointer" }}
                                component="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={ConstantsContact.UrlPGD}>
                                {StringTranslate.pgdlink}
                            </Typography>
                        </Box>

                        {/* ↓ partie - AFFICHAGE le message demandant la sélection d'un plan pour pouvoir poursuivre le processus d'abonnement ↓ */}
                        
                        {(hasSubscription) && (<div className="ssp-plan-view-select-planStripe">
                            <Typography variant='caption' color='error'>{StringTranslate.autounsubcribePlanMessageText}</Typography>
                        </div>)}
                        <div>
                        {/* ↓ partie - AFFICHAGE d'une information concernant le désabonnement ↓ */}
                        {(hasSubscription) && <Typography variant="subtitle1" classes={{ subtitle1:'ssp-plan-view-typo' }}>
                            { `${StringTranslate.desabo} ${ConstantsContact.EmailContact}`}
                            <br />
                            {(cultureValue && (cultureValue === 'es-ES')) ? (
                                <a href={ConstantsContact.UrlContactES} target="_blank" rel="noopener noreferrer" onClick={() => (window.dataLayer) ? window.dataLayer.push({'event': 'suiviBouton', 'action': 'unsuscribe'}) : null}>{ConstantsContact.UrlRoot}</a>
                            ) : (
                                <a href={ConstantsContact.UrlContact} target="_blank" rel="noopener noreferrer" onClick={() => (window.dataLayer) ? window.dataLayer.push({'event': 'suiviBouton', 'action': 'unsuscribe'}) : null}>{ConstantsContact.UrlRoot}</a>
                            )}
                        </Typography>}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    render() {
        return (

            <Box sx={{ margin:"12px" }} >
                {this.renderFormules()}
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    clientUserData: lodashGet(state, 'clientUserData', {}),
    planSubscriptionDico: lodashGet(state, 'clientUserData.planSubscriptionDico', {}),
    hasSubscription: ((lodashGet(state, 'clientUserData.clientDatas.enumTypoClient', -1) !== TypoClientEnum.Freemium) && (lodashGet(state, 'clientUserData.clientDatas.enumTypoClient', -1) !== TypoClientEnum.Explorer )),
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1)
})

const mapDispatchToProps = dispatch => ({
    selectPlan: (planId, discountPlanId) => dispatch(ActionSelectPlan(planId, discountPlanId)),
    getPlansSubscriptions: () => dispatch(ActionGetPlansSubscriptions()),
    saveDiscountedPlan: (discountPlan, planId) => dispatch(ActionAddDiscountedPlan(discountPlan, planId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbonnementFormule);