import lodashGet from 'lodash/get';
import { parcelWebApiProvider } from '../../utils/webApiProvider.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { ActionInitFarmsAndCulturesOfParcels, 
    ActionUnselectParcel, 
    ActionGoToMapAndSelectParcel, 
    ActionForceInviteToPremium, 
    ActionResetAndApplyParcelFilter,
    ReasonInviteToPremium,
    ActionShowInviteToPremium,
    TypoClientEnum,
    ActionUpdateParcelContext} from './contextApp.js';
import { 
    ActionDeleteSatimagesAfterParcelsDeleted, 
    ActionAskFirstHistoForAllParcel, 
    ActionAskFirstHistoForSelectedParcel,
    ActionDeleteSatimageByParcelIdList } from './satImage.js';//@@A renommer en satimage !
import { ActionResetSettingsAsk, ActionSaveSettingsAsk, ActionErrorLoadSettings } from './settings.js';
import { ActionDeleteAllLinkedObservationDatas, ActionDeleteObservationByParcelIdList, ActionAddObservationDico } from './observations.js';
import { ActionAddCounterCompletedDidacticielFirstParcel } from './didacticiel.js';
import { ActionUpdateTypoClient } from './clientUser.js';
import { ActionCloseParcelEdit } from './contextApp.js';
import converter from '../../utils/converter.js';
import DetailsConverter from '../../utils/detailsConverter.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import sendError from '../../utils/errorService.js';
//import Parcel from '../../models/parcel.js';


//RQ Générale: Même si on va charger le parcellaire dans un seul appel à la Web API (lors de la recherche de l'email client), 
//  on aura sans nulle doute besoin d'appeler les actions définies ici ; 
//  -> les actions simples pour notifier le reducer associé (et donc la partie du store Redux stockant les infos du parcellaire).
//  -> l'actions complète (processus d'intérrogation de la Web API) pour du fonctionnel tel que la suppression du parcellaire.

/* actions */
const LOADING_PARCELS = 'LOADING_PARCELS';
const DELETING_PARCELS = 'DELETING_PARCELS';

const LOAD_PARCELS_WITH_CURRENT_SATIMAGE = 'LOAD_PARCELS_WITH_CURRENT_SATIMAGE';
const LOAD_PARCELS = 'LOAD_PARCELS';
const DELETE_PARCELS = 'DELETE_PARCELS';
const DELETE_PARCEL_ID_LIST = 'DELETE_PARCEL_ID_LIST';
const DELETE_PARCEL_ARCHIVED_ID_LIST = 'DELETE_PARCEL_ARCHIVED_ID_LIST';
const ERROR_LOAD_PARCELS = 'ERROR_LOAD_PARCELS';
const UPLOADED_PARCELS = 'UPLOADED_PARCELS';

const LOADING_PARCELS_ARCHIVED_FOR_FERTILIZER = 'LOADING_PARCELS_ARCHIVED_FOR_FERTILIZER';
const LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER = 'LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER';
const ERROR_LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER = 'ERROR_LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER';

const SAVING_DRAWN_PARCEL = 'SAVING_DRAWN_PARCEL';
const ADD_DRAWN_PARCEL = 'ADD_DRAWN_PARCEL';
const DRAWING_PARCEL = 'DRAWING_PARCEL';

const UPDATE_PARCELID_FILTERED_LIST = 'UPDATE_PARCELID_FILTERED_LIST'; //lorsque le filtre sur les parcelles change....

const SET_SATIMAGEID_TO_PARCEL = 'SET_SATIMAGEID_TO_PARCEL';
const CHANGE_SATIMAGEID_TO_PARCEL = 'CHANGE_SATIMAGEID_TO_PARCEL';

const SELECT_LAYER_PARCEL = 'SELECT_LAYER_PARCEL';
const SELECT_LAYER_PARCEL_FOR_MODULATION = 'SELECT_LAYER_PARCEL_FOR_MODULATION';
const SELECT_LAYER_PARCEL_FOR_BIOMASS = 'SELECT_LAYER_PARCEL_FOR_BIOMASS';
const SELECT_LAYER_PARCEL_FOR_FERTILIZER = 'SELECT_LAYER_PARCEL_FOR_FERTILIZER';

const UPDATING_PARCEL_NAME = 'UPDATING_PARCEL_NAME';
const UPDATE_PARCEL_NAME = 'UPDATE_PARCEL_NAME';
const ERROR_UPDATE_PARCEL_NAME = 'ERROR_UPDATE_PARCEL_NAME';

const UPDATE_PARCEL_DICO = 'UPDATE_PARCEL_DICO';
const UPDATING_PARCEL = 'UPDATING_PARCEL';
const UPDATE_PARCEL = 'UPDATE_PARCEL';
const UPDATE_PARCELS_AFTER_BIOMASSES_DELETED = 'UPDATE_PARCELS_AFTER_BIOMASSES_DELETED';
const UPDATE_PARCELS_AFTER_FERTILIZERS_DELETED = 'UPDATE_PARCELS_AFTER_FERTILIZERS_DELETED';

const UPDATE_PARCEL_AFTER_BIOMASS_ADDED = 'UPDATE_PARCEL_AFTER_BIOMASS_ADDED';
const ERROR_UPDATE_PARCEL = 'ERROR_UPDATE_PARCEL';
const UPDATE_PARCEL_HAS_MODULATION = "UPDATE_PARCEL_HAS_MODULATION";

const ASK_TOTAL_CURRENT_MONTH_HISTO_OF_PARCEL = 'ASK_TOTAL_CURRENT_MONTH_HISTO_OF_PARCEL';

const SET_PARCEL_TAB = "SET_PARCEL_TAB";
export const UPDATE_PARCEL_CULTURE = 'UPDATE_PARCEL_CULTURE';
export const UPDATE_PARCEL_LOCALISATION = 'UPDATE_PARCEL_LOCALISATION';
export const UPDATE_PARCEL_CROPYEAR = 'UPDATE_PARCEL_CROPYEAR';
export const UPDATE_PARCEL_CROP = 'UPDATE_PARCEL_CROP';
export const UPDATE_PARCEL_VARIETY = 'UPDATE_PARCEL_VARIETY';
export const UPDATE_PARCEL_SOWING_DATE = 'UPDATE_PARCEL_SOWING_DATE';

/* Enuméré des actions pour la gestion des parcelles: */
export const ActionTypeParcels = {
    LoadingParcels: LOADING_PARCELS,
    DeletingParcels: DELETING_PARCELS,
    loadParcelsWithCurrentSatimage: LOAD_PARCELS_WITH_CURRENT_SATIMAGE,
    LoadParcels: LOAD_PARCELS,
    LoadingParcelsArchivedForFertilizer: LOADING_PARCELS_ARCHIVED_FOR_FERTILIZER,
    LoadParcelsArchivedForFertilizer: LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER,
    ErrorLoadParcelsArchivedForFertilizer: ERROR_LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER,
    UploadedParcels: UPLOADED_PARCELS,
    ErrorLoadParcels: ERROR_LOAD_PARCELS,
    DeleteParcels: DELETE_PARCELS,
    SavingDrawnParcel: SAVING_DRAWN_PARCEL,
    AddDrawnParcel: ADD_DRAWN_PARCEL,
    DrawingParcel: DRAWING_PARCEL,
    UpdateParcelIdFiltered: UPDATE_PARCELID_FILTERED_LIST,
    SetSatimageIdToParcel: SET_SATIMAGEID_TO_PARCEL,
    UpdatingParcelName: UPDATING_PARCEL_NAME,
    UpdateParcelName: UPDATE_PARCEL_NAME,
    UpdateParcelAfterBiomassAdded: UPDATE_PARCEL_AFTER_BIOMASS_ADDED,
    ErrorUpdateParcelName: ERROR_UPDATE_PARCEL_NAME,
    UpdateParcelDico: UPDATE_PARCEL_DICO,
    UpdatingParcel: UPDATING_PARCEL,
    UpdateParcel: UPDATE_PARCEL,
    UpdateParcelsAfterBiomassesDeleted: UPDATE_PARCELS_AFTER_BIOMASSES_DELETED,
    UpdateParcelsAfterFertilizersDeleted: UPDATE_PARCELS_AFTER_FERTILIZERS_DELETED,
    ErrorUpdateParcel: ERROR_UPDATE_PARCEL,
    ChangeSatimageIdToParcel: CHANGE_SATIMAGEID_TO_PARCEL,
    SelectLayerOfParcel: SELECT_LAYER_PARCEL,
    SelectLayerOfParcelForModulation: SELECT_LAYER_PARCEL_FOR_MODULATION,
    SelectLayerOfParcelForBiomass: SELECT_LAYER_PARCEL_FOR_BIOMASS,
    SelectLayerOfParcelForFertilizer: SELECT_LAYER_PARCEL_FOR_FERTILIZER,
    DeleteParcelIdList: DELETE_PARCEL_ID_LIST,
    DeleteParcelArchivedIdList: DELETE_PARCEL_ARCHIVED_ID_LIST,
    askTotalCurrentMonthHistoOfParcel: ASK_TOTAL_CURRENT_MONTH_HISTO_OF_PARCEL,
    updateParcelHasModulation: UPDATE_PARCEL_HAS_MODULATION,

    setParcelTab: SET_PARCEL_TAB,
    UpdateParcelCulture: UPDATE_PARCEL_CULTURE,
    UpdateParcelLocalisation: UPDATE_PARCEL_LOCALISATION,
    UpdateParcelCropYear: UPDATE_PARCEL_CROPYEAR,
    UpdateParcelCrop: UPDATE_PARCEL_CROP,
    UpdateParcelVariety: UPDATE_PARCEL_VARIETY,
    UpdateParcelSowingDate: UPDATE_PARCEL_SOWING_DATE,
}

export const ParcelOriginEnum = {
    //Unknown: 0
    Imported: 1,
    Drawn: 2,
    Other: 3
} 


/**
 * @enum {number}
 */
export const ModelisationModelEnum = {
    Unknown: 0,
    Ble: 1,
    Colza: 2,
    Orge: 3,
}

/* Actions creator */
export function ActionLoadingParcels(loadingImportedOrDrawnParcelsValue) {
    return {
        type: LOADING_PARCELS,
        loadingImportedOrDrawnParcels: loadingImportedOrDrawnParcelsValue
    };
}

export function ActionUploadedParcels() {
    return {
        type: UPLOADED_PARCELS,
    };
}

export function ActionDeletingParcels() {
    return {
        type: DELETING_PARCELS,
    };
}

function ActionParcelsDeleted() {
    return {
        type: DELETE_PARCELS
    };
}

export function ActionErrorLoadParcels(errorValue) {
    return {
        type: ERROR_LOAD_PARCELS,
        errorMessage: errorValue,
        deleting: false,
    };
}

export function ActionErrorLoadParcelsArchivedForFertilizer(errorValue) {
    return {
        type: ERROR_LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER,
        errorMessage: errorValue,
    };
}

export function ActionInitParcelArchivedForFertilizerDico(parcelArchivedForFertilizerDicoValue) {
    return {
        type: LOAD_PARCELS_ARCHIVED_FOR_FERTILIZER,
        parcelArchivedForFertilizerDico: parcelArchivedForFertilizerDicoValue,
    };
}

export function ActionInitParcelDico(parcelDicoValue, parcelIdFilteredListValue) {
    return {
        type: LOAD_PARCELS,
        parcelDico: parcelDicoValue,
        parcelIdFilteredList: parcelIdFilteredListValue,
    };
}

function ActionLoadingParcelArchivedForFertilizerDico() {
    return {
        type: LOADING_PARCELS_ARCHIVED_FOR_FERTILIZER,
    };
}

function ActionInitParcelDicoWithCurrentSatImage(parcelDicoCounter, parcelIdFilteredList, lastSatimageByParcelIdDico) {
    return {
        type: LOAD_PARCELS_WITH_CURRENT_SATIMAGE,
        parcelDico: parcelDicoCounter,
        parcelIdFilteredList: parcelIdFilteredList,
        lastSatimageByParcelIdDico: lastSatimageByParcelIdDico
    };
}

export function ActionAskTotalCurrentMonthHistoOfParcel(parcelIdValue) {
    return {
        type: ASK_TOTAL_CURRENT_MONTH_HISTO_OF_PARCEL,
        parcelId: parcelIdValue,
        alreadyAskAllImagesForThisMonth: true
    }
}

export function ActionUpdateParcelIdFilteredList(parcelIdFilteredList) {
    return {
        type: UPDATE_PARCELID_FILTERED_LIST,
        parcelIdFilteredList: parcelIdFilteredList,
    };
}

export function ActionSavingDrawnParcel() {
   return {
       type: SAVING_DRAWN_PARCEL,
   };
}

export function ActionDrawingParcel(isDrawingParcelValue) {
    return {
        type: DRAWING_PARCEL,
        isDrawingParcel: isDrawingParcelValue
    };
}

/* Actions creator */
export const ActionDeleteParcelIdList = (parcelIdListToDelete = []) => ({
    type: DELETE_PARCEL_ID_LIST,
    parcelIdList: parcelIdListToDelete,
    parcelIdListCounter: parcelIdListToDelete.length,
})

export const ActionDeleteParcelArchivedIdList = (parcelIdListToDelete = []) => ({
    type: DELETE_PARCEL_ARCHIVED_ID_LIST,
    parcelIdList: parcelIdListToDelete,
    parcelIdListCounter: parcelIdListToDelete.length,
})

export function ActionAddDrawnParcel(parcel, parcelId) {    
    return function(dispatch, getState) {
        // Signale la présence de cette nouvelle parcelle (dessinée):
        dispatch({
            type: ADD_DRAWN_PARCEL,
            parcel: parcel,
            parcelId: parcelId,
        });
        
        const currentStoreState = getState();
        const parcelDicoValue = lodashGet(currentStoreState, `parcelsData.parcelDico`, undefined);
        //Puis, il faud prévenir le ContextApp pour injecter (si pas déjà présent) la culture de cette nouvelle parcelle !
        // =>revient à ré-initialiser la liste des exploitations et des cultures identifiées dans les parcelles:
        dispatch( ActionInitFarmsAndCulturesOfParcels(parcelDicoValue) );
    }
}

/* fonction permettant d'affecter la première entité Satimage à l'objet parcel */
export function ActionSetFirstSatimageIdToParcel(parcelIdValue, satimageIdValue, satimageDateValue = undefined) {
    return function(dispatch, getState) {
        const currentStoreState = getState();
        let newSatimageDateValue = satimageDateValue;
        
        if (!newSatimageDateValue) {
            // 1- récupération de la liste des id de parcelle 
            const satimageDatasParcel = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}]`, undefined); // type 'SatimageDatasParcel' !
            // 2- Recherche de la Satimage d'après son ID, pour la parcelle visée:
            const satimageFund = lodashGet(satimageDatasParcel, `satimageDico[${satimageIdValue}]`, undefined);
            if (satimageFund) {
                newSatimageDateValue = new Date(satimageFund.date);
            }
        } else if (!(newSatimageDateValue instanceof Date)) {
            newSatimageDateValue = new Date(newSatimageDateValue);
        }

        return dispatch({
            type: SET_SATIMAGEID_TO_PARCEL,
            parcelId: parcelIdValue,
            satimageId: satimageIdValue,
            satimageDate: newSatimageDateValue, 
        });
    }
}

/* fonction permettant d'affecter une entité Satimage à l'objet parcel, par la fourniture de l'identifiant d'image souhaitée */
export function ActionChangeSatimageIdToParcel(parcelIdValue, satimageIdValue, satimageDateValue = undefined) {
    return function(dispatch, getState) {
        const currentStoreState = getState();
        let newSatimageDateValue = satimageDateValue;
        
        if (!newSatimageDateValue) {
            // 1- récupération de la liste des id de parcelle 
            const satimageDatasParcel = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}]`, undefined); // type 'SatimageDatasParcel' !
            // 2- Recherche de la Satimage d'après son ID, pour la parcelle visée:
            const satimageFund = lodashGet(satimageDatasParcel, `satimageDico[${satimageIdValue}]`, undefined);
            if (satimageFund) {
                newSatimageDateValue = new Date(satimageFund.date);
            }
        } else if (!(newSatimageDateValue instanceof Date)) {
            newSatimageDateValue = new Date(newSatimageDateValue);
        }

        return dispatch({
            type: CHANGE_SATIMAGEID_TO_PARCEL,
            parcelId: parcelIdValue,
            satimageId: satimageIdValue,
            satimageDate: newSatimageDateValue, 
        });
    }
}

export function ActionSelectLayerOfParcel(layertypeValue, parcelIdValue = -1) { // Valeurs possibles: ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function(dispatch, getState) { 
        let currentParcelIdSelected = parcelIdValue;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();
            
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet( currentStoreState, 'contextAppData.parcelIdSelected', -1);
        }

        return dispatch({
            type: SELECT_LAYER_PARCEL,
            layertype: layertypeValue,
            parcelId: currentParcelIdSelected, 
        });
    }
}

export function ActionSelectLayerOfParcelForModulation(layertypeValue, parcelIdValue = -1) { // Valeurs possibles: ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function(dispatch, getState) { 
        let currentParcelIdSelected = parcelIdValue;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();
            
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet( currentStoreState, 'contextAppData.parcelIdSelected', -1);
        }

        return dispatch({
            type: SELECT_LAYER_PARCEL_FOR_MODULATION,
            layertypeForModulation: layertypeValue,
            parcelId: currentParcelIdSelected, 
        });
    }
}

export function ActionSelectLayerOfParcelForBiomass(layertypeValue, parcelIdValue = -1) { // Valeurs possibles: ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function(dispatch, getState) { 
        let currentParcelIdSelected = parcelIdValue;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();
            
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet( currentStoreState, 'contextAppData.parcelIdSelected', -1);
        }

        return dispatch({
            type: SELECT_LAYER_PARCEL_FOR_BIOMASS,
            layertypeForModulation: layertypeValue,
            parcelId: currentParcelIdSelected, 
        });
    }
}

export function ActionSelectLayerOfParcelForFertilizer(layertypeValue, parcelIdValue = -1) { // Valeurs possibles: ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function(dispatch, getState) { 
        let currentParcelIdSelected = parcelIdValue;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();
            
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet( currentStoreState, 'contextAppData.parcelIdSelected', -1);
        }

        return dispatch({
            type: SELECT_LAYER_PARCEL_FOR_FERTILIZER,
            layertypeForFertilizer: layertypeValue,
            parcelId: currentParcelIdSelected, 
        });
    }
}

export function ActionSelectLayerOfParcelForLastNitrogenInput(layertypeValue, parcelIdValue = -1) { // Valeurs possibles: ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function(dispatch, getState) { 
        let currentParcelIdSelected = parcelIdValue;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();
            
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet( currentStoreState, 'contextAppData.parcelIdSelected', -1);
        }

        return dispatch({
            type: SELECT_LAYER_PARCEL_FOR_FERTILIZER,
            layertypeForFertilizer: layertypeValue,
            parcelId: currentParcelIdSelected, 
        });
    }
}

/* fonction permettant de signaler que l'on va enregistrer le nouveau nom d'une parcelle */
export function ActionUpdatingParcelName() {
    return {
        type: UPDATING_PARCEL_NAME,
    };
}

/* fonction permettant d'enregistrer le nouveau nom d'une parcelle */
export function ActionUpdateParcelName(name, parcelId) {
    return {
        type: UPDATE_PARCEL_NAME,
        parcelId: parcelId,
        name: name,
    };
}

/* fonction permettant d'enregistrer la nouvelle culture d'une parcelle */
export function ActionUpdateParcelCulture(cultureValue, parcelIdValue) {
    return {
        type: UPDATE_PARCEL_CULTURE,
        parcelId: parcelIdValue,
        culture: cultureValue,
    };
}

/* fonction permettant d'enregistrer la nouvelle localisation d'une parcelle */
export function ActionUpdateLocalisation(parcelIdValue, localisationValue) {
    return {
        type: UPDATE_PARCEL_LOCALISATION,
        parcelId: parcelIdValue,
        newLocalisation: localisationValue,
    };
}

/* fonction permettant d'enregistrer la nouvelle année de campagne d'une parcelle */
export function ActionUpdateParcelCropYear(cropYearValue, parcelIdValue) {
    return {
        type: UPDATE_PARCEL_CROPYEAR,
        parcelId: parcelIdValue,
        cropYear: cropYearValue,
    };
}

/* fonction permettant d'enregistrer la nouvelle variété d'une parcelle */
export function ActionUpdateParcelVariety(varietyValue, parcelIdValue) {
    return {
        type: UPDATE_PARCEL_VARIETY,
        parcelId: parcelIdValue,
        variety: varietyValue,
    };
}

/* fonction permettant d'enregistrer la nouvelle culture d'une parcelle */
export function ActionUpdateParcelCrop(cropValue, parcelIdValue) {
    return {
        type: UPDATE_PARCEL_CROP,
        parcelId: parcelIdValue,
        crop: cropValue,
    };
}

/* fonction permettant d'enregistrer la nouvelle date de semis d'une parcelle */
export function ActionUpdateParcelSowingDate(sowingDateValue, parcelIdValue) {
    return {
        type: UPDATE_PARCEL_SOWING_DATE,
        parcelId: parcelIdValue,
        sowingDate: sowingDateValue,
    };
}

/* fonction permettant de modifier les infos de biomasses des parcelles */
export function ActionUpdateParcelsAfterBiomassesDeleted(biomassesDeletedValue) {
    return {
        type: UPDATE_PARCELS_AFTER_BIOMASSES_DELETED,
        biomassesDeleted: biomassesDeletedValue,
    };
}

/* fonction permettant de modifier les infos de fumures des parcelles */
export function ActionUpdateParcelsAfterFertilizersDeleted(fertilizersDeletedValue) {
    return {
        type: UPDATE_PARCELS_AFTER_FERTILIZERS_DELETED,
        fertilizersDeleted: fertilizersDeletedValue,
    };
}

export function ActionUpdateParcelAfterBiomassAdded(biomassValue) {
    return {
        type: UPDATE_PARCEL_AFTER_BIOMASS_ADDED,
        biomass: biomassValue,
    };
}

/* fonction permettant d'enregistrer le nouveau nom d'une parcelle */
export function ActionErrorUpdateParcelName(error, name, parcelId) {
    return {
        type: ERROR_UPDATE_PARCEL_NAME,
        error: error,
        parcelId: parcelId,
        name: name,
    };
}

export function ActionUpdateParcelDico(dico) {
    return {
        type: UPDATE_PARCEL_DICO,
        dico: dico,
    }
}

/* fonction permettant de signaler que l'on va enregistrer/modifier les nouveaux chapmps d'une parcelle */
export function ActionUpdatingParcel() {
    return {
        type: UPDATING_PARCEL,
    };
}

/* fonction permettant d'enregistrer/modifier les nouveaux champs d'une parcelle */
export function ActionUpdateParcel(parcelIdValue, parcelValue) {
    return {
        type: UPDATE_PARCEL,
        parcelId: parcelIdValue,
        parcel: parcelValue,
    };
}

/* fonction permettant d'enregistrer/modifier les nouveaux champs d'une parcelle */
export function ActionErrorUpdateParcel(error, parcelIdValue, parcelValue) {
    return {
        type: ERROR_UPDATE_PARCEL,
        error: error,
        parcelId: parcelIdValue,
        //parcel: parcelValue
    };
}

/**
 * fonction permettant de mettre à jour la propriété hasModulation d'une parcelle dans le parcelDico
 * @param {number} parcelId id de la parcelle à mettre à jour
 * @param {boolean} hasModulation valeur de la propriété hasModulation
 */
export function ActionUpdateParcelHasModulation(parcelId, hasModulation) {
    return {
        type: UPDATE_PARCEL_HAS_MODULATION,
        parcelId: parcelId,
        hasModulation: hasModulation,
    }
}

export function ActionSetParcelTab(indexTab) {
    return {
        type: SET_PARCEL_TAB,
        indexTab: indexTab,
    }
}


/* fonctions globales */

/**
 * fonction lancée au démarrage de l'application (premier appel web api)
 *  fonction permettant d'ajouter la liste des parcelles ainsi que, pour chaque, une entité satImage courante 
 */
export const ActionAddParcelsWithCurrentSatImage = (parcelDicoValue, lastSatimageByParcelIdDico) => (dispatch) => {

    // 1- création de la liste d'ids des parcelles filtrées
    const parcelIdFilteredList = ParcelsHelper.selectAllParcelIds(parcelDicoValue);

    // 2- insertion des parcelles (dico complet et celui filtré):
    dispatch( ActionInitParcelDicoWithCurrentSatImage(parcelDicoValue, parcelIdFilteredList, lastSatimageByParcelIdDico) );

    // 3- appel pour initialiser la liste des exploitations et des cultures identifiées dans les parcelles:
    dispatch( ActionInitFarmsAndCulturesOfParcels(parcelDicoValue) );

    // 4- s'il n'y a qu'une seule parcelle, on la sélectionne d'office:
    if (parcelIdFilteredList && (parcelIdFilteredList.length === 1)) {
        dispatch( ActionGoToMapAndSelectParcel(parcelIdFilteredList[0]) );
    }
}

/**
 *  fonction permettant d'ajouter une liste de nouvelles parcelles
 */
export const ActionParcelsLoadedAndFilter = (parcelDicoValue) => (dispatch) => {

    // 1- création de la liste d'ids des parcelles filtrées
    const parcelIdFilteredList = ParcelsHelper.selectAllParcelIds(parcelDicoValue);

    // 2- insertion des parcelles (dico complet et celui filtré):
    dispatch( ActionInitParcelDico(parcelDicoValue, parcelIdFilteredList) );

    // 3- appel pour initialiser la liste des exploitations et des cultures identifiées dans les parcelles:
    dispatch( ActionInitFarmsAndCulturesOfParcels(parcelDicoValue) );

    // 4- s'il n'y a qu'une seule parcelle, on la sélectionne d'office:
    if (parcelIdFilteredList && (parcelIdFilteredList.length === 1)) {
        dispatch( ActionGoToMapAndSelectParcel(parcelIdFilteredList[0]) );
    }
}

/**
 *  Fonction permettant de charger les parcelles archivées pour la fumure
 */
export function ActionLoadParcelArchivedForFertilizer() {
    return function(dispatch, getState) {
        // ↓ indique que l'on lance l'appel à la demande de parcelles archivées ↓ 
        dispatch(ActionLoadingParcelArchivedForFertilizerDico());

        parcelWebApiProvider.loadParcelsArchivedForFertilizer(dispatch, getState)
            .then((response) => {
                //Mise à jour du dico de parcelles archivées pour la fumure
                dispatch(ActionInitParcelArchivedForFertilizerDico(response));
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadParcelsArchivedForFertilizer(error));
            });
    }
}

/**
 * fonction permettant de supprimer le parcellaire 
 */
export function ActionDeleteParcelsAsk() { 
    return function(dispatch, getState) {
        // ↓ indique que l'on lance l'appel à la suppression du parcellaire ↓
        dispatch(ActionDeletingParcels());
        //Signale au contexte que l'on désélectionne la parcelle en cours (si c'est le cas):
        dispatch(ActionUnselectParcel());

        parcelWebApiProvider.deleteParcels(dispatch, getState) //suppression de toutes les données et du parcellaire...
            .then((response) => { //La réponse est juste 'vrai'.
                // Mise à jour du reducer parcel
                dispatch( ActionParcelsDeleted() );
                
                //Signale la remise à zéro des données associées aux parcelles:
                //RQ: pour le moment, cela ne sert à rien de prévenir le contexte car ne fait rien d'autre que la désélection de la parcelle... et déjà fait avant l'appel à la Web API).
                dispatch( ActionDeleteSatimagesAfterParcelsDeleted() );

                // Mise à jour du settings (données par défaut)
                dispatch( ActionResetSettingsAsk() );

                // Suppression uniquement des observations AYANT UNE ASSOCIATION AVEC UNE PARCELLE 
                dispatch( ActionDeleteAllLinkedObservationDatas() );

                // Mise à jour du filtre (réinitialisation)
                dispatch( ActionResetAndApplyParcelFilter() );
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadParcels(error));
            });
    }
}

export function ActionImportParcelsAsk(parcelListDisabledValue, parcelListEnabledValue) { // Liste des entités POCO C# 'Parcel' !
    return function(dispatch, getState) {
        // loadingImportedOrDrawnParcels : indique si le curseur doit tourner ou non. Le curseur tourne maintenant lors d'une importation ou d'un chargement d'images à la création d'une parcelle ou au dessin d'une parcelle
        const loadingImportedOrDrawnParcels = true;
        // ↓ mise à jour de l'objet settings ↓
        dispatch(ActionLoadingParcels(loadingImportedOrDrawnParcels));

        if (parcelListDisabledValue && (parcelListDisabledValue.length > 0)) {
            parcelWebApiProvider.importParcels(dispatch, getState, parcelListDisabledValue, true) //enregistre celles à désactiver...
                .then((responseOfDisabled) => { //La réponse est la liste des entités POCO C# 'Parcel' (actualisé dont l'ID de chacune) !
                    parcelWebApiProvider.importParcels(dispatch, getState, parcelListEnabledValue, false) //enregistre celles à laisser actives !
                        .then((response) => { //La réponse est la liste des entités POCO C# 'Parcel' (actualisé dont l'ID de chacune) !
                            //Signale que l'on a reçu le parcellaire importé:
                            dispatch( ActionParcelsLoadedAndFilter(response.parcelsAdded) ); // on a transmis une liste, mais reçoit un dico !

                            // ajout d'une liste d'observation réactivées
                            if (Object.keys(response.observationsActivated).length > 0)
                                dispatch( ActionAddObservationDico(response.observationsActivated) );

                            //On lance la demande de mise à jour l'historique de chaque parcelle:
                            //Au démarrage de l'application, on ne demande qu'une seule image la plus récente
                            dispatch( ActionAskFirstHistoForAllParcel(1) ); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
                        },
                        (error2) => {
                            //Avertit du mauvais retour:
                            dispatch(ActionErrorLoadParcels(error2));
                        });
                },
                (error) => {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadParcels(error));
                });
        } else {
            parcelWebApiProvider.importParcels(dispatch, getState, parcelListEnabledValue, false) //enregistre celles à laisser actives !
                .then((response) => { //La réponse est la liste des entités POCO C# 'Parcel' (actualisé dont l'ID de chacune) !
                    //Signale que l'on a reçu le parcellaire importé:
                    dispatch(ActionParcelsLoadedAndFilter(response.parcelsAdded));

                    // ajout d'une liste d'observation réactivées
                    if (Object.keys(response.observationsActivated).length > 0)
                        dispatch( ActionAddObservationDico(response.observationsActivated) );
                    
                    //On lance la demande de mise à jour l'historique de chaque parcelle:
                    //Au démarrage de l'application, on ne demande qu'une seule image la plus récente
                    dispatch( ActionAskFirstHistoForAllParcel(1) ); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
                },
                (error) => {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadParcels(error));
                });
        }
    }
}

/**
 * fonction permettant de récupérer/ajouter les parcelles et de lancer son historique
 */
export const ActionParcelsAsk = () => (dispatch, getState) => {
    // loadingImportedOrDrawnParcels : indique si le curseur doit tourner ou non. Le curseur tourne maintenant lors d'une importation ou d'un chargement d'images à la création d'une parcelle ou au dessin d'une parcelle
    const loadingImportedOrDrawnParcels = true;
    // 1- informe que l'on va faire la demande d'obtension du parcellaire du client:
    dispatch(ActionLoadingParcels(loadingImportedOrDrawnParcels));

    // 2- récupération des parcelles
    parcelWebApiProvider.loadParcels(dispatch, getState)
        .then((parcels) => {
            //Fournis les données du parcellaire client:
            dispatch(ActionParcelsLoadedAndFilter(parcels));

            //On lance la demande de mise à jour l'historique de chaque parcelle:
            const nbImagesToShowFirst = 1; // nombre d'images à générer puis afficher au démarrage de l'appli
            dispatch(ActionAskFirstHistoForAllParcel(nbImagesToShowFirst)); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
        },
        (error) => {
            //Avertit du mauvais retour:
            dispatch(ActionErrorLoadParcels(error));
        });
}

/**
 * fonction permettant d'enregistrer une nouvelle parcelle dessinée
 */
export const ActionSaveDrawnParcel = (layer, drawnParcelName = `${StringTranslate.parcelledess}`) => (dispatch, getState) => {
    const stateCurrent = getState();
    // - information d'enregistrement 
    dispatch(ActionSavingDrawnParcel());

    // - on convertit des données geojson vers un objet 'parcel'
    let geojsonToSave = layer.toGeoJSON(); // ← conversion du layer vers un objet geojson ←

    // - insertion des propriétés détails
    const origin = 'autre'; //`${StringTranslate.autre}`; //car pas à traduire....
    DetailsConverter.convertPropertiesToDetailsFromParcel(geojsonToSave, origin);

    const nameExploitation = lodashGet( stateCurrent, 'settingsData.settings.nameExploitation', undefined);
    // - convertion dans une entité poco c#
    var parcelToSave = converter.geojsonLayerToDatabaseParcel(geojsonToSave, ParcelOriginEnum.Drawn, origin, nameExploitation);

    if (parcelToSave) {
        parcelToSave.name = drawnParcelName;
        parcelToSave.clientId = lodashGet( stateCurrent, 'clientUserData.clientDatas.id', -1);
        parcelToSave.lastSeen = new Date();
    } else {
        // On n'enregistre pas la parcelle dessinée, car la conversion a échoué (exemple: ce n'est pas un polygone) !
        dispatch( ActionErrorLoadSettings(`${StringTranslate.errorGenerateParcelDrawed}`) );
        return;
    }

    // - enregistrement de la parcelle en BDD 
    return parcelWebApiProvider.saveParcel(dispatch, getState, parcelToSave)
        .then((response) => {
            if (response.parcelSaved && response.parcelSaved.id > 0) {
                // ↓ enregistrement de la parcelle ↓
                dispatch( ActionAddDrawnParcel(response.parcelSaved, response.parcelSaved.id) );
                // ↓ CAS: SI PREMIERE PARCELLE - on donne un nom d'exploitation par défaut si il n'y en pas ↓
                const state = getState();
                if ( (state.parcelsData.parcelDicoCounter === 1) && ((!state.settingsData.settings.nameExploitation) || (state.settingsData.settings.nameExploitation === '')) ) {
                    let settingsToSave = Object.assign({}, state.settingsData.settings , { nameExploitation: `${StringTranslate.monexploitation}`});
                    dispatch( ActionSaveSettingsAsk(settingsToSave) );
                }


                // ↓ insertion d'anciennes observations réactivées ↓
                if (Object.keys(response.observationsActivated).length > 0)
                    dispatch( ActionAddObservationDico(response.observationsActivated) );

                /*// Si le swipper est affiché, on ne demande pas l'historique de la parcelle car demandé dans le code 
                // de imageSwiper.jsx déjà. (nouvelle fonctionnalité depuis l'optimisation au démarrage de l'appli)
                const showSwipper = lodashGet( stateCurrent, 'settingsData.settings.showSwipper', undefined);
                if (showSwipper === false) {
                    // ↓ récupération de la première image (Lancement 1er historique) ↓
                    //Au démarrage de l'application, on demande toutes les images du mois en cours
                    dispatch( ActionAskFirstHistoForSelectedParcel(0, response.parcelSaved.id) ); // demande de chargement de son historique (de TOUT l'historique)
                }*/
                //désormais, le swipper ne le fait plus ! sauf besoin de slides (mais on va le devancer)
                dispatch( ActionAskFirstHistoForSelectedParcel(0, response.parcelSaved.id) ); // demande de chargement de son historique (de TOUT l'historique)

                dispatch ( ActionUploadedParcels() );
                
                // ↓ Affichage de l'incitation premium si on est en freemium ↓
                if ((!state.clientUserData.clientDatas.enumTypoClient) || (state.clientUserData.clientDatas.enumTypoClient === TypoClientEnum.Freemium)) {
                    if (state.contextAppData.showInviteToPremium === false) {
                        dispatch( ActionShowInviteToPremium(ReasonInviteToPremium.NoLimitParcels) );
                    }
                }

                return response.parcelSaved.id;
            }
        })
        .catch((error) => {
            //Trace Azure:
            sendError('ActionSaveDrawnParcel - saveParcel', error);
        });
}

/**
 * fonction permettant de sauvegarder le nom d'une parcelle en BDD
 */
export const ActionSaveParcelName = (parcelName, parcelId) => (dispatch, getState) => {
    // - information de sauvegarde
    dispatch( ActionUpdatingParcelName() );

    // - enregistrement du nom de la parcelle en BDD 
    return parcelWebApiProvider.saveParcelName(dispatch, getState, parcelName, parcelId)
        .then((bool) => {
            if (bool)
                dispatch( ActionUpdateParcelName(parcelName, parcelId) );
            else
                dispatch( ActionErrorUpdateParcelName(StringTranslate.errorUpdateParcelName, parcelName, parcelId) );
        })
        .catch((error) => {
            dispatch( ActionErrorUpdateParcelName(StringTranslate.errorUpdateParcelName, parcelName, parcelId) );
        });
}

/**
 * fonction permettant de sauvegarder/modifier les champs d'une parcelle en BDD
 */
export const ActionSaveParcel = (parcelIdValue, parcelValue) => (dispatch, getState) => {
    // - information de sauvegarde
    dispatch( ActionUpdatingParcel() );

    // - enregistrement du nom de la parcelle en BDD 
   
    return parcelWebApiProvider.updateParcel(dispatch, getState, parcelIdValue, parcelValue)
        .then((bool) => {
            if (bool)
                {
                    dispatch (ActionUpdateParcel(parcelIdValue, parcelValue));

                    // une fois la parcelle modifiée définitivement en base, on peut mettre a jour eventuellement le context dans l'app
                    dispatch (ActionUpdateParcelContext(parcelValue));

                    // fermeture de la page d'edition de parcelle
                    dispatch (ActionCloseParcelEdit());
                }
            else
                dispatch( ActionErrorUpdateParcel(StringTranslate.errorUpdateParcel, parcelIdValue, parcelValue) );
        })
        .catch((error) => {
            dispatch( ActionErrorUpdateParcel(StringTranslate.errorUpdateParcel, parcelIdValue, parcelValue) );
        });
}

/**
 * fonction permettant de supprimer une liste d'ID de parcelle en BDD
 */
export const ActionDeleteParcelIdListToAPI = (parcelIdListToDelete) => (dispatch) => {

    // ↓ Désélection si parcelle sélectionnée ↓
    dispatch( ActionUnselectParcel() );

    return parcelWebApiProvider.deleteParcelIdList(parcelIdListToDelete)
            .then( (parcelIdListDeleted = []) => { 

                // Mise à jour du reducer observation
                if (parcelIdListToDelete.length > 0) {
                    dispatch( ActionDeleteObservationByParcelIdList(parcelIdListToDelete) );
                }

                // Mise à jour du reducer SatImage
                if (parcelIdListDeleted.length > 0) {
                    dispatch( ActionDeleteSatimageByParcelIdList(parcelIdListDeleted) );
                }
                
                // Mise à jour du reducer parcel
                if (parcelIdListDeleted.length > 0) {
                    dispatch( ActionDeleteParcelIdList(parcelIdListDeleted) );
                }
                
                return parcelIdListDeleted;
            })
            .catch( (error) => {
                //Trace Azure:
                sendError('ActionDeleteParcelIdListToAPI - deleteParcelIdList', error);
            });
}

/**
 * Action lancée pendant l'étape d'enregistrement d'une/plusieurs parcelles - didacticiel firstparcel
 * - enregistrement de la parcelle
 * - enregistrement du nombre de réalisation du didacticiel (counterCompletedTutorial)
 * - changer de typo si nécessaire
 */
export const ActionSaveParcelWhenDidacticielFirstParcel = (layerToSave, parcelNameToSave) => (dispatch, getState) => {
    const stateCurrent = getState();

    // - information d'enregistrement 
    dispatch( ActionSavingDrawnParcel() );

    // - on convertit des données geojson vers un objet 'parcel'
    let geojsonToSave = layerToSave.toGeoJSON(); // ← conversion du layer vers un objet geojson ←

    // - insertion des propriétés détails
    const origin = 'autre'; //`${StringTranslate.autre}`; //car pas à traduire....
    DetailsConverter.convertPropertiesToDetailsFromParcel(geojsonToSave, origin);

    const nameExploitation = lodashGet( stateCurrent, 'settingsData.settings.nameExploitation', undefined);

    // - convertion dans une entité poco c#
    let parcelToSave = converter.geojsonLayerToDatabaseParcel(geojsonToSave, ParcelOriginEnum.Drawn, origin, nameExploitation);
    if (parcelToSave) {
        parcelToSave.name = parcelNameToSave;
        parcelToSave.clientId = lodashGet( stateCurrent, 'clientUserData.clientDatas.id', -1);
    } else {
        // On n'enregistre pas la parcelle dessinée, car la conversion a échoué (exemple: ce n'est pas un polygone) !
        dispatch( ActionErrorLoadSettings(`${StringTranslate.errorGenerateParcelDrawed}`) );
        return;
    }

    // enregistrement en BBD - récupération de la parcelle enregistrée + de la typoClient
    return parcelWebApiProvider.saveParcelWhenDidacticielFirstParcel(parcelToSave)
            .then((response) => {

                // ↓ Mise à jour Parcelle enregistrée en BDD ↓
                if (response.parcelSaved) {
                    // ↓ enregistrement de la parcelle ↓
                    dispatch( ActionAddDrawnParcel(response.parcelSaved, response.parcelSaved.id) );
    
                    // ↓ CAS: SI PREMIERE PARCELLE - on donne un nom d'exploitation par défaut si il n'y en pas ↓
                    const state = getState();
                    if ( (state.parcelsData.parcelDicoCounter === 1) && ((!state.settingsData.settings.nameExploitation) || (state.settingsData.settings.nameExploitation === '')) ) {
                        let settingsToSave = Object.assign({}, state.settingsData.settings , { nameExploitation: `${StringTranslate.monexploitation}`});
                        dispatch( ActionSaveSettingsAsk(settingsToSave) );
                    }
    
                    // ↓ insertion d'anciennes observations réactivées ↓
                    if (Object.keys(response.observationsActivated).length > 0)
                        dispatch( ActionAddObservationDico(response.observationsActivated) );
                }

                // ↓ Mise à jour de la typoClient enregistrée en BDD ↓
                if (response.typoClient) {

                    let { permissions, ...typoClient } = response.typoClient;

                    if (permissions == null || permissions === undefined) {
                        permissions = [];
                    }

                    typoClient.authorizeHistoric = permissions.some(permission => permission.Enum === 1);
                    typoClient.authorizeModulation = permissions.some(permission => permission.Enum === 2);
                    typoClient.authorizeBiomass = permissions.some(permission => permission.Enum === 4);
                    typoClient.authorizeFertilizer = permissions.some(permission => permission.Enum === 8);
                    typoClient.authorizeModelisation = permissions.some(permission => permission.Enum === 16);
                    typoClient.authorizeCornHarvest = permissions.some(permission => permission.Enum === 32);

                    dispatch( ActionUpdateTypoClient(typoClient)); 
                    
                    //Ajouter le déclenchement de l'incitation si définis par la Web API....
                    if (response.forceIncitation === true) {
                        dispatch( ActionForceInviteToPremium(ReasonInviteToPremium.StartExplorer));
                    }
                } //else cela signifie qu'il n'a pas changer de typo (pour diverses raisons tel que : déjà eu son mois Explorer ; typo actuelle = Premium ; pas de parcelles ajoutées ; etc...)

                // ↓ Mise à jour didacticiel firstparcel enregistrée en BDD ↓
                dispatch( ActionAddCounterCompletedDidacticielFirstParcel() );// update reducer counterCompleted + 1

                /*// Si le swipper est affiché, on ne demande pas l'historique de la parcelle car demandé dans le code 
                // de imageSwiper.jsx déjà. (nouvelle fonctionnalité depuis l'optimisation au démarrage de l'appli)
                const showSwipper = lodashGet( stateCurrent, 'settingsData.settings.showSwipper', undefined);
                if (showSwipper === false) {
                    // ↓ récupération de la première image (Lancement 1er historique) ↓
                    //Au démarrage de l'application, on demande toutes les images du mois en cours
                    dispatch( ActionAskFirstHistoForSelectedParcel(0, response.parcelSaved.id) ); // demande de chargement de son historique (de TOUT l'historique)
                }*/
                //désormais, le swipper ne le fait plus ! sauf besoin de slides (mais on va le devancer)
                dispatch( ActionAskFirstHistoForSelectedParcel(0, response.parcelSaved.id) ); // demande de chargement de son historique (de TOUT l'historique)

                return true;
            })
            .catch((error) => {
                //Trace Azure:
                sendError('ActionSaveParcelWhenDidacticielFirstParcel - saveParcelWhenDidacticielFirstParcel', error);
                return false;
            })
}

/**
 * Action lancée pendant l'étape d'enregistrement de parcelles lors d'un import - didacticiel firstparcel
 * - enregistrement des parcelles
 * - incrémentation nombre de réalisation du didacticiel (counterCompletedTutorial)
 * - changer de typo si nécessaire (ex: passage freemium => explorer)
 */
export const ActionParcelListImportWhenDidacticielFirstParcel = (selectedParcelListToSave, unselectedParcelListToSave = []) => (dispatch, getState) => {
        
        // loadingImportedOrDrawnParcels : indique si le curseur doit tourner ou non. Le curseur tourne maintenant lors d'une importation ou d'un chargement d'images à la création d'une parcelle ou au dessin d'une parcelle
        const loadingImportedOrDrawnParcels = true;
        // ↓ mise à jour store ↓
        dispatch( ActionLoadingParcels(loadingImportedOrDrawnParcels) );

        // ↓ préparation du contenu body pour envoi vers l'API
        let parcelsToSave = {
            selectedParcels: selectedParcelListToSave,
            unselectedParcels: unselectedParcelListToSave,
            clientId: lodashGet( getState(), 'clientUserData.clientDatas.id', -1)
        }

        return parcelWebApiProvider.saveParcelListImportWhenDidacticielFirstParcel(parcelsToSave)
            .then((response) => {

                    // ↓ Mise à jour des parcelles enregistrées en BDD ↓
                    dispatch( ActionParcelsLoadedAndFilter(response.parcelsSaved) );

                    // ↓ Mise à jour d'une liste d'observation réactivées ↓
                    if (Object.keys(response.observationsActivated).length > 0)
                        dispatch( ActionAddObservationDico(response.observationsActivated) );

                    // ↓ Mise à jour de la typoClient enregistrée en BDD ↓
                    if (response.typoClient) {

                        let { permissions, ...typoClient } = response.typoClient;

                        if (permissions == null || permissions === undefined) {
                            permissions = [];
                        }
    
                        typoClient.authorizeHistoric = permissions.some(permission => permission.Enum === 1);
                        typoClient.authorizeModulation = permissions.some(permission => permission.Enum === 2);
                        typoClient.authorizeBiomass = permissions.some(permission => permission.Enum === 4);
                        typoClient.authorizeFertilizer = permissions.some(permission => permission.Enum === 8);
                        typoClient.authorizeModelisation = permissions.some(permission => permission.Enum === 16);
                        typoClient.authorizeCornHarvest = permissions.some(permission => permission.Enum === 32);

                        dispatch( ActionUpdateTypoClient(typoClient)); 
                    
                        //Ajouter le déclenchement de l'incitation si définis par la Web API....
                        if (response.forceIncitation === true) {
                            dispatch( ActionForceInviteToPremium(ReasonInviteToPremium.StartExplorer));
                        }
                    }

                    // ↓ Mise à jour didacticiel firstparcel enregistrée en BDD ↓
                    dispatch( ActionAddCounterCompletedDidacticielFirstParcel() );// update reducer counterCompleted + 1

                    // ↓ Lancement 1er historique des parcelles ↓
                    //Au démarrage de l'application, on ne demande qu'une seule image la plus récente
                    dispatch( ActionAskFirstHistoForAllParcel(1) ); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
                    
                    return true;
            })
            .catch((error) => {
                //Trace Azure:
                sendError('ActionParcelListImportWhenDidacticielFirstParcel - saveParcelListImportWhenDidacticielFirstParcel', error);

                dispatch( ActionErrorLoadParcels(error) );
                return false;
            })
}