import * as React from "react";
import { connect } from "react-redux";
import lodashGet from 'lodash/get';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { EnumDownloadChoiceModulation } from "../redux/actions/modulations";

/* Lien de contact */
import LinkToContactUs from './linkToContactUs';

import { ActionShowProfilMenuDialog, ProfilIndex } from '../redux/actions/contextApp.js';


export const EnumTabItems = {
    default: 1, // premier onglet
    other: 2, // onglet supplémentaire
}

/**
 * Composant générique pour l'affichage du choix du format de la donnée de modulation souhaitée 
 * (potentiellement une modulation associée à la donnée principale ; Ex: cas de celle du dernier apport d'azote)
 * les différents paramètres à mettre en place pour l'appelant du dialog sont les suivants :
 * @param hasOpenDownloadDialog : booléen - Détermine si on doit ouvrir la popup de téléchargement
 * @param eventCloseDownloadDialog : fonction : () => void - Appellée pour prévenir le parent de ce composant que la popup de téléchargement se ferme.
 * @param showIncitationToUpgradeModulation : booléen - Détermine si l'utilisateur ne possède pas les droits nécessaire alors on l'incite à faire évolué sont compte
 * @param showExportModulationToDownload : booléen - Détermine si on permet à l'utilisateur de télécharger une carte de modulation
 * @param showFirstStepForDownloading : booléen - Détermine si il faut afficher la première étape (avant le téléchargement des cartes de modulations), en cas de différences pour des types différent (Solid, liquid)
 * @param defaultTab_PdfChoicesRender : définit le rendu des choix possible de téléchargement de l'un des pdf (Cas de la modulation classique ou de la fumure)
 * @param showOtherTab : booléen - Détermine si on doit afficher (ou pas) le second onglet (qui permet le choix d'un téléchargement 'autre' que la modulation elle-même (Ex: celle du conseil azoté ou de son dernier apport).
 * @param showExportOtherModulation : booléen - Détermine si on autorise les exports de la modulation, concernant la donnée 'autre' que la modulation elle-même (Ex: le shp de la carte de modulation du dernier apport)
 * @param otherTab_PdfChoicesRender : définit le rendu des choix possible de téléchargement de l'un des pdf, concernant la donnée 'autre' que la modulation elle-même (Ex: le pdf de la carte de modulation du dernier apport)
 * @param returnChoiceValueForDownload : fonction : (typeDownloadChoice, isFromOtherTab) => void - Appellée pour prévenir le parent de ce composant du choix selectionné par le client
 */
 
class TemplateDialogModulation extends React.Component { //TODO : A utiliser prochainement dans la modulation classique, celle de fumure et dernier apport !
    constructor(props) {
        super(props);
        this.state = {
            typeDownloadChoice: EnumDownloadChoiceModulation.shp,
            hasOpenDownloadDialog: false,
            valueTabs: EnumTabItems.default,
        };
    }

    /**
     * Fonction appelée lors du choix du type de téléchargement : SHP ou ISOXML 
     */
	handleChangeTypeDownloadChoice = (event) => {
		this.setState({ typeDownloadChoice: event.target.value });
	}

    /**
     * Etape de confirmation avant de renvoyer le choix à l'appelant
     */
    handleConfirmValue = () => {
        const { valueTabs, typeDownloadChoice } = this.state;
        // Retour de deux valeurs : 1 le choix du téléchargement, 2 : Savoir si le choix provient du conseil azoté ou du dernier apport
        const isFromOtherTab = (valueTabs === EnumTabItems.other); // true ou false
        this.props.returnChoiceValueForDownload(parseInt(typeDownloadChoice), isFromOtherTab); //Rq : Est-ce vraiment utile le 'parseint' ?
    }

    /**  
     * Permet de changer la valeur choisi pour le menu tabs
     */
    handleChangeTabs = (event, newValue) => {
        this.setState({valueTabs: newValue});
    };

    render () {
        const { 
            showProfilMenuDialog, hasOpenDownloadDialog, eventCloseDownloadDialog, defaultTab_PdfChoicesRender, 
            showExportModulationToDownload, showFirstStepForDownloading, showIncitationToUpgradeModulation, 
            showOtherTab, showExportOtherModulation, otherTab_PdfChoicesRender 
        } = this.props;
        const { typeDownloadChoice, valueTabs } = this.state;

        return (
            <Dialog open={hasOpenDownloadDialog}
                onClose={eventCloseDownloadDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                { /* Dans le cas des widgets, si la parcel possède un dernier apport alors on affiche un menu, permettant de choisir entre le conseil azoté et le dernier apport */}
                {(showOtherTab === true) ? (
                <Tabs
                    value={valueTabs}
                    onChange={this.handleChangeTabs}
                >
                    <Tab label="Conseil azoté" value={EnumTabItems.default} />
                    <Tab label="Dernier apport" value={EnumTabItems.other} />
                </Tabs>
                ) : null}
                <DialogTitle id="alert-dialog-title">{StringTranslate.ColumnMdlToDownLoad}</DialogTitle>
                
                {/* L'étape suivante intervient pour la multi-sélection de parcelle */}
                {(showFirstStepForDownloading === true) ? (<>
                    <DialogContent>
                        <Button
                            color="secondary" variant="text" size="small"
                            onClick={() => showProfilMenuDialog(ProfilIndex.aide_Fumure)}
                            sx={{ mb: 2 }}
                        >
                            {StringTranslate.helpAsk}
                        </Button>
                        {/* Etape 1 : Attention, 2 types d'apports différents, on affiche l'étape suivante qui est le choix entre l'IsoXml et le shp */}
                        <DialogContentText>{StringTranslate.twoSupplyTypesSelected}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>{StringTranslate.annuler}</Button>
                        <Button onClick={this.onGoToNextStepToDownload} variant="contained" color="primary">
                            {StringTranslate.continueStep1}
                        </Button>
                    </DialogActions>
                </>) : (<>
                    <DialogContent sx={{ minWidth: '30%', minHeight: '30%'}}>
                        {/* Etape 2 : Choix entre l'IsoXml, le shp ou le rds (si on veut la/les cartes) OU le/l'un des PDFs*/}
                        <Grid container spacing={2}>   
                            {/* Si le client n'a pas le droit de modulation, on l'incite à nous contacter */}
                            {(showIncitationToUpgradeModulation) ? (
                                <Grid item>
                                    <Typography>{StringTranslate.incitationDownloadPrescriptions}</Typography>
                                    <Typography variant="subtitle1">
                                        <LinkToContactUs displayText={StringTranslate.contactUs} />{StringTranslate.popupIncitationFertilizer}
                                    </Typography>
                                </Grid>
                            ) : (<>
                                {/* On vérifie que c'est ok pour télécharger les carte de modulation ! */}
                                {(((valueTabs === EnumTabItems.default) && (showExportModulationToDownload)) || 
                                  ((valueTabs === EnumTabItems.other) && (showExportOtherModulation))) ? (
                                <>
                                    <Grid item xs={12}>
                                        <Stack direction="column">
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape11}</FormLabel>{/* semantique des trads ! */}
                                                <RadioGroup aria-label="downloadChoice" name="downloadChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
                                                    <FormControlLabel value={EnumDownloadChoiceModulation.shp} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceShp} />
                                                    <FormControlLabel value={EnumDownloadChoiceModulation.isoXml} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceIsoXml} />
                                                    <FormControlLabel value={EnumDownloadChoiceModulation.rds} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceRds} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            color="secondary" variant="text" size="small"
                                            onClick={() => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier)}
                                            sx={{ mb: 2 }}
                                        >
                                            {StringTranslate.helpAskModulation}
                                        </Button>
                                    </Grid>
                                </>)
                                : (<Grid item xs={12}>
                                    <Typography>{StringTranslate.dialogNoModulationBuild}</Typography>
                                </Grid>)}
                            </>)}

                            {((defaultTab_PdfChoicesRender) || (otherTab_PdfChoicesRender)) && (
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape12}</FormLabel>
                                        <RadioGroup aria-label="downloadPDFChoice" name="downloadPDFChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
                                            {/* Affichage du choix du PDF en fonction du menu sélectionné */}
                                            {(valueTabs === EnumTabItems.default) && (defaultTab_PdfChoicesRender)}
                                            {(valueTabs === EnumTabItems.other) && (otherTab_PdfChoicesRender)} 
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    
                    {/* Bouton d'annulation et de confirmation */}
                    <DialogActions>
                        <Button onClick={() => {eventCloseDownloadDialog()}} variant="text" color="error" autoFocus>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={() => {this.handleConfirmValue()}} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </>)}
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: lodashGet(state, 'settingsData.settings', {}),
    }
}
const mapDispatchToProps = (dispatch) => ({
    showProfilMenuDialog: (profilIndex) => dispatch(ActionShowProfilMenuDialog(profilIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDialogModulation);