import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import lodashGet from 'lodash/get';

/* Components MUI */
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

/* Icones */
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import { ModulationsHelper } from '../../../utils/modulationsHelper.js';
import DateHelper from '../../../utils/dateHelper.js';

/* Redux */
import {
    ModulationStep,
    EnumDownloadChoiceModulation,
    ActionGoToShowThisModulation,
    ActionClearModulationValue,
    ActionGenerateFileOfThisModulation,
    ActionBuildListPrescriptions,
    ActionGetAllModulations,
    ActionGoToNextStepOfModulation,
    ActionClearNewModulationCounter,
    ActionGetLastModulationSettings,
    ActionInitializePrescriptions,
    ActionGoToTabOfModulation
} from '../../../redux/actions/modulations';

import {
    ActionGoToModulation,
} from '../../../redux/actions/contextApp.js';

/* Template widget */
import TemplateWidget from './templateWidget.jsx';

///////////////////////////////////////////////////////////////////////////////////////
// fonction de rendu visuel pour la fonctionnalité widget d'une modultation de parcelle 
///////////////////////////////////////////////////////////////////////////////////////
function WidgetModulation(props) {
    const { clientId, getAllModulations } = props;

    useEffect(() => {
        if (getAllModulations && (clientId > 0)) {
            getAllModulations(clientId);
        }
    }, [clientId, getAllModulations]);

    const displayModulation = (event, modulationId) => {
        const { parcelIdSelected, showModulation, clearModulationValue, clientId } = props;

        const modulation = {
            parcelId: parcelIdSelected,
            clientId: clientId,
            id: modulationId
        };

        //si une modulation a déjà été sélectionnée auparavant, vider les valeurs de la modulation :
        if (clearModulationValue) {
            clearModulationValue();
        }

        if (showModulation) {
            showModulation(modulation);
        }
    }

    const generatePdf = (modulationId) => {
        const { generateModulationFile, parcelIdSelected, clientId } = props;

        const modulation = {
            parcelId: parcelIdSelected,
            clientId: clientId,
            id: modulationId
        };

        if (generateModulationFile) {
            generateModulationFile(modulation);
        }
    }

    const downloadFile = (modulation, fileTypeDownloadChoiceType) => {
        const { downloadListPrescriptions } = props;

        let modulationToDownload = [];
        modulationToDownload.push(ModulationsHelper.convertItem(modulation));
        if (downloadListPrescriptions) {
            downloadListPrescriptions(modulationToDownload, fileTypeDownloadChoiceType); //1 : SHP, 2 : ISOXML, 3 : RDS
        }
    }

    const handleSelectModulationPageClick = (event, data) => {
        const {clearNewModulationCounter, clientId, initializePrescriptions, rememberModulationSettings, getLastModulationSettings, goToModulation, goToNextStepOfModulation, goToTabOfModulation } = props;
        //clearNewBiomassCounter();
        
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'modulationPage' });

        clearNewModulationCounter();

        // puis, on passe à la première étape de la génération d'une modulation (pour la première parcelle parmis celles sélectionnées):
        let readableMode = false;

        //Le client a demandé auparavant de mettre par défaut la dose moyenne, la teneur, les paramètres de la dernière modulation enregistrée
        if (rememberModulationSettings === true) {
            getLastModulationSettings(clientId);
        }

        //Initialiser les prescriptions à [] (allPrescriptions),
        initializePrescriptions();
        let newParcelIdsSelected = [data];
        
        if (goToModulation) {
            goToTabOfModulation();
            goToNextStepOfModulation(ModulationStep.CHOIX_PARCELS, newParcelIdsSelected, readableMode);
            goToModulation(true, newParcelIdsSelected, false );
        }
    };

    const getContent = () => {
        const { parcelDico, parcelIdSelected, modulationDico, modulationDicoLoading } = props;

        if (parcelIdSelected <= 0) return;

        // //↓ données parcels (conséquence => nouveau rendu) ↓
        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let modulation = undefined;
        
        if (modulationDico !== undefined) {
            for (const key in modulationDico) {
                const modulItem = modulationDico[key];
                if (modulItem && (modulItem.parcelId === parcelIdSelected)) {
                    modulation = modulItem;
                }
            }
        }

        /**
         * Création des différentes variables et appel à la template de widget
        */
        let dataIsLoaded = null;
        if (modulation !== undefined) {
            dataIsLoaded = true;
        } else if (modulationDicoLoading === true) {
            dataIsLoaded = null;
        } else {
            dataIsLoaded = false;
        }

        let labelButton = StringTranslate.newModulationWidget ;
        let labelLoading = StringTranslate.loadingModulsRecords;
        let titleCardHeader = StringTranslate.mymodulation;
        let generateData = (event) => {handleSelectModulationPageClick(event, parcelIdSelected)};

        let headTable = (
            <TableRow key="row head">
                {/* ↓ cellules concernant le nom de la parcelle ↓ */}
                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnMdlDate}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>Kg/Ha</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnContentRate}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.supplyType}</Typography></TableCell>
            </TableRow>
        );

        let bodyTable = () => (
            <TableRow key={modulation.id} onClick={(event) => displayModulation(event, modulation.id)} hover>
                <TableCell>{DateHelper.formati18n(new Date(modulation.dateImagSource), 'P')}</TableCell>
                <TableCell>{modulation.doseNominal <= 0 ? modulation.doseMedium : modulation.doseNominal}</TableCell>
                <TableCell>{modulation.content}</TableCell>
                <TableCell>{modulation.supplyType === 1 ? StringTranslate.supplyTypeLiquid : StringTranslate.supplyTypeSolid}</TableCell>
            </TableRow>
        );

        let menuItemsList = [
            <MenuItem key={'menu_generate_pdf'} onClick={() => generatePdf(modulation.id)}>
                <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulPdf}</Typography>
            </MenuItem>,
            <MenuItem key={'menu_download_file1'} onClick={() => downloadFile(modulation, EnumDownloadChoiceModulation.shp)}>
                <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulShp}</Typography>
            </MenuItem>,
            <MenuItem key={'menu_download_file2'} onClick={() => downloadFile(modulation, EnumDownloadChoiceModulation.isoXml)}>
                <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulIso}</Typography>
            </MenuItem>,
            <MenuItem key={'menu_download_file3'} onClick={() => downloadFile(modulation, EnumDownloadChoiceModulation.rds)}>
                <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulRds}</Typography>
            </MenuItem>
        ];

        return (
            <TemplateWidget 
                labelButton={labelButton}
                labelLoading={labelLoading}
                titleCardHeader={titleCardHeader}
                dataIsLoaded={dataIsLoaded}
                headTable={headTable}
                bodyTable={bodyTable}
                isTable={true}
                generateData={generateData}
                menuItemsList={menuItemsList}
            />
        )
    }
    return (
        getContent()
    );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        modulationDicoLoading: lodashGet(state, 'modulationsData.loading', false),
        modulationDico: lodashGet(state, 'modulationsData.modulationDico', {}),
        modulationDicoCounter: lodashGet(state, 'modulationsData.modulationDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

const mapDispatchToProps = dispatch => ({
    showModulation: (modulationSelectedItem) => dispatch( ActionGoToShowThisModulation(modulationSelectedItem, true) ),
    clearModulationValue: () => dispatch( ActionClearModulationValue() ),
    generateModulationFile: (modulationSelectedItem) => dispatch( ActionGenerateFileOfThisModulation(modulationSelectedItem) ),
    downloadListPrescriptions: (modulationParameterList, exportFormat) => dispatch( ActionBuildListPrescriptions(modulationParameterList, exportFormat) ),
    getAllModulations: (clientId) => dispatch( ActionGetAllModulations(clientId) ),
    getLastModulationSettings: (clientId) => dispatch(ActionGetLastModulationSettings(clientId)),
    initializePrescriptions: () => dispatch(ActionInitializePrescriptions()),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    clearNewModulationCounter: () => dispatch(ActionClearNewModulationCounter()),
    goToTabOfModulation: () => dispatch(ActionGoToTabOfModulation(0)),
    goToModulation: () => dispatch(ActionGoToModulation(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetModulation);