import StringTranslate from '../assets/i18n/stringLanguage';

const permissionsHelper = {
    /**
     * Liste des enums de certaines permissions
     */
    permissionEnum: {
        history: 1,
        modulation: 2,
        biomass: 4,
        fertilizer: 8,
        modelisation: 16,
        harvest: 32,
    },

    /**
     * Filtre une liste de permissions en retirant une ou plusieurs permissions de la liste
     * @param {Array} permissions Liste de permissions que l'on souhaite trier 
     * @param {Array} excludedEnums Tableau des enums de permissions à exclure 
     * @returns Une liste de permissions sans une ou plusieurs permissions
     */
    filterPermissions(permissions, excludedEnums = []) {
        return permissions.filter(perm => !excludedEnums.includes(perm.Enum));
    },

    /**
     *  Retourne les permissions manquantes d'un utilisateur en fonction de toutes les permissions existantes, et on peut écarter des permissions pendant la récupération
     * @param {Array} allPermissions Liste de toutes les permissions existantes
     * @param {Array} userPermissions Permissions de l'utilisateur
     * @param {Array} excludedEnums Tableau des enums de permissions à exclure 
     * @returns  Retourne les permissions manquantes
     */
    getMissingPermissions(allPermissions, userPermissions, excludedEnums = []) {
        return allPermissions.filter(
        perm => !userPermissions.some(userPerm => userPerm.Enum === perm.Enum) && !excludedEnums.includes(perm.Enum)
        );
    },

    /**
     * Retourne une permission en fonction de son enum
     * @param {Array} allPermissions Liste de toutes les permissions existantes
     * @param {Int} targetEnum Enum de la permission ciblée
     * @returns Une permission ciblée
     */
    getPermissionsByEnum(allPermissions, targetEnum) {
        return allPermissions.filter(perm => perm.Enum === targetEnum);
    },

    /**
    * Récupère les descriptions des permissions
    * @param {Array} permissions Liste des permissions
    * @param {Object} descriptionsMap Mapping des descriptions des permissions
    * @returns {Array} Liste des descriptions
    */
    getDescriptions(permissions, descriptionsMap) {
        const descriptions = [];
        permissions.forEach((perm) => {
            if (descriptionsMap[perm.Enum]) {
                // Ajoute chaque description séparément
                descriptions.push(...descriptionsMap[perm.Enum]);
            } else {
                // Description par défaut si aucune correspondance
                descriptions.push(StringTranslate.defaultDescriptionForPermission);
            }
        });
        return descriptions;
    },
}

export default permissionsHelper;