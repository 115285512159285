import React from 'react';
import { Grid, Typography, List, ListItemText, Button, Box } from '@mui/material';
import MainCard from '../subLayouts/mainCard';
import StringTranslate from '../../assets/i18n/stringLanguage';

/* Theme */
import getTheme from "../../themes/index.js";
let theme = getTheme();

const PlanCard = ({
    title,
    currentSubscription = false,
    description,
    staticPermissions = [],
    dynamicPermissions = [],
    buttonText,
    onButtonClick,
    cardStyles = {},
    headerIcon = null,
    abovePermissions = null,
    belowTitle = null,
    showButton = false,
}) => {
    // Fusion des permissions statiques et dynamiques
    const allPermissions = [...staticPermissions, ...dynamicPermissions];

    return (
        <MainCard
            boxShadow
            sx={{
                pt: 1.75,
                border: '1px solid rgba(133, 145, 161, 0.46)',
                borderRadius: '8px',
                padding: 2,
                ...cardStyles,
            }}
        >
            <Grid container textAlign="center" spacing={2}>
                {/* Icône de la carte */}
                {headerIcon && 
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                width: 80,
                                height: 80,
                                background: theme.palette.primary.light,
                                color: theme.palette.primary.main,
                                '& > svg': {
                                    width: 35,
                                    height: 35
                                }
                            }}
                        >
                            {headerIcon}
                        </Box>
                    </Grid>
                }

                {/* Titre de la carte */}
                <Grid item xs={12}>
                {currentSubscription && <Typography variant="h5">{StringTranslate.currentSubscription}</Typography>}
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: '1.5625rem',
                            fontWeight: 500,
                            position: 'relative',
                            mb: 1.875,
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                bottom: -15,
                                left: 'calc(50% - 25px)',
                                width: 50,
                                height: 4,
                                background: (theme) => theme.palette.primary.main,
                                borderRadius: '3px',
                            },
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>

                {/* Description principale */}
                {description && (
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {description}
                        </Typography>
                    </Grid>
                )}

                {/* Contenu de la carte */}
                <Grid item xs={12} textAlign="center">
                    {/* Contenu sous le titre */}
                    {belowTitle && 
                        <Typography variant="h5" sx={{ mb: 2.2}}>{belowTitle}</Typography>
                    }
                    <Typography textAlign="start" variant="h4">{StringTranslate.featuresNewSubscription}</Typography>
                    {/* Texte juste au dessus des permissions */}
                    {abovePermissions && abovePermissions}
                    
                    {/* Liste des permissions */}
                    <List dense={true} sx={{justifyItems: "start"}}>
                        {allPermissions.map((permission, index) => (
                            <ListItemText key={index} primary={permission} />
                        ))}
                    </List>
                </Grid>

                {/* Bouton */}
                {showButton &&
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={onButtonClick}>
                            {buttonText}
                        </Button>
                    </Grid>
                }
            </Grid>
        </MainCard>
    );
};

export default PlanCard;
