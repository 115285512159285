import { lastNitrogenInputWebApiProvider } from '../../utils/webApiProvider.js';
import lodashGet from 'lodash/get';
import lodashIsNil from 'lodash/isNil';
import lodashCloneDeep from 'lodash/cloneDeep';
import { TabAbstractFertilizer, ActionSelectFertilizerTab, ActionUpdateHasLastNitrogenInput } from './fertilizer';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { ActionSelectLayerOfParcelForLastNitrogenInput } from './parcels.js';
import { ActionSelectParcel } from './contextApp.js';
import sendError from '../../utils/errorService.js';
import { IsNativeHoster } from '../../utils/platformHelper';
/*Helpers */
import { ModulationsHelper } from '../../utils/modulationsHelper.js';
import numberHelper from '../../utils/numberHelper.js';
import dateHelper from '../../utils/dateHelper.js';
import lastNitrogenInputHelper from '../../utils/lastNitrogenInputHelper.js';

const SELECT_ALL_LAST_NITROGEN_INPUTS = 'SELECT_ALL_LAST_NITROGEN_INPUTS';
const SELECT_LAST_NITROGEN_INPUT = 'SELECT_LAST_NITROGEN_INPUT';
const UPDATE_LAST_NITROGEN_INPUT = 'UPDATE_LAST_NITROGEN_INPUT';

const GO_TO_STEP_OF_LAST_NITROGEN_INPUT= 'GO_TO_STEP_OF_LAST_NITROGEN_INPUT';

const DELETING_LAST_NITROGEN_INPUT = 'DELETING_LAST_NITROGEN_INPUT';
const DELETE_LAST_NITROGEN_INPUT = 'DELETE_LAST_NITROGEN_INPUT';
const ERROR_DELETE_LAST_NITROGEN_INPUT = 'ERROR_DELETE_LAST_NITROGEN_INPUT';

const LOADING_LAST_NITROGEN_INPUTS = 'LOADING_LAST_NITROGEN_INPUTS';
const LOAD_LAST_NITROGEN_INPUTS = 'LOAD_LAST_NITROGEN_INPUTS';
const ERROR_LOAD_LAST_NITROGEN_INPUTS = 'ERROR_LOAD_LAST_NITROGEN_INPUTS';

const BUILDING_LAST_NITROGEN_INPUTS = 'BUILDING_LAST_NITROGEN_INPUTS'; 
const BUILD_LAST_NITROGEN_INPUT = 'BUILD_LAST_NITROGEN_INPUT'; 
const ERROR_BUILD_LAST_NITROGEN_INPUT = 'ERROR_BUILD_LAST_NITROGEN_INPUT';

const DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS = 'DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS'; 
const DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS = 'DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS'; 
const ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS = 'ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS';

const CLEAR_LAST_NITROGEN_INPUT_ERROR = 'CLEAR_LAST_NITROGEN_INPUT_ERROR';

const BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES = 'BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES';
const BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES = 'BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES';
const ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES = 'ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES';

const SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES = 'SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES';
const SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES = 'SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES';
const ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES = 'ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES';

const SELECT_LAYER_FOR_LAST_NITROGEN_INPUT = 'SELECT_LAYER_FOR_LAST_NITROGEN_INPUT';

const CREATE_LAST_NITROGEN_INPUT_PARAMETER = 'CREATE_LAST_NITROGEN_INPUT_PARAMETER';

const GENERATING_LASTNITROGENINPUT_FILE = 'GENERATING_LASTNITROGENINPUT_FILE';
const LASTNITROGENINPUT_FILE_GENERATED = 'LASTNITROGENINPUT_FILE_GENERATED';

/* Les différentes étapes de la modulation de dernier apport */
export const LastNitrogenInputStep = {
    CHOIX_LAST_NITROGEN_INPUTS: 'CHOIX_LAST_NITROGEN_INPUTS',
    MAP: 'MAP',
    RESULT: 'RESULT'
}

export const ActionTypeLastNitrogenInput = {
    SELECT_ALL_LAST_NITROGEN_INPUTS: SELECT_ALL_LAST_NITROGEN_INPUTS,
    SELECT_LAST_NITROGEN_INPUT: SELECT_LAST_NITROGEN_INPUT,
    UPDATE_LAST_NITROGEN_INPUT: UPDATE_LAST_NITROGEN_INPUT,

    GO_TO_STEP_OF_LAST_NITROGEN_INPUT: GO_TO_STEP_OF_LAST_NITROGEN_INPUT,
    
    DELETING_LAST_NITROGEN_INPUT: DELETING_LAST_NITROGEN_INPUT,
    DELETE_LAST_NITROGEN_INPUT: DELETE_LAST_NITROGEN_INPUT,
    ERROR_DELETE_LAST_NITROGEN_INPUT: ERROR_DELETE_LAST_NITROGEN_INPUT,

    LOADING_LAST_NITROGEN_INPUTS: LOADING_LAST_NITROGEN_INPUTS,
    LOAD_LAST_NITROGEN_INPUTS: LOAD_LAST_NITROGEN_INPUTS,
    ERROR_LOAD_LAST_NITROGEN_INPUTS: ERROR_LOAD_LAST_NITROGEN_INPUTS,

    BUILDING_LAST_NITROGEN_INPUTS: BUILDING_LAST_NITROGEN_INPUTS,
    BUILD_LAST_NITROGEN_INPUT: BUILD_LAST_NITROGEN_INPUT,
    ERROR_BUILD_LAST_NITROGEN_INPUT: ERROR_BUILD_LAST_NITROGEN_INPUT,

    DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,
    DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,
    ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,

    BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES: BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES,
    BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES: BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES,
    ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES: ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES,
    
    SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES: SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES,
    SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES: SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES,
    ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES: ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES,

    CLEAR_LAST_NITROGEN_INPUT_ERROR: CLEAR_LAST_NITROGEN_INPUT_ERROR,

    SELECT_LAYER_FOR_LAST_NITROGEN_INPUT: SELECT_LAYER_FOR_LAST_NITROGEN_INPUT,
    
    CREATE_LAST_NITROGEN_INPUT_PARAMETER: CREATE_LAST_NITROGEN_INPUT_PARAMETER,

    GENERATING_LASTNITROGENINPUT_FILE: GENERATING_LASTNITROGENINPUT_FILE,
    LASTNITROGENINPUT_FILE_GENERATED: LASTNITROGENINPUT_FILE_GENERATED,
};

export const ActionSelectLastNitrogenInput = (parcelId = -1) => ({
    type: SELECT_LAST_NITROGEN_INPUT,
    lastNitrogenInputSelected: parcelId,
});
export const ActionUnselectLastNitrogenInput = () => ActionSelectLastNitrogenInput();

/*export*/ const ActionLoadingLastNitrogenInputs = () => ({
//return {
    type: LOADING_LAST_NITROGEN_INPUTS,
    //};
});

/*export*/ function ActionInitLastNitrogenInputDico(lastNitrogenInputsDicoValue) {
    return {
        type: LOAD_LAST_NITROGEN_INPUTS,
        lastNitrogenInputDico: lastNitrogenInputsDicoValue
    };
}

export function ActionErrorLoadLastNitrogenInputs(errorValue) {
    return { 
        type: ERROR_LOAD_LAST_NITROGEN_INPUTS,
        errorMessage: errorValue,
    };
}

export function ActionGetAllLastNitrogenInputs() {
    return function (dispatch, getState) {
        // Si déjà en train de chargé, alors on en fait pas plus
        const currentState = getState();
        const loading = lodashGet(currentState, 'lastNitrogenInputData.loading', false);
        if (loading === true)
            return;

        // 1- informe que l'on va faire la demande d'obtension des données de dernier apport du client:
        dispatch(ActionLoadingLastNitrogenInputs());
        // 2- récupération des données de dernier apport
        lastNitrogenInputWebApiProvider.loadLastNitrogenInputs(dispatch, getState)
            .then((lastNitrogenInputs) => {
                dispatch(ActionInitLastNitrogenInputDico(lastNitrogenInputs));
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadLastNitrogenInputs(error));
            });    
    };
}

const ActionGeneratingLastNitrogenFile = () => ({
    type: GENERATING_LASTNITROGENINPUT_FILE,
})
const ActionLastNitrogenInputFileGenerated = () => ({
    type: LASTNITROGENINPUT_FILE_GENERATED,
})

// Action permettant la génération du pdf
export function ActionGenerateFileOfThisLastNitrogen(lastInputNSelected) {
    return function (dispatch, getState, {history, userAgentAuthent}) {
        if (lastInputNSelected != null) {
            dispatch(ActionGeneratingLastNitrogenFile());
            // Appel de l'api avec la méthode de génération de pdf
            lastNitrogenInputWebApiProvider.generateLastNitrogenInputPdf(dispatch, getState, lastInputNSelected, false, false)
                .then((respDatas) => {
                    dispatch(ActionLastNitrogenInputFileGenerated() );

                    //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
                },
                (error) => {
                    dispatch( ActionLastNitrogenInputFileGenerated());

                    dispatch(ActionErrorLoadLastNitrogenInputs(error));
                });
        }
    }
}

// PDF de récap dernier apport action
export function ActionGenerateFileOfResumeLastNitrogen(lastNitrogenSelectedItems) {
    return function (dispatch, getState, { history, userAgentAuthent}) {
        dispatch ( ActionGeneratingLastNitrogenFile() );
        let idsOfParcelLastNitrogenInput = [];
        if (lastNitrogenSelectedItems && Array.isArray(lastNitrogenSelectedItems) && (lastNitrogenSelectedItems.length > 0)) {
            idsOfParcelLastNitrogenInput = lastNitrogenSelectedItems;
        }

        lastNitrogenInputWebApiProvider.GenerateLastNitrogenPdfResume(dispatch, getState, idsOfParcelLastNitrogenInput, false, false)
            .then((respDatas) => {
                dispatch ( ActionLastNitrogenInputFileGenerated() );

                //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
            }, 
            (error) => {
                dispatch( ActionLastNitrogenInputFileGenerated() );

                dispatch( ActionErrorLoadLastNitrogenInputs(error) );
            }); 
    }
}

export function ActionGoToLastNitrogenInputsResults(showThisResultId = -1) {
    return function (dispatch, getState) {
        //pas de chargement particulier !

        //on affiche le second onglet:
        dispatch( ActionSelectFertilizerTab(TabAbstractFertilizer.LASTINPUT_LIST) );

        //si un résultat, en particulier, doit être mis en avant : 
        if (showThisResultId > 0) {
            //TODO : dispatch( ... ); //pour aller sur la bonne page où ce situe l'item en question.
        }
    };
}

/*export*/ function ActionBuildingLastNitrogenInputs(selectedListItems) { //lancement pour un ou lot de parcelles !
    return {
        type: BUILDING_LAST_NITROGEN_INPUTS,
        lastInputNStartingList: selectedListItems,
    };
}

/*export*/ function ActionUpdateLastNitrogenInput(newLastNitrogenInputValue) { //résultat de dernier apport pour l'une des parcelles !
    return {
        type: BUILD_LAST_NITROGEN_INPUT,
        newLastNitrogenInput: newLastNitrogenInputValue
    };
}

export function ActionErrorBuildLastNitrogenInput(errorValue, lastNitrogenInputParcelIdValue) { //erreur de calcul pour le dernier apport de la parcelle identifiée !
    return { 
        type: ERROR_BUILD_LAST_NITROGEN_INPUT,
        errorMessage: errorValue,
        lastNitrogenInputParcelId: lastNitrogenInputParcelIdValue,
    };
}

export function ActionBuildLastNitrogenInputs(selectedListItems) { // 'selectedItems' liste des entités sur lesquelles demander un (re)calcul !
    return async function (dispatch, getState) {
        if ((!selectedListItems) || (!Array.isArray(selectedListItems)) || (selectedListItems.length <= 0)) return;

        // 1- informe que l'on va faire la demande de production des données de dernier apport du client:
        dispatch( ActionBuildingLastNitrogenInputs(selectedListItems) );

        // 2- appel à génération des données de dernier apport / parcelle:
        // /!\ Attention, Comme on utilise un await dans la boucle, on DOIT utiliser un for ... of et pas une boucle du type selectedListItems.forEach
        for (const itemLastInputN of selectedListItems) {
            if ((itemLastInputN !== undefined) && (itemLastInputN.parcelId > 0)) {
                // Ajout d'un délai d'attente entre chaque demande pour éviter des erreurs provenant des moteurs de Fumure (Ex: FTW avec DeadLock)
                await new Promise(resolve => setTimeout(resolve, 3 * 1000));

                lastNitrogenInputWebApiProvider.buildLastNitrogenInput(dispatch, getState, itemLastInputN)
                    .then((newLastNitrogenInput) => {
                        if (newLastNitrogenInput) {
                            dispatch( ActionUpdateLastNitrogenInput(newLastNitrogenInput) );
                            dispatch( ActionUpdateHasLastNitrogenInput(newLastNitrogenInput.fertiSpecificId, true) );

                            //Au premier bon résultat, on passe sur le second onglet :
                            dispatch( ActionSelectFertilizerTab(TabAbstractFertilizer.LASTINPUT_LIST) );
                        }
                        else {
                            //Avertit du mauvais retour (pour cette parcelle):
                            dispatch( ActionErrorBuildLastNitrogenInput(StringTranslate.noResultLastNitrogenInputBuild, itemLastInputN.parcelId) );
                        }
                    },
                    (error) => {
                        //Avertit du mauvais retour (pour cette parcelle):
                        dispatch( ActionErrorBuildLastNitrogenInput(error, itemLastInputN.parcelId) );
                    });    
            }
            //else //pas normal !
        }
    };
}

/*export*/ function ActionLastNitrogenInputPrescriptionsDownLoading() { 
    return {
        type: DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,
    };
}

/*export*/ function ActionLastNitrogenInputPrescriptionsDownLoaded() { 
    return {
        type: DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,
    };
}

/*export*/ function ActionErrorDownLoadLastNitrogenInputPrescriptions(errorValue) { 
    return { 
        type: ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS,
        errorMessage: errorValue,
    };
}

/**
 * Action encadrant la demande de génération de la prescription d'un ou plusieurs conseil(s) "dernier apport" de parcelle d'un client.
 * @param {int[]} selectedIds : Liste des identifiants de conseils sélectionnées. 
 * @param {EnumDownloadChoiceModulation} exportFormat : Choix du format de cette prescription.
 */
export function ActionBuildLastNitrogenInputListPrescriptions(selectedIds, exportFormat) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (!selectedIds) return;

        //Début de la génération + téléchargement:
        dispatch( ActionLastNitrogenInputPrescriptionsDownLoading() );

        lastNitrogenInputWebApiProvider.buildLastNitrogenListPrescriptions(dispatch, getState, selectedIds, exportFormat)
            .then((isOkResult) => { 
                if ((isOkResult) && (isOkResult === true)) {
                    //Fin des téléchargements:
                    dispatch( ActionLastNitrogenInputPrescriptionsDownLoaded() );
                } else {
                    dispatch( ActionErrorDownLoadLastNitrogenInputPrescriptions(StringTranslate.badCompletBuildLniModulation) );
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de téléchargement:
                dispatch( ActionErrorDownLoadLastNitrogenInputPrescriptions(StringTranslate.badCompletBuildLniModulation) );
            });
    };
}

// #region ↓↓ SUPPRESSION ↓↓ 
//Avertit le début de la suppresion de dernier apport azoté
/*export*/ const ActionDeletingLastNitrogenInput = () => ({
    type: DELETING_LAST_NITROGEN_INPUT,
})

//Avertit la fin de la suppression de dernier apport azoté
/*export*/ const ActionLastNitrogenInputDeleted = (lastNitrogenInputDico) => ({
    type: DELETE_LAST_NITROGEN_INPUT,
    lastNitrogenInputDico: lastNitrogenInputDico
})

//Avertit de l'erreur survenue lors de la suppression de dernier apport azoté
/*export*/ const ActionLastNitrogenInputDeletedError = (errorMessage) => ({
    type: ERROR_DELETE_LAST_NITROGEN_INPUT,
    errorMessage: errorMessage
})

//Action qui permet la suppression de dernier apport azoté
export function ActionDeleteLastNitrogenInput(parcelIdsToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingLastNitrogenInput());

        lastNitrogenInputWebApiProvider.deleteLastNitrogenInputs(dispatch, getState, parcelIdsToDelete)
            // Récupère le nouveau dico ayant des entrées pour les derniers apports supprimé mais ne disposant pas de données de calcule
            .then((response) => {
                dispatch(ActionLastNitrogenInputDeleted(response));

                // Récupère les ids des conseil azoté des derniers apports supprimés
                let idsFerti = Object.values(response).filter(lni => parcelIdsToDelete.includes(lni.parcelId)).map(lni => lni.fertiSpecificId);
                dispatch(ActionUpdateHasLastNitrogenInput(idsFerti, false));
            },
            (error) => {
                //Avertit du mauvais retour: non !
                dispatch(ActionLastNitrogenInputDeletedError(error));
            });
    }
}

// #endregion

export function ActionClearLastNitrogenInputError() {
    return {
        type: CLEAR_LAST_NITROGEN_INPUT_ERROR,
    };
}

export function ActionGoToStepOfLastNitrogenInput(nextStepValue) {
    return  {
        type: GO_TO_STEP_OF_LAST_NITROGEN_INPUT,
        lastNitrogenInputStep: nextStepValue,
    }
}

export function ActionBuildLastNitrogenSampleAndDoses(lastInputNParameter){
    return function(dispatch, getState){
        dispatch(ActionStartBuildingLastNitrogenSampleAndDoses(lastInputNParameter));
        const currentState = getState();
        const lastNitrogenInputStep = lodashGet(currentState, 'lastNitrogenInputData.lastNitrogenInputStep', LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS);

        const lastNitrogenInputSelected = lodashGet(currentState, 'lastNitrogenInputData.lastNitrogenInputSelected', -1);
        const lastNitrogenInput = lodashGet(currentState, `lastNitrogenInputData.lastNitrogenInputDico[${lastNitrogenInputSelected}]`, {});

        let nbZones = lastInputNParameter.zonesCounter;

        //On vérifie s'il y a un changement d'image ou de nombre de zones, si ce n'est pas le cas alors il n'y a pas besoin de rappeler la web API.
        if((lastNitrogenInput) && (lastNitrogenInput.zonesCounter === nbZones) && (dateHelper.isSameDateDDMMYYYY(lastNitrogenInput.dateImagSource, lastInputNParameter.dateImagSource)) &&
            (lastNitrogenInput.providerImagSource === lastInputNParameter.providerImagSource)
        ){

            let newLastNitrogenInput = lodashCloneDeep(lastNitrogenInput);
            try {
                let nitrogenPrescription = parseFloat(lastNitrogenInput.nitrogenPrescription);

                // Vérification liée à la dose déjà apportée et de la dose totale à utiliser
                if ((lastNitrogenInput.dosePreviouslyInput !== lastInputNParameter.dosePreviouslyInput) || (lastNitrogenInput.usingAjustedDose !== lastInputNParameter.usingAjustedDose) || 
                    (lastNitrogenInput.doseMedium !== lastNitrogenInput.nitrogenPrescription)){
                    // On check quelle dose on doit utiliser pour la modulation
                    if (lastInputNParameter.usingAjustedDose === undefined)
                    {
                        // Si on a pas de dose ajusté (possible???), on prend la dose PPF
                        if (lastNitrogenInput.newNitrogenTotal)
                            lastInputNParameter.usingAjustedDose = false;
                        else
                            // Si la dose ajusté est en faveur du client, on la prend sinon on prend celle du PPF (on choisi la dose la plus élevée)
                            lastInputNParameter.usingAjustedDose = (lastNitrogenInput.newNitrogenTotal >= lastNitrogenInput.fertiNitrogenTotal);
                    }

                    if (lastInputNParameter.usingAjustedDose === true)
                    {
                        if ((lastNitrogenInput.newNitrogenTotal) && (lastNitrogenInput.newNitrogenTotal > 0))
                        {
                            // Si la dose totale ajustée n'a pas déjà été apportée
                            if (lastInputNParameter.dosePreviouslyInput < lastNitrogenInput.newNitrogenTotal)
                                // On retire la dose déjà apportée à la dose totale ajustée
                                nitrogenPrescription = lastNitrogenInput.newNitrogenTotal - lastInputNParameter.dosePreviouslyInput;
                            else
                                nitrogenPrescription = 0.0;
                        }
                        // Pas de valeur, pas de prescription !
                        else
                            nitrogenPrescription = null;
                    }
                    // Si on doit utiliser la dose PPF
                    else
                    {
                        if (lastNitrogenInput.fertiNitrogenTotal > 0)
                        {
                            // Si la dose totale PPF n'a pas déjà été apportée
                            if (lastInputNParameter.dosePreviouslyInput < lastNitrogenInput.fertiNitrogenTotal)
                                // On retire la dose déjà apportée à la dose totale PPF
                                nitrogenPrescription = lastNitrogenInput.fertiNitrogenTotal - lastInputNParameter.dosePreviouslyInput;
                            else
                                nitrogenPrescription = 0.0;
                        }
                        // Pas de valeur, pas de prescription !
                        else
                            nitrogenPrescription = null;
                    }

                    newLastNitrogenInput.nitrogenPrescription = nitrogenPrescription;
                    newLastNitrogenInput.doseMedium = nitrogenPrescription;
                    newLastNitrogenInput.dosePreviouslyInput = lastInputNParameter.dosePreviouslyInput;
                    newLastNitrogenInput.usingAjustedDose = lastInputNParameter.usingAjustedDose;
                }

                //on n'appelle jamais avec la teneur ! Cf. 'dosesModulation.jsx' afin de toujour manipuler des Unité d'azote
                // c'est l'affichage qui décidera d'afficher suivant la bonne unité (U ou Kg|L) suivant la présence ou non de la teneur.
                let content = (lastInputNParameter.content) ? lastInputNParameter.content : 100;
                let optimisation = true;
                let repartition = lastInputNParameter.dispersion;

                let ratios = [];
                if (lastNitrogenInput.zones) { //autant utiliser ceux de la précédente génération car même image (date) et même nombre de zones !
                    lastNitrogenInput.zones.forEach(zoneItem => ratios.push(zoneItem.ratio));
                }

                //Calculs lié à la teneur
                const oldContent = lastNitrogenInput.content;
                let newZones = lodashCloneDeep(lastNitrogenInput.zones);
                let newQuantityToExpand = lastNitrogenInput.quantityToExpand
        
                // Si la nouvelle teneur est différente que celle contenue dans rédux
                if (oldContent !== content) {
                    newQuantityToExpand = 0; //TODO: A voir l'utilité de cette partie puisque c'est recalculé dans le lastNitrogenInputHelper.getFromWebApiEntity()
                    //on actualise la dose de chaque zone (par proportionnalité entre l'ancienne et nouvelle teneur !)
                    if ((numberHelper.testDataValid(oldContent) && (oldContent > 0) && (oldContent <= 100))) {
                        newZones.forEach(zoneItem => {
                            if (zoneItem) {
                                if (zoneItem.withContent === true) {//pour éviter de faire la conversion deux fois !
                                    //Rq : on devrait toujours passer ici !
                                    zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                        zoneItem.actualDose : ((zoneItem.actualDose * oldContent) / content);
                                } else {//On ne devrait jamais être dans ce cas !
                                    zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                        zoneItem.actualDose : ((zoneItem.actualDose * 100.0) / content);
            
                                    zoneItem.withContent = true;
                                }
                                newQuantityToExpand += (zoneItem.area * (
                                    (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                            }
                        });
                    } else { //on considère donc que l'ancienne valeur n'était pas bonne et que donc, les doses était exprimées en U/Ha !
                        //Il faut convertir les doses en tenant compte de la teneur nouvellement définie:
                        newZones.forEach(zoneItem => {
                            if (zoneItem && (zoneItem.withContent !== true)) {//pour éviter defaire la conversion deux fois !
                                zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                    zoneItem.actualDose : ((zoneItem.actualDose * 100.0) / content);
        
                                zoneItem.withContent = true;
        
                                newQuantityToExpand += (zoneItem.area * (
                                    (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                            } //else //ne touche à rien ! (car n'aurai jamais dû arriver ET dispose du bon état désormais)
                        });
                    }

                    //Définition des nouvelles zones et la quantité totale à épandre suite au changement de la teneur
                    newLastNitrogenInput.zones = newZones;
                    newLastNitrogenInput.content = content;
                    newLastNitrogenInput.quantityToExpand = newQuantityToExpand;
                }

                const automaticDoses = ModulationsHelper.get_Doses_Auto(nbZones, ratios, nitrogenPrescription, content, optimisation, repartition);

                //Modification des zones suite au recalcul de celles-ci
                if (lastNitrogenInput.zones && (lastNitrogenInput.zones[0])) {

                    //Rappel: 'newlastNitrogenInput' est une copie de 'lastNitrogenInput' 
                    newLastNitrogenInput.zones.forEach((zoneItem, index) => {
                        zoneItem.actualDose = numberHelper.fixeDecimal(automaticDoses[index]);
                    });

                    newLastNitrogenInput.doseMin = Math.min(...newLastNitrogenInput.zones.map(z => z.actualDose));
                    newLastNitrogenInput.doseMax = Math.max(...newLastNitrogenInput.zones.map(z => z.actualDose));
                }

                lastNitrogenInputHelper.getFromWebApiEntity(newLastNitrogenInput);
                dispatch(ActionLastNitrogenSampleAndDosesBuilded(newLastNitrogenInput) );
                if(lastNitrogenInputStep===LastNitrogenInputStep.MAP) {
                    dispatch(ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.RESULT))
                } // else on est déjà sur l'écran de résultat donc pas on y reste
            }
            catch (e) {
                //Avertit du mauvais retour:

                // @@Ajouter un senderror pour les traces azures et tout bazar
                sendError('BuildSampleAndDosesLastInputNFront', {
                    "erreur": "Une erreur s'est produite lors du calculs des doses de modulation d'un conseil dernier approt côté FRONT.",
                    "err": e,
                    "idOfClient": lastNitrogenInput.clientId,
                });
                dispatch( ActionErrorBuildingLastNitrogenSampleAndDoses(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses) );
            }

        } else {
            lastNitrogenInputWebApiProvider.buildLastNitrogenSampleAndDoses(dispatch, getState, lastInputNParameter)
                .then((newLastNitrogenInput) => {
                    if (newLastNitrogenInput) {
                        dispatch(ActionLastNitrogenSampleAndDosesBuilded(newLastNitrogenInput) );
                        if(lastNitrogenInputStep===LastNitrogenInputStep.MAP) {
                            dispatch(ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.RESULT))
                        } // else on est déjà sur l'écran de résultat donc pas on y reste
                    }
                    else {
                        //Avertit du mauvais retour:
                        dispatch( ActionErrorBuildingLastNitrogenSampleAndDoses(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses) );
                    }
                },
                (error) => {
                    //Avertit du mauvais retour:
                    dispatch( ActionErrorBuildingLastNitrogenSampleAndDoses(error) );
                })
                .catch(() => {
                    //Avertit du mauvais retour:
                    dispatch( ActionErrorBuildingLastNitrogenSampleAndDoses(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses) );
                });    
        }
    }
}

//Avertit le début de la modulation de dernier apport azoté
/*export*/ const ActionStartBuildingLastNitrogenSampleAndDoses = (lastInputNParameter) => ({
    type: BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES,
    lastInputNParameter: lastInputNParameter,
})

//Avertit la fin de la modulation de dernier apport azoté
/*export*/ const ActionLastNitrogenSampleAndDosesBuilded = (newLastNitrogenInputValue) => ({
    type: BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES,
    newLastNitrogenInput: newLastNitrogenInputValue
})

//Avertit de l'erreur survenue lors de la modulation de dernier apport azoté
/*export*/ const ActionErrorBuildingLastNitrogenSampleAndDoses = (errorMessage) => ({
    type: ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES,
    errorMessage: errorMessage
})

export function ActionSaveLastNitrogenInputModulation(lastNitrogenInput) {
    return function (dispatch, getState) {
        dispatch( ActionStartSavingLastNitrogenSampleAndDoses() );

        lastNitrogenInputWebApiProvider.saveLastNitrogenSampleAndDoses(dispatch, getState, lastNitrogenInput)
            .then((newLastNitrogenInput) => {
                if (!lodashIsNil(newLastNitrogenInput)) {
                    dispatch( ActionLastNitrogenSampleAndDosesSaved(newLastNitrogenInput) );
                    dispatch( ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS) );
                }
                else {
                    //Avertit du mauvais retour:
                    dispatch( ActionErrorSavingLastNitrogenSampleAndDoses(StringTranslate.noResultLastNitrogenInputSavedSampleAndDoses) );
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorSavingLastNitrogenSampleAndDoses(error) );
            })
            .catch(() => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorSavingLastNitrogenSampleAndDoses(StringTranslate.noResultLastNitrogenInputSavedSampleAndDoses) );
            });   
    }
}

//Avertit le début de la sauvegarde d'une modulation de dernier apport azoté
/*export*/ const ActionStartSavingLastNitrogenSampleAndDoses = () => ({
    type: SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES,
})

//Avertit la fin de la sauvegarde d'une modulation de dernier apport azoté
/*export*/ const ActionLastNitrogenSampleAndDosesSaved = (newLastNitrogenInputValue) => ({
    type: SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES,
    newLastNitrogenInput: newLastNitrogenInputValue
})

//Avertit de l'erreur survenue lors de la sauvegarde d'une modulation de dernier apport azoté
/*export*/ const ActionErrorSavingLastNitrogenSampleAndDoses = (errorMessage) => ({
    type: ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES,
    errorMessage: errorMessage
})

// #region ↓↓ CHOIX DES COUCHES ↓↓
export function ActionSelectLayerForLastNitrogenInput(baseLayertypeValue, forewardLayertypeValue) {
    return function (dispatch, getState) {
        //Besoin d'actualiser le choix des couches si une parcelle est sélectionnée et corresponds à un type spécifique 'Parcelle':
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleParcelLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviParcelLayerName))) {
            const currentStoreState = getState();
            // 1- récupération de la parcelle sélectionnée
            const currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            if (currentParcelIdSelected > 0)
                dispatch(ActionSelectLayerOfParcelForLastNitrogenInput(forewardLayertypeValue, currentParcelIdSelected));
        }

        // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
        // Valeurs possibles pour 'forewardLayertypeValue' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
        return dispatch({
            type: SELECT_LAYER_FOR_LAST_NITROGEN_INPUT,
            baseLayertypeForLastNitrogenInput: baseLayertypeValue,
            forewardLayertypeForLastNitrogenInput: forewardLayertypeValue,
        });
    }
}
// #endregion ↑↑ CHOIX DES COUCHES ↑↑

export function ActionGoToSelectImage(parcelId) {
    return function (dispatch, getState) {
        dispatch(ActionSelectParcel(parcelId)); //TODO: Attention ! Il se peut qu'on est pas fini de tout faire avant de réaliser la ligne suivante
        dispatch(ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.MAP));
    }
}

export function ActionGoToModifyModulation(parcelId) {
    return function (dispatch, getState) {
        dispatch(ActionSelectLastNitrogenInput(parcelId));
        dispatch(ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.RESULT));
    }
}

export function ActionGoToSelectParcelLastNitrogen(parcelId) {
    return function (dispatch, getState) {
        dispatch(ActionSelectLastNitrogenInput(parcelId));
        dispatch(ActionGoToStepOfLastNitrogenInput(LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS));
    }
}

export function ActionCreateLastInputNParameter (lastInputNDatas){
    return {
        type: CREATE_LAST_NITROGEN_INPUT_PARAMETER,
        lastInputNParameter: lastInputNDatas
    }
}