import lodashGet from 'lodash/get';
import { modulationWebApiProvider } from '../../utils/webApiProvider.js';
import { ActionSelectParcel, ActionGoToModulationFromMap } from '../actions/contextApp';
import { ActionAddTheLastSelectedSatImagesToSetOnMap } from '../actions/satImage.js';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { ActionSelectLayerOfParcelForModulation, ActionUpdateParcelHasModulation } from '../actions/parcels';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';// et utilisé comme ceci: ${StringTranslate.xxx}
import numberHelper from '../../utils/numberHelper.js';
import { ModulationsHelper } from '../../utils/modulationsHelper.js';
import dateHelper from '../../utils/dateHelper';
import { IsNativeHoster } from '../../utils/platformHelper';


export const GO_TO_TAB_OF_MODULATION = 'GO_TO_TAB_OF_MODULATION';
export const ERROR_LOAD_MODULATIONS = 'ERROR_LOAD_MODULATIONS';
export const LOADING_MODULATIONS = 'LOADING_MODULATIONS';
export const LOAD_MODULATIONS = 'LOAD_MODULATIONS';
export const SELECT_MODULATIONS = 'SELECT_MODULATIONS';
export const SELECT_MODULATION = 'SELECT_MODULATION';
export const GO_TO_NEXT_STEP_OF_MODULATION = 'GO_TO_NEXT_STEP_OF_MODULATION';
export const GO_TO_STEP_OF_MODULATION = 'GO_TO_STEP_OF_MODULATION';
export const CLEAR_NEW_MODULATION = 'CLEAR_NEW_MODULATION';
export const UP_NEW_MODULATION = 'UP_NEW_MODULATION';
export const GET_ALL_MODULATIONS_BY_CLIENTID = 'GET_ALL_MODULATIONS_BY_CLIENTID';
export const OPEN_CONGRATULATIONS_DIALOG = 'OPEN_CONGRATULATIONS_DIALOG';
export const ADD_MODULATION = 'ADD_MODULATION';
export const DELETING_MODULATION = 'DELETING_MODULATION';
export const DELETE_MODULATION = 'DELETE_MODULATION';
export const DELETING_MODULATIONS = 'DELETING_MODULATIONS';
export const DELETE_MODULATIONS = 'DELETE_MODULATIONS';
export const START_DOWNLOAD_MODULATION = 'START_DOWNLOAD_MODULATION';
export const ERROR_DOWNLOAD_MODULATION = 'ERROR_DOWNLOAD_MODULATION';
export const DOWNLOAD_MODULATION_END = 'DOWNLOAD_MODULATION_END';
export const CLEAR_MODULATION_VALUE = 'CLEAR_MODULATION_VALUE';
export const SELECT_LAYER_FOR_MODULATION = 'SELECT_LAYER_FOR_MODULATION';
export const START_BUILD_MODULATION = 'START_BUILD_MODULATION';
export const ERROR_BUILD_MODULATION = 'ERROR_BUILD_MODULATION';
export const CLEAN_ERROR = 'CLEAN_ERROR';
export const GET_LAST_MODULATION_SETTINGS_LOADING = 'GET_LAST_MODULATION_SETTINGS_LOADING';
export const GET_LAST_MODULATION_SETTINGS_LOADED = 'GET_LAST_MODULATION_SETTINGS_LOADED';
export const ADD_PRESCRIPTION = 'ADD_PRESCRIPTION';
export const INITIALIZE_PRESCRIPTION = 'INITIALIZE_PRESCRIPTION';
export const GENERATING_MODULATION_FILE = 'GENERATING_MODULATION_FILE';
export const MODULATION_FILE_GENERATED = 'MODULATION_FILE_GENERATED';

/**
 * Les différents Tabs de la Modulation
 */
export const ModulationTab = {
    NewModulation: 0,
    MyModulations: 1,
    OptimalNitrogenDose: 2,
}

/* Les différentes étapes de la modulation */
export const ModulationStep = {
    CHOIX_PARCELS: 'CHOIX_PARCELS',
    MAP: 'MAP',
    DOSES: 'DOSES',
    CONGRATULATIONS: 'CONGRATULATIONS'
}

export const SupplyType = {
    Solid: 'Solid',
    Liquid: 'Liquid'
}

export const ActionTypeModulation = {
    GO_TO_TAB_OF_MODULATION: GO_TO_TAB_OF_MODULATION,
    ERROR_LOAD_MODULATIONS: ERROR_LOAD_MODULATIONS,
    LOADING_MODULATIONS: LOADING_MODULATIONS,
    LOAD_MODULATIONS: LOAD_MODULATIONS,
    SELECT_MODULATIONS: SELECT_MODULATIONS,
    GO_TO_NEXT_STEP_OF_MODULATION: GO_TO_NEXT_STEP_OF_MODULATION,
    GO_TO_STEP_OF_MODULATION: GO_TO_STEP_OF_MODULATION,
    CLEAR_NEW_MODULATION: CLEAR_NEW_MODULATION,
    UP_NEW_MODULATION: UP_NEW_MODULATION,
    GET_ALL_MODULATIONS_BY_CLIENTID: GET_ALL_MODULATIONS_BY_CLIENTID,
    SELECT_MODULATION: SELECT_MODULATION,
    OPEN_CONGRATULATIONS_DIALOG: OPEN_CONGRATULATIONS_DIALOG,
    ADD_MODULATION: ADD_MODULATION,
    DELETING_MODULATION: DELETING_MODULATION,
    DELETING_MODULATIONS: DELETING_MODULATIONS,
    DELETE_MODULATION: DELETE_MODULATION,
    DELETE_MODULATIONS: DELETE_MODULATIONS,
    START_DOWNLOAD_MODULATION: START_DOWNLOAD_MODULATION,
    ERROR_DOWNLOAD_MODULATION: ERROR_DOWNLOAD_MODULATION,
    DOWNLOAD_MODULATION_END: DOWNLOAD_MODULATION_END,
    CLEAR_MODULATION_VALUE: CLEAR_MODULATION_VALUE,
    SELECT_LAYER_FOR_MODULATION: SELECT_LAYER_FOR_MODULATION,
    START_BUILD_MODULATION: START_BUILD_MODULATION,
    ERROR_BUILD_MODULATION: ERROR_BUILD_MODULATION,
    CLEAN_ERROR: CLEAN_ERROR,
    GET_LAST_MODULATION_SETTINGS_LOADING: GET_LAST_MODULATION_SETTINGS_LOADING,
    GET_LAST_MODULATION_SETTINGS_LOADED: GET_LAST_MODULATION_SETTINGS_LOADED,
    ADD_PRESCRIPTION: ADD_PRESCRIPTION,
    INITIALIZE_PRESCRIPTION: INITIALIZE_PRESCRIPTION,
    GENERATING_MODULATION_FILE: GENERATING_MODULATION_FILE,
    MODULATION_FILE_GENERATED: MODULATION_FILE_GENERATED,
}

// A changer pour 'EnumDownloadChoiceModulation' lorsque l'on exploitera 'templateDialogModulation' dans la modulation classique et celle de fumure.
export const TypeDownloadChoice = {
    shp: "SHP", //correspond à 1 côté WebApi (et aussi 0 car associé au 'défaut')
    isoXml: "ISOXML", //correspond à 2 côté WebApi
    rds: "RDS", //correspond à 3 côté WebApi
    map: "MAP", //4
    ppf: "PPF", //5
};

export const EnumDownloadChoiceModulation = {
    shp: 1, // SHP
    isoXml: 2, // ISO XML
    rds: 3, // RDS
    map: 4, // Map modulation
    ppf: 5, // ppf
};


export const ActionGoToTabOfModulation = (modulationTab) => ({
    type: GO_TO_TAB_OF_MODULATION,
    modulationTab: modulationTab,
})


const ActionGetLastModulationSettingsLoading = () => ({
    type: GET_LAST_MODULATION_SETTINGS_LOADING,
})

export const ActionGetLastModulationSettingsLoaded = (modulationSettingsValue) => ({
    type: GET_LAST_MODULATION_SETTINGS_LOADED,
    modulationSettings: modulationSettingsValue
})

export const ActionGetLastModulationSettings = (clientId) => {
    return function (dispatch, getState) {
        //Début des téléchargements:
        dispatch(ActionGetLastModulationSettingsLoading());

        //Récupère les settings de la dernière modulation (dose medium, teneur, paramètres):
        modulationWebApiProvider.getLastModulationSettings(dispatch, getState, clientId)
            .then((result) => { // reçoit l'entité C# 'SettingsModulation' !
                //Fin de la récupération des settings de la dernière modulation:
                dispatch(ActionGetLastModulationSettingsLoaded(result));
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de la récupération des settings de la dernière modulation:
                dispatch(ActionErrorLoadModulations(error));
            });
    };
}

/*export const ActionDeletingModulation = () => ({
    type: DELETING_MODULATION,
})*/

/*export*/ const ActionDeletingModulations = () => ({
    type: DELETING_MODULATIONS,
})

/*export const ActionModulationDeleted = (modulationIdToDeleteValue) => ({
    type: DELETE_MODULATION,
    modulationIdDeleted: modulationIdToDeleteValue
})*/

/*export*/ const ActionModulationsDeleted = (modulationIdsToDeleteValue) => ({
    type: DELETE_MODULATIONS,
    modulationIdsDeleted: modulationIdsToDeleteValue
})

export const ActionCleanErrorModulation = () => ({
    type: CLEAN_ERROR,
})

export function ActionDeleteModulations(modulationIdsToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingModulations());

        // Récupère tous les ids des parcelles liés aux modulations allant être supprimés
        const currentStoreState = getState();
        const currentModulationDico = lodashGet(currentStoreState, 'modulationsData.modulationDico', {});
        let parcelIdsToUpdate = [];
        modulationIdsToDelete.forEach(element => {
            const currentModulationToDelete = currentModulationDico[element];
            parcelIdsToUpdate.push(currentModulationToDelete.parcelId);
        });

        modulationWebApiProvider.deleteModulations(dispatch, getState, modulationIdsToDelete)
            .then((response) => {
                dispatch(ActionModulationsDeleted(modulationIdsToDelete));
                // maj des parcelles
                parcelIdsToUpdate.forEach(parcelId => {
                    dispatch(ActionUpdateParcelHasModulation(parcelId, false));
                });
            },
            (error) => {
                //Avertit du mauvais retour: non !
                dispatch(ActionModulationsDeleted(undefined));
            });
    }
}

export function ActionOpenCongratulationsDialog(openCongratulationsDialogValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        //On ouvre OU ferme l'écran de dialogue ( affichait le message de félicitation pour la(les) génération(s) produite(s) ) :
        dispatch({
            type: OPEN_CONGRATULATIONS_DIALOG,
            openCongratulationsDialog: openCongratulationsDialogValue
        });

        //Si on ferme cette fenêtre, on retourne sur la page d'accueil des modulations 
        if (openCongratulationsDialogValue === false) {
            // (en relançant une lecture en bdd des générations existantes :
            const currentStoreState = getState();
            //récupération de l'identifiant client
            const currentClientId = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            dispatch(ActionGetAllModulations(currentClientId));
            // Et en ouvrant ce panneau affichant celles-ci):
            dispatch(ActionGoToStepOfModulation(ModulationStep.CHOIX_PARCELS, undefined, true));
        }
    };
}

export function ActionErrorLoadModulations(errorValue) {
    return {
        type: ERROR_LOAD_MODULATIONS,
        errorMessage: errorValue,
    };
}

/*export*/ const ActionLoadingModulations = () => ({
    //return {
    type: LOADING_MODULATIONS,
    //};
});

/*export*/ function ActionInitModulationDico(modulationDicoValue) {
    return {
        type: LOAD_MODULATIONS,
        modulationDico: modulationDicoValue
    };
}

export const ActionSelectModulations = (parcelIdsSelectedForModulationValue) => ({
    type: SELECT_MODULATIONS,
    parcelIdsSelected: parcelIdsSelectedForModulationValue,

});

export function ActionSelectModulation(modulationSelectedValue, readableModeValue = false) {
    return {
        type: SELECT_MODULATION,
        modulationSelected: modulationSelectedValue,
        readableMode: readableModeValue,
    }
};

export function ActionClearModulationValue() {
    return {
        type: CLEAR_MODULATION_VALUE
    }
};

/*export*/ function ActionModulationDownLoading() {
    return {
        type: START_DOWNLOAD_MODULATION,
    }
};

/*export*/ function ActionModulationDownLoaded() {
    return {
        type: DOWNLOAD_MODULATION_END,
    }
};

/*export*/ function ActionErrorDownLoadModulations(errorValue) {
    return {
        type: ERROR_DOWNLOAD_MODULATION,
        errorMessage: errorValue,
    };
}

/*export function ActionDownLoadModulation(modulationSelectedValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if ((!modulationSelectedValue) ||
            (modulationSelectedValue.clientId === undefined) || (modulationSelectedValue.clientId <= 0) ||
            (modulationSelectedValue.parcelId === undefined) || (modulationSelectedValue.parcelId <= 0) ||
            (modulationSelectedValue.id === undefined) || (modulationSelectedValue.id <= 0)) return;

        //Début des téléchargements:
        dispatch(ActionModulationDownLoading());

        //Télécharge le SHP:
        modulationWebApiProvider.downloadModulationFiles(dispatch, getState,
            modulationSelectedValue.clientId, modulationSelectedValue.parcelId)
            .then(() => { // (resultOfAll) => { //simple booléen !
                //Fin des téléchargements:
                dispatch(ActionModulationDownLoaded());
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de téléchargement:
                dispatch(ActionErrorDownLoadModulations(error));
            });
    };
};*/

/**
 * Action encadrant la demande de génération de la prescription d'une ou plusieurs modulation(s) classique(s) d'un client.
 * @param {Modulation[]} modulationParameterListValue : Liste des entités de modulations sélectionnées.
 * @param {EnumDownloadChoiceModulation} exportFormat : Type de format choisi pour cette prescription.
 */
export function ActionBuildListPrescriptions(modulationParameterListValue, exportFormat) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (!modulationParameterListValue) return;

        //Début de la génération + téléchargement:
        dispatch(ActionNewModulationBuilding());
        dispatch(ActionModulationDownLoading());

        modulationWebApiProvider.buildListPrescriptions(dispatch, getState, modulationParameterListValue, exportFormat)
            .then((isOkResult) => { 
                if ((isOkResult) && (isOkResult === true)) {
                    //Fin des téléchargements:
                    dispatch(ActionModulationDownLoaded());
                } else {
                    dispatch(ActionErrorDownLoadModulations(StringTranslate.badCompletBuildModulation));
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de téléchargement:
                dispatch(ActionErrorDownLoadModulations(StringTranslate.badCompletBuildModulation));
            });
    };
}

export function ActionAddPrescription(modulationParametersValue) {
    return {
        type: ADD_PRESCRIPTION,
        modulationParameters: modulationParametersValue,
    };
}

export function ActionInitializePrescriptions() {
    return {
        type: INITIALIZE_PRESCRIPTION
    };
}

export function ActionGoToStepOfModulation(nextStepValue, readableModeValue, openFirstPanelValue = false) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        const currentStoreState = getState();
        let newOpenFirstPanelValue = openFirstPanelValue;

        //Si on demande à retourner sur l'étape de choix des parcelles (écran de départ) 
        // alors que l'on était en cours de génération de nouvelles modulation 
        // et qu'au moins une nouvelle modulation a été produite => on recharge les modulations complète(s) existante(s)= !
        const hasModulationUpdate = lodashGet(currentStoreState, 'modulationsData.hasModulationUpdate', false);
        if ((nextStepValue === ModulationStep.CHOIX_PARCELS) && (hasModulationUpdate === true)) {
            //récupération de l'identifiant client
            const currentClientId = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            dispatch(ActionGetAllModulations(currentClientId));
            // Et en ouvrant ce panneau affichant celles-ci):
            newOpenFirstPanelValue = true;
        }

        //Signale le changement d'étape (avec le mode lecture seule activable sur l'étape de 'carto'):
        dispatch({
            type: GO_TO_STEP_OF_MODULATION,
            modulationStep: nextStepValue,
            readableMode: (nextStepValue === ModulationStep.MAP) ? false : ((readableModeValue !== undefined) ? readableModeValue : true),
            openFirstPanel: newOpenFirstPanelValue,
        });
    };
}

export function ActionClearNewModulationCounter() {
    return {
        type: CLEAR_NEW_MODULATION,
    };
}

/*export*/ function ActionUpNewModulationCounter(parcelIdModulatedValue) {
    return {
        type: UP_NEW_MODULATION,
        parcelIdModulated: parcelIdModulatedValue
    };
}

export function ActionGoToNextStepOfModulation(nextStepValue, parcelIdsSelectedForModulationValue, readableModeValue, modulationSelectedItemValue = undefined) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        dispatch(ActionSelectModulations(parcelIdsSelectedForModulationValue));
        dispatch(ActionGoToStepOfModulation(nextStepValue, readableModeValue)); //@@faudrait fusionner les deux appels car je sent le bogue planner !

        if (nextStepValue === ModulationStep.MAP) {
            dispatch(ActionSelectParcel(parcelIdsSelectedForModulationValue[0]));

            //Mémoriser les dates des images courantes des parcelles pour pouvoir les remettre à la fin de la fumure, biomasse ou modulation
            const currentStoreState = getState();
            parcelIdsSelectedForModulationValue.forEach(parcelId => {
                const satimageId = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelId}].currentSatimageId`, null);
                const satImageDate = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelId}].currentSatimageDate`, null);
                
                if ((satimageId !== null) && (satImageDate !== null)) {
                    dispatch( ActionAddTheLastSelectedSatImagesToSetOnMap(parcelId, satimageId, satImageDate) );
                }
            });
        } else if (modulationSelectedItemValue) {
            //Ce cas de figure est proche de 'ActionGoToShowThisModulation' (cas de consultation d'une modulation), 
            // mais où l'on souhaite (possibilité) pouvoir refaire /modifier la modulation !

            dispatch(ActionSelectParcel(modulationSelectedItemValue.parcelId));

            //Si on n'a pas l'image, on l'obtient:
            if ((!modulationSelectedItemValue.dataImg) && (!modulationSelectedItemValue.azureImagURL)) {
                modulationWebApiProvider.loadImagModulation(dispatch, getState, modulationSelectedItemValue)
                    .then((newModulation) => {
                        //Affiche l'entité modulation actualisée:                       
                        dispatch(ActionSelectModulation(newModulation, readableModeValue));
                    },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadModulations(error));

                        //Affiche l'entité modulation (sans son image):
                        dispatch(ActionSelectModulation(modulationSelectedItemValue, readableModeValue));
                    });
            } else {
                dispatch(ActionSelectModulation(modulationSelectedItemValue, readableModeValue));
            }
        }
    }
}

export function ActionGoToShowThisModulation(modulationSelectedItem, isAskedFromMap = false) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (modulationSelectedItem) {
            const readableMode = true;

            dispatch(ActionSelectModulations([modulationSelectedItem.parcelId]));

            //Si l'utilisateur demande à voir la modulation en mode lecture => redirection
            if (isAskedFromMap === true) {
                dispatch(ActionGoToModulationFromMap());
            }
            else {
                dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, readableMode));
            }

            dispatch(ActionSelectParcel(modulationSelectedItem.parcelId));

            //Si on n'a pas l'image, on l'obtient:
            if ((!modulationSelectedItem.dataImg) && (!modulationSelectedItem.azureImagURL)) {
                modulationWebApiProvider.loadImagModulation(dispatch, getState, modulationSelectedItem)
                    .then((newModulation) => {
                        //Affiche l'entité modulation actualisée:                    
                        dispatch(ActionSelectModulation(newModulation, readableMode));
                    },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadModulations(error));

                        //Affiche l'entité modulation (sans son image):
                        dispatch(ActionSelectModulation(modulationSelectedItem, readableMode));

                    });
            } else {
                dispatch(ActionSelectModulation(modulationSelectedItem, readableMode));
            }
        } //else //ne fait rien !
    }
}

export function ActionGoToShowThisModulationByParcelId(parcelId) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (parcelId) {
            const readableMode = true;
            dispatch(ActionSelectModulations([parcelId]));
            dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, readableMode));

            dispatch(ActionSelectParcel(parcelId));

            modulationWebApiProvider.loadModulationByParcelId(dispatch, getState, parcelId)
                .then((newModulation) => {
                    //Affiche l'entité modulation actualisée:                    
                    dispatch(ActionSelectModulation(newModulation, readableMode));
                },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadModulations(error));

                        //Affiche l'entité modulation (sans son image):
                        //dispatch(ActionSelectModulation(newModulation, readableMode));

                    });
        }
    } //else //ne fait rien !
}

/*export*/ const ActionGeneratingModulationFile = () => ({
    type: GENERATING_MODULATION_FILE,
})

/*export*/ const ActionModulationFileGenerated = () => ({
    type: MODULATION_FILE_GENERATED,
})

export function ActionGenerateFileOfThisModulation(modulationSelectedItem) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (modulationSelectedItem) {
            //Affiche l'attente:
            dispatch( ActionGeneratingModulationFile() );

            //Génère le pdf de cette modulation:
            //modulationWebApiProvider.GenerateModulationPdf(dispatch, getState, modulationSelectedItem, false, true) //demande le stockage du fichier Pdf !
            modulationWebApiProvider.GenerateModulationPdf(dispatch, getState, modulationSelectedItem, false, false) //demande le téléchargement du fichier Pdf !
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch( ActionModulationFileGenerated() );
                    
                    //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionModulationFileGenerated() );
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadModulations(error));
                });
        } //else //ne fait rien !
    }
}

export function ActionBuildModulationSample(idClient, modulationParameter) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const canBuildFromTypo = lodashGet(currentStoreState, 'clientUserData.clientDatas.authorizeModulation', false);

        if (canBuildFromTypo === true) { //Droit accordé !
            //Lance l'attente de génération:
            dispatch(ActionNewModulationBuilding());

            modulationWebApiProvider.buildModulationSample(dispatch, getState, idClient, modulationParameter)
                .then((modulation) => {
                    //Si le retour est positif mais qu'il indique une 'invalidité' du nombre de zones, on avertit l'utilisateur:
                    if (modulation && (modulation.isValidZones === false)) {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildModulation(
                            StringTranslate.formatString(StringTranslate.unvalidZonesFromBuild, (modulationParameter.zonesCounter - 1))
                        ));
                    } else { //on est ok, on affiche le résultat !
                        // on limite les décimales ici sur les valeurs calculées:
                        modulation.doseNominal = numberHelper.fixeDecimal(modulation.doseNominal);
                        modulation.doseMedium = numberHelper.fixeDecimal(modulation.doseMedium);

                        /* pour la toute première génération, nous recevons des ratios. Pour chaque ratio reçu, nous
                        réduisons le nombre de décimales */
                        if (modulation.ratios !== undefined) {
                            modulation.ratios.forEach((itemRatio) => {
                                itemRatio.ratio = numberHelper.fixeDecimal(itemRatio.ratio, 4);
                            });
                        }

                        if (modulation.zones !== undefined) {//En principe, pas présent !
                            modulation.zones.forEach((itemZone) => {
                                itemZone.actualDose = numberHelper.fixeDecimal(itemZone.actualDose);
                            });
                        }

                        //RQ: Appelé sans fournir la teneur (en principe) donc pas besoin de faire appel à 'clearValuesIfContentDefined' !
                        // Assigne les nouvelles données générées:
                        dispatch(ActionSelectModulation(modulation));

                        //Si on était sur l'étape de choix de la date de clichet, on passe sur l'étape de choix des doses:
                        const currentStep = lodashGet(currentStoreState, 'modulationsData.modulationStep', ModulationStep.MAP);
                        if (currentStep === ModulationStep.MAP) {
                            dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, false));
                        } //else //on est déjà dessus en principe !
                    }
                },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildModulation(error));
                    });

        }
        //else //Client pas autorisé à générer !
    }

}

/**
 * Rebuild la modulation avec les nouvelles données
 * @param {*} idClient 
 * @param {*} modulationParameter 
 */
export function ActionBuildModulationSampleAndDoses(idClient, modulationParameter) {
    return function (dispatch, getState) {
        if (!modulationParameter) return;
        const currentStoreState = getState();
        const canBuildFromTypo = lodashGet(currentStoreState, 'clientUserData.clientDatas.authorizeModulation', false);

        if (canBuildFromTypo === true) { //Droit accordé !
            let canBuildDoses = false;


            // Lancement de la génération d'image OU image+doses suivant les options choisies:
            //->Soit on est en dosage automatique ('DoseChange') à vrai et on a la dose nominale => buildModulationSampleAndDoses
            if ((modulationParameter.doseChange === true) && 
                (modulationParameter.doseNominal) && (modulationParameter.doseNominal > 0)) {
                canBuildDoses = true;
            }
            //->Soit on est en dosage manuel et l'utilisateur nous a renseigné chaque dose => buildModulationSampleAndDoses
            else if ((modulationParameter.doseChange === false) && (modulationParameter.zones)) {
                //vérifie la présence de toutes les doses:
                let firstIndexZoneWithoutDose = -1; //index de la zone sans dose que l'on aura trouvé.
                modulationParameter.zones.forEach((zoneItem, index) => {
                    if ((!zoneItem) || (numberHelper.testDataValid(zoneItem.actualDose) !== true)) {
                        firstIndexZoneWithoutDose = index; // ou zoneNumber ?!
                    }
                });

                if (firstIndexZoneWithoutDose < 0) {
                    canBuildDoses = true;
                }
            }
            //else {//->Sinon (tous autres cas) => buildModulationSample !

            if (canBuildDoses === true) { //Génération avec les doses !
                const modulationState = lodashGet(currentStoreState, 'modulationsData.modulationSelected', undefined);

                let nbZones = modulationParameter.zonesCounter;
                //On n'augmente ni diminue le nombre de zones,
                //On n'a pas changer de date non-plus 
                //On ne change pas de fournisseur non-plus => alors génération côté React:
                if ((modulationState) && (modulationState.zonesCounter === nbZones) &&
                    (dateHelper.isSameDateDDMMYYYY(modulationState.dateImagSource, modulationParameter.dateImagSource)) &&
                    (modulationState.providerImagSource === modulationParameter.providerImagSource)
                ) {
                    //Si on arrive ici, on a forcèment eu une première génération !
                    // Mais pas forcement avec la liste des doses (peut-être que les ratios)

                    //crée une nouvelle instance pour que les visuels détecte bien les changements
                    let newModulation = { ...modulationState };
                    try {
                        //Génération 'dosage automatique' des doses à partir de la dose nominale !
                        if (modulationParameter.doseChange === true) {
                            //Lance l'attente de génération:
                            dispatch(ActionNewModulationBuilding());

                            let doseNominale = parseFloat(modulationParameter.doseNominal);
                            //on n'appelle jamais avec la teneur ! Cf. 'dosesModulation.jsx' afin de toujour manipuler des Unité d'azote
                            // c'est l'affichage qui décidera d'afficher suivant la bonne unité (U ou Kg|L) suivant la présence ou non de la teneur.
                            let content = (modulationParameter.content) ? modulationParameter.content : undefined;
                            let optimisation = modulationParameter.distributionType;
                            let repartition = modulationParameter.dispersion;

                            let ratios = [];
                            let zones = [];
                            //RQ: si on est arrivé là, c'est que l'on a au moins les ratios !
                            if (modulationState.zones) { //autant utiliser ceux de la précédente génération car même image (date) et même nombre de zones !
                                modulationState.zones.forEach(zoneItem => ratios.push(zoneItem.ratio));
                            } else if (modulationState.ratios) { //autant utiliser ceux de la précédente génération car même image (date) et même nombre de zones !
                                modulationState.ratios.forEach(ratioItem => ratios.push(ratioItem.ratio));
                            } else {
                                modulationParameter.zones.forEach(ratioItem => ratios.push(ratioItem.ratio));
                            }

                            const automaticDoses = ModulationsHelper.get_Doses_Auto(nbZones, ratios, doseNominale, content, optimisation, repartition);
                            
                            // Si on a des zones, s'assurer qu'il existe des actualDoses dans chaque dose même si on trouve des initialDoses = 0
                            // Si on ne trouve pas de initialDose, alors on créé un objet contenant : 
                            //clientId, color, id, modulationId, parcelId, ratio, initialDose, actualDose, zoneNumber
                            if (modulationState.zones && (modulationState.zones[0])) {
                                if (numberHelper.testDataValid(modulationState.zones[0].actualDose) !== true) { //@@A mon avis si 'zones est là, on a aussi 'actualDose' ! (à ne pas confondre avec 'modulationParameter')
                                    modulationState.zones.forEach((ratio, index) => zones.push(
                                        {
                                            clientId: ratio.clientId,
                                            color: ratio.color,
                                            id: ratio.id,
                                            modulationId: ratio.modulationId,
                                            parcelId: ratio.parcelId,
                                            ratio: ratio.ratio,
                                            actualDose: numberHelper.fixeDecimal(automaticDoses[index]),
                                            zoneNumber: ratio.zoneNumber,
                                        }
                                    ));
                                    //On a des nuages, la dernière génération aura déjà retourné une zone de plus ! => rien à faire de plus ici.

                                    newModulation.zones = zones;
                                }
                                else {
                                    //Rappel: 'newModulation' est une copie de 'modulationState' 
                                    newModulation.zones.forEach((zoneItem, index) => {
                                        //ratio //Ne devrait pas avoir changé !
                                        zoneItem.actualDose = numberHelper.fixeDecimal(automaticDoses[index]);
                                    });
                                    //On a des nuages, la dernière génération aura déjà retourné une zone de plus ! => rien à faire de plus ici.
                                }

                                newModulation.doseChange = true;
                                newModulation.supplyType = modulationParameter.supplyType;
                                newModulation.distributionType = modulationParameter.distributionType;
                                newModulation.doseNominal = numberHelper.fixeDecimal(doseNominale);
                                newModulation.doseMedium = modulationState.doseMedium;
                                newModulation.content = content;
                                newModulation.isValidZones = true;
                                newModulation.cloudShadowDetected = modulationState.cloudShadowDetected;
                                newModulation.ratios = undefined;

                                //RQ: Appelé sans fournir la teneur (en principe) donc pas besoin de faire appel à 'clearValuesIfContentDefined' !
                                //on est ok, on affiche le résultat !
                                dispatch(ActionSelectModulation(newModulation));
                            }
                            else { //si pas de liste de zones, on a au moins la liste des ratios !
                                //On crée la liste des zones à partir de la liste des ratios:
                                if (modulationState.ratios) {//RQ: si on est arrivé là, c'est que l'on a au moins les ratios !
                                    modulationState.ratios.forEach((ratioItem, index) => zones.push(
                                        {
                                            clientId: ratioItem.clientId,
                                            color: ratioItem.color,
                                            id: ratioItem.id,
                                            modulationId: ratioItem.modulationId,
                                            parcelId: ratioItem.parcelId,
                                            ratio: ratioItem.ratio,
                                            actualDose: numberHelper.fixeDecimal(automaticDoses[index]),
                                            zoneNumber: ratioItem.zoneNumber,
                                        }
                                    ));
                                    //On a des nuages, la première génération (générant que l'image) aura déjà retourné une zone de plus ! => rien à faire de plus ici.
                                } else { //A défaut, on exploite ceux des paramètres fournis:
                                    modulationParameter.zones.forEach((ratioItem, index) => zones.push({
                                            clientId: modulationParameter.clientId,
                                            color: 0xFFFFFFFF,
                                            id: -1,
                                            modulationId: -1,
                                            parcelId: modulationParameter.parcelId,
                                            ratio: ratioItem.ratio,
                                            actualDose: numberHelper.fixeDecimal(automaticDoses[index]),
                                            zoneNumber: index,
                                        }
                                    ));
                                }

                                newModulation.doseChange = true;
                                newModulation.supplyType = modulationParameter.supplyType;
                                newModulation.distributionType = modulationParameter.distributionType;
                                newModulation.doseNominal = numberHelper.fixeDecimal(doseNominale);
                                newModulation.doseMedium = modulationState.doseMedium;
                                newModulation.content = content;
                                newModulation.isValidZones = true;
                                newModulation.cloudShadowDetected = modulationState.cloudShadowDetected;
                                newModulation.ratios = undefined;
                                newModulation.zones = zones;

                                //RQ: Appelé sans fournir la teneur (en principe) donc pas besoin de faire appel à 'clearValuesIfContentDefined' !
                                //on est ok, on affiche le résultat !
                                dispatch(ActionSelectModulation(newModulation));
                            }

                            //Si on était sur l'étape de choix de la date de clichet, on passe sur l'étape de choix des doses:
                            const currentStoreState = getState();
                            const currentStep = lodashGet(currentStoreState, 'modulationsData.modulationStep', ModulationStep.MAP);
                            if (currentStep === ModulationStep.MAP) {
                                dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, false));
                            } //else //on est déjà dessus en principe !
                        }
                        else {//Génération 'dosage manuel' en utilisant les doses fournies !
                            //Lance l'attente de génération:
                            dispatch(ActionNewModulationBuilding());
                            
                            let doses = [];
                            let ratios = [];
                            let content = modulationParameter.content ? modulationParameter.content : undefined;

                            //on est obligé de les avoir ! (ne servirai à rien de prendre le ration de la dernière modulation 
                            // car on n'aurai pas les doses nécessaires).
                            if (modulationParameter.zones) {
                                modulationParameter.zones.forEach(zone => {
                                    ratios.push(zone.ratio);
                                    doses.push(zone.actualDose);
                                });
                            } //else le retour d'appel sera négatif !

                            let mediumDoseValue = ModulationsHelper.get_Dose_Moyenne(doses, ratios, content);

                            //Rappel: 'newModulation' est une copie de 'modulationState' 
                            newModulation.doseChange = false;
                            newModulation.supplyType = modulationParameter.supplyType;
                            newModulation.distributionType = modulationParameter.distributionType;
                            newModulation.doseNominal = modulationState.doseNominal;
                            newModulation.doseMedium = numberHelper.fixeDecimal(mediumDoseValue);
                            newModulation.content = content;
                            newModulation.isValidZones = true; //même si ne devrait pas avoir changé !
                            newModulation.cloudShadowDetected = modulationState.cloudShadowDetected;
                            newModulation.ratios = undefined;

                            newModulation.zones.forEach((zoneItem, index) => {
                                //ratio //Ne devrait pas avoir changé !
                                zoneItem.actualDose = numberHelper.fixeDecimal(doses[index]);
                            });

                            //on est ok, on affiche le résultat !
                            dispatch(ActionSelectModulation(newModulation));

                            //Si on était sur l'étape de choix de la date de cliché on passe sur l'étape de choix des doses:
                            const currentStep = lodashGet(currentStoreState, 'modulationsData.modulationStep', ModulationStep.MAP);
                            if (currentStep === ModulationStep.MAP) {
                                dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, false));
                            } //else //on est déjà dessus en principe !
                        }
                    }
                    catch (e) {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildModulation(StringTranslate.detectException));
                    }
                }
                else { //Génération côté web API
                    //Lance l'attente de génération:
                    dispatch(ActionNewModulationBuilding());

                    //Lance l'appel à la Web API:
                    modulationWebApiProvider.buildModulationSampleAndDoses(dispatch, getState, idClient, modulationParameter)
                        .then((modulation) => {
                            //Si le retour est positif mais qu'il indique une 'invalidité' du nombre de zones, on avertit l'utilisateur:
                            if (modulation && (modulation.isValidZones === false)) {
                                //Avertit du mauvais retour:
                                dispatch(ActionErrorBuildModulation(
                                    StringTranslate.formatString(StringTranslate.unvalidZonesFromBuild, (modulationParameter.zonesCounter - 1))
                                ));
                            } else { //on est ok, on affiche le résultat !
                                // on limite les décimales ici sur les valeurs calculées:
                                modulation.doseNominal = numberHelper.fixeDecimal(modulation.doseNominal);
                                modulation.doseMedium = numberHelper.fixeDecimal(modulation.doseMedium);

                                modulation.content = numberHelper.fixeDecimal(modulation.content);

                                if (modulation.zones !== undefined) {
                                    modulation.zones.forEach((itemZone) => {
                                        itemZone.actualDose = numberHelper.fixeDecimal(itemZone.actualDose);
                                    });
                                }
                                
                                //RQ: Appelé sans fournir la teneur (en principe) donc pas besoin de faire appel à 'clearValuesIfContentDefined' !
                                // Assigne les nouvelles données générées:
                                dispatch(ActionSelectModulation(modulation));

                                //Si on était sur l'étape de choix de la date de clichet, on passe sur l'étape de choix des doses:
                                const currentStoreState = getState();
                                const currentStep = lodashGet(currentStoreState, 'modulationsData.modulationStep', ModulationStep.MAP);
                                if (currentStep === ModulationStep.MAP) {
                                    dispatch(ActionGoToStepOfModulation(ModulationStep.DOSES, false));
                                } //else //on est déjà dessus en principe !
                            }
                        },
                            (error) => {
                                //Avertit du mauvais retour:
                                dispatch(ActionErrorBuildModulation(error));
                            });
                }
            } else { //Génération QUE de l'image !
                dispatch(ActionBuildModulationSample(idClient, modulationParameter));
            }
        }
        //else //Client pas autorisé à générer !
    }
}

export function ActionSaveModulation(modulationParameter, withDoses = false) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const canBuildFromTypo = lodashGet(currentStoreState, 'clientUserData.clientDatas.authorizeModulation', false);

        if (canBuildFromTypo === true) { //Droit accordé !
            //Lance l'attente de génération (même si dans ce cas, on ne génère pas, on demande juste l'enregitrement en BdD):
            dispatch(ActionNewModulationBuilding());

            modulationWebApiProvider.saveModulation(dispatch, getState, modulationParameter, withDoses)
                .then((resultCall) => { //attention : de type booléen ou entité 'ModulationDataImag' ou 'ModulationDataDoses' ! (si besoin de regénérer)
                    // RAS !!!
                    //on arrete juste l'attente en cours:
                    dispatch(ActionUpNewModulationCounter(modulationParameter.parcelId));
                    dispatch(ActionUpdateParcelHasModulation(modulationParameter.parcelId, true));
                })
                .catch((error) => {
                    //Avertit du mauvais retour + arrêt de l'attente de génération (même si dans ce cas, on ne génère pas, on demande juste l'enregitrement en BdD)::
                    dispatch(ActionErrorBuildModulation(error));
                });
        }
        //else //Client pas autorisé à accéder aux fonctionnalités de modulation !
    }
}

/*export*/ function ActionNewModulationBuilding() {
    return {
        type: START_BUILD_MODULATION,
    };
}

/*export*/ function ActionErrorBuildModulation(errorValue) {
    return {
        type: ERROR_BUILD_MODULATION,
        errorMessage: errorValue,
    };
}

export function ActionGetAllModulations(clientId) {
    return function (dispatch, getState) {
        // 1- informe que l'on va faire la demande d'obtension des modulations du client:
        dispatch(ActionLoadingModulations());

        // 2- récupération des modulations
        modulationWebApiProvider.loadModulations(dispatch, getState, clientId)
            .then((modulations) => {
                //Fournis les données des modulations du client:
                dispatch(ActionInitModulationDico(modulations));
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadModulations(error));
            });
    }
}

///////////////////////////////////////////////////////////////// ↓↓ CHOIX DES COUCHES ↓↓
export function ActionSelectLayerForModulation(baseLayertypeValue, forewardLayertypeValue) {
    return function (dispatch, getState) {
        //Besoin d'actualiser le choix des couches si une parcelle est sélectionnée et corresponds à un type spécifique 'Parcelle':
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleParcelLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviParcelLayerName))) {
            const currentStoreState = getState();
            // 1- récupération de la parcelle sélectionnée
            const currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            if (currentParcelIdSelected > 0)
                dispatch(ActionSelectLayerOfParcelForModulation(forewardLayertypeValue, currentParcelIdSelected));
        }

        // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
        // Valeurs possibles pour 'forewardLayertypeValue' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
        return dispatch({
            type: SELECT_LAYER_FOR_MODULATION,
            baseLayertypeForModulation: baseLayertypeValue,
            forewardLayertypeForModulation: forewardLayertypeValue,
        });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ CHOIX DES COUCHES ↑↑