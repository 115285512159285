import PropTypes from 'prop-types';


const DATEIMG_NOT_ASK = 'DATEIMG_NOT_ASK';
const DATEIMG_IN_PROGRESS = 'DATEIMG_IN_PROGRESS';
const DATEIMG_ASKED_OK = 'DATEIMG_ASKED_OK';
const DATEIMG_ASKED_NODATE = 'DATEIMG_ASKED_NODATE';
const DATEIMG_ON_ERROR = 'DATEIMG_ON_ERROR';

/* Enuméré définissant les différents status de 'chargement' des dates/images (d'un mois/année, valable aussi au global sur la parcelle ET valable aussi pour tous le parcellaire) */
export const SatimageState = {
    stateNotAsk: DATEIMG_NOT_ASK,
    stateAskOnProgress: DATEIMG_IN_PROGRESS,
    stateAskOk: DATEIMG_ASKED_OK,
    stateAskNoDate: DATEIMG_ASKED_NODATE,
    stateOnError: DATEIMG_ON_ERROR,
};

/**
 * Enuméré pour la réparation d'un mois d'historique
 * @readonly
 * @enum {number}
 */
export const RepairState = {
    Unknown: 0,
    Loading: 1,
    Loaded: 2,
    Error: 3,
}


/**
 * Objet POCO représentant l'état de la demande des dates/images, pour le mois/année définis, pour la parcelle visée
 */
export class StateYearMonthOfSatimages {
    constructor(props) {
        this.year = props.year || 0;
        this.month = props.month || 0;
        this.stateAsk = props.stateAsk || SatimageState.stateNotAsk;
        this.errorMessage = props.errorMessage || '';
        this.satimageCounter = props.satimageCounter || 0;
        this.requestCounter =  props.requestCounter || 1; // nombre de fois - demande d'histo parcelId-mois-année - demandée
        this.stateRepair = props.stateRepair || RepairState.Unknown;
        
        //Infos redondantes:
        this.parcelId = props.parcelId || -1;
    }
}
StateYearMonthOfSatimages.defaultProps = {};
StateYearMonthOfSatimages.propTypes = {
    year: PropTypes.number,
    month: PropTypes.number,
    stateAsk: PropTypes.oneOf([SatimageState.stateNotAsk, SatimageState.stateAskOnProgress, SatimageState.stateAskOk, SatimageState.stateAskNoDate, SatimageState.stateOnError]),
    errorMessage: PropTypes.string,
    satimageCounter: PropTypes.number,
    requestCounter: PropTypes.number,
    stateRepair: PropTypes.oneOf(Object.values(RepairState)),
    parcelId: PropTypes.number,
};
