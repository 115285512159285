import React from 'react';
import { connect } from 'react-redux';

import Menu from '@mui/material/Menu';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

/* Icones */
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import lodashGet from 'lodash/get';

/* Theme Berry */
import { styled } from '@mui/material/styles';
import getTheme from "../../../themes/index.js";
import { TableBody } from '@mui/material';
import SubCard from '../../subLayouts/subCard';
const theme = getTheme();

const IconWrapper = styled('div')({
    transform: 'rotate(25deg)',
    '&> svg': {
        color: theme.palette.common.white,
        [theme.breakpoints.only('xs')]: {
            marginTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '17%'
        },
        width: '79px',
        height: '79px', 
        opacity: '0.35',
        position: "absolute",
        marginTop: '56px',
        marginLeft: '-16px'
    }
});

/**
 * Template des widgets de l'application.
 * @param {String} labelButton : Texte en fonction de la culture du widget
 * @param {String} labelLoading : Texte de chargement des données
 * @param {String} titleCardHeader : Titre donné en fonction de la culture du widget
 * @param {Boolean} dataIsLoaded : Boolean permettant de vérifier le chargement des données
 * @param {React.Component} headTable : rendu associé au titre des colonnes pour le tableau ; ce n'est pas une fonction, comme 'bodyTable' car pas encore eu le besoin de faire appel à du code tel que '.map(item => ....)' !
 * @param {function () => React.Component} bodyTable : Corps du tableau avec les données
 * @param {Boolean} isTable : si true alors on affiche le tableau, si false alors l'appelant défini lui même le contenue
 * @param {React.Component} otherHeaderContent : Contenu défini par l'appelant s'il souhaite définir l'affichage de l'entête
 * @param {function} generateData : Ajout d'un event au click du bouton pour créer une donnée 
 * @param {React.Component} menuItemsList : Fournis une liste d'items qui sera affiché dans le menu du widget
 * @param {React.Component} otherContent : Contenu défini par l'appelant si le tableau n'est pas voulu pour l'affichage (si 'isTable' = false)
 */
class TemplateWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isTable: this.props.isTable,
        };
    }

    setAnchorEl = (anchorEl) => {
        this.setState({ anchorEl });
    };
    
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
    this.setState({ anchorEl: null });
    };
    
    getContent = () => {
        const {labelButton, labelLoading, otherHeaderContent, titleCardHeader, generateData, dataIsLoaded, headTable, bodyTable, menuItemsList, otherContent } = this.props;
        const { anchorEl, isTable } = this.state;
        const openMenu = Boolean(anchorEl);
        // Lorsque les données sont chargés on rentre dans le premier bloc, 
        if (dataIsLoaded === true) { 
            return (
                <>
                    <CardHeader
                        action={
                            (!menuItemsList) ? (otherHeaderContent) : (
                            <IconButton key="defaultBtn" aria-label="settings" onClick={(event) => this.handleClick(event)}>
                                <MoreHorizIcon className="widget-color" />
                            </IconButton>       
                            )
                        }
                        title={titleCardHeader}
                    />
                    {/* Dans certains cas le menu de droite ne sera pas utile alors on vérifie si vide ou non et on l'affiche en conséquence */}
                    {(!menuItemsList) ? (null) : (            
                        <Menu 
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={this.handleClose}
                            keepMounted
                        >   
                            {menuItemsList}
                        </Menu>)
                    }
                    
                    <Divider />
                    {/* On affiche un contenue qui est gérer par le widget en lui même, cela permet d'afficher un tableau, un graphique ou autre */}
                    <CardContent>
                        <Grid container spacing={0}>
                            {(isTable === true) ? (
                                <TableContainer className="widget-table-container">
                                    <Table size="small">
                                        <TableHead>
                                            {headTable}
                                        </TableHead>
                                        <TableBody>
                                            {bodyTable()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (otherContent)} 
                        </Grid>
                    </CardContent>
                </>
            );
        } else if (dataIsLoaded === null) { // Si aucune données encore charger alors affiche un message d'attente             
            return (
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="body1" gutterBottom style={{ textAlign: "center" }}>{labelLoading}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                </CardContent>
            );
        } else { // Et si aucune données n'est détecter en retour d'api alors on propose la création
            return (
                <CardContent sx={{ backgroundColor: theme.palette.grey[300] }}>
                    <IconWrapper><InfoOutlinedIcon /></IconWrapper>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item sm={12}>
                            <Button
                                aria-label="show-infos"
                                color="primary"
                                variant="contained"
                                onClick={generateData}
                            >
                               <AddCircleIcon sx={{mr: 1}} /> {labelButton}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            );
        }
    }

    render () {
        return (
            <SubCard title={false} content={false} sx={{ zIndex: "1100" }}>
                {this.getContent()}
            </SubCard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(TemplateWidget);


