//énuméré des cultures, au sens général, connues et reconnues :
export const enumCultureGlobal = {
    unknown: 0,
    ble: 1,
    orge: 2,
    colza: 3,
    mais: 4,
}


//énuméré permettant la gestion d'un conseil Azoté (Equivalent à 'AvailableCropEnum' côté C#) !
export const enumCultureFertilizer = {
    unknown: 0,
    ble_hiver: 1,
    orge_hiver: 2,
    colza_hiver: 3,
    ble_dur_hiver: 4,
}

export const enumCultureHarvest = {
    None: 0,
    Corn: 1
}