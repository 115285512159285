import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { Box, Grid } from "@mui/material";
import '../../assets/css/profil.css'
import ConfigAuthent from '../../utils/configAuthent';

import StripeInfoCard from '../stripe/stripeInfoCard';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';

import UserInfo from './userInfo'
import FarmInfo from './farmInfo'
import AdresseFacturationInfo from './adresseFacturationInfo'
import MainCard from "../subLayouts/mainCard.jsx";
import StringTranslate from "../../assets/i18n/stringLanguage";

import { ActionGetClientBilling, ActionCreateStripeCustomer } from "../../redux/actions/clientUser";


class MonExploit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: props.language,
            clientDatas: props.clientDatas,
            onEdit_Naming: true, // permet de mettre tout le formulaire en édition 
            onEdit_Settings: true,

            stripe: null,
        }
        
        this.stripeInfoCardRef = React.createRef();
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        /* Enregistrement dans Redux du nom de la page actuelle */

        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
            });
        }
    }

    render() {
        //const { hasStripeLink } = this.props; //TODO : A remettre lorsque l'on aura le temps de mettre en place le bouton navigeant vers 

        return (
            <Box
                sx={{
                    height:"fit-content"
                }}
            >
                <StripeProvider stripe={this.state.stripe}>
                    <Grid container spacing={3} id="grid_master_ExploitInfo"
                    >
                        <Grid item xs={12} xl={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <MainCard title={StringTranslate.myaccount}>
                                        <FarmInfo/>
                                    </MainCard>
                                </Grid>
                                <Grid item xs={12} sx={{display:{xs:"none", md:"none", xl:"block"}}}>
                                    <MainCard title={StringTranslate.cartebanc}>
                                        <Elements locale={this.state.language} ref={this.stripeInfoCardRef} >
                                            <StripeInfoCard {...this.props} />
                                        </Elements>
                                        {/*(hasStripeLink !== true) && (<ajout d'un bouton pour aller sur la page des abonnements)*/}
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <MainCard title={StringTranslate.infoUsers}>
                                <UserInfo/>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <MainCard title={StringTranslate.adresseLabelCard}>
                                <AdresseFacturationInfo/>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} xl={4} sx={{display:{xs:"block", md:"block", xl:"none"}}}>
                            <MainCard title={StringTranslate.cartebanc}>
                                <Elements locale={this.state.language} ref={this.stripeInfoCardRef} >
                                    <StripeInfoCard {...this.props} />
                                </Elements>
                            </MainCard>
                        </Grid>
                    </Grid>
                </StripeProvider>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    language: lodashGet(state, 'settingsData.settings.language', ''),
    clientDatas: lodashGet(state, 'clientUserData.clientDatas', {}),
    codeCountry: lodashGet(state, 'settingsData.settings.codeCountry', ''),
    hasStripeLink: lodashGet(state, 'clientUserData.clientDatas.hasStripeLink', false),
    creditCard: lodashGet(state, 'clientUserData.clientDatas.creditCard', {}),
})

const mapDispatchToProps = dispatch => ({
    getClientBilling: (limitInvoices) => dispatch(ActionGetClientBilling(limitInvoices)),
    createStripeCustomer: (cardToken) => dispatch(ActionCreateStripeCustomer(cardToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MonExploit);
