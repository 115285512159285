import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsNil from 'lodash/isNil';
import lodashFind from 'lodash/find';

/* Components MUI */
import {
    Dialog, DialogContent, FormControl, TextField, DialogTitle,
    Grid, InputAdornment, Typography, DialogActions,
    Button, Stack, ButtonGroup, FormHelperText, Autocomplete, CircularProgress, Alert, Checkbox,
} from '@mui/material';

/* DatePicker */
import CustomDatePicker  from '../../customDatePicker.jsx';

/* Actions Redux */
import {
    ActionShowSpecificInfosManagementPopup, ActionGetSpecificListSoilKind,
    ActionSaveSpecificInfosManagement, ActionUpdateLocalisationAndReload, FertilizerEngineEnum
} from '../../../redux/actions/fertilizer';
import { ActionShowProfilMenuDialog } from '../../../redux/actions/contextApp';
import { ProfilIndex } from '../../../redux/actions/contextApp';

/* Traduction */
import StringTranslate from '../../../assets/i18n/stringLanguage';

/* Icons MUI */
import { InfoOutlined, Remove, Save } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import Help from '@mui/icons-material/Help';

/* Components React */
import MainCard from '../../subLayouts/mainCard';

/* Utils */
/* pour la recherche/modification de la localisation d'une parcelle */
import { geoSearch } from '../../../utils/api_search_geo';
import sendError from '../../../utils/errorService';
import { validatePostalCode } from '../../profil/userInfo';
import fertilizerHelper from '../../../utils/fertilizerHelper';
import dateHelper from '../../../utils/dateHelper';
import { enumCultureFertilizer } from '../../../models/constantEnumCulture.js';

/* Thème Berry */
import getTheme from '../../../themes';


let theme = getTheme();

const disabled_habitudeTypeApportOrga = { id: "1", label: StringTranslate.no, code: "1" };
const disabled_habTypeCultInter = { id: "1", label: StringTranslate.noFrequency, code: "1" };
const disabled_dureePrairie = { id: "1", label: StringTranslate.noMeadow, code: "0" };
const theGood_gestionDesResidusPrec = { id: "1", label: StringTranslate.buried, code: "0" };

const codeCountry = "FR";

const themeColorForNotEmptyField = theme.palette.primary[100];

const backToBlankAutocompleteValue = null;
const backToBlankTextFieldValue = "";
const backToBlankDateValue = null;

class DialogSpecificInfosEntry extends Component {
    constructor(props) {
        super(props);

        let fertilizer = undefined;
        if (props.fertilizerDicoCounter > 0) {
            fertilizer = fertilizerHelper.getFertilizerByParcelIdAndCropType(props.fertilizerDico, props.parcelId, props.cultureCrop.cropType);
        }

        const codePostal = lodashGet(props, 'parcelLocalisation.codePostal', undefined);

        const init_gestionDesResidusPrec = fertilizerHelper.getItemFromList(props.prevTailingManagments, fertilizer, "previousTailingsManagement", null, null);

        const initDatePloughing =
            (
                (lodashGet(init_gestionDesResidusPrec, 'id', null) === theGood_gestionDesResidusPrec.id)
            ) ?
                fertilizerHelper.getValue(fertilizer, 'previousPloughingDate', null, null, true) : null;

        let initDateDeSemi = fertilizerHelper.getSowingDateValue(fertilizer, 'sowingDate', props.commonInfo_SowingDate, props.parcelSowingDate);
        if (initDateDeSemi === null) { initDateDeSemi = fertilizerHelper.getDefaultSowingDateByCulture(props.cultureCrop) };

        let initDateDeRecolte = fertilizerHelper.getValue(fertilizer, 'harvestDate', props.commonInfo_harvestDate, null, true);
        if (initDateDeRecolte === null) { initDateDeRecolte = fertilizerHelper.getDefaultHarvestDateByCulture(props.cultureCrop) };

        /* Variables concernant les données de l'année passée */
        const previousCropArchived = fertilizerHelper.getItemFromList(props.previousCropList, props.archivedFertilizer, "crop", null, null);
        const soilTypeArchived = fertilizerHelper.getItemFromList(props.typeDeSolList, props.archivedFertilizer, "soilType", null, null);
        const pebbleRateArchived = fertilizerHelper.getValue(props.archivedFertilizer, 'pebbleRate', undefined, null, false);
        const organicFrequencyArchived= fertilizerHelper.getItemFromList(props.organicInputFrequencies, props.archivedFertilizer, "organicFrequency", null, null);
        const intermediateCropFrequencyArchived = fertilizerHelper.getItemFromList(props.interCropFrequency, props.archivedFertilizer, "intermediateCropFrequency", null, null);
        const harvestDateArchived = fertilizerHelper.getValue(props.archivedFertilizer, 'harvestDate', undefined, null, false);

        const anneeCampagne = (initDateDeSemi !== null) ? (initDateDeSemi.getFullYear() + 1) : null;
        const initHasApportOrga = (fertilizer !== undefined) ? fertilizer.hasOrganicInput : false;
        const initDatePrevHarvest = fertilizerHelper.getValue(fertilizer, 'previousHarvestDate', null, (harvestDateArchived !== null) ? harvestDateArchived : fertilizerHelper.getDefaultPreviousHarvestDateByCulture(anneeCampagne), true);
        const init_commonInfo_content = (props.commonInfo_content !== 0) ? props.commonInfo_content : null;
        const init_labourCheckBox = (fertilizer !== undefined) ? fertilizer.ploughing : props.commonInfo_ploughing;

        //Les données communes en fonction du moteur de fumure de la parcelle
        const objProdFromCommonInfos = lodashGet(props.commonInfo_productionTarget, `commonListElements.Azofert`, undefined);
        const fertilizerTypeFromCommonInfos = lodashGet(props.commonInfo_fertilizerType, `commonListElements.Azofert`, undefined);
        const tailingManagementFromCommonInfos = lodashGet(props.commonInfo_tailingsManagement, `commonListElements.Azofert`, undefined);
        const interCropTypeFromCommonInfos = lodashGet(props.commonInfo_interCropType, `commonListElements.Azofert`, undefined);
        const organicInputFrequencyFromCommonInfos = lodashGet(props.commonInfo_organicInputFrequency, `commonListElements.Azofert`, undefined);
        const interCropFrequencyFromCommonInfos = lodashGet(props.commonInfo_intermediateCultureFrequency, `commonListElements.Azofert`, undefined);

        const objProdBle_init = fertilizerHelper.getItemFromList(props.productionTargets, fertilizer, "productionTarget", objProdFromCommonInfos, null);

        const isImprovingCrop = fertilizerHelper.getValue(fertilizer, 'isImprovingCrop', undefined, false, false)

        this.state = {
            parcelFertilizerData: fertilizer,

            // Les valeurs par défaut:
            // Dates : null => si undefined alors la date du jour est montrée par défaut, si null alors le champ est vide (ce qui est ce que nous voulons dans ce cas)
            // Textfield: undefined
            // Autocomplete: null => si undefined alors en cas de modifs le champ passe de uncontrolled à controlled ce qui déclenche des warnings

            // Variables CheckBox
            isImprovingCrop: isImprovingCrop,

            // Variables Switch
            hasApportOrga: initHasApportOrga,
            irrigation: (fertilizer !== undefined) ? fertilizer.isIrrigated : false,
            // Si on a une valeur
            //  => si c'est analysé => false
            // sinon true (true étant la VPD)
            // remarque: si c'était une autre valeur que analysé ou valeurRégionale => VPD 
            reliquatIsAvg: ((!lodashIsNil(fertilizer)) && (fertilizer.relicType === fertilizerHelper.relicTypeEnum.analyse)) ?
                                false
                            : 
                                true,
            labourCheckBox: init_labourCheckBox,
            natureEngraisIsSolide: ((fertilizer !== undefined) && (fertilizer.supplyType === true)) ? false : true, //côté C# 'supplyType' est solide si le bool est à faux !

            // Variables avec des datePicker
            dateDeSemi: initDateDeSemi,
            dateDeRecolte: initDateDeRecolte,
            dateApportAO: fertilizerHelper.getValue(fertilizer, 'organicInputDate', null, fertilizerHelper.getDefaultOrganicInputDateByCulture(anneeCampagne, initHasApportOrga), true),
            dateDeRecoltePrec: initDatePrevHarvest,
            reliquatDate: fertilizerHelper.getValue(fertilizer, 'relicDate', null, null, true),
            // la valeur par défaut de la date d'enfouissement si il n'y en a pas une déjà enregistrer dans les données specifiques sera dateDeRecoltePrec + 1 jour
            dateEnfouissementPrec:
                (
                    (lodashGet(init_gestionDesResidusPrec, 'id', null) === theGood_gestionDesResidusPrec.id) ?
                        ((initDatePloughing === null) ?
                            (
                                initDatePrevHarvest === null ? null : new Date(initDatePrevHarvest).setDate(initDatePrevHarvest.getDate() + 1)
                            )
                            : initDatePloughing
                        )
                        :
                        null
                ),
            onblurDataName: undefined, //pas une date mais contient de celle dont l'utilisateur vient de quitter la saisie Date

            // Variables TextField
            objRendement: fertilizerHelper.getValue(fertilizer, 'performanceGoal', props.commonInfo_performanceGoal, backToBlankTextFieldValue, false),
            tauxDeCailloux: (pebbleRateArchived !== null) ? pebbleRateArchived : fertilizerHelper.getValue(fertilizer, 'pebbleRate', (pebbleRateArchived !== null) ? pebbleRateArchived : undefined, backToBlankTextFieldValue, false),
            profondeurDeLabour: (init_labourCheckBox) ? fertilizerHelper.getValue(fertilizer, 'ploughingDepth', props.commonInfo_ploughingDepth, backToBlankTextFieldValue, false) : backToBlankTextFieldValue,
            qteApportOrga: fertilizerHelper.getValue(fertilizer, 'organicInputQuantity', undefined, backToBlankTextFieldValue, false),
            irrigationQteEau: fertilizerHelper.getValue(fertilizer, 'waterQuantity', undefined, backToBlankTextFieldValue, false),
            irrigationTeneurNitrates: fertilizerHelper.getValue(fertilizer, 'waterNitrateContent', undefined, backToBlankTextFieldValue, false),
            reliquatAverageSno3nh4: fertilizerHelper.getValue(fertilizer, 'sNO3NH4', undefined, backToBlankTextFieldValue, false),
            teneurEnAzote: fertilizerHelper.getValue(fertilizer, 'content', init_commonInfo_content, backToBlankTextFieldValue, false),
            qteAzoteApporte: fertilizerHelper.getValue(fertilizer, 'doseExtended', props.commonInfo_doseProvided, backToBlankTextFieldValue, false),
            qteAzoteReserve: fertilizerHelper.getValue(fertilizer, 'doseSaved', props.commonInfo_doseReserved, backToBlankTextFieldValue, false),
            rendementPrec: fertilizerHelper.getValue(fertilizer, 'previousPerformance', undefined, backToBlankTextFieldValue, false),
            fertilAzoteePrec: fertilizerHelper.getValue(fertilizer, 'previousNitrogenFertilization', undefined, backToBlankTextFieldValue, false),
            codePostal: this.codePostalToString(codePostal),

            // Variables avec Autocomplete
            objProdBle: objProdBle_init,
            typeFertilisationMajoritaire: fertilizerHelper.getItemFromList(props.fertilizerTypes, fertilizer, "fertilizerType", fertilizerTypeFromCommonInfos, null),
            gestionDesResidus: fertilizerHelper.getItemFromList(props.tailingManagements, fertilizer, "tailingsManagement", tailingManagementFromCommonInfos, null),
            freqApportOrga: fertilizerHelper.getItemFromList(props.organicInputFrequencies, fertilizer, "organicInputFrequency", (organicFrequencyArchived !== null) ? organicFrequencyArchived : organicInputFrequencyFromCommonInfos, organicFrequencyArchived),
            habFreqCultInter: fertilizerHelper.getItemFromList(props.interCropFrequency, fertilizer, "interCropFrequency", (intermediateCropFrequencyArchived !== null) ? intermediateCropFrequencyArchived : interCropFrequencyFromCommonInfos, intermediateCropFrequencyArchived),
            habTypeCultInter: fertilizerHelper.getItemFromList(props.interCropTypes, fertilizer, "interCropType", interCropTypeFromCommonInfos, null),
            retournementPrairie: fertilizerHelper.getItemFromList(props.turningOverGrasslandFrequencies, fertilizer, "turningOverGrassland", null, null),
            dureePrairie: fertilizerHelper.getItemFromList(props.grasslandDuration, fertilizer, "grasslandDuration", null, null),
            culturePrec: fertilizerHelper.getItemFromList(props.previousCropList, fertilizer, "previousCrop", null, previousCropArchived),
            gestionDesResidusPrec: init_gestionDesResidusPrec,
            habitudeTypeApportOrga: fertilizerHelper.getItemFromList(props.organicInputTypes, fertilizer, "organicInputType", null, null),
            commune: lodashGet(props, 'parcelLocalisation.commune', undefined),

            variete: fertilizerHelper.getItemFromList(
                fertilizerHelper.getRelevantVarietyList(props.varieteList, props.varieteList_bleProt, props.cultureCrop, objProdBle_init, FertilizerEngineEnum.AZOFERT, isImprovingCrop)
                , fertilizer, "variety", null, null),

            loadedVarietyApplied: false, // Booléen qui indique si la variété a été appliqué automatiquement une fois

            typeDeSol: fertilizerHelper.getItemFromList(props.typeDeSolList, fertilizer, "soilKindType", null, soilTypeArchived),
            typeApportOrga: fertilizerHelper.getItemFromList(props.organicInputList, fertilizer, "organicInput", null, null),

            // Variable TextField Dans des listes            
            reliquatMeasuredNo3: fertilizerHelper.getRelicsData(fertilizer, true),
            reliquatMeasuredNh4: fertilizerHelper.getRelicsData(fertilizer, false),

            // Variable compteur
            reliquatMeasuredLayers: fertilizerHelper.isHorizon3Exist(lodashGet(fertilizer, "relicSamples", null)), // ( 2 horizons min et 3 horizons max / Compte le nombre d'horizons du reliquat pour la présence du '+' et '-'.)

            // Dico de gestion des erreurs
            errorManagerDico: undefined,

            // Booléen de minimum syndical
            specificInfosAreGoodForNextStep: false,

            //Engine (azofert ou géofolia)
            fertilizerEngine: ((fertilizer !== undefined) && (fertilizer.engine !== undefined)) ? fertilizer.engine : props.fertilizerEngine,
            // Liste des communes correspondant au code postal
            cities: [],
            localisationHasChange: false, //définis si la localisation a changé !
        }

        this.handleBlurDatePicker = this.handleBlurDatePicker.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleReliquatMeasured = this.handleReliquatMeasured.bind(this);
        this.handleSaveNewLocalisation = this.handleSaveNewLocalisation.bind(this);

        this.fullScreen = (window && window.innerWidth && (window.innerWidth < theme.breakpoints.values.lg)) ? true : false;
    }

    componentDidMount() {
        const { codePostal } = this.state;

        // Si on a un code postal valide on récupérer les communes correspondante
        this.searchCitiesFromPostalCode(codePostal);
    }

    componentDidUpdate(prevProps, prevState) {
        const { varieteList, varieteList_bleProt, typeDeSolList, fertilizerDico, fertilizerDicoCounter, cultureCrop, specificInfosManagementSaving,
            typeDeSolListe_attenteDeReceptionDeLaListe, loadedVariety } = this.props;
        const { typeDeSol, parcelFertilizerData, onblurDataName, objProdBle, isImprovingCrop, loadedVarietyApplied } = this.state;

        const defaultValue = null;

        // Si on vient de recevoir la liste des variétés et ou la liste des types de sol, 
        // on tente d'initialiser l'écran avec le choix précédent (reçu de la lecture en BdD)
        // const canFindVariety = (((!prevProps) || (!prevProps.varieteList) || (prevProps.varieteList.length <= 0)
        //     || (!prevProps.fertilizerDico) || (!prevProps.cultureCrop) || (prevProps.varieteList_attenteDeReceptionDeLaListe === true)) &&
        //     (varieteList && (varieteList.length > 0) && (fertilizerDico) && (cultureCrop) && (varieteList_attenteDeReceptionDeLaListe === false))) ? true : false;
        const canFindSoilKind = (((!prevProps) || (!prevProps.typeDeSolList) || (prevProps.typeDeSolList.length <= 0)
            || (!prevProps.fertilizerDico) || (!prevProps.cultureCrop) || (prevProps.typeDeSolListe_attenteDeReceptionDeLaListe === true)) &&
            (typeDeSolList && (typeDeSolList.length > 0) && (fertilizerDico) && (cultureCrop) && (typeDeSolListe_attenteDeReceptionDeLaListe === false))) ? true : false;


        // Si on a potentiellement une save, on a pas plus besoin de canFindVariety car soit la variete est deja save, soit elle est fourni par l'api qui a deja effectué les verfications
        if (fertilizerDicoCounter > 0 && loadedVarietyApplied === false) {
            const fertilizer = parcelFertilizerData;

            const newVarietyItem = fertilizerHelper.getItemFromList(
                fertilizerHelper.getRelevantVarietyList(varieteList, varieteList_bleProt, cultureCrop, objProdBle, FertilizerEngineEnum.AZOFERT, isImprovingCrop)
                , fertilizer, "variety", null, null);

            if (newVarietyItem) {
                this.setState({ variete: newVarietyItem, loadedVarietyApplied: true });
            } else {
                // Si la save ne contient pas de variete
                if (loadedVariety) {
                    this.setState({ variete: loadedVariety, loadedVarietyApplied: true });
                }
            }
        } else {
            // Si on a pas de save mais qu'on a reussi a load une variete
            if (loadedVariety && loadedVarietyApplied === false) {
                this.setState({ variete: loadedVariety, loadedVarietyApplied: true });
            }
        }

        if (canFindSoilKind && (fertilizerDicoCounter > 0)) {
            const fertilizer = parcelFertilizerData;

            if (fertilizer) { //si on a retrouvé/reçu l'entité, on peut traiter !
                let newSoilKindItem = typeDeSol; //ancienne valeur

                if (canFindSoilKind && (fertilizer.soilKindType)) {
                    newSoilKindItem = fertilizerHelper.getItemFromList(typeDeSolList, fertilizer, "soilKindType", defaultValue, defaultValue);
                    this.setState({ typeDeSol: newSoilKindItem });
                }
            }
        }

        // Si on avait un enregistrement en cours, et que ce n'est plus le cas, c'est peut-être le changement de localisation qui vient de se terminer:
        if (((!prevProps) || (prevProps.specificInfosManagementSaving === true)) &&
            (specificInfosManagementSaving !== true)) {
            this.setState({ localisationHasChange: false, });
        }

        if ((prevState && ((!prevState.onblurDataName) || (prevState.onblurDataName === ''))) &&
            (onblurDataName && (onblurDataName !== ''))) {
            try {
                const curentDateValue = this.state[onblurDataName];
                const futureValue = dateHelper.getDateValid(curentDateValue, true);

                if (curentDateValue !== futureValue) {
                    const newState = { onblurDataName: '', };
                    newState[onblurDataName] = futureValue;
                    this.setState(newState);
                } else {
                    this.setState({ onblurDataName: '', });
                }
            }
            catch (errDate) {
                //console.log('onblurDataName: ', errDate);
                this.setState({ onblurDataName: '', });
            }
        }
    }

    /* Définition du thème pour les textfields */
    getThemeTextField = (param) => {
        if ((param !== "") && (param !== null) && (param !== undefined)) {
            return ({
                '& .MuiOutlinedInput-input': { backgroundColor: themeColorForNotEmptyField },
                '& .MuiOutlinedInput-root': { backgroundColor: themeColorForNotEmptyField }
            });
        }
    }

    /**
     * Fonction permettant de transformer le code postal (int) en string pour la saisie
     */
    codePostalToString = (codePostal) => {
        let codePostalStr = undefined;

        if ((codePostal !== null) && (codePostal !== undefined)) {
            codePostalStr = codePostal.toString();
            // il se peut que le code ne fasse que 4 caractères (ex: 2100 au lieu de 02100, perte du 0 à cause du int)
            if (codePostalStr.length === 4) {
                codePostalStr = "0" + codePostalStr;
            }
        }

        return codePostalStr;
    }

    handleBlurDatePicker = (valueType) => {
        if (!valueType) return;

        //En utilisant 'dateHelper.getDateValid(curentDateValue, true)' pour actualiser/forcer une valeur correcte, on peut (potetiellement) récupérer l'ancienne valeur du state local 
        // (valeur avant que 'onchange' est eu le temps d'actualisé ce state OU ce dernier soit à jour).
        //Du coup, on avertit juste de quelle saisie de date vient de perdre le focus. Et la détection de changement de valeur et ce 'onblurDataName' fera le contrôle.
        switch (valueType) {
            case 'dateDeSemi':
            case 'dateDeRecolte':
            case 'dateDeRecoltePrec':
            case 'dateEnfouissementPrec':
            case 'dateApportAO':
            case 'reliquatDate':
                this.setState({ onblurDataName: valueType, });
                break;

            default: //RAS !
                break;
        }
    }

    handleChangeValue = (valueType, value) => {
        const { dateDeRecoltePrec, isImprovingCrop, variete } = this.state;
        const { productionTargets, varieteList, varieteList_bleProt, cultureCrop } = this.props;

        const newState = {};
        var futureValue = '';

        switch (valueType) {
            case 'dateDeSemi':
            case 'dateDeRecolte':
            case 'dateDeRecoltePrec':
            case 'dateEnfouissementPrec':
            case 'dateApportAO':
            case 'reliquatDate':
                //futureValue = new Date(value);
                futureValue = dateHelper.getDateValid(value);
                break;
            case 'objRendement':
            case 'tauxDeCailloux':
            case 'teneurEnAzote':
            case 'qteAzoteApporte':
            case 'qteAzoteReserve':
            case 'qteApportOrga':
            case 'irrigationQteEau':
            case 'irrigationTeneurNitrates':
            case 'rendementPrec':
            case 'fertilAzoteePrec':
                //On aurai pû vérifier qu'il n'y a QUE des chiffres de saisie (pas de virgule, ni point... de décimal), pour la saisie d'entier,
                // mais il est plus simple de les convertir au moment de les exploiter dans les algos !
                //futureValue = parseInt(value.currentTarget.value); //Commentaire à conserver !
                futureValue = value.currentTarget.value;
                break;
            case 'profondeurDeLabour':
            case 'reliquatAverageSno3nh4':
                futureValue = value.currentTarget.value;
                break;
            case 'codePostal':
                newState['localisationHasChange'] = true; //pour autoriser l'enregistrement de cette nouvelle localisation !
                futureValue = value.currentTarget.value;
                this.searchCitiesFromPostalCode(futureValue);
                break;
            case 'commune':
                newState['localisationHasChange'] = true; //pour autoriser l'enregistrement de cette nouvelle localisation !
                futureValue = value;    // les listes de textfield qui sont des autocomplete
                break;
            case 'typeFertilisationMajoritaire':
            case 'gestionDesResidus':
            case 'habFreqCultInter':
            case 'habTypeCultInter':
            case 'retournementPrairie':
            case 'dureePrairie':
            case 'habitudeTypeApportOrga':
            case 'freqApportOrga':
                futureValue = value;    // les listes de textfield qui sont des autocomplete
                break;
            case 'gestionDesResidusPrec':
                futureValue = value;    // les listes de textfield qui sont des autocomplete
                //si la gestion des résidus précédents est "Laissé en surface", 
                //on supprime la date d'enfouissement précédent et on grise le champ de la date
                if (valueType === 'gestionDesResidusPrec' &&
                    (value !== undefined && value !== null &&
                        (value.id !== theGood_gestionDesResidusPrec.id.toString())
                    )) {
                    newState["dateEnfouissementPrec"] = backToBlankDateValue;
                } else {
                    if (dateDeRecoltePrec !== null) {
                        newState["dateEnfouissementPrec"] = new Date(dateDeRecoltePrec).setDate(dateDeRecoltePrec.getDate() + 1);
                    }
                }
                break;
            case 'irrigation':                  // si il est à faux: la qte d'eau et la teneur en N sont désactivés et vide
                if (value === false) {
                    newState["irrigationQteEau"] = backToBlankTextFieldValue;
                    newState["irrigationTeneurNitrates"] = backToBlankTextFieldValue;
                }
                futureValue = value;
                break;
            case 'labourCheckBox':              // si il est à faux: la profondeur de labour est désactivé et vide
                if (value === false) {
                    newState["profondeurDeLabour"] = backToBlankTextFieldValue;
                }
                futureValue = value;
                break;
            case 'natureEngraisIsSolide':       // lui n'a pas d'impacte sur un autre champ
                futureValue = value;
                break;
            case 'reliquatIsAvg':
                futureValue = value;
                if (value === true) {           // si il est à estimé alors les infos du reliquat analysé se vident
                    let reliquatMeasuredNo3 = [...this.state.reliquatMeasuredNo3];
                    reliquatMeasuredNo3[0] = backToBlankTextFieldValue;
                    reliquatMeasuredNo3[1] = backToBlankTextFieldValue;
                    reliquatMeasuredNo3[2] = backToBlankTextFieldValue;

                    let reliquatMeasuredNh4 = [...this.state.reliquatMeasuredNh4];
                    reliquatMeasuredNh4[0] = backToBlankTextFieldValue;
                    reliquatMeasuredNh4[1] = backToBlankTextFieldValue;
                    reliquatMeasuredNh4[2] = backToBlankTextFieldValue;

                    newState["reliquatMeasuredNo3"] = reliquatMeasuredNo3;
                    newState["reliquatMeasuredNh4"] = reliquatMeasuredNh4;
                } else {                        // si il est à analysé alors les données de l'estimé se vident
                    newState["reliquatAverageSno3nh4"] = backToBlankTextFieldValue;
                }
                break;
            case 'hasApportOrga':
                futureValue = value;
                if (value === false) {
                    newState["typeApportOrga"] = backToBlankAutocompleteValue;
                    newState["qteApportOrga"] = backToBlankTextFieldValue;
                    newState["dateApportAO"] = backToBlankDateValue;
                }
                break;
            case 'culturePrec':
            case 'typeApportOrga':
            case 'typeDeSol':
            case 'variete':
                futureValue = value;    // les autoComplete
                break;
            case 'objProdBle':
                futureValue = value;
                // On change de type de culture (rendement/proteine)
                // Pour AZO, on change de culture, donc on réinitialise la variété
                newState['variete'] = fertilizerHelper.findCommonListElementByLabel(
                    fertilizerHelper.getRelevantVarietyList(varieteList, varieteList_bleProt, cultureCrop, value, FertilizerEngineEnum.AZOFERT, isImprovingCrop),
                    lodashGet(variete, "label", undefined), 
                    null
                );

                break;
            case 'isImprovingCrop':
                // Si la culture est améliorante, alors on est en objectif protéine
                let prodTargetProteine = lodashFind(productionTargets, (pt) => pt.code === '2');
                if (value === true && prodTargetProteine) {
                    newState['objProdBle'] = prodTargetProteine;
                    // On change de type de culture (rendement/proteine)
                    // Pour AZO, on change de culture, donc on réinitialise la variété
                    newState['variete'] = fertilizerHelper.findCommonListElementByLabel(
                        fertilizerHelper.getRelevantVarietyList(varieteList, varieteList_bleProt, cultureCrop, prodTargetProteine, FertilizerEngineEnum.AZOFERT, value),
                        lodashGet(variete, "label", undefined), 
                        null
                    );
                }
                futureValue = value;
                break;
            default:
                futureValue = value;
                break;
        }

        newState[valueType] = futureValue;
        this.setState(newState);
    }

    handleReliquatMeasured = (type, value, index) => {
        const newState = {};
        var listeDuType = "";
        let item = value.currentTarget.value;

        switch (type) {
            case 'reliquatMeasuredNo3':
                listeDuType = [...this.state.reliquatMeasuredNo3];
                listeDuType[index] = item;
                break;
            case 'reliquatMeasuredNh4':
                listeDuType = [...this.state.reliquatMeasuredNh4];
                listeDuType[index] = item;
                break;
            default:
                listeDuType = [...this.state.reliquatMeasuredNh4];
                listeDuType[index] = item;
                break;
        }

        newState[type] = listeDuType;
        this.setState(newState);
    }

    handleReliquatMeasuredLayers = (isMore) => {
        const { reliquatMeasuredNo3, reliquatMeasuredNh4 } = this.state;

        // Fonction qui permet d'ajouter ou soustraire un horizon du reliquat
        this.setState(prevState => {
            return { reliquatMeasuredLayers: isMore ? prevState.reliquatMeasuredLayers + 1 : prevState.reliquatMeasuredLayers - 1 }
        });

        //Suppression des données de reliquat de l'horizon 3 quand on retranche le dernier reliquat
        if (isMore === false) {
            reliquatMeasuredNo3[2] = null;
            reliquatMeasuredNh4[2] = null;
            this.setState({
                reliquatMeasuredNo3: reliquatMeasuredNo3,
                reliquatMeasuredNh4: reliquatMeasuredNh4,
            });

        }
    }

    /* fonction permettant de requêter une API pour récupérer le nom des villes */
    searchCitiesFromPostalCode(postalCode) {
        const { commune } = this.state;

        if (!validatePostalCode(postalCode, codeCountry)) return;

        geoSearch.getCitiesFromPostalCode(codeCountry, postalCode)
            .then((result) => {
                if (result) {
                    if (result.length > 0) {
                        if (commune && result.some(element => element === commune)) {
                            this.setState({ cities: result, commune: commune }); // Si la commune etait déjà renseignée et qu'lle fait partie de la liste des villes trouvées en fonction du code postale
                        }
                        else {
                            this.setState({ cities: result, commune: result[0] }); // Si on a un ou plusieur resultat on auto-complete commune avec le premier
                        }
                    } else {
                        this.setState({ cities: [], commune: '', localisationHasChange: false }); // Si tableau vide, on considère qu'on a pas de resultat, et qu'il n'est pas sauvegardable
                    }
                } else {
                    this.setState({ cities: [], commune: '', localisationHasChange: false }); // Si pas de resultat, on empêche de save des données invalide
                }
            })
            .catch((err) => {
                this.setState({ cities: [], }); //touche pas à 'commune' qui a peut être été alimenté !

                sendError('dialogSpecificInfosEntry - searchCitiesFromPostalCode', {
                    "error": err,
                    "postalCode": postalCode,
                    "codeCountry": codeCountry,
                });
            });
    }

    handleSaveNewLocalisation = () => {
        const { codePostal, commune, localisationHasChange } = this.state;
        const { specificInfosManagementSaving, updateLocalisationOfParcel, parcelId } = this.props;

        if (specificInfosManagementSaving === true) { //si un enregistrement est en cours...
            return;
        }
        //Si les données ne sont pas ok...
        if ((localisationHasChange !== true) || (!codePostal) || (codePostal === '') || (!commune) || (commune === '')) {
            return;
        }

        //Fait l'appel à l'enregistrement de cette nouvelle localisation:
        updateLocalisationOfParcel(parcelId, { codePostal, commune });
    }

    renderRenseignementAgronomique = () => {
        const {
            dateDeSemi, dateDeRecolte, objRendement, objProdBle, isImprovingCrop,
            tauxDeCailloux, gestionDesResidus, typeDeSol,
            variete, errorManagerDico
        } = this.state;

        const {
            cultureCrop, productionTargets, tailingManagements, typeDeSolList,
            varieteList, varieteList_bleProt, typeDeSolListe_attenteDeReceptionDeLaListe,
            varieteList_attenteDeReceptionDeLaListe, specificInfosManagementSaving, varietyLoading
        } = this.props;

        let currentVarietyList = varieteList;

        const warningSowingDate = lodashGet(errorManagerDico, "sowingDate", undefined);
        const warningHarvestDate = lodashGet(errorManagerDico, "harvestDate", undefined);
        const warningSoilKindType = lodashGet(errorManagerDico, "soilKindType", undefined);
        const warningPreformanceGoal = lodashGet(errorManagerDico, "performanceGoal", undefined);
        const warningProductionTarget = lodashGet(errorManagerDico, "productionTarget", undefined);

        const enumOfcultureCrop = lodashGet(cultureCrop, "cropType", enumCultureFertilizer.unknown);

        if ((enumOfcultureCrop === enumCultureFertilizer.ble_hiver) && (lodashGet(objProdBle, "code", null) === "2")) {
            currentVarietyList = varieteList_bleProt;
        };

        return (
            <MainCard title={StringTranslate.agronomicInformation}>
                <Grid container spacing={2}>
                    {/* Blé améliorant? / uniquement pour le blé (condition d'affichage dans le sx) */}
                    <Grid
                        item xs={12}
                        sx={{
                            display: 'none',
                            ...((enumOfcultureCrop === enumCultureFertilizer.ble_hiver) && {
                                display: 'block'
                            })
                        }}
                    >
                        <Stack direction="row" alignContent="center">
                            <Checkbox
                                id="fumure_SpecificFieldInfos_isImprovingCrop"
                                checked={isImprovingCrop}
                                onChange={() => this.handleChangeValue('isImprovingCrop', !isImprovingCrop)}
                            />
                            <Typography sx={{ alignSelf: 'center', }}>{"Blé Améliorant"}</Typography>
                        </Stack>
                    </Grid>

                    {/* Blé - Objectif de production / uniquement pour le blé (condition d'affichage dans le sx) */}
                    <Grid
                        item xs={12}
                        sx={{
                            display: 'none',
                            ...((enumOfcultureCrop === enumCultureFertilizer.ble_hiver) && {
                                display: 'block'
                            })
                        }}
                    >
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_objProdBle"
                                disableClearable
                                disableListWrap
                                disabled={specificInfosManagementSaving || isImprovingCrop}
                                value={objProdBle}
                                options={productionTargets}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('objProdBle', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required
                                            label={StringTranslate.productionTarget}
                                            error={warningProductionTarget !== undefined}
                                            sx={{
                                                ...this.getThemeTextField(objProdBle)
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* variété / uniquement pour le blé (tendre ou dur) et orge (condition d'affichage dans le sx) */}
                    <Grid
                        item xs={12}
                        sx={{
                            display: 'none',
                            ...(((enumOfcultureCrop === enumCultureFertilizer.ble_hiver) || (enumOfcultureCrop === enumCultureFertilizer.ble_dur_hiver) || 
                                 (enumOfcultureCrop === enumCultureFertilizer.orge_hiver)) && {
                                display: 'block'
                            })
                        }}
                    >
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_variete"
                                value={variete}
                                // Fournis la liste des éléments à afficher triés dans l'ordre alphabétique
                                options={currentVarietyList.sort((a, b) => a.label.localeCompare(b.label))}
                                getOptionLabel={currentElement => currentElement.label}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={
                                    (event, newValue) =>
                                        this.handleChangeValue('variete', newValue)
                                }
                                disabled={
                                    ((enumOfcultureCrop === enumCultureFertilizer.ble_hiver) && lodashIsNil(objProdBle === null)) ||
                                    specificInfosManagementSaving
                                }
                                loading={(varieteList_attenteDeReceptionDeLaListe === true) && (varietyLoading === true)}
                                loadingText={StringTranslate.loading}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            id="TextField de autocomplete"
                                            {...params}
                                            label={StringTranslate.libelecolumnvariete}
                                            sx={{
                                                ...this.getThemeTextField(variete)
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {(varieteList_attenteDeReceptionDeLaListe === true) && (currentVarietyList.length <= 0) ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* Date de Semis */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                label={`${StringTranslate.libelecolumnsemis} *`}
                                disabled={specificInfosManagementSaving}
                                value={(dateDeSemi !== null) ? new Date(dateDeSemi) : null}
                                onChange={(newValue) => this.handleChangeValue('dateDeSemi', newValue)}
                                placeholder={`${StringTranslate.libelecolumnsemis} *`}
                                helperText={warningSowingDate}
                                error={warningSowingDate !== undefined}
                                dialogOption={1}
                                onBlur={() => this.handleBlurDatePicker('dateDeSemi')}
                            />
                        </FormControl>
                    </Grid>

                    {/* Date de récolte */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                dialogOption={1}
                                label={`${StringTranslate.libelecolumnrecolte} *`}
                                disabled={specificInfosManagementSaving}
                                value={(dateDeRecolte !== null) ? new Date(dateDeRecolte) : null}
                                onChange={(newValue) => this.handleChangeValue('dateDeRecolte', newValue)}
                                placeholder={`${StringTranslate.libelecolumnrecolte} *`}
                                error={warningHarvestDate !== undefined}
                                helperText={warningHarvestDate}
                                onBlur={() => this.handleBlurDatePicker('dateDeRecolte')}
                            />
                        </FormControl>
                    </Grid>

                    {/* Objectif de rendement */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={StringTranslate.performanceObjective}
                                id="fumure_SpecificFieldInfos_objRendement"
                                disabled={specificInfosManagementSaving}
                                value={objRendement}
                                onChange={(newValue) => this.handleChangeValue('objRendement', newValue)}
                                type="number"
                                InputProps={{ endAdornment: <InputAdornment position="end">q/ha</InputAdornment> }}
                                error={warningPreformanceGoal !== undefined}
                                helperText={warningPreformanceGoal}
                                sx={{
                                    ...this.getThemeTextField(objRendement)
                                }}
                                onWheel={event => event.target.blur()}
                            />
                            <FormHelperText>
                                {StringTranslate.subTextPerformanceObjective}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {/* Type de Sol */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_typeDeSol"
                                value={typeDeSol}
                                // Fournis la liste des éléments à afficher triés dans l'ordre alphabétique
                                options={typeDeSolList.sort((a, b) => a.label.localeCompare(b.label))}
                                // Pour le label des différents éléments de la liste on est obligé de ralouter le code, sinon lors de la recherche d'un élément
                                // de la liste, certains éléments sont dupliqués pour des raisons qui nous sont inconnu.
                                getOptionLabel={currentElement => currentElement.label}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={(typeDeSolListe_attenteDeReceptionDeLaListe === true) ? StringTranslate.loading : StringTranslate.noAvailableChoice}
                                disabled={(typeDeSolListe_attenteDeReceptionDeLaListe && !(typeDeSolList.length > 0)) || specificInfosManagementSaving}
                                onChange={(event, newValue) => this.handleChangeValue('typeDeSol', newValue)}
                                loading={(typeDeSolListe_attenteDeReceptionDeLaListe === true)}
                                loadingText={StringTranslate.loading}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required
                                            label={StringTranslate.soilType}
                                            error={warningSoilKindType !== undefined}
                                            helperText={warningSoilKindType}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {(typeDeSolListe_attenteDeReceptionDeLaListe === true) && (typeDeSolList.length <= 0) ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            sx={{
                                                ...this.getThemeTextField(typeDeSol)
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* Taux de cailloux en % */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.pebbleRate}
                                disabled={specificInfosManagementSaving}
                                id="fumure_SpecificFieldInfos_tauxDeCailloux"
                                value={tauxDeCailloux}
                                onChange={(newValue) => this.handleChangeValue('tauxDeCailloux', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { min: 0, max: 100 }
                                }}
                                sx={{
                                    ...this.getThemeTextField(tauxDeCailloux)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Gestion des résidus / équivalent DRR chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_gestionDesResidus"
                                disabled={specificInfosManagementSaving}
                                disableClearable
                                disableListWrap
                                value={gestionDesResidus}
                                options={tailingManagements}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('gestionDesResidus', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.residueManagement}
                                            sx={{
                                                ...this.getThemeTextField(gestionDesResidus)
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderHabitudeCulturalesDeLaParcelle = () => {
        const {
            labourCheckBox, profondeurDeLabour, retournementPrairie, dureePrairie,
            freqApportOrga, habitudeTypeApportOrga, habFreqCultInter, habTypeCultInter
        } = this.state;

        const {
            interCropFrequency, organicInputTypes, organicInputFrequencies,
            interCropTypes, grasslandDuration, turningOverGrasslandFrequencies,
            specificInfosManagementSaving
        } = this.props;

        return (
            <MainCard title={StringTranslate.cultivationHabitsPlot}>
                <Grid container spacing={2}>

                    {/* Labour / équivalent LAB chez azo / AZO */}
                    <Grid item xs={12}>
                        {/* labourCheckBox a true => Oui, false => Non */}
                        <Stack direction="row" alignContent="center">
                            <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.plowing}</Typography>
                            <ButtonGroup disableElevation variant='contained' disabled={specificInfosManagementSaving}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('labourCheckBox', true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(labourCheckBox)}
                                >
                                    {StringTranslate.yes}
                                </Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('labourCheckBox', false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(!labourCheckBox)}
                                >
                                    {StringTranslate.no}
                                </Button>

                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    {/* Profondeur de labour / équivalent PLAB chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.plowingDepth}
                                id="fumure_SpecificFieldInfos_profondeurDeLabour"
                                value={profondeurDeLabour}
                                onChange={(newValue) => this.handleChangeValue('profondeurDeLabour', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                }}
                                disabled={(!labourCheckBox) || specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(profondeurDeLabour)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Fréquence apport organique / équivalent FAO chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_freqApportOrga"
                                disabled={specificInfosManagementSaving}
                                disableClearable
                                disableListWrap
                                value={freqApportOrga}
                                options={organicInputFrequencies}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('freqApportOrga', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.organicFertilizerFrequency}
                                            sx={{
                                                ...this.getThemeTextField(freqApportOrga)
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        {/* Type d'apport organique (si apport organique) / équivalent NAO chez azo */}
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_habitudeTypeApportOrga"
                                disableClearable
                                disableListWrap
                                // Permet de ne pas afficher la valeur si la fréquence est "Jamais"
                                // /!\ ne change pas sa valeur, cela modifie juste son affichage 
                                // (ce n'est pas dérangeant d'envoyer une valeur pour le type alors que l'habitude est jamais)
                                value={((freqApportOrga !== null) && (freqApportOrga !== undefined) && (freqApportOrga.code === disabled_habitudeTypeApportOrga.code)) ?
                                    null : habitudeTypeApportOrga}
                                options={organicInputTypes}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('habitudeTypeApportOrga', newValue)}
                                disabled={
                                    (((freqApportOrga === null) || (freqApportOrga === undefined)) ||
                                        ((freqApportOrga !== null) && (freqApportOrga !== undefined) ? (freqApportOrga.code === disabled_habitudeTypeApportOrga.code) : false)) ||
                                    specificInfosManagementSaving
                                }
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.organicFertilizerType}
                                            sx={{
                                                ...(((((freqApportOrga !== null) && (freqApportOrga !== undefined) && (freqApportOrga.code === disabled_habitudeTypeApportOrga.code)) ? null : habitudeTypeApportOrga) !== null) && {
                                                    '& .MuiOutlinedInput-root': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    }
                                                })
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>


                    {/* Habitude de Fréquence Culture intermédiaires / équivalent FCI chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_habFreqCultInter"
                                disableClearable
                                disableListWrap
                                disabled={specificInfosManagementSaving}
                                value={habFreqCultInter}
                                options={interCropFrequency}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('habFreqCultInter', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.intermediatecropFrequency}
                                            sx={{
                                                ...this.getThemeTextField(habFreqCultInter)
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* Habitude de type culture intermédiaire / équivalent NCI chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_habTypeCultInter"
                                disableClearable
                                disableListWrap
                                // Permet de ne pas afficher la valeur si la fréquence est "Jamais"
                                // /!\ ne change pas sa valeur, cela modifie juste son affichage 
                                // (ce n'est pas dérangeant d'envoyer une valeur pour le type alors que l'habitude est jamais)
                                value={((habFreqCultInter !== null) && (habFreqCultInter !== undefined) && (habFreqCultInter.code === disabled_habTypeCultInter.code)) ?
                                    null : habTypeCultInter}
                                options={interCropTypes}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('habTypeCultInter', newValue)}
                                disabled={
                                    ((habFreqCultInter === null) || (habFreqCultInter === undefined) ||
                                        (((habFreqCultInter !== null) && (habFreqCultInter !== undefined)) ? (habFreqCultInter.code === disabled_habTypeCultInter.code) : false)) ||
                                    specificInfosManagementSaving
                                }
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.intermediateCultureType}
                                            sx={{
                                                ...(((((habFreqCultInter !== null) && (habFreqCultInter !== undefined) && (habFreqCultInter.code === disabled_habTypeCultInter.code)) ? null : habTypeCultInter) !== null) && {
                                                    '& .MuiOutlinedInput-root': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    }
                                                })
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {/* Retournement de prairie / équivalent DRPR chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_retournementPrairie"
                                disableClearable
                                disableListWrap
                                value={retournementPrairie}
                                options={turningOverGrasslandFrequencies}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('retournementPrairie', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.meadowTurning}
                                            sx={{
                                                ...this.getThemeTextField(retournementPrairie)
                                            }}
                                        />
                                }
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    {/* Durée de la prairie / équivalent AGEPR chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_dureePrairie"
                                disableClearable
                                disableListWrap
                                // Permet de ne pas afficher la valeur si la fréquence est "Jamais"
                                // /!\ ne change pas sa valeur, cela modifie juste son affichage 
                                // (ce n'est pas dérangeant d'envoyer une valeur pour le type alors que l'habitude est jamais)
                                value={((retournementPrairie !== null) && (retournementPrairie !== undefined) && (retournementPrairie.code === disabled_dureePrairie.code)) ?
                                    null : dureePrairie}
                                options={grasslandDuration}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('dureePrairie', newValue)}
                                disabled={
                                    (retournementPrairie === null || retournementPrairie === undefined ||
                                        ((retournementPrairie !== null) && (retournementPrairie !== undefined) ? (retournementPrairie.code === disabled_dureePrairie.code) : false)) ||
                                    specificInfosManagementSaving
                                }
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.meadowDuration}
                                            sx={{
                                                ...(((((retournementPrairie !== null) && (retournementPrairie !== undefined) && (retournementPrairie.code === disabled_dureePrairie.code)) ? null : dureePrairie) !== null) && {
                                                    '& .MuiOutlinedInput-root': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        backgroundColor: themeColorForNotEmptyField
                                                    }
                                                })
                                            }}
                                        />
                                }
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderCulturePrecedente = () => {
        const {
            culturePrec, dateDeRecoltePrec, gestionDesResidusPrec,
            dateEnfouissementPrec, rendementPrec, fertilAzoteePrec,
            errorManagerDico
        } = this.state;

        const {
            prevTailingManagments, previousCropList, specificInfosManagementSaving
        } = this.props;

        const warningPreviousHarvestDate = lodashGet(errorManagerDico, "previousHarvestDate", undefined);
        const warningPreviousCrop = lodashGet(errorManagerDico, "previousCrop", undefined);
        const warningPreviousPerformance = lodashGet(errorManagerDico, "previousPerformance", undefined);
        const warningPreviousPloughingDate = lodashGet(errorManagerDico, "previousPloughingDate", undefined);

        return (
            <MainCard title={"Culture précédente"}>
                <Grid container spacing={2}>

                    {/* Culture N-1 / équivalent PREC chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                required
                                id="fumure_SpecificFieldInfos_culturePrec"
                                value={culturePrec}
                                // Fournis la liste des éléments à afficher triés dans l'ordre alphabétique
                                options={previousCropList.sort((a, b) => a.label.localeCompare(b.label))}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('culturePrec', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required
                                            label={StringTranslate.previousCrop}
                                            error={warningPreviousCrop !== undefined}
                                            helperText={warningPreviousCrop}
                                            sx={{
                                                ...this.getThemeTextField(culturePrec)
                                            }}
                                        />
                                }
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    {/* Rendement N-1 / équivalent RPREC chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={StringTranslate.previousYield}
                                id="fumure_SpecificFieldInfos_rendementPrec"
                                value={rendementPrec}
                                onChange={(newValue) => this.handleChangeValue('rendementPrec', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">q/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                error={warningPreviousPerformance !== undefined}
                                helperText={warningPreviousPerformance}
                                sx={{
                                    ...this.getThemeTextField(rendementPrec)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Fertilisation azotée N-1 / équivalent NPREC chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.nitrogenFertilizerN1}
                                id="fumure_SpecificFieldInfos_fertilAzoteePrec"
                                value={fertilAzoteePrec}
                                onChange={(newValue) => this.handleChangeValue('fertilAzoteePrec', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kg N/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(fertilAzoteePrec)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Date de récolte N-1 / équivalent DPREC chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                label={StringTranslate.harvestDateN1}
                                value={(dateDeRecoltePrec !== null) ? new Date(dateDeRecoltePrec) : null}
                                onChange={(newValue) => this.handleChangeValue('dateDeRecoltePrec', newValue)}
                                disabled={specificInfosManagementSaving}
                                placeholder={StringTranslate.harvestDateN1}
                                error={warningPreviousHarvestDate !== undefined}
                                helperText={warningPreviousHarvestDate}
                                dialogOption={1}
                                onBlur={() => this.handleBlurDatePicker('dateDeRecoltePrec')}
                            />
                        </FormControl>
                    </Grid>

                    {/* Devenir des résidus N-1 / équivalent RESPR chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_gestionDesResidusPrec"
                                disableClearable
                                disableListWrap
                                value={gestionDesResidusPrec}
                                options={prevTailingManagments}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('gestionDesResidusPrec', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.becomingResiduesN1}
                                            sx={{
                                                ...this.getThemeTextField(gestionDesResidusPrec)
                                            }}
                                        />
                                }
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    {/* Date d'enfouissement N-1 / équivalent DINC chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                label={StringTranslate.dateBurialResiduesN1}
                                value={(dateEnfouissementPrec !== null) ? new Date(dateEnfouissementPrec) : null}
                                onChange={(newValue) => this.handleChangeValue('dateEnfouissementPrec', newValue)}
                                placeholder= {StringTranslate.dateBurialResiduesN1}
                                error= {warningPreviousPloughingDate !== undefined}
                                helperText={warningPreviousPloughingDate}
                                onBlur={() => this.handleBlurDatePicker('dateEnfouissementPrec')}
                                disabled={
                                    specificInfosManagementSaving ||
                                    (gestionDesResidusPrec !== undefined && gestionDesResidusPrec !== null &&
                                        (gestionDesResidusPrec.id !== theGood_gestionDesResidusPrec.id)
                                    ) ||
                                    (gestionDesResidusPrec === null)}
                                dialogOption={1}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderApportOrganique = () => {
        const {
            hasApportOrga, typeApportOrga, qteApportOrga,
            dateApportAO, errorManagerDico
        } = this.state;

        const {
            organicInputList, specificInfosManagementSaving
        } = this.props;

        const warningOrganicInputDate = lodashGet(errorManagerDico, "organicInputDate", undefined);
        const warningOrganicInputQuantity = lodashGet(errorManagerDico, "organicInputQuantity", undefined);
        const warningOrganicInputType = lodashGet(errorManagerDico, "organicInput", undefined);
        return (
            <MainCard title={StringTranslate.organicContribution}>

                <Grid container spacing={2}>

                    {/* Apport Organique*/}
                    <Grid item xs={12}>
                        {/* hasApportOrga a true => Oui, false => Non */}
                        <Stack direction="row" alignContent="center">
                            <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.organicContribution}:</Typography>
                            <ButtonGroup disableElevation variant='contained' disabled={specificInfosManagementSaving}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('hasApportOrga', true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={hasApportOrga}
                                >
                                    {StringTranslate.yes}
                                </Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('hasApportOrga', false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(!hasApportOrga)}
                                >
                                    {StringTranslate.no}
                                </Button>

                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    {/* Type d'apport organique / équivalent TAO chez azo / AZO  */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_typeApportOrga"
                                value={typeApportOrga}
                                // Fournis la liste des éléments à afficher triés dans l'ordre alphabétique
                                options={organicInputList.sort((a, b) => a.label.localeCompare(b.label))}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('typeApportOrga', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required={hasApportOrga}
                                            error={warningOrganicInputType !== undefined}
                                            helperText={warningOrganicInputType}
                                            label={StringTranslate.supplyType}
                                            sx={{
                                                ...this.getThemeTextField(typeApportOrga)
                                            }}
                                        />
                                }
                                disabled={(!hasApportOrga) || specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    {/* quantité apporté / équivalent QTAO chez azo / AZO  */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required={hasApportOrga}
                                label={StringTranslate.quantitySupplied}
                                id="fumure_SpecificFieldInfos_qteApporteAO"
                                value={qteApportOrga || ""}
                                onChange={(newValue) => this.handleChangeValue('qteApportOrga', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">T/ha</InputAdornment>
                                }}
                                disabled={(!hasApportOrga) || specificInfosManagementSaving}
                                error={warningOrganicInputQuantity !== undefined}
                                helperText={warningOrganicInputQuantity}
                                sx={{
                                    ...this.getThemeTextField(qteApportOrga)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* date de l'apport / équivalent DTAO chez azo / AZO  */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                label={(hasApportOrga) ? `${StringTranslate.dateContribution} *` : StringTranslate.dateContribution}
                                value={(dateApportAO !== null) ? new Date(dateApportAO) : null}
                                onChange={(newValue) => this.handleChangeValue('dateApportAO', newValue)}
                                placeholder= {(warningOrganicInputDate) ? `${StringTranslate.dateContribution} *` :  StringTranslate.dateContribution}
                                error= {warningOrganicInputDate !== undefined}
                                helperText= {warningOrganicInputDate}
                                dialogOption={1}
                                onBlur={() => this.handleBlurDatePicker('dateApportAO')}
                                disabled={(!hasApportOrga) || specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderIrrigation = () => {
        const {
            irrigation, irrigationQteEau, irrigationTeneurNitrates
        } = this.state;

        const {
            specificInfosManagementSaving
        } = this.props;

        return (
            <MainCard title={StringTranslate.irrigation}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {/* irrigation a true => Oui, false => Non */}
                        <Stack direction="row" alignContent="center">
                            <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.irrigation}:</Typography>
                            <ButtonGroup disableElevation variant='contained' disabled={specificInfosManagementSaving}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('irrigation', true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(irrigation)}
                                >
                                    {StringTranslate.yes}
                                </Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('irrigation', false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(!irrigation)}
                                >
                                    {StringTranslate.no}
                                </Button>

                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    {/* Quantité d'eau / QIR chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.quantityWater}
                                id="fumure_SpecificFieldInfos_irrigationQteEau"
                                value={irrigationQteEau || ""}
                                onChange={(newValue) => this.handleChangeValue('irrigationQteEau', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                }}
                                disabled={(!irrigation) || specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(irrigationQteEau)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Teneur en N / CNIR chez azo */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.nitrateContent}
                                id="fumure_SpecificFieldInfos_irrigationTeneurNitrates"
                                value={irrigationTeneurNitrates || ""}
                                onChange={(newValue) => this.handleChangeValue('irrigationTeneurNitrates', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mg/L</InputAdornment>
                                }}
                                disabled={(!irrigation) || specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(irrigationTeneurNitrates)
                                }}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderLocalisation = () => {
        const { codePostal, commune, cities, localisationHasChange } = this.state;
        const { specificInfosManagementSaving } = this.props;

        return (
            <MainCard title={"Localisation"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={`${StringTranslate.codepostal}`}
                                id="fumure_SpecificFieldInfos_CodePostal"
                                type="number"
                                onWheel={event => event.target.blur()}
                                value={codePostal}
                                onChange={(newValue) => this.handleChangeValue('codePostal', newValue)}
                                inputProps={{ maxLength: 5 }}
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_Commune"
                                disableClearable
                                disableListWrap
                                value={commune}
                                options={cities}
                                onChange={(event, newValue) => this.handleChangeValue('commune', newValue)}
                                noOptionsText={StringTranslate.AnyCityFromCurrentPostalcode}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={StringTranslate.ville}
                                        />
                                }
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button startIcon={<Save />} color='primary' variant='contained'
                            onClick={() => this.handleSaveNewLocalisation()}
                            disabled={(specificInfosManagementSaving === true) || (localisationHasChange !== true)}>
                            <Typography>{StringTranslate.enregistrer}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
        )
    }

    renderReliquatMesTemplate = (horizon) => {
        const {
            reliquatMeasuredNo3, reliquatMeasuredNh4,
            reliquatIsAvg, errorManagerDico
        } = this.state;

        const {
            specificInfosManagementSaving
        } = this.props;

        const valHorizon = [StringTranslate.reliquat30, StringTranslate.reliquat60, StringTranslate.reliquat90];

        const warningRelic0nO3 = lodashGet(errorManagerDico, "relic0nO3", undefined);
        const warningRelic0nH4 = lodashGet(errorManagerDico, "relic0nH4", undefined);
        const warningRelic1nO3 = lodashGet(errorManagerDico, "relic1nO3", undefined);
        const warningRelic1nH4 = lodashGet(errorManagerDico, "relic1nH4", undefined);
        const warningRelic2nO3 = lodashGet(errorManagerDico, "relic2nO3", undefined);
        const warningRelic2nH4 = lodashGet(errorManagerDico, "relic2nH4", undefined);

        return (
            <Grid
                item xs={12}
                sx={{
                    display: "block",
                    ...((reliquatIsAvg === true) && {
                        display: "none"
                    })
                }}
            >
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}><Typography> {StringTranslate.horizon} {valHorizon[horizon]} cm </Typography></Grid>

                    {/* NO3 */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={"NO3"}
                                id="fumure_SpecificFieldInfos_reliquatMeasuredNo3"
                                value={reliquatMeasuredNo3[horizon] || ""}
                                onChange={(newValue) => this.handleReliquatMeasured('reliquatMeasuredNo3', newValue, horizon)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg N/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                error={((horizon === 0) && (warningRelic0nO3 !== undefined)) || ((horizon === 1) && (warningRelic1nO3 !== undefined)) || ((horizon === 2) && (warningRelic2nO3 !== undefined))}
                                helperText={((horizon === 0) && (warningRelic0nO3 !== undefined)) ? 
                                    warningRelic0nO3 : 
                                    ((horizon === 1) && (warningRelic1nO3 !== undefined)) ? 
                                        warningRelic1nO3 : 
                                        ((horizon === 2) && (warningRelic2nO3 !== undefined)) ? 
                                            warningRelic2nO3 : 
                                            null }
                                sx={{
                                    ...this.getThemeTextField(reliquatMeasuredNo3[horizon])
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* NHA */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={"NH4"}
                                id="fumure_SpecificFieldInfos_reliquatMeasuredNh4"
                                value={reliquatMeasuredNh4[horizon] || ""}
                                onChange={(newValue) => this.handleReliquatMeasured('reliquatMeasuredNh4', newValue, horizon)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg N/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                error={((horizon === 0) && (warningRelic0nH4 !== undefined)) || ((horizon === 1) && (warningRelic1nH4 !== undefined)) || ((horizon === 2) && (warningRelic2nH4 !== undefined))}
                                helperText={((horizon === 0) && (warningRelic0nH4 !== undefined)) ? 
                                    warningRelic0nH4 : 
                                    ((horizon === 1) && (warningRelic1nH4 !== undefined)) ? 
                                        warningRelic1nH4 : 
                                        ((horizon === 2) && (warningRelic2nH4 !== undefined)) ? 
                                            warningRelic2nH4 : 
                                            null}
                                sx={{
                                    ...this.getThemeTextField(reliquatMeasuredNh4[horizon])
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Grid>

        )
    }

    renderReliquatAzotee = () => {
        const {
            reliquatIsAvg, reliquatAverageSno3nh4, reliquatMeasuredLayers,
            reliquatDate, errorManagerDico
        } = this.state;

        const {
            specificInfosManagementSaving
        } = this.props;

        const warningRelicDate = lodashGet(errorManagerDico, "relicDate", undefined);
        const warningRelic = lodashGet(errorManagerDico, "relic", undefined);

        return (
            <MainCard title={"Reliquat"}>
                <Grid container spacing={2}>

                    {/* Reliquat */}
                    <Grid item xs={12}>
                        {/* reliquatIsAvg a true => Average, false => Measured */}
                        <Stack direction="row" alignContent="center">
                            <ButtonGroup disableElevation variant='contained' disabled={specificInfosManagementSaving}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('reliquatIsAvg', true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(reliquatIsAvg)}
                                >
                                    {StringTranslate.relicTypeRegionalValue}
                                </Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('reliquatIsAvg', false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(!reliquatIsAvg)}
                                >
                                    {StringTranslate.relicTypeAnalysis}
                                </Button>

                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    {/* Date du reliquat */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <CustomDatePicker
                                label={StringTranslate.remainderDate}
                                value={(reliquatDate !== null) ? new Date(reliquatDate) : null}
                                onChange={(newValue) => this.handleChangeValue('reliquatDate', newValue)}
                                disabled={specificInfosManagementSaving}
                                placeholder={StringTranslate.formatDatePlaceHolder}
                                error={warningRelicDate !== undefined}
                                helperText={warningRelicDate}
                                dialogOption={1}
                                onBlur={() => this.handleBlurDatePicker('reliquatDate')}
                            />
                        </FormControl>
                    </Grid>

                    {/* AVERAGE */}
                    <Grid
                        item xs={12}
                        sx={{
                            display: "block",
                            ...((reliquatIsAvg !== true) && {
                                display: "none"
                            })
                        }}
                    >
                        {/* SNO3NH4 */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    label={StringTranslate.fertilizerQuantity}
                                    id="fumure_SpecificFieldInfos_reliquatAverageSno3nh4"
                                    value={reliquatAverageSno3nh4}
                                    onChange={(newValue) => this.handleChangeValue('reliquatAverageSno3nh4', newValue)}
                                    type="number"
                                    onWheel={event => event.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kg N/ha</InputAdornment>
                                    }}
                                    disabled={specificInfosManagementSaving}
                                    error={warningRelic !== undefined}
                                    helperText={warningRelic}
                                    sx={{
                                        ...this.getThemeTextField(reliquatAverageSno3nh4)
                                    }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    {/* Measured */}

                    {this.renderReliquatMesTemplate(0)}
                    {this.renderReliquatMesTemplate(1)}
                    {(reliquatMeasuredLayers > 2) ? <>{this.renderReliquatMesTemplate(2)}</> : <></>}
                    <Grid
                        item xs={12}
                        sx={{
                            display: "flex",
                            ...(((reliquatIsAvg) || (reliquatMeasuredLayers > 2)) && {
                                display: "none"
                            })
                        }}
                    >
                        <Button
                            startIcon={<Add />}
                            onClick={() => this.handleReliquatMeasuredLayers(true)}
                        >
                            <Typography>{StringTranslate.addHorizon}</Typography>
                        </Button>
                    </Grid>
                    <Grid
                        item xs={12}
                        sx={{
                            display: "flex",
                            ...(((reliquatIsAvg) || (reliquatMeasuredLayers !== 3)) && {
                                display: "none"
                            })
                        }}
                    >
                        <Button
                            startIcon={<Remove />}
                            onClick={() => this.handleReliquatMeasuredLayers(false)}
                        >
                            <Typography>{StringTranslate.removeHorizon}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
        )
    }

    renderEngrais = () => {
        const {
            typeFertilisationMajoritaire, qteAzoteApporte,
            qteAzoteReserve, teneurEnAzote, natureEngraisIsSolide, errorManagerDico
        } = this.state;

        const {
            fertilizerTypes, specificInfosManagementSaving, cultureCrop
        } = this.props;

        const warningFertilizerType = lodashGet(errorManagerDico, "fertilizerType", undefined);
        const warningFertilizerContent = lodashGet(errorManagerDico, "content", undefined);

        const enumOfcultureCrop = lodashGet(cultureCrop, "cropType", enumCultureFertilizer.unknown);

        return (
            <MainCard title={"Fertilisation"}>
                <Grid container spacing={2}>

                    {/* Nature de l'engrai */}
                    <Grid item xs={12}>
                        {/* natureEngraisIsSolide a true => Solide, false => Liquide */}
                        <Stack direction="row" alignContent="center">
                            <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.supplyType}:</Typography>
                            <ButtonGroup disableElevation variant='contained' disabled={specificInfosManagementSaving}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('natureEngraisIsSolide', true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={natureEngraisIsSolide}
                                >
                                    {StringTranslate.supplyTypeSolid}
                                </Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeValue('natureEngraisIsSolide', false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(!natureEngraisIsSolide)}
                                >
                                    {StringTranslate.supplyTypeLiquid}
                                </Button>

                            </ButtonGroup>
                        </Stack>
                    </Grid>
                    {/* Type de fertilisation majoritaire / équivalent TENG chez azo / AZO */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="fumure_SpecificFieldInfos_typeFertilisationMajoritaire"
                                disableClearable
                                value={typeFertilisationMajoritaire}
                                options={fertilizerTypes}
                                getOptionLabel={currentElement => currentElement.label}
                                noOptionsText={StringTranslate.noAvailableChoice}
                                onChange={(event, newValue) => this.handleChangeValue('typeFertilisationMajoritaire', newValue)}
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            required
                                            label={StringTranslate.typeToSpread}
                                            error={warningFertilizerType !== undefined}
                                            helperText={warningFertilizerType}
                                            sx={{
                                                ...this.getThemeTextField(typeFertilisationMajoritaire)
                                            }}
                                        />
                                }
                                disabled={specificInfosManagementSaving}
                            />
                        </FormControl>
                    </Grid>

                    {/* Teneur en azote de l'engrais */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label={StringTranslate.nitrogenContent}
                                id="fumure_SpecificFieldInfos_teneurEnAzote"
                                value={teneurEnAzote}
                                onChange={(newValue) => this.handleChangeValue('teneurEnAzote', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                error={warningFertilizerContent !== undefined}
                                helperText={warningFertilizerContent}
                                sx={{
                                    ...this.getThemeTextField(teneurEnAzote)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Quantité en azote déjà apporté */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.quantityAlreadySupplied}
                                id="fumure_SpecificFieldInfos_qteAzoteApporte"
                                value={qteAzoteApporte}
                                onChange={(newValue) => this.handleChangeValue('qteAzoteApporte', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">U/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(qteAzoteApporte)
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Quantité d'azote à réserver */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={StringTranslate.quantityToKeep}
                                id="fumure_SpecificFieldInfos_qteAzoteReserve"
                                value={qteAzoteReserve}
                                onChange={(newValue) => this.handleChangeValue('qteAzoteReserve', newValue)}
                                type="number"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">U/ha</InputAdornment>
                                }}
                                disabled={specificInfosManagementSaving}
                                sx={{
                                    ...this.getThemeTextField(qteAzoteReserve)
                                }}
                            />
                            <FormHelperText id="info-reserve-text2">{StringTranslate.doseLastInstake}</FormHelperText>
                        </FormControl>
                    </Grid>

                    {/* aide pour culture gélive || uniquement pour le colza */}
                    <Grid
                        item xs={12}
                        sx={{ display: (enumOfcultureCrop === enumCultureFertilizer.colza_hiver) ? 'block' : 'none' }}
                    >
                        <Alert
                            severity='info'
                            icon={<InfoOutlined fontSize="inherit" />}
                        >
                            <Typography>
                                {StringTranslate.associatedFrostCulture}
                            </Typography>
                        </Alert>
                    </Grid>

                </Grid>
            </MainCard>
        )
    }

    renderFieldsToComplete = () => {

        return (
            <>

                {/* Grid desktop  sx={{display:{xs:"none", md:"block", xl:"block"}}}*/}
                <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex", xl: "flex" } }}>
                    <Grid item xs={3} id="SpecificInfosEntryPremiereColonne">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderRenseignementAgronomique()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderApportOrganique()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} id="SpecificInfosEntryDeuxiemeColonne">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderReliquatAzotee()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderCulturePrecedente()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} id="SpecificInfosEntryTroisiemeColonne">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderEngrais()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderIrrigation()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} id="SpecificInfosEntryTroisiemeColonne">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderHabitudeCulturalesDeLaParcelle()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderLocalisation()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Grid mobile */}
                <Grid container spacing={2} sx={{ display: { xs: "block", md: "none", xl: "none" } }}>
                    <Grid item xs={12}>
                        {this.renderRenseignementAgronomique()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderReliquatAzotee()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderCulturePrecedente()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderEngrais()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderHabitudeCulturalesDeLaParcelle()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderApportOrganique()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderIrrigation()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderLocalisation()}
                    </Grid>
                </Grid>
            </>
        )
    }

    handleHelp = () => {
        this.props.showHelp(ProfilIndex.aide_Fumure);
    }

    handleClosePopUp = (needValidation) => {
        const {
            dateDeSemi, dateDeRecolte, dateDeRecoltePrec, dateEnfouissementPrec,
            dateApportAO, reliquatDate, objRendement, tauxDeCailloux,
            profondeurDeLabour, qteApportOrga, irrigationQteEau, irrigationTeneurNitrates,
            reliquatAverageSno3nh4, teneurEnAzote, qteAzoteApporte, qteAzoteReserve,
            typeFertilisationMajoritaire, gestionDesResidus, habFreqCultInter, habTypeCultInter,
            retournementPrairie, dureePrairie, gestionDesResidusPrec, habitudeTypeApportOrga,
            freqApportOrga, irrigation, hasApportOrga, typeApportOrga, labourCheckBox, reliquatIsAvg, natureEngraisIsSolide,
            culturePrec, typeDeSol, variete, objProdBle, rendementPrec, fertilAzoteePrec,
            reliquatMeasuredNo3, reliquatMeasuredNh4, fertilizerEngine, parcelFertilizerData, isImprovingCrop
        } = this.state;

        const {
            saveOrUpdateSpecificInfos, idClient, parcelId, cultureCrop
        } = this.props;

        const enumOfcultureCrop = lodashGet(cultureCrop, "cropType", enumCultureFertilizer.unknown);

        let echantillonReliquat = [
            {
                depth: (reliquatIsAvg === true) ? undefined : "30",
                nO3: (reliquatMeasuredNo3[0] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNo3[0],
                nH4: (reliquatMeasuredNh4[0] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNh4[0],
            },
            {
                depth: (reliquatIsAvg === true) ? undefined : "30",
                nO3: (reliquatMeasuredNo3[1] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNo3[1],
                nH4: (reliquatMeasuredNh4[1] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNh4[1],
            },
            {
                depth: (reliquatIsAvg === true) ? undefined : "30",
                nO3: (reliquatMeasuredNo3[2] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNo3[2],
                nH4: (reliquatMeasuredNh4[2] <= 0) || (reliquatIsAvg === true) ? undefined : reliquatMeasuredNh4[2],
            }
        ];

        let fertilizerSpecificInfosManagement = {
            // infos client et parcelle
            // id: 0,                                                   // à revoir
            idClient: idClient,
            idParcel: parcelId,
            // codeCity: le nouveau code INSEE

            enumOfCrop: enumOfcultureCrop, // identifiant de culture Spotifarm, type 'enumCultureFertilizer' !
            crop: undefined, // Identifiant de culture associé au moteur de fumure  
            isImprovingCrop: isImprovingCrop,        
            engine: fertilizerEngine,
            variety: variete,
            harvestDate: dateDeRecolte,
            sowingDate: dateDeSemi,
            productionTarget: objProdBle,
            performanceGoal: objRendement,
            fertilizerType: typeFertilisationMajoritaire,
            soilKindType: typeDeSol,
            pebbleRate: tauxDeCailloux,
            tailingsManagement: gestionDesResidus,
            ploughing: labourCheckBox,
            ploughingDepth: profondeurDeLabour,
            organicInputFrequency: freqApportOrga,
            organicInputType: habitudeTypeApportOrga,
            interCropFrequency: habFreqCultInter,
            interCropType: habTypeCultInter,
            turningOverGrassland: retournementPrairie,
            grasslandDuration: dureePrairie,
            previousCrop: culturePrec,
            previousPerformance: rendementPrec,
            previousNitrogenFertilization: fertilAzoteePrec,
            previousHarvestDate: dateDeRecoltePrec,
            previousTailingsManagement: gestionDesResidusPrec,
            previousPloughingDate: dateEnfouissementPrec,
            hasOrganicInput: hasApportOrga,
            organicInput: typeApportOrga,
            organicInputQuantity: qteApportOrga,
            organicInputDate: dateApportAO,
            isIrrigated: irrigation,
            waterQuantity: irrigationQteEau,
            waterNitrateContent: irrigationTeneurNitrates,
            relicType: reliquatIsAvg ? fertilizerHelper.relicTypeEnum.valeurRegionale : fertilizerHelper.relicTypeEnum.analyse ,
            relicDate: reliquatDate,
            sNO3NH4: reliquatAverageSno3nh4,
            relicSamples: echantillonReliquat,                    // besoin de plus d'infos

            doseExtended: qteAzoteApporte,
            doseSaved: qteAzoteReserve,
            content: teneurEnAzote,
            supplyType: (natureEngraisIsSolide === true) ? false : true, //côté C# 'supplyType' est solide si le bool est à faux !

            beginningWinterDate: (parcelFertilizerData) ? parcelFertilizerData.beginningWinterDate : null,
            beginningWinterImagId: (parcelFertilizerData) ? parcelFertilizerData.beginningWinterImagId : -1,
            beginningWinterImagProvider: (parcelFertilizerData) ? parcelFertilizerData.beginningWinterImagProvider : 1,
            endingWinterDate: (parcelFertilizerData) ? parcelFertilizerData.endingWinterDate : null,
            endingWinterImagId: (parcelFertilizerData) ? parcelFertilizerData.endingWinterImagId : -1,
            endingWinterImagProvider: (parcelFertilizerData) ? parcelFertilizerData.endingWinterImagProvider : 1,
        };

        // Mise en place du dictionnaire de la liste des erreurs
        let errorManagerDico = fertilizerHelper.checkMinimumValuesSaved(enumOfcultureCrop, fertilizerSpecificInfosManagement, FertilizerEngineEnum.AZOFERT);
        errorManagerDico = fertilizerHelper.checkConsistencyOfValues(fertilizerSpecificInfosManagement, errorManagerDico, FertilizerEngineEnum.AZOFERT);

        this.setState({
            errorManagerDico: errorManagerDico
        });

        fertilizerSpecificInfosManagement.areMinimumValuesValid = errorManagerDico.isOk;

        //La fermeture de la popup se fait dans la fonction saveOrUpdateSpecificInfos
        saveOrUpdateSpecificInfos(fertilizerSpecificInfosManagement, errorManagerDico.isOk, needValidation);
    }

    handleOnClose = () => {
        this.handleClosePopUp(false);
    }

    render() {
        const { infoCurrentSelectedParcel, specificInfosManagementSaving, cultureCrop } = this.props;

        return (
            <Dialog
                sx={{ '& .MuiDialog-paper': { maxHeight: { xs: "100%", md: "90%", lg: "90%" } } }}
                fullWidth={true}
                maxWidth={false}
                fullScreen={this.fullScreen}
                open={this.props.displayDialogAzofertSaisieIndividuel}
                //On ne permet pas la fermeture par un clic en dehors de la popup quand un enregistrement est en cours.
                onClose={(specificInfosManagementSaving === true) ? null : this.handleOnClose}
            >
                <DialogTitle>
                    <Typography>
                        {StringTranslate.libelecolumnculture} : {cultureCrop.name} | {infoCurrentSelectedParcel.nom}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        paddingTop: "20px !important"
                    }}
                >
                    {this.renderFieldsToComplete()}
                </DialogContent>
                <DialogActions
                    sx={{ display: "block" }}
                >
                    <Stack
                        justifyContent="space-between"
                        direction="row"
                    >
                        <Button
                            // color='success'
                            variant='text'
                            onClick={this.handleHelp}
                            sx={{
                                color: theme.palette.secondary.main,
                                display: { xs: 'none', sm: 'none', md: 'block' }
                            }}
                        >
                            {StringTranslate.importmenuaide}
                        </Button>
                        <Button
                            // color='success'
                            variant='text'
                            onClick={this.handleHelp}
                            sx={{
                                color: theme.palette.secondary.main,
                                display: { xs: 'block', sm: 'block', md: 'none' }
                            }}
                        >
                            <Help />
                        </Button>
                        <div>
                            <Button
                                color="error"
                                variant="text"
                                onClick={() => this.handleClosePopUp(false)}
                                disabled={specificInfosManagementSaving}
                            >
                                {StringTranslate.quit}
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                disabled={specificInfosManagementSaving}
                                startIcon={specificInfosManagementSaving ? <CircularProgress color="inherit" size={20} /> : null}
                                sx={{
                                    ml: 1
                                }}
                                onClick={() => this.handleClosePopUp(true)}
                            >
                                {StringTranslate.enregistrer}
                            </Button>

                        </div>
                    </Stack>
                </DialogActions>

            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    displayDialogAzofertSaisieIndividuel: lodashGet(state, 'fertilizerData.specificInfosAzofertManagementActivation', false),
    infoCurrentSelectedParcel: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel', undefined),

    // la culture de la parcelle actuelle de spotifarm
    // 1 => BLE / 2 => ORGE / 3 => COLZA / 4 => BLE DUR
    cultureCrop: lodashGet(state, 'fertilizerData.cultureSelected', undefined),

    // infoParcel
    parcelId: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel.id', null),
    idClient: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel.parcel.clientId', null),
    parcelSowingDate: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel.parcel.details.dateSemi', null),
    parcelLocalisation: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel.parcel.localisation', null),

    // Les listes:
    fertilizerTypes: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].fertilizerTypes', []),
    productionTargets: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].productionTargets', []),
    tailingManagements: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].tailingManagments', []),
    interCropFrequency: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].interCropFrequency', []),
    organicInputTypes: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].organicInputTypes', []),
    organicInputFrequencies: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].organicInputFrequencies', []),
    interCropTypes: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].interCropTypes', []),
    turningOverGrasslandFrequencies: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].turningOverGrasslandFrequencies', []),
    grasslandDuration: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].grasslandDuration', []),
    prevTailingManagments: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].prevTailingManagments', []),
    // Plus grosses listes
    organicInputList: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].organicInputs', []),
    previousCropList: lodashGet(state, 'fertilizerData.fertilizerListsDico[Azofert].prevCrops', []),
    typeDeSolList: lodashGet(state, 'fertilizerData.fertilizerSpecificList_TypeDeSolDico[Azofert]', []),
    typeDeSolListe_attenteDeReceptionDeLaListe: lodashGet(state, 'fertilizerData.fertilizerSpecificList_TypeDeSol_awaitingAnAnswer', false),
    varieteList: lodashGet(state, 'fertilizerData.fertilizerSpecificList_VarieteDico[Azofert]', []),
    varieteList_bleProt: lodashGet(state, 'fertilizerData.fertilizerSpecificList_VarieteProtDico[Azofert]', []),
    varieteList_attenteDeReceptionDeLaListe: lodashGet(state, 'fertilizerData.fertilizerSpecificList_Variete_awaitingAnAnswer', false),

    // Infos communes / commonInfo_
    commonInfo_harvestDate: lodashGet(state, 'fertilizerData.commonInfosByCulture.harvestDate', null),
    commonInfo_SowingDate: lodashGet(state, 'fertilizerData.commonInfosByCulture.sowingDate', null),
    commonInfo_productionTarget: lodashGet(state, 'fertilizerData.commonInfosByCulture.productionTarget', null),
    commonInfo_performanceGoal: lodashGet(state, 'fertilizerData.commonInfosByCulture.performanceGoal', undefined),
    commonInfo_intermediateCultureFrequency: lodashGet(state, 'fertilizerData.commonInfosByCulture.intermediateCultureFrequency', null),
    commonInfo_tailingsManagement: lodashGet(state, 'fertilizerData.commonInfosByCulture.tailingsManagement', null),
    commonInfo_fertilizerType: lodashGet(state, 'fertilizerData.commonInfosByCulture.fertilizerType', null),
    commonInfo_content: lodashGet(state, 'fertilizerData.commonInfosByCulture.content', undefined),
    commonInfo_ploughing: lodashGet(state, 'fertilizerData.commonInfosByCulture.ploughing', false),
    commonInfo_ploughingDepth: lodashGet(state, 'fertilizerData.commonInfosByCulture.ploughingDepth', undefined),
    commonInfo_organicInputType: lodashGet(state, 'fertilizerData.commonInfosByCulture.organicInputType', undefined),
    commonInfo_organicInputFrequency: lodashGet(state, 'fertilizerData.commonInfosByCulture.organicInputFrequency', null),
    commonInfo_interCropType: lodashGet(state, 'fertilizerData.commonInfosByCulture.interCropType', undefined),
    commonInfo_doseReserved: lodashGet(state, 'fertilizerData.commonInfosByCulture.doseReserved', null),
    commonInfo_doseProvided: lodashGet(state, 'fertilizerData.commonInfosByCulture.doseProvided', null),

    // Infos spécifiques / sauvegarde / Dico
    specificInfosManagementSaving: lodashGet(state, 'fertilizerData.specificInfosManagementSaving', false),
    fertilizerDico: lodashGet(state, 'fertilizerData.fertilizerDico', {}), //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'Fertilizerdata'. => Uniquement les pesée de la fumure complète en BdD.
    fertilizerDicoCounter: lodashGet(state, 'fertilizerData.fertilizerDicoCounter', 0),

    archivedFertilizer: lodashGet(state, 'fertilizerData.archivedFertilizer', undefined),

    fertilizerEngine: lodashGet(state, 'fertilizerData.fertilizerEngine', undefined),
    loadedVariety: lodashGet(state, 'fertilizerData.loadedVariety', null),
    varietyLoading: lodashGet(state, 'fertilizerData.varietyPending', false),
})

const mapDispatchToProps = dispatch => ({
    openPopUp: () => dispatch(ActionShowSpecificInfosManagementPopup()),
    showHelp: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    getListeTypeDeSol: (parcelId) => dispatch(ActionGetSpecificListSoilKind(parcelId)),
    saveOrUpdateSpecificInfos: (specificInfos, noErrors, needValidation) => dispatch(ActionSaveSpecificInfosManagement(specificInfos, noErrors, needValidation)),
    updateLocalisationOfParcel: (parcelId, newLocalisation) => dispatch(ActionUpdateLocalisationAndReload(parcelId, newLocalisation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogSpecificInfosEntry);