import React from 'react';
import { connect } from 'react-redux';

import LayerCloudSelectionButtonControl from "./LayerCloudSelectionButtonControl.jsx";
import DisplayNdviCharts from '../histogramme/displayNdviCharts.jsx';
import ImageSwiper from '../imageSwiper';
import lodashGet from 'lodash/get';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { ActionSetDisplaySwipeablePanelForDesktop } from '../../redux/actions/contextApp.js';
import '../../assets/css/swipeablePanel.css';
import { Collapse, Button, Grid, Card, CardContent, Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RenderManageOfWidget from './renderManageOfWidget';

//import getTheme from "../../themes/index.js";

function SwipeablePanelForDesktop(props) {

	const { parcelIdSelected, displaySwipeablePanelForDesktop } = props;

	/* Ouverture /fermeture du panneau floattant des informations sur la parcelle */
	const handleExpandClick = () => {
		props.setDisplaySwipeablePanelForDesktop(!displaySwipeablePanelForDesktop);
	};

	
	return (
		(parcelIdSelected > 0) && (
			<div className="container-infos-image">
				<div className="swiper-parcel-infos">
					<Collapse in={!displaySwipeablePanelForDesktop} orientation="horizontal" timeout="auto">
						<Button
							aria-label="show-infos"
							color="primary"
							variant="contained"
							className="fabShow-infos-image leaflet-control"
							onClick={handleExpandClick}
						>
							<ArrowRightIcon viewBox="4 3 18 18" />
						</Button>
					</Collapse>

					<Collapse in={displaySwipeablePanelForDesktop} orientation="horizontal" timeout="auto">
						{/* Bouton flèche pour réduire le panneau */}
						<Button
							aria-label="show-infos"
							color="primary"
							variant="contained"
							className="fabShow-infos-image-open leaflet-control"
							onClick={handleExpandClick}
							sx={{
								zIndex: "1100"
							}}
						>
							<ArrowLeftIcon viewBox="4 3 18 18" />
						</Button>

						<Box
							className="widgets-desktop leaflet-control"
							sx={{
								zIndex: "1100",
								width: 466,
							}}
						>
							<PerfectScrollbar component="div">
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{/* card Button clouds */}
									<LayerCloudSelectionButtonControl className='leaflet-control' renderMapsCmp={props.renderMapsCmp} />
								</Grid>
								<Grid item xs={12}>
								
									{/* card ndvi chart */}
									<DisplayNdviCharts className='leaflet-control' />
								</Grid>
								{/* Gere les différents widget qui vont être afficher en fonction de la culture */}
								<RenderManageOfWidget {...props} />
							</Grid>
							</PerfectScrollbar>
						</Box>
					</Collapse>

					<Card sx={{ zIndex: "1100", ml: "0px !important" }} className='leaflet-control'>
						<CardContent>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<ImageSwiper />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</div>
			</div>
		)
	);
}
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
		displaySwipeablePanelForDesktop: lodashGet(state, 'contextAppData.displaySwipeablePanelForDesktop', true),
	};
}

const mapDispatchToProps = dispatch => ({
	setDisplaySwipeablePanelForDesktop: (mapPanelExpanded) => dispatch(ActionSetDisplaySwipeablePanelForDesktop(mapPanelExpanded)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwipeablePanelForDesktop);
