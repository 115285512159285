import { ActionTypeContextApp, PageName, EnumGoToView, ProfilIndex } from '../actions/contextApp.js';
import { AreaComparatorAllCode } from '../../models/parcelFilterParams.js';
import lodashGet from 'lodash/get';
//import StringTranslate from '../../assets/i18n/stringLanguage.jsx';


const initialState = {
    //Navigation:
    currentPage: undefined,
    askToShowBiomassFromMap: undefined,
    askToShowModulationFromMap: undefined,

    //Selection de parcelle
    parcelIdSelected: -1,
    parcelNameSelected: undefined, //Rq: Donnée non-obligatoire mais permettant d'éviter de lancer x fois la même recherche...
    parcelIdZoomed: -1, /* Quand le swipeablePanelForMobile est fermé entièrement, on reste zommé sur la parcelle mais celle-ci n'est pas sélectionnée */

    //Sélection d'observation
    observationIdSelected: -1,

    // ↓ Données en lien avec le filtre ↓
    isFilterOpened: false, // le zone de filtre etait-elle etendue?
    isFilterApplied: false, // y a t il un filtre appliqué ?
    //filterFarmName: undefined, //cas mono-sélection !
    filterFarmList: [], // filtre sur les nomsd'exploitations (dont les parcelles références)
    filterParcelName: '', // filtre basée sur le nom de parcelle
    filterCropList: [], // filtre se basant sur une liste de culture cochée par l'utilisateur
    filterAreaComparator: AreaComparatorAllCode, // filtre représentant le comparateur (all, <, >)
    filterAreaValue: 0, // filtre représentant la surface en hectare

    //liste de toutes les exploitations et des cultures trouvées dans les parcelles de l'utilisateur
    farmNameList: [],
    cropNameList: [],

    //Choix de l'image (pour une parcelle sélectionnée):
    satimageIdSelectedOfParcel: -1, 
    satimageDateSelectedOfParcel: undefined,
    providerSrcImageSelectedOfParcel: 0, //non-définis !

    //Gestion du bouton de menu burger mobile:
    burgerMenuIsOpen: false,

    //Gestion du calendrier:
    //@

    //Gestion de l'histogramme:
    chartShowed: true,

    //Cas d'erreur (toutes actions confondues):
    errorMessage: undefined,

    //Gestion des différents panneaux visibles:
    panelParcelInfoOpened: false,
    panelImageSwiperOpened: false,
    isPanelOpened: false,
    displaySwipeablePanelForMobile: true,
    isOpenMenuBurgerDesktop: true,
    displaySwipeablePanelForDesktop: true,

    //Gestion du menu des layers:
    menuLayerSelectionOpened: false, 

    //Gestion du chargement d'une vue globale
    globalLayerOnLoading: false,

    // objet contenant les paramètres URL lors d'une connexion à l'application (ex: key|'goToPage' value|'profil' )
    URLParameters: null,

    // Données en lien avec le recentrage d'une parcelle ou du parcellaire lors du click bouton (parcellaire ou nom de parcelle)
    fitMapToBounds: null,
    
    /*// Données en lien avec l'affichage du composant d'injection des parcelles GEOFOLIA (un seul affichage)
    isFirstDisplayGeofoliaFah: true,
    showDisplayGeofoliaFah: false,*/ //plus utile depuis la mise en place de la liaison PV

    // Doonée concernant l'affichage du message expliquant la nécessiter de sélectionner une parcelle pour permettre l'utilisation d'une couche globale:
    openDialogOfNoOpenGlobalLayer: false,

    // Doonée concernant l'incitation au passage en mode Premum:
    showInviteToPremium: false,
    reasonInviteToPremium: undefined,
    discountCodeForInviteToPremium: undefined,

    // Doonées permettant le démarrage automatique de la fonction de dessin d'observation
    enableDrawMarkerPindrop: false, 
    enableDrawAreaPindrop: false, 

    // Données permettant l'affichage du formulaire de création d'observation en mode mobile (fenêtre de dialogue),
    pinDropFormDialogOpened: false,
    pinDropFormDialogDatas: null,

    // Données permettant l'affichage de la fenêtre d'identification en mode mobile (fenêtre de dialogue),
    identificationDialogOpened: false,

    // Donnée qui exprime le fait que de nouvelles images sont actuellement affichées sur la carte (le composant MapInfo l'utilise)
    newImageShowedOnMap: false,

    // donnée qui exprime le nom de la page où l'utilisaeur se trouve dans l'appli.
    CurrentPageName: PageName.Map,
    showTutorialFirstParcelAfterDeletingSlots: false,

    // donnée qui permet de lancer le formulaire d'inscription lors d'une inscription via les réseaux sociaux
    showSocialAccountSignupForm: false,

    snackbarNewImagesHasBeenShown: false, //affichage du snacbar des nouvelles images (on l'affiche une fois, au démarrage)
    snackbarHelpNdviHasBeenShown: false, //affichage du snackbar d'aide de l'indice de végétation (on l'affiche une fois après le premier import/dessin)

    //DOMNode natif du menu des différentes couches // bouton des couches // (visible, etc.)
    anchorLayersPanelTarget: null,

    // Affichage du menu de profil
    showProfilMenu: false,
    profilIndex: ProfilIndex.monExploitation,
    profilPageUrl: undefined,

    // Affichage du calendrier
    calendarIsOpen: false,

    // Menu edit parcel
    openDialogParcelEdit: false,
    parcelEdit_Infos: undefined,
    parcelEdit_ThumbnailsInfos: undefined,

    // LayerGroup de la localisation de l'utilisateur
    geolocationCircleLayers: undefined,

    //Affichage de la pop-up de l'image à afficher en gros plan
    showImageDialogUri: "",
}

function contextAppManagerAction(state, action) {    
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) { //@@a complèter d'après ce qu'il y a dans les actions de contexte applicatif !
        //Affichage ou non du snackbar des nouvelles images (affichage une fois au démarrage de l'appli)
        case ActionTypeContextApp.SnackbarNewImagesHasBeenNewImages: {
            return Object.assign({}, state, {
                snackbarNewImagesHasBeenShown: action.snackbarNewImagesHasBeenShown,
            });
        }  

        //Affichage ou non du snackbar de l'aide de l'indice de végétation (affichage une fois au premier import/dessin d'un nouvel utilisateur)
        case ActionTypeContextApp.snackbarHelpNdviHasBeenShown: {
            return Object.assign({}, state, {
                snackbarHelpNdviHasBeenShown: action.snackbarHelpNdviHasBeenShown,
            });
        }  

        //Selection et filtre sur les parcelles:
        case ActionTypeContextApp.SelectParcel: {
            return Object.assign({}, state, {
                parcelIdSelected: action.parcelId, 
                parcelNameSelected: action.parcelName, 
                satimageIdSelectedOfParcel: action.dateImgId, 
                satimageDateSelectedOfParcel: action.dateImgDate, 
                providerSrcImageSelectedOfParcel: action.providerImagSource, 
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                observationIdSelected: -1 //quand une parcelle est sélectionnée, on désélectionne l'observation (si il y a)
            });
        }
            
        case ActionTypeContextApp.UnselectParcel: {
            return Object.assign({}, state, {
                parcelIdSelected: -1,                
                parcelNameSelected: undefined,
                satimageIdSelectedOfParcel: -1, 
                satimageDateSelectedOfParcel: undefined,
                providerSrcImageSelectedOfParcel: 0, //non-définis !
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                panelParcelInfoOpened: false,
                panelImageSwiperOpened: false,
                observationIdSelected: -1,
                isPanelOpened: false, //le statut d'ouverture d'un des panneaux dépend donc de celui-ci !
            });
        }
            
        case ActionTypeContextApp.DeselectParcel: {
            return Object.assign({}, state, {
                parcelIdSelected: -1,
            });
        }
            
        case ActionTypeContextApp.InitFarmsAndCulturesParcel: {
            return Object.assign({}, state, {
                farmNameList: action.farmNameList, 
                cropNameList: action.cropNameList, 
            });
        }

        case ActionTypeContextApp.setIsFilterOpened: {
            return {
                ...state,
                isFilterOpened: action.isOpen,
            }
        }
            
        case ActionTypeContextApp.UpdateFilterParcels: {
            if (action.paramsFilterParcels) {
                return Object.assign({}, state, {
                    isFilterApplied: lodashGet(action, 'paramsFilterParcels.isFilterApplied', false), 
                    //filterFarmName: (action.paramsFilterParcels.filterFarmName) ? action.paramsFilterParcels.filterFarmName : undefined, //Cas de la mono-sélection !
                    filterFarmList: lodashGet(action, 'paramsFilterParcels.filterFarmList', []),
                    filterParcelName: lodashGet(action, 'paramsFilterParcels.filterParcelName', ''),
                    filterCropList: lodashGet(action, 'paramsFilterParcels.filterCropList', []), 
                    filterAreaComparator: lodashGet(action, 'paramsFilterParcels.filterAreaComparator', AreaComparatorAllCode), 
                    filterAreaValue: lodashGet(action, 'paramsFilterParcels.filterAreaValue', 0),
                    //burgerMenuIsOpen: false, // il ne faut pas fermer le menu burger car l'utilisateur peut vouloir ajouter une donnée de filtre avant de valider...
                });
            } else {
                return Object.assign({}, state, {
                    burgerMenuIsOpen: false, // ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                });
            }
        }

        case ActionTypeContextApp.updateCultures: {
            return {
                ...state,
                cropNameList: action.cropNameList, 
            }
        }
        
        case ActionTypeContextApp.updateExploitations: {
            return {
                ...state,
                farmNameList: action.farmNameList, 
            }
        }
        
        //Choix de l'image (pour une parcelle sélectionnée):
        case ActionTypeContextApp.SelectDateImgOfParcel: {
            return Object.assign({}, state, {
                parcelIdSelected: action.parcelId, 
                parcelNameSelected: (action.parcelName) ? action.parcelName : ( (action.parcelId === state.parcelIdSelected) ? state.parcelNameSelected : '' ),
                satimageIdSelectedOfParcel: action.dateImgId, 
                satimageDateSelectedOfParcel: action.dateImgDate, 
                providerSrcImageSelectedOfParcel: action.providerImagSource, 
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
            });
        }

        //Gestion du bouton de menu burger mobile:
        case ActionTypeContextApp.BurgerMenuOpen: {
            return Object.assign({}, state, {
                burgerMenuIsOpen: true,
            });
        }

        //Gestion du bouton de menu burger desktop:
        case ActionTypeContextApp.BurgerMenuOpenDesktop: {
            return Object.assign({}, state, {
                isOpenMenuBurgerDesktop: !state.isOpenMenuBurgerDesktop
            });
        }

        case ActionTypeContextApp.BurgerMenuClose: {
            return Object.assign({}, state, {
                burgerMenuIsOpen: false,
            });
        }
        
        //Gestion du calendrier:
        //@@

        //Gestion de l'histogramme:
        //@@
        
        // Gestion des différents panneaux visibles:
        case ActionTypeContextApp.SwiperPanelOpen: {
            return {
                ...state,
                panelImageSwiperOpened: true,
                panelParcelInfoOpened: false,
                isPanelOpened: true,
            };
        }

        case ActionTypeContextApp.SwiperPanelClose: {
            return {
                ...state,
                panelImageSwiperOpened: false,
                panelParcelInfoOpened: false,
                isPanelOpened: false,
            };
        }

        case ActionTypeContextApp.setDisplaySwipeablePanelForMobile: {
           return {
                ...state,
                parcelIdSelected: -1,                
                parcelNameSelected: undefined,
                satimageIdSelectedOfParcel: -1, 
                satimageDateSelectedOfParcel: undefined,
                providerSrcImageSelectedOfParcel: 0,
                parcelIdZoomed: action.parcelIdZoomed,
                panelParcelInfoOpened: false,
                observationIdSelected: -1,
                isPanelOpened: false, //le statut d'ouverture d'un des panneaux dépend donc de celui-ci !
                displaySwipeablePanelForMobile: action.displaySwipeablePanelForMobile,
                panelImageSwiperOpened: false,
           } 
        }

        case ActionTypeContextApp.ParcelInfoPanelOpen: {
            return {
                ...state,
                panelParcelInfoOpened: true,
                panelImageSwiperOpened: false,
                isPanelOpened: true,
            };
        }

        case ActionTypeContextApp.ParcelInfoPanelClose: {
            return {
                ...state,
                panelParcelInfoOpened: false,
                panelImageSwiperOpened: false,
                isPanelOpened: false,
            };
        }

        case ActionTypeContextApp.LayersMenuOpen: {
            return {
                ...state,
                menuLayerSelectionOpened: true,
            };
        }

        case ActionTypeContextApp.LayersMenuClose: {
            return {
                ...state,
                menuLayerSelectionOpened: false,
            };
        }

        case ActionTypeContextApp.PanelCollapse: {
            return {
                ...state,
                panelParcelInfoOpened: false,
                panelImageSwiperOpened: false,
                isPanelOpened: false,
            };
        }

        case ActionTypeContextApp.SetAnchorLayersPanel: {
            return {
                ...state,
                anchorLayersPanelTarget: action.anchorLayersPanelTarget,
            };
        }

        //Gestion de la Géo-localisation:
        //@@
        
        // Navigation:
        case ActionTypeContextApp.goToPage: {
            return Object.assign({}, state, {
                currentPage: action.currentPage,
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                askToShowBiomassFromMap: (action.currentPage === EnumGoToView.biomass) ? action.askToShowBiomassFromMap : undefined, //Accède à la biomasse soit par la carte générale, soit par le tableau de biomass
                askToShowModulationFromMap: (action.currentPage === EnumGoToView.modulation) ? action.askToShowModulationFromMap : undefined, //Accède à la modulation soit par la carte générale, soit par le tableau de modulation
                // pour fermer le menu de profil s'il est ouvert
                showProfilMenu: (action.currentPage !== EnumGoToView.profil) ? false : state.currentPage,
            });
        }

        case ActionTypeContextApp.goToMapSelectParcel: {
            return Object.assign({}, state, {
                currentPage: action.currentPage,
                askToShowBiomassFromMap: (action.currentPage === EnumGoToView.biomass) ? action.askToShowBiomassFromMap : undefined, //Accède à la biomasse soit par la carte générale, soit par le tableau de biomass
                askToShowModulationFromMap: (action.currentPage === EnumGoToView.modulation) ? action.askToShowModulationFromMap : undefined, //Accède à la modulation soit par la carte générale, soit par le tableau de modulation
                parcelIdSelected: action.parcelId,
                parcelNameSelected: action.parcelName, 
                satimageIdSelectedOfParcel: action.dateImgId, 
                satimageDateSelectedOfParcel: action.dateImgDate, 
                providerSrcImageSelectedOfParcel: action.providerImagSource, 
                observationIdSelected: -1,
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                showProfilMenu: false,
            });
        }

        case ActionTypeContextApp.goToMapModulationAndSelectParcel: {
            return Object.assign({}, state, {
                currentPage: action.currentPage,
                askToShowBiomassFromMap: undefined, 
                askToShowModulationFromMap: undefined, 
                parcelIdSelected: action.parcelId,
                parcelNameSelected: action.parcelName, 
                satimageIdSelectedOfParcel: action.dateImgId, 
                satimageDateSelectedOfParcel: action.dateImgDate, 
                providerSrcImageSelectedOfParcel: action.providerImagSource, 
                observationIdSelected: -1,
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
            });
        }

        case ActionTypeContextApp.goToMapUnselectParcel: {
            return Object.assign({}, state, {
                currentPage: action.currentPage,
                askToShowBiomassFromMap: undefined, 
                askToShowModulationFromMap: undefined, 
                parcelIdSelected: -1,                
                parcelNameSelected: undefined,
                satimageIdSelectedOfParcel: -1, 
                satimageDateSelectedOfParcel: undefined,           
                providerSrcImageSelectedOfParcel: 0, //non-définis !     
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                panelParcelInfoOpened: false,
                panelImageSwiperOpened: false,
                observationIdSelected: -1,
                isPanelOpened: false, //le statut d'ouverture d'un des panneaux dépend donc de celui-ci !
                showProfilMenu: false,
            });
        }

        case ActionTypeContextApp.goToMapSelectObservation: {
            return Object.assign({}, state, {
                currentPage: action.currentPage,
                askToShowBiomassFromMap: undefined, 
                askToShowModulationFromMap: undefined, 
                observationIdSelected: action.observationId,
                observationNameSelected: action.observationName, 
                parcelIdSelected: -1,
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
            });
        }

        case ActionTypeContextApp.fitMapToBounds: {
            return {
                ...state,
                fitMapToBounds: { parcelId: (action.parcelId > 0) ? action.parcelId : null }
            }
        }

        case ActionTypeContextApp.LoadingGlobalLayer: {
            return Object.assign({}, state, {
                globalLayerOnLoading: true,
            });
        }

        case ActionTypeContextApp.GlobalLayerLoaded: {
            return Object.assign({}, state, {
                globalLayerOnLoading: false,
            });
        }

        case ActionTypeContextApp.setURLParameters: {
            return {
                ...state,
                URLParameters: action.URLParameters,
            }
        }

        case ActionTypeContextApp.showDialogToSelectParcelForUseGlobalLayer: {
            return {
                ...state,
                openDialogOfNoOpenGlobalLayer: true,
            }
        }

        case ActionTypeContextApp.closeDialogToSelectParcelForUseGlobalLayer: {
            return {
                ...state,
                openDialogOfNoOpenGlobalLayer: false,
            }
        }

        case ActionTypeContextApp.incitationPremium: {
            /*Cas restant à traiter plus tard (en créeant des affichages d'incitation associés):
            else if (action.reasonInvitation === ReasonInviteToPremium.NoLimitParcels) {
                specificText = StringTranslate.invitationPremiumNoLimitParcels;
            } 
            else if (action.reasonInvitation === ReasonInviteToPremium.NoLimitHistoricParcels) {
                specificText = StringTranslate.invitationPremiumNoLimitHitoricParcels;
            }*/
            
            return {
                ...state,
                showInviteToPremium: true,
                reasonInviteToPremium: action.reasonInvitation,
                discountCodeForInviteToPremium: action.discountCode,
                burgerMenuIsOpen: false, //ferme le menu burger (sans s'occuper de savoir si en mode mobile ou pas)
                panelParcelInfoOpened: false,
                panelImageSwiperOpened: false,
                isPanelOpened: false,
            }
        }

        case ActionTypeContextApp.closeIncitationPremium: {
            return {
                ...state,
                showInviteToPremium: false,
                reasonInviteToPremium: undefined, 
                discountCodeForInviteToPremium: undefined,
            }
        }

        case ActionTypeContextApp.enableDrawMarkerPindrop: {
            return {
                ...state,
                enableDrawMarkerPindrop: true, 
            }
        }

        case ActionTypeContextApp.enableDrawAreaPindrop: {
            return {
                ...state,
                enableDrawAreaPindrop: true, 
            }
        }

        case ActionTypeContextApp.disableDrawMarkerPindrop: {
            return {
                ...state,
                enableDrawMarkerPindrop: false, 
            }
        }

        case ActionTypeContextApp.disableDrawAreaPindrop: {
            return {
                ...state,
                enableDrawAreaPindrop: false, 
            }
        }

        case ActionTypeContextApp.setValueNewImageShowedOnMap: {
            return {
                ...state,
                newImageShowedOnMap: action.bool, 
            }
        }

        case ActionTypeContextApp.openPinDropFormDialog: {
            return {
                ...state,
                pinDropFormDialogOpened: true,
                pinDropFormDialogDatas: action.datas,
            }
        }

        case ActionTypeContextApp.closePinDropFormDialog: {
            return {
                ...state,
                pinDropFormDialogOpened: false, 
                pinDropFormDialogDatas: null,
            }
        }

        case ActionTypeContextApp.setCurrentPageName: {
            return {
                ...state,
                CurrentPageName: action.currentPageName,
                showTutorialFirstParcelAfterDeletingSlots: action.showTutorialFirstParcelAfterDeletingSlots
            }
        }

        case ActionTypeContextApp.showSocialAccountSignupForm: {
            return {
                ...state,
                showSocialAccountSignupForm: true
            }
        }

        case ActionTypeContextApp.showProfilMenu: {
            return {
                ...state,
                showProfilMenu: true,
                profilIndex: action.profilIndex,
                profilPageUrl: action.profilPageUrl,
            }
        }

        case ActionTypeContextApp.closeProfilMenu: {
            return {
                ...state,
                showProfilMenu: false,
            }
        }

        case ActionTypeContextApp.showCalendar: {
            return {
                ...state,
                calendarIsOpen: true,
            }
        }

        case ActionTypeContextApp.closeCalendar: {
            return {
                ...state,
                calendarIsOpen: false,
            }
        }

        case ActionTypeContextApp.openDialogParcelEdit: {
            return {
                ...state,
                openDialogParcelEdit: true,
                parcelEdit_Infos: action.parcel,
                parcelEdit_ThumbnailsInfos: action.thumbnailInfos,
            }
        }

        case ActionTypeContextApp.closeDialogParcelEdit: {
            return {
                ...state,
                openDialogParcelEdit: false,
            }
        }

        case ActionTypeContextApp.setGeolocationCircleLayers: {
            return {
                ...state,
                geolocationCircleLayers: action.geolocationCircleLayers,
            }
        }

        case ActionTypeContextApp.openIdentificationDialog: {
            return {
                ...state,
                identificationDialogOpened: true,
            }
        }

        case ActionTypeContextApp.closeIdentificationDialog: {
            return {
                ...state,
                identificationDialogOpened: false, 
                identificationDialogDatas: null,
            }
        }

        case ActionTypeContextApp.showImageDialog: {
            return {
                ...state,
                showImageDialogUri: action.uri,
            }
        }

        case ActionTypeContextApp.closeImageDialog: {
            return {
                ...state,
                showImageDialogUri: "",
            }
        }

        case ActionTypeContextApp.setDisplaySwipeablePanelForDesktop: {
            return {
                    ...state,
                    displaySwipeablePanelForDesktop: action.displaySwipeablePanelForDesktop,
            } 
        }

        default:
            return state;
    }
}

export default contextAppManagerAction;
