import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI components */
import {
    Step, Stepper, StepLabel, Grid, List, ListItemText, Typography, Box,
    DialogTitle, Dialog, IconButton, Divider, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

/* React components */
import AbonnementFormule from "./abonnementFormule";
import AbonnementAdresseFact from "./abonnementAdresseFact";
import AbonnementPayment from "./abonnementPayment";
import AbonnementSkeleton from "./abonnementSkeleton";
import MainCard from "../subLayouts/mainCard";
import PlanCard from "./planCard";
import { SuccessSubscription, FailSubscription } from '../stripe/stripeSuccessFailView';
import { validateDatasForInvoice } from './userInfo';
import ADVPlanCard from "../profil/aDVplanCard";
import ADVLink from "../profil/aDVLink";
import CustomIFrame from "../customIFrame";
import LogoSpotifarm from '../../assets/logoSpotifarm/FeuilleSpotifarmLogoCouleur';

/* Redux */
import {
    ActionGetPlansSubscriptions, ActionErrorSubscriptionProcess,
    ActionResetSubscriptionProcess, ActionGetClientDatasAfterSubscriptionAsk,
    ActionSetIsNewSubscription, ActionGetClientBilling, ActionSetIsFormValid, ActionGetAllPermissions
} from '../../redux/actions/clientUser.js';
import { ActionGoToMap } from "../../redux/actions/contextApp";

/* css */
import '../../assets/css/profil.css';

/* Utils */
import { StripeProvider } from 'react-stripe-elements';
import ConfigAuthent from '../../utils/configAuthent';
import { IsNativeHoster } from "../../utils/platformHelper";
import ConstantsTutorialHelps from "../../utils/constantsTutorialHelps";
import permissionsHelper from "../../utils/permissionsHelper";
import dateHelper from "../../utils/dateHelper";
import StringTranslate from "../../assets/i18n/stringLanguage";

/* Theme */
import getTheme from "../../themes/index.js";
let theme = getTheme();
/* Custom plans pour clients adv */
const planDatasMap = {
    id: 1,
    title: StringTranslate.ADVPlanTDPTitle,
    description: StringTranslate.ADVPlanTDPDescription,
    subDescription:
        <List dense={true}>
            <ListItemText primary={StringTranslate.appsPermissionDescription} />
            <ListItemText primary={StringTranslate.parcelsPermissionDescription} />
            <ListItemText primary={StringTranslate.telepacPermissionDescription} />
            <ListItemText primary={StringTranslate.geofoliaPermissionDescription} />
            <ListItemText primary={StringTranslate.indicevegePermissionDescription} />
            <ListItemText primary={StringTranslate.historyPermissionDescription} />
            <ListItemText primary={StringTranslate.geolocPermissionDescription} />
            <ListItemText primary={StringTranslate.observationPermissionDescription} />
            <ListItemText primary={StringTranslate.zonesPermissionDescription} />
            <ListItemText primary={StringTranslate.ADVPlanNoModulMapsPermissions} />
            <ListItemText primary={StringTranslate.ADVPlanNoModulDosesPermissions} />
            <ListItemText primary={StringTranslate.ADVPlanNoModulStrategiesPermissions} />
            <ListItemText primary={StringTranslate.ADVPlanNoModulExportPermissions} />
            <ListItemText primary={StringTranslate.filterPermissionDescription} />
            <ListItemText primary={StringTranslate.supportPermissionDescription} />
        </List>,
};

const planDatasModulation = {
    id: 2,
    title: StringTranslate.ADVPlanModulTitle, 
    description: StringTranslate.ADVPlanModulDescription, 
    subDescription:
        <List dense={true}>
            <ListItemText primary={StringTranslate.appsPermissionDescription} />
            <ListItemText primary={StringTranslate.parcelsPermissionDescription} />
            <ListItemText primary={StringTranslate.telepacPermissionDescription} />
            <ListItemText primary={StringTranslate.geofoliaPermissionDescription} />
            <ListItemText primary={StringTranslate.indicevegePermissionDescription} />
            <ListItemText primary={StringTranslate.historyPermissionDescription} />
            <ListItemText primary={StringTranslate.geolocPermissionDescription} />
            <ListItemText primary={StringTranslate.observationPermissionDescription} />
            <ListItemText primary={StringTranslate.zonesPermissionDescription} />
            <ListItemText primary={StringTranslate.modulationMapsPermissionDescription} />
            <ListItemText primary={StringTranslate.modulationDosesPermissionDescription} />
            <ListItemText primary={StringTranslate.modulationStrategiesPermissionDescription} />
            <ListItemText primary={StringTranslate.modulationExportPermissionDescription} />
            <ListItemText primary={StringTranslate.filterPermissionDescription} />
            <ListItemText primary={StringTranslate.supportPermissionDescription} />
        </List>
};

const planDatasOption = {
    id: 3,
    title: StringTranslate.fertilizerOption,
    subDescription:
        <List dense={true}>
            <ListItemText primary={StringTranslate.fertilizerPlanPermissionDescription} />
            <ListItemText primary={StringTranslate.fertilizerPilotingPermissionDescription} />
            <ListItemText primary={StringTranslate.wheatLNIPermissionDescription} />
        </List>
};

class Abonnements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            etapeCourante: 0,
            etapeAchevees: {},
            nbEtapes: 4,
            stripe: null,
            completeAddress: props.address,

            openSubscriptionFormDialog: false,

        }
        this.isOnNativeApp = (IsNativeHoster() === true) ? true : false;

        this.handleChange = this.handleChange.bind(this);
        this.goToStep = this.goToStep.bind(this);
        this.getEtapes = this.getEtapes.bind(this);
    }

    getEtapes() {
        let etapes = [
            `${StringTranslate.selectionabo}`,
            `${StringTranslate.infofac}`,
            `${StringTranslate.infobanc}`
        ];
        return etapes;
    }

    handleChange = param => (event) => {
        const {
            etapeCourante
        } = this.state;

        if (param === 'next') {
            if (window.dataLayer && this.state.activeStep > 0) // google tag manager => seulement sur les étapes 2 et 3 
                window.dataLayer.push({ 'event': 'suiviBouton', 'action': `validation étape ${this.state.etapeCourante + 1}` });
            if (window.fbq && this.state.activeStep === 1) // facebook pixel => seulement sur le passage de l'étape 2 à 3 (infos profil remplies)
                window.fbq('track', 'AddPaymentInfo', { value: 0.0, currency: 'EUR' });

            this.setState({
                etapeCourante: etapeCourante + 1,
            })
        } else if (param === 'back') {
            if (window.dataLayer) // google tag manager
                window.dataLayer.push({ 'event': 'suiviBouton', 'action': `retour étape ${this.state.etapeCourante}` });
            this.setState({
                etapeCourante: etapeCourante - 1,
            })
        }

    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        const {getAllPermissions, allPermissions} = this.props;
        /* Enregistrement dans Redux du nom de la page actuelle */
        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
            });
        }

        // ↓ Récupération des plans munis de leur souscription (si cela n'a pas déjà été demandé) ↓
        if (this.isOnNativeApp === true) {
            if (!this.props.planSubscriptionDico || (Object.values(this.props.planSubscriptionDico).length <= 0))
                this.props.getPlansSubscriptions();
        } else if (allPermissions.length <= 0) {
            getAllPermissions()
        }
    }

    /* fonction permettant d'aller directement à une étape voulue */
    goToStep = stepIndexAsk => (event) => {
        const { nbEtapes, etapeCourante } = this.state;
        const { subscriptionProcess } = this.props;

        // Premier contrôle : on autorise le changement que si l'étape demandée est dans la liste (l'interval connu)...
        if ((stepIndexAsk < nbEtapes) && (stepIndexAsk >= 0)) {
            //this.setState({ etapeCourante : stepIndexAsk }); //il y a d'autres contrôles à faire !
            switch (stepIndexAsk) {
                case 0: { // 1ère étape: CHOIX DU PLAN
                    if (etapeCourante !== 0)
                        this.setState({ etapeCourante: 0 });

                    return;
                }

                case 1: { // 2eme étape: FORMULAIRE D'INFORMATIONS DE FACTURATION CLIENT
                    if (etapeCourante <= 0) { //on ne permet pas de passer de l'étape 'choix du plan' à celle de la 'saisie des infos client' (car seul le bouton sur un plan doit le permettre)
                        /* //@@A voir avec CDu si on remet (ou pas) en place ce message !
                        // ↓ on affiche juste un petit message invitant l'utilisateur à faire son choix parmis les plans actifs ! ↓
                        if (showSelectPlanMessage !== true) {
                            this.setState({ showSelectPlanMessage: true });
                            setTimeout(() => (this) ? this.setState({ showSelectPlanMessage: false }) : null, 5000) // on supprime le message au bout de 5s
                        }
                        */
                    }
                    else if (etapeCourante > 1) {
                        this.setState({ etapeCourante: 1 });
                    }

                    return;
                }

                case 2: { // 3eme étape: CARTE BANCAIRE POUR PAIEMENT
                    //Si le choix du plan est fait (en principe: oui car on a bloqué le passage à l'étape N°2 par le choix du plan)
                    //Et si les informations de facturation étaient déjà renseignées, alors on autorise l'affichage de cette étape.
                    // => d'ailleurs, il faudrait ne faire que l'affichage de la CB actuelle (si renseignée), sinon la saisie de la première CB.    
                    if (etapeCourante <= 0) { //on ne permet pas de passer de l'étape 'choix du plan' à celle de la 'saisie de la CB' (car seul le bouton sur un plan doit le permettre)
                        /* //@@A voir avec CDu si on remet (ou pas) en place ce message !
                        // ↓ on affiche juste un petit message invitant l'utilisateur à faire son choix parmis les plans actifs ! ↓
                        if (showSelectPlanMessage !== true) {
                            this.setState({ showSelectPlanMessage: true });
                            setTimeout(() => this.setState({ showSelectPlanMessage: false }), 5000) // on supprime le message au bout de 5s
                        }
                        */
                    }
                    else if (etapeCourante === 1) {
                        //on PEUT autoriser à passer à la troisième étape sans clic sur le bouton de validation, 
                        // SSI tous les champs sont remplis et valides.... Pour le vérifier, on lance une vérification.
                        if (subscriptionProcess.isFormValid !== true) {
                            const { naming, address, phone, codeCountry } = this.props;
                            const stateToUpdate = validateDatasForInvoice({
                                firstName: (naming && naming.firstName) ? naming.firstName : '',
                                lastName: (naming && naming.lastName) ? naming.lastName : '',
                                phone: phone,
                                label: (address && address.label) ? address.label : '',
                                postalCode: (address && address.postalCode) ? address.postalCode : '',
                                cityName: (address && address.cityName) ? address.cityName : '',
                                country: (address && address.country) ? address.country : 'France',
                                codeCountry: codeCountry,
                            });

                            if (!stateToUpdate.errorMessage) { //on autorise à passer à la troisième étape si les champs de facturation sont ok !
                                //subscriptionProcess.isFormValid = true; //il faut plutôt actualiser Redux !
                                this.props.setIsFormValid(true);

                                this.setState({ etapeCourante: 2 });
                            }
                        } else { //on autorise à passer à la troisième étape si les champs de facturation étaient déjà ok !
                            this.setState({ etapeCourante: 2 });
                        }
                    }

                    return; //on ne change rien sinon !
                }

                default:
                    return; //si cas non-géré, on ne change rien !
            }
        } else if (etapeCourante !== 0) { //on autorise à retourner sur la première étape...
            this.setState({ etapeCourante: stepIndexAsk });
        }
    }

    renderCurrentSubscriptionStep = () => {
        const { etapeCourante } = this.state;

        switch (etapeCourante) {
            case 0:   // choix de la formule d'abonnement
                return (
                    <>
                        <AbonnementFormule
                            handleNextStep={this.handleChange('next')}
                        />
                    </>
                )
            case 1:   // confirmation de l'adresse de facturation
                return (
                    <>
                        <AbonnementAdresseFact
                            handleNextStep={this.handleChange('next')}
                            handlePreviousStep={this.handleChange('back')}
                        />

                    </>
                )
            case 2:   // confirmation des infos de payment et validation
                return (
                    <>
                        <AbonnementPayment
                            handleNextStep={this.handleChange('next')}
                            handlePreviousStep={this.handleChange('back')}
                        />
                    </>
                )
            case 3: // 4eme étape: affichage écran de succès ou d'erreur
                if (this.props.subscriptionProcess && this.props.subscriptionProcess.error)
                    return <MainCard><FailSubscription {...this.props} handleBackToFirstStep={this.goToStep(0)} /></MainCard>;
                else
                    return <MainCard><SuccessSubscription {...this.props} /></MainCard>;
            default:
                return (
                    <>

                    </>
                )
        }

    }

    renderAbonnementCard = () => {
        const { etapeCourante } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stepper activeStep={etapeCourante} alternativeLabel sx={{ mb: 4 }}>
                        {this.getEtapes().map((label, index) => (
                            <Step key={label} onClick={this.goToStep(index)} completed={index < etapeCourante}>
                                <StepLabel sx={{ cursor: "pointer" }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

                <Grid item xs={12}>
                    {/* {(etapeCourante === 2) ? 
                        <Button variant="outlined" color="primary" onClick={this.handleChange('back')}>{`${StringTranslate.revenir}`}</Button> 
                    : 
                        <></>
                    } */}

                    {/* ↓↓ Partie - CHARGEMENT EN COURS (pas de planSouscription ou bien pas encore d'informations client) - (contenu: SPINNER + fond grisé) ↓↓ */}
                    {(!(this.props.clientId > 0) || (this.props.gettingPlansSubscriptions && (!this.props.isNewSubscription) && (etapeCourante === 0))) && (
                        <AbonnementSkeleton />
                    )}
                    {this.renderCurrentSubscriptionStep()}
                </Grid>


            </Grid>
        );
    }

    renderAbonnementCardAdv = () => {
        const { enumTypoClient } = this.props;

        return (<>
        {/* Plans ADV à afficher en fonction de la typo client */}
            <Grid container spacing={3}>
                <ADVPlanCard planDatas={planDatasMap} subscribedResume={(enumTypoClient === 2) || (enumTypoClient === 5)}></ADVPlanCard>
                <ADVPlanCard planDatas={planDatasModulation} subscribedResume={(enumTypoClient === 4) || (enumTypoClient === 6)}></ADVPlanCard>
                <ADVPlanCard planDatas={planDatasOption} subscribedResume={(enumTypoClient === 5) || (enumTypoClient === 6)}></ADVPlanCard>
                <Grid item xs={12}>
                    <ADVLink />
                </Grid>
            </Grid>

        
        </>
        );
    }

    openSubscriptionForm = () => {
		this.setState({
			openSubscriptionFormDialog: true,
		});
    }

    handleCloseSubscriptionForm = () => {
		this.setState({
			openSubscriptionFormDialog: false,
		});
    }

    render() {
        const {openSubscriptionFormDialog} = this.state;
        const {userDatas, gettingAllPermissions, allPermissions, typoEndingDate} = this.props;

        const enumTypoClient = userDatas.enumTypoClient;
        const isExplorer = enumTypoClient === 3;
        const isFreemium = enumTypoClient === 1;
        
        //On retire la permission de l'historique pour éviter de l'afficher dans la liste des permissions de l'utilisateur puisqu'actuellement on ne souhaite plus l'afficher
        const userPermissionsWithoutHistory = permissionsHelper.filterPermissions(userDatas.permissions, [permissionsHelper.permissionEnum.history])

        //*Laisser les permissions statiques du TDP à cet endroit car elles ne seront potentiellement plus utilisées à l'avenir
        const staticTDPPermissions = [
            StringTranslate.exploitationNumbersPermissionDescription,
            StringTranslate.parcelsPermissionDescription,
            StringTranslate.surfacePermissionDescription,
            StringTranslate.geolocPermissionDescription,
            StringTranslate.adventitiousFloraPermissionDescription,
            StringTranslate.importPermissionDescription,
        ];

        //Traduction des typos, déplacement à cet endroit pour les traductions dans toutes les langues et pas seulement le français
        const TypoDescription = {
            1: StringTranslate.freemiumDescription,
            2: StringTranslate.tourDePlaineDescription,
            3: StringTranslate.explorerDescription,
            4: StringTranslate.modulationDescription,
            5: StringTranslate.tourDePlaineAndFumreDescription,
            6: StringTranslate.modulationAndFumureDescription,
        }

        //Traduction des typos, déplacement à cet endroit pour les traductions dans toutes les langues et pas seulement le français
        const PermissionsDescription = {
            1: [StringTranslate.historyPermissionDescription],
            2: [StringTranslate.modulationIntraparcelPermissionDescription, StringTranslate.modulationAutomaticZoningPermissionDescription, StringTranslate.modulationManualAndAutomaticPermissionDescription, StringTranslate.modulationStrategiesPermissionDescription, StringTranslate.modulationExportPermissionDescription],
            4: [StringTranslate.biomassPermissionDescription],
            8: [StringTranslate.fertilizerNitrogenDosageAdvicePermissionDescription, StringTranslate.fertilizerTargetPermissionDescription, StringTranslate.fertilizerFractionalAdvicePermissionDescription, StringTranslate.fertilizerWheatLNIPermissionDescription, StringTranslate.fertilizerReserveDosePermissionDescription, StringTranslate.fertilizerPPFEditionPermissionDescription],
            16: [StringTranslate.modelingPermissionDescription],
            32: [StringTranslate.harvestPermissionDescription],
        }

        //Permissions manquantes à l'utilisateur (sans la fumure et l'historisation)
        const missingPermissions = permissionsHelper.getMissingPermissions(allPermissions, userDatas.permissions, [permissionsHelper.permissionEnum.fertilizer, permissionsHelper.permissionEnum.history]);

        //Permissions de tour de plaine (on retire la modulation pour séparer les deux abonnements)
        const missingPermissionsForTDP = permissionsHelper.filterPermissions(missingPermissions, [permissionsHelper.permissionEnum.modulation ]);
        const permissionsForTDP = permissionsHelper.filterPermissions(allPermissions, [permissionsHelper.permissionEnum.modulation, permissionsHelper.permissionEnum.fertilizer, permissionsHelper.permissionEnum.history]);
        const permissionsToUseForTDP = isExplorer ? permissionsForTDP : missingPermissionsForTDP;
    
        //Permissions de modulation
        const missingPermissionsForModul = permissionsHelper.getPermissionsByEnum(missingPermissions, permissionsHelper.permissionEnum.modulation);
        const permissionsForModul = permissionsHelper.getPermissionsByEnum(allPermissions, permissionsHelper.permissionEnum.modulation);
        const permissionsToUseForModul = isExplorer ? permissionsForModul : missingPermissionsForModul;

        //Permissions de fumure
        const permissionsForFertilizer = permissionsHelper.getPermissionsByEnum(allPermissions, permissionsHelper.permissionEnum.fertilizer);

        //Temps restant pour l'abonnement Explorer
        const daysLeftForExplorer = dateHelper.calculateDaysLeft(typoEndingDate);

        //Pour gérer l'affichage mobile de certains composants
        const isMobile = window.matchMedia("(max-width: 600px)").matches;
        
        //TODO : Quand on voudra retirer l'abonnement in-app sur les stores, il faudra modifier les appels à Stripe, il faudra donc vérifier tous les composants liés à Stripe
        //! Pour le moment, on laisse le scénario classique d'achat que pour la version mobile car c'est utilisé par celle-ci, c'est pour ça qu'on vérifie si on est sur la version native
        if (this.isOnNativeApp === true) {
            /* Si le client est ADV, on affiche ce qu'il a droit en fonction de sa typo client, sinon,
            on affiche les cartes d'abonnements */
            if (this.props.isAdvClient !== true) {
                return (
                    <StripeProvider stripe={this.state.stripe}>
                        {this.renderAbonnementCard()}
                    </StripeProvider>
                );
            }
            else {
                return (this.renderAbonnementCardAdv());
            }
        } else { //Pour la version web, on retire la possibilité de s'abonner via Stripe, et on affiche différentes cartes représentant les différents abonnements de Spotifarm, ou alors ce que le client possède et ce qu'il reste comme abonnement/option de disponible
            return (
                <>
                    {gettingAllPermissions ?
                        <AbonnementSkeleton /> //Skeleton qui s'affiche en attendant d'avoir récupérer les permissions
                    :
                        <Grid container spacing={2}>
                            {/* Bandeau si on est en freemium ou explorer, avec le temps restant de l'abonnement */}
                            {(enumTypoClient && (enumTypoClient > 0) && (isExplorer || isFreemium)) &&
                                <Box 
                                    sx={{
                                    backgroundColor: theme.palette.secondary.light, 
                                    padding: 2,
                                    borderRadius: '8px', 
                                    border: '1px solid #ddd', 
                                    mt: 1,
                                    ml: '20px',
                                    textAlign: 'center',
                                    borderColor: theme.palette.secondary.main,
                                    width: '100%'
                                    }}
                                >
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                        {(enumTypoClient && daysLeftForExplorer && daysLeftForExplorer > 0 && enumTypoClient > 0 && isExplorer) ?
                                        `${TypoDescription[enumTypoClient]} ${StringTranslate.daysLeftForExplorer} ${daysLeftForExplorer} ${StringTranslate.day}${daysLeftForExplorer > 1 ? 's' : ''}`:
                                        TypoDescription[enumTypoClient]
                                        }
                                    </Typography>                                
                                </Box>
                                
                            }
                            {/* ↓ partie - AFFICHAGE des plans ↓ */}
                            <Grid item xs={12} container spacing={2}>
                                {/*Plan actuel de l'utilisateur qui ne s'affiche que s'il possède au moins un tour de plaine */}
                                {(enumTypoClient && (enumTypoClient > 0) && !isExplorer && !isFreemium) &&
                                    <Grid item xs={12} sm={6} md={4}>
                                        <PlanCard
                                            title={TypoDescription[enumTypoClient] || StringTranslate.defaultDescriptionForTypo}
                                            currentSubscription={true}
                                            staticPermissions={staticTDPPermissions}
                                            dynamicPermissions={permissionsHelper.getDescriptions(userPermissionsWithoutHistory, PermissionsDescription)}
                                            buttonText={StringTranslate.subscribe}
                                            onButtonClick={this.openSubscriptionForm}
                                            headerIcon={<LogoSpotifarm/>}
                                            cardStyles={{
                                                pt: 1.75,
                                                border: '2px solid',
                                                borderColor: theme.palette.secondary.main,
                                                bgcolor: theme.palette.secondary.light,
                                                "&:last-child": {
                                                    paddingBottom: 0
                                                }
                                            }}
                                        />
                                    </Grid>
                                }
                                {/* Abonnement Tour de plaine */}
                                {((enumTypoClient && (enumTypoClient > 0) && isExplorer) || (missingPermissionsForTDP.length > 0)) &&
                                    <Grid item xs={12} sm={6} md={4}>
                                        <PlanCard
                                            title={StringTranslate.tourDePlaineSubscription}
                                            dynamicPermissions={permissionsHelper.getDescriptions(permissionsToUseForTDP, PermissionsDescription)}
                                            staticPermissions={staticTDPPermissions}
                                            buttonText={StringTranslate.subscribe}
                                            onButtonClick={this.openSubscriptionForm}
                                            showButton={true}
                                        />
                                    </Grid>
                                }
                                {/* Abonnement modulation */}
                                {((enumTypoClient && (enumTypoClient > 0) && isExplorer) || (missingPermissionsForModul.length > 0)) &&
                                    <Grid 
                                        item
                                        direction="column"
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 2
                                        }}
                                        xs={12} 
                                        sm={6} 
                                        md={4}
                                    >
                                        <Grid item xs={12} sm={6} md={4} sx={{mb: 2.5}}>
                                            <PlanCard
                                                title={StringTranslate.modulSubscription}
                                                dynamicPermissions={permissionsHelper.getDescriptions(permissionsToUseForModul, PermissionsDescription)}
                                                staticPermissions={[]}
                                                buttonText={StringTranslate.subscribe}
                                                onButtonClick={this.openSubscriptionForm}
                                                abovePermissions={
                                                    <>
                                                        <Typography textAlign="center" variant="h4" sx={{mt: 2}}>{StringTranslate.tourDePlaineSubscription}</Typography>
                                                        <Typography textAlign="center" variant="h4" sx={{mt: 2}}>+</Typography>
                                                    </>
                                                }
                                                showButton={true}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                {/*Option fumure */}
                                { ((userDatas.permissions) && userDatas.permissions.every((perm) => perm.Enum !== 8)) &&
                                    <>
                                        <Grid item container xs={12} sm={6} md={4} spacing={2} sx={{ display: isMobile ? "block" : "flex"}}>
                                            <Grid item xs={isMobile ? 12 : 1} sm={isMobile ? 12 : 1} md={isMobile ? 12 : 1}>
                                                <Divider        
                                                    orientation={isMobile ? "horizontal" : "vertical"}
                                                    sx={{
                                                        "&::before, &::after": {
                                                            borderColor: theme.palette.primary.main,
                                                        },
                                                        alignItems: "center",
                                                    }}      
                                                >
                                                    <AddCircleOutlinedIcon fontSize="large" sx={{color:theme.palette.primary.main}}/>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 11} sm={isMobile ? 12 : 11} md={isMobile ? 12 : 11}>
                                                <PlanCard
                                                    title={StringTranslate.fertilizerOption}
                                                    dynamicPermissions={permissionsHelper.getDescriptions(permissionsForFertilizer, PermissionsDescription)}
                                                    buttonText={StringTranslate.addOption}
                                                    onButtonClick={this.openSubscriptionForm}
                                                    belowTitle={StringTranslate.fertilizerOptionDescription}
                                                    showButton={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Dialog
                                    open={openSubscriptionFormDialog}
                                    onClose={this.handleCloseSubscriptionForm}
                                    fullWidth
                                    maxWidth={false}
                                    sx={{
                                        '& .MuiDialog-paper': {
                                            width: '80%',
                                            maxHeight: '90vh',
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <IconButton
                                            onClick={this.handleCloseSubscriptionForm}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <CustomIFrame className="iframes_profil" url={ConstantsTutorialHelps.UrlContactFormForSuscription}/>
                                        </Grid>
                                    </Grid> 
                                </Dialog>
                            </Grid>
                        </Grid>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = state => ({

    enumTypoClient: lodashGet(state, 'clientUserData.clientDatas.enumTypoClient', -1),
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
    userId: lodashGet(state, 'clientUserData.userDatas.id', -1),
    isAdvClient: lodashGet(state, 'clientUserData.clientDatas.id', false),
    userDatas: lodashGet(state, 'clientUserData.userDatas', {}), //Utilisation du userDatas, suite à l'évolution du multi-users
    typoEndingDate: lodashGet(state, 'clientUserData.userDatas.typoEndingDate', null), //Date de fin de la typo Explorer

    //Permissions
    gettingAllPermissions: lodashGet(state, 'clientUserData.gettingAllPermissions', false),
    allPermissions: lodashGet(state, 'clientUserData.allPermissions', {}),

    // Pour StripeCreditCardView.jsx
    creditCard: lodashGet(state, 'clientUserData.clientDatas.creditCard', null),
    subscriptionProcess: lodashGet(state, 'clientUserData.subscriptionProcess', null),
    language: lodashGet(state, 'settingsData.settings.language', 'FR'),
    naming: lodashGet(state, 'clientUserData.clientDatas.naming', ''),
    phone: lodashGet(state, 'clientUserData.clientDatas.phone', ''),
    address: lodashGet(state, 'clientUserData.clientDatas.address', {}),
    clientDatas: lodashGet(state, 'clientUserData.clientDatas', {}),
    codeCountry: lodashGet(state, 'settingsData.settings.codeCountry', ''),
    isNewSubscription: lodashGet(state, 'clientUserData.isNewSubscription', false),
    gettingPlansSubscriptions: lodashGet(state, 'clientUserData.gettingPlansSubscriptions', false),
    waitLoadingClient: lodashGet(state, 'clientUserData.isWaitingToSearchClient', false),
    planSubscriptionDico: lodashGet(state, 'clientUserData.planSubscriptionDico', {}),
})

const mapDispatchToProps = dispatch => ({
    //Pour gérer les permissions
    getAllPermissions: () => dispatch(ActionGetAllPermissions()),

    // Pour StripeCreditCardView.jsx
    addErrorToSubscriptionProcess: (error) => dispatch(ActionErrorSubscriptionProcess(error)),

    // Pour stripeSuccessFailView.jsx
    getClientDatasAfterSubscription: () => dispatch(ActionGetClientDatasAfterSubscriptionAsk()),
    resetSubscriptionProcess: () => dispatch(ActionResetSubscriptionProcess()),
    getPlansSubscriptions: () => dispatch(ActionGetPlansSubscriptions()),
    setIsNewSubscription: (bool) => dispatch(ActionSetIsNewSubscription(bool)),
    goToMap: () => dispatch(ActionGoToMap()),
    getClientBilling: (limitInvoices) => dispatch(ActionGetClientBilling(limitInvoices)),

    setIsFormValid: (bool) => dispatch(ActionSetIsFormValid(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Abonnements);
