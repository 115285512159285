import React from 'react';
//import { connect } from 'react-redux';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import {
    Typography, Grid, InputAdornment, OutlinedInput,
    Link, IconButton, Alert, Card, CardContent,
    Stack, Button, ButtonGroup
} from "@mui/material";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Transitions from '../../themes/Transitions.js';
import calculDoseAzoteOpti, { CultureForNitrogenEnum, liensArticles } from '../../utils/calculetteAzoteHelper';

import getTheme from "../../themes/index.js";

let theme = getTheme();

class CalculetteDoseAzote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isColza: false,
            culture: CultureForNitrogenEnum.Ble,
            prixVente: "",
            prixAchat: "",
            pourcentageAzote: "",
            doseAzote: "",
            doseOptimal: "",
            msgErr: undefined,
            showInfo: false,
        };
    }

    //Change la culture et efface les données liées
    onChangeCulture = () => {
        if (this.state.culture === CultureForNitrogenEnum.Ble) {
            this.setState({ culture: CultureForNitrogenEnum.Colza, isColza: true, prixVente: "", doseAzote: "", doseOptimal: "", msgErr: undefined });
        } else { //on est sur 'CultureForNitrogenEnum.Colza' actuellement !
            this.setState({ culture: CultureForNitrogenEnum.Ble, isColza: false, prixVente: "", doseAzote: "", doseOptimal: "", msgErr: undefined });
        }
    }

    //Appeler lors de l'appuie sur une touche
    onPressEnter = (e) => {
        if (e.key === "Enter") {
            this.onEndFillInForm(e);
        }
    }

    //Appeller lorsque les inputs ont perdu le focus
    onEndFillInForm = (event) => {
        event.preventDefault();
        const { culture, prixAchat, prixVente, pourcentageAzote, doseAzote } = this.state;

        //on vérifie si tout les champs ont été saisies avec de faire le calcul
        if ((prixAchat !== "") && (prixVente !== "") && (pourcentageAzote !== "") && (doseAzote !== "")) {

            const calculResult = calculDoseAzoteOpti(culture, prixVente, prixAchat, pourcentageAzote, doseAzote);
            if (calculResult !== null) {
                if ((calculResult.msgError !== null) && (calculResult.msgError !== undefined)) {
                    this.setState({ msgErr: calculResult.msgError, doseOptimal: "" });
                }
                if (calculResult.value !== null) {
                    this.setState({ doseOptimal: calculResult.value, msgErr: undefined });
                }
            }

            //Pour les mobiles: scroll auto pour le résultat/erreur
            if (window.innerWidth <= 614) {
                window.scrollTo({ top: 300, behavior: "smooth" })
            }
        }
    }

    //Vérification des inputs (n'accepte que des chiffres et limite la taille du nombre)
    onChangeInput = (prop) => (e) => {
        const value = e.target.value

        if (prop === "pourcentageAzote") {
            if ((!isNaN(value)) && (value <= 100)) {
                this.setState({ [prop]: value });
            }
        } else {
            if ((!isNaN(value)) && (value < 10000)) {
                this.setState({ [prop]: value });
            }
        }

    }

    //Affiche ou cache la rubrique info
    onClickInfo = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }

    render() {
        const { doseOptimal, msgErr, isColza, prixVente, prixAchat, pourcentageAzote, doseAzote, showInfo } = this.state;

        //Le message a affiché une fois le calcul réalisé (message d'erreur ou valeur obtenue)
        const msgResult =
            msgErr !== undefined
                ? msgErr
                : `${StringTranslate.calculDoseNitrogenOptimized} ${doseOptimal} ${StringTranslate.calculDoseNitrogenUnite}/ha`;

        const stringTranslateCulture = isColza ? StringTranslate.calculDoseNitrogenTypeColza : StringTranslate.calculDoseNitrogenTypeBle;

        return (
            <Grid container spacing={2}>
                <Grid item lg={8} xs={12}>
                    <Card 
                        sx={{
                            border: '1px solid',
                            borderColor: theme.palette.grey[100],
                        }}
                    >
                        <CardContent>                            
                            {/* isColza a true => Blé, false => Colza */}
                            <Stack direction="row" sx={{ marginBottom: "10px" }}>
                                <ButtonGroup disableElevation variant='contained'>
                                    <Button 
                                        size='medium'
                                        onClick={this.onChangeCulture}
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "0.4",
                                            '&:disabled': {
                                                bgcolor: theme.palette.primary.main,
                                                color: theme.palette.common.white,
                                                opacity: "1",
                                            }
                                        }}
                                        disabled={(!isColza)}
                                    >
                                        {StringTranslate.calculDoseNitrogenTypeBle}
                                    </Button>
                                    <Button 
                                        size='medium'
                                        onClick={this.onChangeCulture}
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "0.4",
                                            '&:disabled': {
                                                bgcolor: theme.palette.primary.main,
                                                color: theme.palette.common.white,
                                                opacity: "1",
                                            }
                                        }}
                                        disabled={isColza}
                                    >
                                        {StringTranslate.calculDoseNitrogenTypeColza}
                                    </Button>
                                </ButtonGroup>
                            </Stack>

                            <Typography>{`${StringTranslate.formatString(StringTranslate.calculDoseNitrogenDesc, stringTranslateCulture)}`}</Typography>

                            <Grid item container sx={{ mt: 1 }}>
                                <Grid item sx={{ mt: 1 }}>
                                    <Typography>{`${StringTranslate.formatString(StringTranslate.calculDoseNitrogenSellPrice, stringTranslateCulture)} `}</Typography>
                                </Grid>
                                <Grid item sx={{ ml: 1 }}>
                                    <OutlinedInput
                                        sx={{
                                            width: '100px',
                                            marginLeft: '5px'
                                        }}
                                        size="small"
                                        endAdornment={<InputAdornment color="primary" position="end">€/t</InputAdornment>}
                                        inputMode="numeric"
                                        placeholder="-"
                                        value={prixVente}
                                        onChange={this.onChangeInput("prixVente")}
                                        onKeyPress={this.onPressEnter}
                                        onBlur={this.onEndFillInForm}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container sx={{ mt: 1 }}>
                                <Grid item sx={{ mt: 1 }}>
                                    <Typography>{`${StringTranslate.calculDoseNitrogenPurchaseCost} `}</Typography>
                                </Grid>
                                <Grid item sx={{ml: 1 }}>
                                    <OutlinedInput
                                        sx={{
                                            width: '100px',
                                        }}
                                        size="small"
                                        endAdornment={<InputAdornment color="primary" position="end">€/t</InputAdornment>}
                                        inputMode="numeric"
                                        placeholder="-"
                                        value={prixAchat}
                                        onChange={this.onChangeInput("prixAchat")}
                                        onKeyPress={this.onPressEnter}
                                        onBlur={this.onEndFillInForm}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item sx={{ mt: 1 }}>
                                <Grid item sx={{ mt: 1 }}>
                                    <Typography>{`${StringTranslate.calculDoseNitrogenConcentration} `}</Typography>
                                </Grid>
                                <Grid item sx={{ml: 1, mr: 1}}>
                                    <OutlinedInput
                                       sx={{
                                            width: '80px',
                                        }}
                                        size="small"
                                        endAdornment={<InputAdornment color="primary" position="end">%</InputAdornment>}
                                        inputMode="numeric"
                                        placeholder="-"
                                        value={pourcentageAzote}
                                        onChange={this.onChangeInput("pourcentageAzote")}
                                        onKeyPress={this.onPressEnter}
                                        onBlur={this.onEndFillInForm}
                                    />
                                </Grid>
                                <Grid item sx={{ mt: 1 }}>
                                    <Typography>{` ${StringTranslate.calculDoseNitrogenConcentrationEnd}`}</Typography>
                                </Grid>

                            </Grid>

                            <Grid container item sx={{ mt: 1 }}>

                                <Grid item sx={{ mt: 1 }}>
                                    <Typography>{`${StringTranslate.calculDoseNitrogenRecommanded} `}</Typography>
                                </Grid>
                                <Grid item sx={{ ml: 1 }}>
                                    <OutlinedInput
                                        sx={{
                                            width: '150px',
                                        }}
                                        size="small"
                                        color="primary"
                                        endAdornment={<InputAdornment color="primary" position="end">{`${StringTranslate.calculDoseNitrogenUnite}/ha`}</InputAdornment>}
                                        inputMode="numeric"
                                        placeholder="-"
                                        value={doseAzote}
                                        onChange={this.onChangeInput("doseAzote")}
                                        onKeyPress={this.onPressEnter}
                                        onBlur={this.onEndFillInForm}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={4} xs={12}>
                    <Transitions type={'slide'} in={((doseOptimal !== "") || (msgErr !== undefined))} position={'top-left'} direction={'left'}>
                        <Card
                            sx={{
                                border: '1px solid',
                                borderColor: theme.palette.grey[100],
                                bgcolor: theme.palette.primary.main,
                                color: theme.palette.background.paper,
                            }}
                        >
                            <CardContent>

                                <Typography variant="body2" color="inherit">
                                    {`${msgResult}`}
                                </Typography>

                            </CardContent>
                        </Card>
                    </Transitions>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        {(!showInfo) &&
                            (<IconButton onClick={this.onClickInfo}>
                                <InfoOutlinedIcon fontSize="medium" sx={{ color:theme.palette.info.light }} />
                            </IconButton>)}
                        {showInfo &&
                            (<Alert
                                severity="info"
                                icon={<InfoOutlinedIcon />}
                                sx={{ color: theme.palette.primary.main }}
                                action={
                                    <IconButton size="small" aria-label="close" color="inherit"  onClick={this.onClickInfo}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }>
                                {`${StringTranslate.calculDoseNitrogenInfo}`}
                                {`${StringTranslate.calculDoseNitrogenGoToArticle}`}
                                <Link
                                    variant="subtitle2"
                                    href={liensArticles.ble}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    underline="always"
                                >
                                    {`${StringTranslate.calculDoseNitrogenArticle}`}
                                </Link>
                                {` ${StringTranslate.calculDoseNitrogenGoToArticleBetween}`}
                                <Link
                                    variant="subtitle2"
                                    href={liensArticles.colza}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    underline="always"
                                >
                                    {`${StringTranslate.calculDoseNitrogenArticle}`}
                                </Link>
                                {` ${StringTranslate.calculDoseNitrogenGoToArticleEnd}`}
                            </Alert>)}
                    </Card>
                </Grid>
            </Grid>

        );
    }

}

export default CalculetteDoseAzote;
