import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* Utils */
import StringTranslate from "../../../assets/i18n/stringLanguage";
import { ParcelsHelper } from "../../../utils/parcelsHelper";
import dicoFunction from "../../../datas/dicoDetails";

/* Redux */
import { 
    ActionShowSpecificInfosManagementPopup, 
    FertilizerStep,
    ActionUpdateCropParcel,
    ActionInitFertilizerDatas,
    TabAbstractFertilizer,
    ActionSelectFertilizerTab,
    ActionCleanErrorFertilizer,
    ActionSelectWinterDatesByParcelId,
    ActionGoToStepOfFertilizer,
    ActionPreselectWinterImage,
} from "../../../redux/actions/fertilizer";

/* Icons */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

/* MUI Components */
import { Button, Typography, Divider, Grid, Stack, CircularProgress, Badge, Box } from '@mui/material';

/* React Components */
import DialogAzofertSpecificInfosEntry from "./dialogAzofertSpecificInfosEntry.jsx";
import DialogFertiwebSpecificInfosEntry from "./dialogFertiwebSpecificInfosEntry.jsx";
import CustomDataGrid from "../../customDataGrid";
import { ThumbnailParcelShapeFromPathInfos } from "../../thumbnail/ThumbnailParcelShape";
import AlertDialog from '../../alertDialog';
import LinkToContactUs from '../../linkToContactUs';

/* theme */
import getTheme from "../../../themes/index.js";
import { EditOutlined } from "@mui/icons-material";
import { enumCultureFertilizer } from "../../../models/constantEnumCulture";
let theme = getTheme();

/////////////////////////////////////////////////////////////
// Composant pour la saisie unique (parcelle par parcelle) //
/////////////////////////////////////////////////////////////
class SpecificInfosManagement extends React.Component {

    constructor(props) {
        super(props);

        this.columns = this.initialiseColumns(props.parcelIdsSelected);
        this.datas = this.initialiseDatas(props.parcelIdsSelected);
        this.state = {
            openPopupConfirmChangeCulture: false,
            openDialogOfErrors: (props.errorMessage !== '' && props.errorMessage !== undefined) ? true : false,
            parcelIdSelected: undefined, //parcelle sélectionnée pour remplir les données spécifiques
        };
        
        // Définition du popup contenant le message d'erreur à diffuser au client:
        this.popupErrorDialog = {
            getTitle: () => { 
                return (
                    <Stack direction="row" spacing={1} alignItems="flex-end">
                        <ErrorOutlineIcon />
                        <Typography>{StringTranslate.erroroups} &#9785; {StringTranslate.errorsuite}</Typography>
                    </Stack>
                ); 
            },
            description: props.errorMessage, //Rq : sera mis à jour en foncton de la prop 'errorMessage' de redux 'fertilizerData' !
            getAdditionalDescription: () => { 
                return (
                    <Typography variant="subtitle1">{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs}/>.</Typography>
                ); 
            },
            button: StringTranslate.close,
        };
    }

    componentDidMount() {
        const { parcelIdsSelected, cultureSelected, fertilizerDico, biomassDico, preselectWinterImage } = this.props;
        //Vérifie que la culture sélectionnée pour la fumure est bien du colza
        if (cultureSelected.cropType === enumCultureFertilizer.colza_hiver) {
            parcelIdsSelected.forEach( parcelId => {
                // Initialiser un tableau pour les données correspondant à cette parcelle
                const parcelData = [];

                for (const key in biomassDico) {
                    if (biomassDico[key].parameter.parcelId === parcelId) {
                        // Ajouter les données correspondantes (entrée ou sortie d'hiver) à cette parcelle dans le tableau parcelData
                        parcelData.push(biomassDico[key]);
                    }
                }
                
                //Si la parcelle possède au minimum une entrée d'hiver
                if (parcelData.length > 0) {
                    let entryWinterData = null;
                    let exitWinterData = null;
    
                    //Récupération des données d'image
                    parcelData.forEach(data => {
                        const { isBeginningWinter, imagSourceId, imagSourceProvider, dateImagSource } = data.parameter;
    
                        if (isBeginningWinter) {
                            entryWinterData = {
                                imagSourceId,
                                imagSourceProvider,
                                dateImagSource
                            };
                        } else {
                            exitWinterData = {
                                imagSourceId,
                                imagSourceProvider,
                                dateImagSource
                            };
                        }
                    });
    
                    if (entryWinterData || exitWinterData) {
                        // Trouver l'entrée dans fertilizerDico qui correspond à ce parcelId
                        for (const fertiId in fertilizerDico) {
                            const currentFertilizer = fertilizerDico[fertiId];
    
                            // Vérifier si l'entrée correspond à la parcelle actuelle
                            if (currentFertilizer.idParcel === parcelId) {
                                // Pré-sélectionner les données d'entrée d'hiver si la date a changé
                                if (currentFertilizer.beginningWinterDate !== entryWinterData.dateImagSource) {
                                    const newEntryWinterData = {
                                        imagSourceId: entryWinterData.imagSourceId,
                                        dateImagSource: entryWinterData.dateImagSource,
                                        imagSourceProvider: entryWinterData.imagSourceProvider
                                    };
    
                                    //Action qui préselectionne l'image d'entrée et l'image de sortie d'hiver
                                    preselectWinterImage(parcelId, newEntryWinterData, exitWinterData);
                                }
                            }
                        }
                    }
                }//Si elle n'a pas de pesée, alors pas besoin de préselectionner une image
            });
        }
        //Si ce n'est pas du colza on ne fait rien d'autre 
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { specificInfosManagementSaving, parcelIdsSelected, askingSpecificInfosArchived } = this.props;

        //suite à un enregistrement, on ré-actualise les données affichées :
        if ( ((specificInfosManagementSaving === true) && (nextProps.specificInfosManagementSaving  !== true)) ||
            ((nextProps.askingSpecificInfosArchived === true) && (askingSpecificInfosArchived === false)) ) {
            this.datas = this.initialiseDatas(parcelIdsSelected, nextProps.fertilizerDico);
        }

        return true;  
    }

    /**
     * Fonction permettant de générer les colonnes à afficher dans le tableau
     */
    initialiseColumns = (parcelIdFilteredList) => {
        const { parcelDico } = this.props;

        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            /* ↓ ajout colonne non visible - id de la parcelle ↓ */
            newColumns.push({ headerName: "Id", field: "id", editable: true, hideable: true });

            newColumns.push({
                field: 'données',
                editable: false,
                hideable: false,
                // headerName: 'Données de fumure',
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold'>{StringTranslate.fertilizerData}</Typography>)
                },
                width: 170,
                renderCell: params => {
                    return (
                        <Badge 
                            color="error" 
                            sx={{width:"100%"}}
                            variant="dot"   // obligatoire si il ne contient pas un chiffre sinon le badge ne s'affiche pas
                            invisible={(params.row.firstTry || params.row.areMinimumValuesValid)}
                        >
                            <Button
                                color={ params.row.areMinimumValuesValid ? "success" : "primary" }
                                variant={ params.row.areMinimumValuesValid ? "contained" : "outlined" }
                                onClick={() => { this.handleClickFertilizerData(params.row) }}
                                fullWidth
                                disabled={(this.state.parcelIdSelected === params.row.idParcel)}
                                startIcon={(this.state.parcelIdSelected === params.row.idParcel) ? <CircularProgress color="inherit" size={25} /> : <EditOutlined/>}
                            >
                                {params.row.areMinimumValuesValid ? StringTranslate.modifier : StringTranslate.complete}
                            </Button>
                        </Badge>
                    )
                }
            });

            newColumns.push({
                field: 'pilotage',
                editable: false,
                hideable: false,
                // headerName: "Pilotage de l'azote",
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nitrogenManagement}</Typography>)
                },
                width: 170,
                renderCell: params => {
                    return (
                        <Button
                            color={ (params.row.isComplete === true) ? "success" : "primary" }
                            variant={ params.row.isComplete ? "contained" : "outlined" }
                            disabled={!params.row.areMinimumValuesValid}
                            onClick={() => { this.handleClickNitogenManagement(params.row.idParcel) }}
                            fullWidth
                            startIcon={<EditOutlined/>}
                        >
                            {(params.row.areMinimumValuesValid) && (params.row.isComplete === true) ? StringTranslate.modifier : StringTranslate.complete}
                        </Button>
                    )
                }
            });

            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "culture") {
                                newColumns.push({
                                    headerName: `${StringTranslate.libelecolumnculture}`,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<>
                                            <Typography fontWeight='bold'>{column.libeleColumn}</Typography>
                                        </>)
                                    },
                                });
                            }
                        }
                    });
                }
            }

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => {
                    return (<Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>)
                }
            });

            /* ↓ ajout colonne visible - (variété) ↓ */
            newColumns.push({
                headerName: "Variété",
                field: "variety",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnvariete}</Typography>)
                }
            });

            /* ↓ ajout colonne visible - (totalNDose) ↓ */
            newColumns.push({
                headerName: "totalNDose",
                field: "totalNDose",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.totalNdose} (U/Ha)</Typography>)
                }
            });

            /* ↓ ajout colonne visible - (NDoseModulated) ↓ */
            newColumns.push({
                headerName: "NDoseModulated",
                field: "NDoseModulated",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.modulatedNdose} (U/Ha)</Typography>)
                }
            });

            
        }
        return newColumns;
    }

    /**
     *  Fonction permettant d'extraire les données qui seront utilisées dans le visuel
     */ 
    initialiseDatas = (parcelIdFilteredList, newFertilizerDico = undefined) => {
        const { fertilizerDico, fertilizerDicoCounter, parcelDico, thumbnailParcelDico, cultureSelected } = this.props;

        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];
        
        for (var itemPropName in parcels) {
            let id = undefined;
            let name = undefined;
            let crop = undefined;
            let fertilizerDatas = undefined;
            let thumbnailParcelItem = undefined;

            const parcel = parcels[itemPropName];

            if (parcel) {
                thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);
                
                id = parcel.id;
                name = parcel.name;
                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        const propValue = parcel.details[itemDetailName];
                        if (itemDetailName.toLowerCase() === 'culture') {
                            crop = propValue;
                        }
                    }
                }

                if (fertilizerDicoCounter > 0) {
                    const useThisFertilizerDico = (newFertilizerDico) ? newFertilizerDico : fertilizerDico;
                    
                    if (useThisFertilizerDico) { //TODO : contenu de ce if ==> cela revient à utiliser 'fertilizerHelper.getFertilizerByParcelIdAndCropType' ?!
                        for (const key in useThisFertilizerDico) {
                            const fertilizerItem = useThisFertilizerDico[key];

                            //On récupère les infos spécifiques en base de données en fonction de la culture et de l'id Parcel.
                            //Il se peut qu'il y ait des données spécifiques en base par rapport à une parcelle donnée,
                            //Veiller à ne pas afficher les données spécifiques pour une culture qui ne correspond pas à la culture choisie
                            //à l'étape 1 (choix de la culture et des parcelles)
                            if ((id === fertilizerItem.idParcel) && (cultureSelected.cropType === fertilizerItem.enumOfCrop)) {
                                    fertilizerDatas = fertilizerItem;
                            }
                        }
                    }
                }
            }

            newDatas.push({
                id: id,
                idParcel: id,
                nom: name,
                culture: crop,
                thumbnailInfos: thumbnailParcelItem,
                variety: lodashGet(fertilizerDatas, 'variety.label', ''),
                totalNDose: lodashGet(fertilizerDatas, 'nitrogenTotal', ''),
                NDoseModulated: lodashGet(fertilizerDatas, 'nitrogenPrescription', ''),
                actions: parcel,
                parcel: parcel,
                dataFertilizer: fertilizerDatas,
                areMinimumValuesValid: lodashGet(fertilizerDatas, 'areMinimumValuesValid', false),
                firstTry: ((lodashGet(fertilizerDatas, 'areMinimumValuesValid', null) === null) ? true : false), // la création de cette variable a pour but de savoir si on a déjà fait des saisies individuelles
                isComplete: lodashGet(fertilizerDatas, 'isComplet', false),
            });

        }
        return newDatas;
    }

    /**
     * Fontion permettant d'ouvrir la popup de saisie des données de fumure
     */
    handleClickFertilizerData = (params) => {
        const { openSpecificInfosPopUp } = this.props;


        this.setState({
            parcelIdSelected: params.idParcel,
        });

        //On charge les listes, les données puis on ouvre la popup
        openSpecificInfosPopUp(params);
    }

    /**
     * Fontion permetant de d'aller à la saisie des dates d'entrée et de sortie d'hiver (map)
     */
    handleClickNitogenManagement = (parcelId) => {
        
        const { selectBeginningAndEndOfWinterDatesByParcelId } = this.props;

        selectBeginningAndEndOfWinterDatesByParcelId(parcelId);
    }

    goBackToCommonInfosManagement = () => {
        //Redirection vers létape 2 : 
        this.props.goToStep(FertilizerStep.COMMON_INFOS_MANAGEMENT);
    }

    goBackToCultureAndParcelsChoice = () => {

        const { initFertilizerDatas, goToStep } = this.props;

        //Initialisation (Mise à zéro des données concernant la fumure) :
        initFertilizerDatas();

        //Redirection vers létape 1 : 
        goToStep(FertilizerStep.CULTURE_AND_PARCELS_CHOICE);
    }

    //Redirection vers la page de la liste des fumures complètes
    goToFertilizersList = () => {
        this.props.setValueTabForAbstractFertilizer(TabAbstractFertilizer.FERTILIZERS_LIST);
    }

    handleClosePopupConfirmChangeCulture = () => {
        this.setState({
            openPopupConfirmChangeCulture: false
        });
    }

    componentDidUpdate(prevProps, prevState) {
		const { errorMessage, askingSpecificInfosArchived, fertilizerSpecificList_TypeDeSol_awaitingAnAnswer } = this.props;

		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '')) &&
			(errorMessage && (errorMessage !== ''))) {
            this.popupErrorDialog.description = errorMessage;
			this.setState({
				openDialogOfErrors: true,
			});
		}

        if (((askingSpecificInfosArchived === false) && (prevProps.askingSpecificInfosArchived === true)) ||
        ((askingSpecificInfosArchived === false) && (prevProps.askingSpecificInfosArchived === false) && (prevState.parcelIdSelected > 0) &&
        (fertilizerSpecificList_TypeDeSol_awaitingAnAnswer === false))) {
            this.setState({
                parcelIdSelected: undefined,
            });
        }
	}

    /* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		this.setState({
			openDialogOfErrors: false,
		});

		if (this.props.cleanErrorFertilizer) {
			this.props.cleanErrorFertilizer();
		}
	}

    /* Fonction qui renvoie true quand le bouton de redirection vers la liste des fumures doit être désactivé
    et renvoie false quand le bouton doit être activé */
    disableBtnGoToFertilizersList = () => {
        const { fertilizerDicoCounter } = this.props;

        //S'il n'y a aucun conseil azoté complet, on désactive le bouton
        if (fertilizerDicoCounter <= 0) {
            return true;
        }
        else {
            return (this.datas.filter(data => (data.isComplete === true)).length <= 0);
        }
    }

    //Ce qui est commenté : sera pour une prochaine version
    /* handleConfirmChangeCulture = () => {
        const { 
            selectBeginningAndEndOfWinterDatesByParcelId, 
            parcelIdsSelected, updateCropParcel, cultureSelected
        } = this.props;

        this.setState({
            openPopupConfirmChangeCulture: false
        });

        updateCropParcel(parcelIdsSelected[0], cultureSelected);

        selectBeginningAndEndOfWinterDatesByParcelId(parcelIdsSelected[0]);
    } */
  
    render() {
        const { cultureSelected, rowsPerPageForTableParcels, 
            displayDialogAzofertSaisieIndividuel, displayDialogFertiwebSaisieIndividuel, fertilizerDicoIsLoaded, 
            fertilizerListsAsked, askingSpecificInfosArchived, fertilizerSpecificList_TypeDeSol_awaitingAnAnswer } = this.props;

        const { openDialogOfErrors } = this.state;

        const disableBtnGoToFertilizersList = this.disableBtnGoToFertilizersList();

        return (
            <>
                {/* Dialog des erreurs */}
                {(openDialogOfErrors === true) &&
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                }
                {/* Ce qui est commenté : sera pour une prochaine version */}
                {/* {openPopupConfirmChangeCulture &&
                    <Dialog open={openPopupConfirmChangeCulture}
                        onClose={this.handleClosePopupConfirmChangeCulture}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Culture différente</DialogTitle>
                        <DialogContent>
                            <DialogContentText>La culture de la parcelle est différente de la culture choisie pour la fumure.
                                Confirmes-tu de changer la culture de cette parcelle automatiquement ?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePopupConfirmChangeCulture} variant="text" color="error" autoFocus>
                                Annuler
                            </Button>

                            <Button onClick={this.handleConfirmChangeCulture} variant="contained" color="primary">
                                {StringTranslate.confirmDeleteAction}
                            </Button>
                        </DialogActions>
                    </Dialog>
                } */}
                {displayDialogAzofertSaisieIndividuel && 
                (fertilizerListsAsked === true) && (askingSpecificInfosArchived === false) && (fertilizerSpecificList_TypeDeSol_awaitingAnAnswer === false) ? 
                    <DialogAzofertSpecificInfosEntry /> : 
                    null}
                {displayDialogFertiwebSaisieIndividuel && 
                (fertilizerListsAsked === true) && (askingSpecificInfosArchived === false) && (fertilizerSpecificList_TypeDeSol_awaitingAnAnswer === false) ? 
                    <DialogFertiwebSpecificInfosEntry /> : 
                    null}
                <Typography
                    fontWeight="bold"
                    margin={2}
                >
                    {StringTranslate.cropName} {cultureSelected.name}
                </Typography>

                <Divider mb={3} />

                { (fertilizerDicoIsLoaded === true) ? <CustomDataGrid
                    pageSize={rowsPerPageForTableParcels}
                    rows={this.datas}
                    columns={this.columns}
                /> : <CircularProgress color="inherit" size={25} /> }

                {/* Boutons :
                 - retour vers l'étape des données communes à saisir,
                 - téléchargement des cartes qui mène à la page "Mes fumures",
                 - Terminer qui mène à l'étape de choix de la culture */}
                <Grid container marginTop={3}>
                    <Grid item xs={4} sm={4}>
                        <Button variant="text" onClick={this.goBackToCommonInfosManagement}>
                            {StringTranslate.revenir}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        { (fertilizerDicoIsLoaded === true) && <Stack direction="row"
                            sx={{ 
                                [theme.breakpoints.only('xs')]: { justifyContent: "flex-end" },
                                [theme.breakpoints.up('md')]: { justifyContent: "center" },
                             }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                disabled={disableBtnGoToFertilizersList} 
                                onClick={this.goToFertilizersList}>
                                {StringTranslate.downloadMaps}
                            </Button>
                        </Stack> }
                    </Grid>
                    
                    <Grid item xs={12} sm={4} sx={{ [theme.breakpoints.only('xs')]: { marginTop: 1 } }}>
                        <Stack direction="row" justifyContent="flex-end">
                            <Button variant="contained" color="secondary" onClick={this.goBackToCultureAndParcelsChoice}>
                                {StringTranslate.quit}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    biomassDico: lodashGet(state, 'biomassData.biomassDico', {}),

    rowsPerPageForTableParcels: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
    displayDialogAzofertSaisieIndividuel: lodashGet(state, 'fertilizerData.specificInfosAzofertManagementActivation', false),
    displayDialogFertiwebSaisieIndividuel: lodashGet(state, 'fertilizerData.specificInfosFertiwebManagementActivation', false),
    
    // infoCurrentSelectedParcel: lodashGet(state, 'fertilizerData.infoCurrentSelectedParcel', undefined),
    cultureSelected: lodashGet(state, 'fertilizerData.cultureSelected', undefined),
    fertilizerDicoIsLoaded: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoaded', false),
    fertilizerDico: lodashGet(state, 'fertilizerData.fertilizerDico', {}),
    fertilizerDicoCounter: lodashGet(state, 'fertilizerData.fertilizerDicoCounter', 0),
    specificInfosManagementSaving: lodashGet(state, 'fertilizerData.specificInfosManagementSaving', false),
    errorMessage: lodashGet(state, 'fertilizerData.errorMessage', undefined),
    fertilizerListsAsked: lodashGet(state, 'fertilizerData.fertilizerListsAsked', false),
    askingSpecificInfosArchived: lodashGet(state, 'fertilizerData.askingSpecificInfosArchived', false),
    fertilizerSpecificList_TypeDeSol_awaitingAnAnswer: lodashGet(state, 'fertilizerData.fertilizerSpecificList_TypeDeSol_awaitingAnAnswer', false),
    parcelIdsSelected: lodashGet(state, 'fertilizerData.parcelIdsSelected', undefined),
});

const mapDispatchToProps = dispatch => ({
    goToStep: (step) => dispatch(ActionGoToStepOfFertilizer(step)),

    openSpecificInfosPopUp: (infoCurrentSelectedParcel) => dispatch( ActionShowSpecificInfosManagementPopup(infoCurrentSelectedParcel) ),
    updateCropParcel: (parcelId, cultureSelected) => dispatch( ActionUpdateCropParcel(parcelId, cultureSelected) ),
    initFertilizerDatas: () => dispatch( ActionInitFertilizerDatas() ),
    setValueTabForAbstractFertilizer: (tab) => dispatch( ActionSelectFertilizerTab(tab) ),
	cleanErrorFertilizer: () => dispatch( ActionCleanErrorFertilizer() ),
    selectBeginningAndEndOfWinterDatesByParcelId: (parcelIdSelected, isGoBack) => dispatch(ActionSelectWinterDatesByParcelId(parcelIdSelected, isGoBack)),
    preselectWinterImage: (parcelId, entryWinterData, exitWinterData) => dispatch(ActionPreselectWinterImage(parcelId, entryWinterData, exitWinterData)),
});

export default connect(mapStateToProps,mapDispatchToProps)(SpecificInfosManagement);