import lodashGet from 'lodash/get';
import lodashSetWith from 'lodash/setWith';
import lodashIsNil from 'lodash/isNil';
import { ActionTypeSatimage } from '../actions/satImage.js';//@@A renommer en satimage !
import { StateYearMonthOfSatimages, SatimageState, RepairState } from '../../models/stateYearMonthOfSatimages.js';
import { SatimageDatasParcel } from '../../models/satimageDatasParcel.js';
import { SatimageDatasParcelHelper } from '../../utils/satimageDatasParcelHelper.js';
import { ReducerSatimageHelper } from '../../utils/reducerSatimageHelper.js';
import dateHelper from '../../utils/dateHelper.js';
import { SatimageHelper } from '../../utils/satimageHelper.js';
import { forIn as lodashForIn, size as lodashSize } from 'lodash';


/* state initial */
const initialState = {
    //Valeur d'énuméré possible: [SatimageState.stateNotAsk, SatimageState.stateAskOnProgress, SatimageState.stateAskOk, SatimageState.stateOnError],
    stateAsk: SatimageState.stateNotAsk,
    satimagesByParcelDico: {}, //'dico' et pas 'List'/'Array' car on gère une parcelle référencé par leur ID => donc type 'Dico<parcelId: int, SatimageDatasParcel>' ! 
    parcelDicoCounter: 0,
    parcelsInProgressCounter: 0, 
    
    //satImageList: {}, // dico { imageId: <Satimage>, ... } => reporté dans chaque item (entité 'SatimageDatasParcel') du dico 'satimagesByParcelDico'
    //satImageStateList: {}, // dico { imageId: 'state', ... } => reporté dans chaque item du dico 'satimagesByParcelDico' (en créant une propriété associée)
    
    loadingAnalys: false, //permettra de mettre une waiting cursor lors d'une attente d'obtension d'analyse...
    analysCounter: 0,//pour permettre d'être notifier d'un besoin de mise à jour quand 

    //Permet de se repositionner sur la bonne satImage quand on retourne sur la carte après avoir fait une modulation, biomasse ou une fumure.
    theLastSelectedSatImageToSetOnMapDico: {}, 
    theLastSelectedSatImageToSetOnMapCounter: 0,
    errorMessage: "",

    reloadingSatImages: undefined, //Permet de savoir si les images sont rechargées

    loadingImagesForWinter: false, //Permet d'ouvrir ou fermer la pop up (snackbar) pour prévenir le client qu'on récupère des images

    // Permet de savoir si une réparation est en cours
    repairingOldHisto: false,
}

function satimageManagerAction(state = initialState, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case ActionTypeSatimage.LoadingSatimagesOfParcel: { // demande le passage à 'en cours' pour une parcelle donnée (pour une année/mois précis)
            if (action.parcelIdLinked && (action.parcelIdLinked > 0)) {
                let newStateAsk = state.stateAsk;

                //Recherche de l'entité POCO associée à cette parcelle:
                let entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                let hasThisParcel = true;
                if (!entityForThisParcel) { //si l'entité n'existe pas encore:
                    hasThisParcel = false;
                    entityForThisParcel = new SatimageDatasParcel({ parcelId: action.parcelIdLinked, satimageDico: undefined });
                    ReducerSatimageHelper.addParcel(state.satimagesByParcelDico, entityForThisParcel);
                    state.parcelDicoCounter += 1; //pas besoin de tester le retour... si on est là c'est hustement par ce quel'on ne l'a pas trouvé !
                }

                //Définition du mois et année visé:
                let month = action.month;
                let year = action.year;
                if (!dateHelper.isValid(year, month)) { //si mois/année incorrecte (ou non-définis)
                    // Force le mois en cours:
                    const dateAsked = new Date();
                    month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
                    year = dateAsked.getUTCFullYear();
                }
                
                //Recherche de l'entité POCO associée au status des date/image pour le mois/année visé:
                let stateYearMonthOfSatimages = undefined;
                if (hasThisParcel) { //si l'entité POCO pour la parcelle n'existe pas, cela ne sert à rien de rechercher l'item souhaité, tout est vide dedans !
                    stateYearMonthOfSatimages = SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(entityForThisParcel, year, month);
                }
                if (!stateYearMonthOfSatimages) { //si l'entité n'existe pas encore:
                    stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ year: year, month: month, parcelId: action.parcelIdLinked });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages);
                }

                //Mise à jour le status sur le mois en cours: 
                //et les compteurs... 
                if (stateYearMonthOfSatimages.stateAsk !== SatimageState.stateAskOnProgress) {
                    entityForThisParcel.yearMonthStateInProgressCounter += 1;

                    if (entityForThisParcel.stateAsk !== SatimageState.stateAskOnProgress) {
                        state.parcelsInProgressCounter += 1;
                    } else {
                        state.parcelsInProgressCounter -= 1;
                    }
                }
                stateYearMonthOfSatimages.stateAsk = SatimageState.stateAskOnProgress;
                entityForThisParcel.stateAsk = SatimageState.stateAskOnProgress;
                newStateAsk = SatimageState.stateAskOnProgress;

                return Object.assign({}, state, { //en réalité, les instances ne changent pas ! Juste leur valeur / contenu.
                    stateAsk: newStateAsk,
                });
            } else {
                return state; //On ne change rien si on n'a pas la parcelle visée !
            }
        }

        case ActionTypeSatimage.LoadingSatimagesAllParcels: { //Premier chargement... Signifie le passage à 'en cours' pour toutes les parcelles pour l'année/mois en cours !                        
            let newStateAsk = state.stateAsk;
            const dateAsked = new Date();
            const month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
            const year = dateAsked.getUTCFullYear();
            
            state.satimagesByParcelDico = {}; //ré-init, si pas déjà le cas !
            state.parcelDicoCounter = 0;
            state.parcelsInProgressCounter = 0;

            // Pour chaque parcelle connue, il faut modifier le statut afin d'indiquer le début du chargement des dates (celles du mois en cours...):
            if (action.allParcelIds && Array.isArray(action.allParcelIds) && (action.allParcelIds.length > 0)) {
               
                action.allParcelIds.forEach((itemParcelId) => {
                    //Crée l'entité POCO associée à la parcelle:
                    const entityForThisParcel = new SatimageDatasParcel({ parcelId: itemParcelId, satimageDico: undefined });
                    const hasAdded = ReducerSatimageHelper.addParcel(state.satimagesByParcelDico, entityForThisParcel);
                    if (hasAdded === true) state.parcelDicoCounter += 1; //'Faux' ne pas dire échec mais pas ajouté (car déjà présent)!

                    //crée l'entité POCO associée à la liste des date/image pour le mois/année en cours:
                    const stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ year: year, month: month, parcelId: itemParcelId });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages);
                    
                    //Mise à jour le status sur le mois en cours: 
                    //et les compteurs... 
                    stateYearMonthOfSatimages.stateAsk = SatimageState.stateAskOnProgress;
                    entityForThisParcel.yearMonthStateInProgressCounter += 1;
                    entityForThisParcel.stateAsk = SatimageState.stateAskOnProgress;
                    state.parcelsInProgressCounter += 1;
                });
            } else {
                //Sinon, traite celles connues:
                try {
                    for (var itemPropName in state.satimagesByParcelDico) {
                        if (itemPropName) {
                            const itemDateImgDatasParcel = state.satimagesByParcelDico[itemPropName];
                            if (itemDateImgDatasParcel) {
                                //Recherche de l'entité POCO associée à la liste des date/image pour le mois/année visé:
                                let stateYearMonthOfSatimages = SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(itemDateImgDatasParcel, year, month);//@@vérifier partout que les aprams commence d'abord par 'year' !
                                if (!stateYearMonthOfSatimages) { //si l'entité n'existe pas encore:
                                    stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ year: year, month: month, parcelId: itemDateImgDatasParcel.parcelId });
                                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(itemDateImgDatasParcel, stateYearMonthOfSatimages);
                                }

                                //Mise à jour le status sur le mois en cours: 
                                //et les compteurs... 
                                if (stateYearMonthOfSatimages.stateAsk !== SatimageState.stateAskOnProgress) {
                                    itemDateImgDatasParcel.yearMonthStateInProgressCounter += 1;

                                    if (itemDateImgDatasParcel.stateAsk !== SatimageState.stateAskOnProgress) {
                                        state.parcelsInProgressCounter += 1;
                                    }
                                }
                                stateYearMonthOfSatimages.stateAsk = SatimageState.stateAskOnProgress;
                                itemDateImgDatasParcel.stateAsk = SatimageState.stateAskOnProgress;
                            }
                            //else //la valeur de la propriété n'est pas du type attendue.
                        }
                        //else //pas une propriété dont le nom semble correcte (ne devrait pas arriver).
                    }
                } catch(e) {
                    //RAS
                }
            }

            newStateAsk = SatimageState.stateAskOnProgress;

            return Object.assign({}, state, { //en réalité, les instances ne changent pas ! Juste leur valeur / contenu.
                stateAsk: newStateAsk,
            });
        }
        
        // Action qui va mettre à jour les donnnées de satImage (suivant une parcelle donnée et un couple mois/année précis !)
        case ActionTypeSatimage.SatimagesLoadedParcel: {
            if ((action.parcelIdLinked > 0) && action.satimageDico) { // undefined > 0 => false                
                let newStateAsk = state.stateAsk;
                
                // 1- Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                let entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                if (!entityForThisParcel) { //si l'entité n'existe pas encore:
                    entityForThisParcel = new SatimageDatasParcel({ parcelId: action.parcelIdLinked, satimageDico: undefined/*action.satimageDico*/ }); //ajouté par la suite...
                    ReducerSatimageHelper.addParcel(state.satimagesByParcelDico, entityForThisParcel);
                    entityForThisParcel.stateAsk = SatimageState.stateAskOnProgress;
                    state.parcelDicoCounter += 1; //pas besoin de tester le retour... si on est là c'est hustement par ce quel'on ne l'a pas trouvé !
                    state.parcelsInProgressCounter += 1;
                }

                // 2- Récupération du mois et année visé:
                let month = action.month;
                let year = action.year;
                if (!dateHelper.isValid(year, month)) { //si mois/année incorrecte (ou non-définis), on la crée
                    // Force le mois en cours:
                    const dateAsked = new Date();
                    year = dateAsked.getUTCFullYear();
                    month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
                }
                
                // 3- Recherche de l'entité POCO stateYearMonthOfSatimages pour le mois/année visé (devrait toujours exister) et bien-sûr la parcelId:
                let stateYearMonthOfSatimages = (entityForThisParcel) ? SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(entityForThisParcel, year, month) : null;
                // - si l'entité n'existe pas encore - on l'a crée
                if ( !stateYearMonthOfSatimages ) { 
                    stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ parcelId: action.parcelIdLinked, month: month, year: year, stateAsk: SatimageState.stateAskOnProgress });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages); // ⚠️ - A ce jour, on affecte les données sans contrôle si il y a déjà des données
                    stateYearMonthOfSatimages.stateAsk = SatimageState.stateAskOnProgress; //on le défint en progression pour être correctement traité plus bas...(dans les conditions de décrément des compteurs)
                    entityForThisParcel.yearMonthStateInProgressCounter += 1;
                }
                // - si l'entité existe - mais que l'on est sur un cas de relance d'une demande d'historique (précèdemment inError)
                else if ( action.reload ) {
                    stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ 
                        parcelId: action.parcelIdLinked, 
                        month: month, 
                        year: year, 
                        stateAsk: SatimageState.stateAskOnProgress,
                        errorMessage: stateYearMonthOfSatimages.errorMessage,
                        requestCounter: stateYearMonthOfSatimages.requestCounter + 1
                    });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages); // ⚠️ - A ce jour, on affecte les données sans contrôle si il y a déjà des données
                    // entityForThisParcel.yearMonthStateInProgressCounter += 1; //pas d'incrémentation car si 'SatimageState.stateAskOnProgress' était déjà son état !
                    //CAn : MAIS si ce n'était pas son état ????
                } //else //ok, on est bon : 'stateYearMonthOfSatimages' est assigné !

                // 4- Insertion/Affectation de la nouvelle liste d'entité satimage à la parcelle reçu (action.satimageDico)
                if (entityForThisParcel) {
                    SatimageDatasParcelHelper.addSatimages(entityForThisParcel, action.satimageDico);
                }

                //rappel: le status progression doit prendre le dessus sur celui d'erreur, lui-même prenant le dessus sur les autres...
                
                // 5- On va mettre à jour le nouveau statut du stateYearMonthOfSatimages sur le mois en cours ou le mois/année définis par 'action.month' et 'action.year': 
                if (stateYearMonthOfSatimages.stateAsk === SatimageState.stateAskOnProgress) {
                    entityForThisParcel.yearMonthStateInProgressCounter -= 1; //décrémente le nombre de mois/année dont une demande de génération en cours est désormais terminée

                    // - Si plus aucune demande de génération de mois/année pour cette parcelle, on change son status:
                    if (entityForThisParcel.yearMonthStateInProgressCounter <= 0) {
                        
                        // - On vérifie s il y a des entités POCO stateYearMonthOfSatimages en erreur - SI NON => on peut passer le state de l'entité satimageDatasParcel à 'ok'
                        const anotherDateImgDataListOnErrorCounter = SatimageDatasParcelHelper.countSatimageStateOfYearMonthOnState(entityForThisParcel, SatimageState.stateOnError);
                        if (anotherDateImgDataListOnErrorCounter <= 0) { // si pas d'erreur                 
                            
                            // - Si le state de l'entité SatimageDatasParcel (ensemble des données à la parcelle) était en progression
                            const thisParcelWasOnProgress = (entityForThisParcel.stateAsk === SatimageState.stateAskOnProgress); // était elle en progression ?
                            entityForThisParcel.stateAsk = SatimageState.stateAskOk; // on la met à jour
                            if (thisParcelWasOnProgress) { // si elle était en progression alors on décrémente le compteur d'état de progression à la parcelle (state.parcelsInProgressCounter)
                                state.parcelsInProgressCounter -= 1; //décrémente le nombre de parcelle dont une demande de génération est en cours.

                                // - Si plus d'élements en progression dans la totalité des parcelles - on met à jour le state générale du reducer satimage
                                if (state.parcelsInProgressCounter <= 0) { 
                                    //if (state.stateAsk !== entityForThisParcel.stateOnError) { //A l'idéal, il faudrait vérifier l'état des autres parcelles pour s'assurer qu'aucun est en erreur !
                                    
                                    //on peut passer de l'entité représentant l'ensemble des parcelles à 'ok' seulement si aucune autre demande sur une parcelle n'est en erreur.
                                    const anotherParcelOnErrorCounter = ReducerSatimageHelper.countParcelOnState(state.satimagesByParcelDico, SatimageState.stateOnError);
                                    if (anotherParcelOnErrorCounter <= 0) {
                                        newStateAsk = SatimageState.stateAskOk;
                                    }
                                    else //on FORCE le status en erreur sur l'entité représentant les données de l'ensemble des parcelles !
                                        newStateAsk = SatimageState.stateOnError;
                                }
                                //else on laisse le status actuel sur l'entité représentant les données de l'ensemble des parcelles, car des demandes sur des parcelles sont encore en cours !
                            }
                            //else //on ne change rien sur l'entité de l'ensemble des parcelles !
                        }
                        else //on FORCE le status en erreur sur l'entité représentant les données de cette parcelle !
                            entityForThisParcel.stateAsk = SatimageState.stateOnError;
                    }
                    //else on laisse le status actuel sur l'entité représentant les données de cette parcelle, car des demandes sont encore en cours !
                }
                //else //on ne change rien sur l'entité de cette parcelle et rien sur l'entité de l'ensemble des parcelles !
                stateYearMonthOfSatimages.stateAsk = (Object.entries(action.satimageDico).length > 0) ? SatimageState.stateAskOk :  SatimageState.stateAskNoDate;

                return Object.assign({}, state, { //en réalité, les instances ne changent pas ! Juste leur valeur / contenu.
                    stateAsk: newStateAsk,
                });
            } else {
                return state; //On ne change rien si on n'a pas la parcelle visée !
            }
        }

        // CAS: Ajout d'un dico de données représentant des objets satImages dont le taux d'ennuagement est supérieur au maxcc (paramètrage client) en relation avec un Id de parcelle
        case ActionTypeSatimage.addSatimagesWithHighermaxccByparcelidDico: {
            
            // ↓ Si aucune donnée liée à l'Id de parcelle demandé - on crée l'entité ↓ 
            if ( !state.satimagesByParcelDico[action.parcelIdLinked] ) {
                state.satimagesByParcelDico[action.parcelIdLinked] = new SatimageDatasParcel({ parcelId: action.parcelIdLinked });
            }

            return {
                ...state,
                satimagesByParcelDico: {
                    ...state.satimagesByParcelDico,
                    [action.parcelIdLinked] : {
                        ...state.satimagesByParcelDico[action.parcelIdLinked],
                        satImageWithHigherMaxccDico: {
                            ...state.satimagesByParcelDico[action.parcelIdLinked].satImageWithHigherMaxccDico,
                            ...action.satimageWithHigherMaxccDico
                        }
                    }
                }
            }
        }
            
        case ActionTypeSatimage.errorLoadSatimageParcel: { // Signale l'erreur au chargement pour une parcelle donnée, pour une année/mois précis !            
            
            if (action.parcelIdLinked > 0) { // suffisant => si action.parcelIdLinked is undefined => renvoie false
                let newStateAsk = state.stateAsk;
                
                //Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                let entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                let hasThisParcel = true;
                if (!entityForThisParcel) { //si l'entité n'existe pas encore:
                    hasThisParcel = false;
                    entityForThisParcel = new SatimageDatasParcel({ parcelId: action.parcelIdLinked, satimageDico: undefined });
                    ReducerSatimageHelper.addParcel(state.satimagesByParcelDico, entityForThisParcel);
                    entityForThisParcel.stateAsk = SatimageState.stateAskOnProgress;
                    state.parcelDicoCounter += 1; //pas besoin de tester le retour... si on est là c'est hustement par ce quel'on ne l'a pas trouvé !
                    state.parcelsInProgressCounter += 1;
                }

                //Définition du mois et année visé:
                let year = action.year;
                let month = action.month;
                if (!dateHelper.isValid(year, month)) { //si mois/année incorrecte (ou non-définis)
                    // Force le mois en cours:
                    const dateAsked = new Date();
                    year = dateAsked.getUTCFullYear();
                    month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
                }
                
                //Recherche de l'entité POCO associée à la liste des date/image pour le mois/année visé (devrait toujours exister):
                let stateYearMonthOfSatimages = undefined;
                if (hasThisParcel) { //si l'entité POCO pour la parcelle n'existe pas, cela ne sert à rien de rechercher l'item souhaité, tout est vide dedans !
                    stateYearMonthOfSatimages = SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(entityForThisParcel, year, month);
                }
                if (!stateYearMonthOfSatimages) { //si l'entité n'existe pas encore:
                    stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ year: year, month: month, parcelId: action.parcelIdLinked });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages);
                    stateYearMonthOfSatimages.stateAsk = SatimageState.stateAskOnProgress;
                    entityForThisParcel.yearMonthStateInProgressCounter += 1;
                }

                //Mise à jour le status sur le mois en cours ou le mois/année définis par 'action.month' et 'action.year': 
                //rappel: le status progression doit prendre le dessus sur celui d'erreur, lui-même prenant le dessus sur les autres...
                if (stateYearMonthOfSatimages.stateAsk === SatimageState.stateAskOnProgress) {
                    entityForThisParcel.yearMonthStateInProgressCounter -= 1; //décrémente le nombre de mois/année dont une demande de génération est en cours.
                    
                    // Actualise le statut de la demande de l'année/mois concernée avant la recherche du statut en progression sur d'autres année/mois de cette parcelle:
                    stateYearMonthOfSatimages.stateAsk = SatimageState.stateOnError;
                    stateYearMonthOfSatimages.errorMessage = action.errorMessage;

                    if (entityForThisParcel.yearMonthStateInProgressCounter <= 0) {
                        const thisParcelWasOnProgress = (entityForThisParcel.stateAsk === SatimageState.stateAskOnProgress);

                        //on peut passer de l'entité de cette parcelle à 'en erreur' seulement si aucune autre demande de mois/année sur cette parcelle n'est en cours.
                        const anotherDateImgDataListOnProgressCounter = SatimageDatasParcelHelper.countSatimageStateOfYearMonthOnState(entityForThisParcel, SatimageState.stateAskOnProgress);
                        //RQ: Un peu redondant car on a déjà l'info via 'yearMonthStateInProgressCounter', mais par sécurité!
                        if (anotherDateImgDataListOnProgressCounter <= 0) {
                            entityForThisParcel.stateAsk = SatimageState.stateOnError;

                            if (thisParcelWasOnProgress) {
                                state.parcelsInProgressCounter -= 1; //décrémente le nombre de parcelle dont une demande de génération est en cours.

                                if (state.parcelsInProgressCounter <= 0) {
                                    //on peut passer de l'entité de cette parcelle à 'en erreur' seulement si aucune autre demande sur une parcelle n'est en cours.
                                    const anotherParcelOnProgressCounter = ReducerSatimageHelper.countParcelOnState(state.satimagesByParcelDico, SatimageState.stateAskOnProgress);
                                    //RQ: Un peu redondant, mais pas sécurité!
                                    if (anotherParcelOnProgressCounter <= 0) {
                                        newStateAsk = SatimageState.stateOnError;
                                    }
                                    //else on laisse le status en progression sur l'entité représentant les données de l'ensemble des parcelles !
                                    //RQ: c'est la dernière parcelle qui passera l'ensemble à "En erreur" !
                                }
                                //else on laisse le status actuel sur l'entité représentant les données de l'ensemble des parcelles, car des demandes sur des parcelles sont encore en cours !
                            }
                            //else //on ne change rien sur l'entité de l'ensemble des parcelles !
                        }
                        //else on laisse le status en progression sur l'entité représentant les données de cette parcelle !
                        //RQ: c'est le dernier couple année/mois qui passera l'ensemble à "En erreur" !
                    }
                    //else on laisse le status actuel sur l'entité représentant les données de cette parcelle, car des demandes sont encore en cours !
                }
                //else //on ne change rien sur l'entité de cette parcelle et rien sur l'entité de l'ensemble des parcelles !

                return Object.assign({}, state, { //en réalité, les instances ne changent pas ! Juste leur valeur / contenu.
                    stateAsk: newStateAsk,
                });
            } else {
                return state; //On ne change rien si on n'a pas la parcelle visée !
            }
        }

        case ActionTypeSatimage.UpdatingAllSatimagesAllParcels: { //On cherche à obtenir la mise à jour de la liste des images 'valides' pour chaque parcelle...
            //pour autant, tant que l'on a pas reçu la nouvelle liste, on ne change rien !
                        
            //On change juste les états de chargement...
            // Pour chaque parcelle connue, il faut modifier le statut afin d'indiquer le début du chargement des dates (celles du mois en cours...):
            let counterParcelAffected = 0;
            if (action.parcelIdList && (Array.isArray(action.parcelIdList)) && (action.parcelIdList.length > 0))
            {
                try { 
                    // ↓ mise à jour du dico de parcelle et de la liste de parcelId filterée ↓
                    action.parcelIdList.forEach( itemParcelId => { 
                        if (itemParcelId) {
                            const itemDateImgDatasParcel = lodashGet(state, `satimagesByParcelDico[${itemParcelId}]`, undefined);
                            if (itemDateImgDatasParcel) {
                                itemDateImgDatasParcel.stateAsk = SatimageState.stateAskOnProgress;
                                counterParcelAffected++;
                            }
                        }
                    });
                } catch(e) {
                    //RAS
                }
            }
            //else //ne change rien !
            
            return Object.assign({}, state, {
                stateAsk: SatimageState.stateAskOnProgress,
                parcelsInProgressCounter: counterParcelAffected, //state.parcelDicoCounter,
            });
        }

        case ActionTypeSatimage.AllSatimagesUpdatedParcel: { //On cherchait à obtenir la mise à jour de la liste des images 'valides' pour chaque parcelle...
            //on vient d'obtenir celle pour l'une d'elle. Si ok, on vire l'ancien contenu:
            if ((action.parcelIdLinked > 0) && action.satimageDico) { // undefined > 0 => false                
                let newStateAsk = state.stateAsk;
                
                //Recherche de l'entité POCO associée à cette parcelle (remplacera l'ancienne instance):
                let entityForThisParcel = new SatimageDatasParcel({ parcelId: action.parcelIdLinked, satimageDico: undefined/*action.satimageDico*/ }); //ajouté par la suite...

                // 2- Récupération du mois et année visé (Force le mois en cours)
                const dateAsked = new Date();
                let year = dateAsked.getUTCFullYear();
                let month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
                
                // 3- Recherche de l'entité POCO stateYearMonthOfSatimages pour le mois/année visé (devrait toujours exister) et bien-sûr la parcelId:
                let stateYearMonthOfSatimages = new StateYearMonthOfSatimages({ year: year, month: month, parcelId: action.parcelIdLinked });
                SatimageDatasParcelHelper.addSatimageStateOfYearMonth(entityForThisParcel, stateYearMonthOfSatimages);

                // 4- Insertion/Affectation de la nouvelle liste d'entité satimage à la parcelle reçu (action.satimageDico)
                SatimageDatasParcelHelper.addSatimages(entityForThisParcel, action.satimageDico);//RQ: les states seront mis à jour en fonction des année/mois où il y a des images.

                //rappel: le status progression doit prendre le dessus sur celui d'erreur, lui-même prenant le dessus sur les autres...
                stateYearMonthOfSatimages.stateAsk = (Object.entries(action.satimageDico).length > 0) ? SatimageState.stateAskOk :  SatimageState.stateAskNoDate;
                
                // 5- On va mettre à jour le nouveau statut du stateYearMonthOfSatimages sur le mois en cours ou le mois/année définis par 'action.month' et 'action.year': 
                entityForThisParcel.stateAsk = SatimageState.stateAskOk;
                //recherche l'ancienne entité pour vérifier qu'elle était bien en progression:
                let oldEntityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                if (oldEntityForThisParcel && (oldEntityForThisParcel.stateAsk === SatimageState.stateAskOnProgress)) {
                    state.parcelsInProgressCounter -= 1; //décrémente le nombre de parcelle dont une demande de génération est en cours.
                }
                else 
                    state.parcelsInProgressCounter -= 1;
                    state.parcelDicoCounter += 1; //car si cette parcelle n'existait pas avant (pas normal en soit), c'est donc un ajout !

                // - Si plus d'élements en progression dans la totalité des parcelles - on met à jour le state générale du reducer satimage
                if (state.parcelsInProgressCounter <= 0) { 
                    //on peut passer de l'entité représentant l'ensemble des parcelles à 'ok' seulement si aucune autre demande sur une parcelle n'est en erreur.
                    const anotherParcelOnErrorCounter = ReducerSatimageHelper.countParcelOnState(state.satimagesByParcelDico, SatimageState.stateOnError);
                    if (anotherParcelOnErrorCounter <= 0) {
                        newStateAsk = SatimageState.stateAskOk;
                    }
                    else //on FORCE le status en erreur sur l'entité représentant les données de l'ensemble des parcelles !
                        newStateAsk = SatimageState.stateOnError;
                }
                //else on laisse le status actuel sur l'entité représentant les données de l'ensemble des parcelles !

                // ↓ Mise à jour des données des entités satimage non valides (taux d'ennuagement supérieur au maxcc client nouvellement mise à jour) ↓
                if (!lodashIsNil(oldEntityForThisParcel) && !lodashIsNil(oldEntityForThisParcel.satImageWithHigherMaxccDico)) {
                    Object.entries(oldEntityForThisParcel.satImageWithHigherMaxccDico).forEach( ([satImageId, satImage]) => {
                        if (satImage.maxcc <= action.maxcc) 
                            delete oldEntityForThisParcel.satImageWithHigherMaxccDico[satImageId];
                    });
                    entityForThisParcel.satImageWithHigherMaxccDico = oldEntityForThisParcel.satImageWithHigherMaxccDico;
                }

                return Object.assign({}, state, { //en réalité, les instances ne changent pas ! Juste leur valeur / contenu.
                    stateAsk: newStateAsk,
                    satimagesByParcelDico: {
                        ...state.satimagesByParcelDico, 
                        [action.parcelIdLinked] : entityForThisParcel,
                    },
                });
            } else {
                return state; //On ne change rien si on n'a pas la parcelle visée !
            }
        }
            
        case ActionTypeSatimage.DeleteAllSatimages: { // on nous signale que les parcelles ont été supprimé ! On supprimer les liste de dates d'images.
            // ⚠️ - on ne peut pas utiliser initialState (ne fonctionne pas) et on ne respecte pas la notion d immuabilité
            return {
                stateAsk: SatimageState.stateNotAsk,
                satimagesByParcelDico: {},
                parcelDicoCounter: 0,
                parcelsInProgressCounter: 0,
                loadingAnalys: false, 
                analysCounter: 0, 
            };
        }

        case ActionTypeSatimage.DeleteSatimageByParcelidList: {
            if (action.parcelIdList && (Array.isArray(action.parcelIdList)) && (action.parcelIdList.length > 0))
            {
                // ↓ mise à jour du dico de parcelle et de la liste de parcelId filterée ↓
                action.parcelIdList.forEach( parcelId => { 
                    delete state.satimagesByParcelDico[parcelId]; // on supprime l'entrée depuis le dico de parcelle
                    state.parcelDicoCounter = state.parcelDicoCounter - 1;
                });

                return {
                    ...state,
                    satimagesByParcelDico: {
                        ...state.satimagesByParcelDico
                    },
                    parcelDicoCounter: state.parcelDicoCounter,
                };
            }
            //else...
            return state;
        }

        case ActionTypeSatimage.loadingSatimage: { 
            if (action.parcelIdLinked && (action.parcelIdLinked > 0) && action.imageId && (action.imageId > 0)) {
                //Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                if (entityForThisParcel) {
                    const entityOfThisSatimage = SatimageDatasParcelHelper.selectImageFromId(entityForThisParcel, action.imageId);
                    if (entityOfThisSatimage) {
                        entityOfThisSatimage.stateAsk = SatimageState.stateAskOnProgress;
                    }
                }
            }
            //else // on ne change rien !

            return state;
        }
            
        case ActionTypeSatimage.errorLoadSatimage: { 
            if (action.parcelIdLinked && (action.parcelIdLinked > 0) && action.imageId && (action.imageId > 0)) {
                //Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                if (entityForThisParcel) {
                    const entityOfThisSatimage = SatimageDatasParcelHelper.selectImageFromId(entityForThisParcel, action.imageId);
                    if (entityOfThisSatimage) {
                        entityOfThisSatimage.stateAsk = SatimageState.stateOnError; //ajout / modification de la propriété 'stateAsk'
                        //@@ que fait-on de la propriété 'action.errorMessage' ???
                    }
                }
            }
            //else // on ne change rien !

            return state;
        }

        case ActionTypeSatimage.addSatimage: {
            if (action.parcelIdLinked && (action.parcelIdLinked > 0) && action.imageId && (action.imageId > 0) && (action.satimage)) {
                //Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelIdLinked);
                if (entityForThisParcel) {
                    const entityOfThisSatimage = SatimageDatasParcelHelper.selectImageFromId(entityForThisParcel, action.imageId);
                    if (entityOfThisSatimage) { //si l'entité existe, on actualise les flux
                        entityOfThisSatimage.data = action.satimage.data;
                        entityOfThisSatimage.dataLight = action.satimage.dataLight;
                        
                        entityOfThisSatimage.stateAsk = SatimageState.stateAskOk; //TODO : ??? Est-ce un résidu de quand on avait pas encore les png dans le compte de stockage ???
                    } else { //si l'image n'existe pas dans le dico, on l'ajoute
                        //On lui ajoute des propriétés pour faciliter son exploitation:
                        /*let date = new Date(action.satimage.date);      
                        action.satimage.day = (date.getDate());          
                        action.satimage.month = (date.getUTCMonth()+1);
                        action.satimage.year = (date.getUTCFullYear());
                        action.satimage.dateStr = `${action.satimage.day}/${action.satimage.month}/${action.satimage.year}`;*/
                        SatimageHelper.buildDateString(action.satimag);
                        
                        action.satimage.stateAsk = SatimageState.stateAskOk; //TODO : ??? Est-ce un résidu de quand on avait pas encore les png dans le compte de stockage ???
                        
                        //ajout de la nouvelle image non-référencée:
                        SatimageDatasParcelHelper.addSatimageToParcel(entityForThisParcel, action.satimage);
                    }
                } //else //pas normal !
            }
            //else // on ne change rien !

            return state;
        }

        case ActionTypeSatimage.addAllLastSatimageByparcelid: {
            let parcelIdArray = Object.keys(action.lastSatImageByParcelIdDico);
            parcelIdArray.map( (key) => 
                state.satimagesByParcelDico[key] = new SatimageDatasParcel({ 
                    parcelId: key, 
                    satimageDico: SatimageHelper.convertToDico([action.lastSatImageByParcelIdDico[key]]),
                })
            )
            
            return {
                ...state,
                satimagesByParcelDico: { 
                    ...state.satimagesByParcelDico
                },
                parcelDicoCounter: parcelIdArray.length
            }
        }

        case ActionTypeSatimage.loadingSatimageAnalys: {
            return {
                ...state,
                loadingAnalys: true, 
            };
        }

        case ActionTypeSatimage.errorLoadSatimageAnalys: {
            return {
                ...state,
                loadingAnalys: false, 
            };
        }

        case ActionTypeSatimage.addSatimageAnalys: {
            let increment = 0;
            if ((action.parcelId !== undefined) && (action.parcelId > 0) && (action.imageId !== undefined) && (action.imageId > 0) && (action.analys)) {
                //Recherche de l'entité POCO associée à cette parcelle (devrait toujours exister):
                const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(state.satimagesByParcelDico, action.parcelId);
                if (entityForThisParcel) {
                    const entityOfThisSatimage = SatimageDatasParcelHelper.selectImageFromId(entityForThisParcel, action.imageId);
                    if (entityOfThisSatimage) { //si l'entité existe, on actualise les flux
                        entityOfThisSatimage.dataAnalyse = action.analys;
                        increment = 1;
                    } 
                    //else //si l'image n'existe pas dans le dico... pas possible !
                } 
                //else //pas normal non-plus !
            }
            //else // on ne change rien car pas du tout normal !

            return  {
                ...state,
                loadingAnalys: false, 
                analysCounter: state.analysCounter + increment,
            };
        }

        case ActionTypeSatimage.addTheLastSelectedSatImagesToSetOnMap: {

            let theLastSelectedSatImageToSetOnMapDico = state.theLastSelectedSatImageToSetOnMapDico;
            let theLastSelectedSatImageToSetOnMapCounter = Object.values(state.theLastSelectedSatImageToSetOnMapDico).length;

            if (!state.theLastSelectedSatImageToSetOnMapDico[action.parcelId]) {
                state.theLastSelectedSatImageToSetOnMapDico[action.parcelId] = { 
                    parcelId: action.parcelId, 
                    satImageId: action.satImageId, 
                    satImageDate: action.satImageDate
                };
                theLastSelectedSatImageToSetOnMapCounter = state.theLastSelectedSatImageToSetOnMapCounter + 1;

                theLastSelectedSatImageToSetOnMapDico = state.theLastSelectedSatImageToSetOnMapDico;
            }

            return Object.assign({}, state, { 
                theLastSelectedSatImageToSetOnMapDico: theLastSelectedSatImageToSetOnMapDico, 
                theLastSelectedSatImageToSetOnMapCounter: theLastSelectedSatImageToSetOnMapCounter
            });
        }

        case ActionTypeSatimage.setOnMapTheLastSelectedSatImages: {
            return Object.assign({}, state, { 
                theLastSelectedSatImageToSetOnMapDico: {}, 
                theLastSelectedSatImageToSetOnMapCounter: 0
            });
        }

        case ActionTypeSatimage.setSatImageDicoByParcel: {
            let newState = Object.assign({}, state);
            let newSatimagesByParcelDico = Object.assign({}, lodashGet(newState, 'satimagesByParcelDico', {}));

            lodashForIn(action.satImageDicoByParcel, (value, key) => {
                newSatimagesByParcelDico[key].satimageDico = value;
                newSatimagesByParcelDico[key].satimageDicoCounter = lodashSize(value);
            });

            newState.satimagesByParcelDico = newSatimagesByParcelDico;
            return newState;
        }
        case ActionTypeSatimage.setSatImageWithHigherMaxccDico: {
            let newState = Object.assign({}, state);

            newState.satimagesByParcelDico[action.parcelId].satImageWithHigherMaxccDico = action.satImageWithHigherMaxccDico;

            return newState;
        }

        case ActionTypeSatimage.setMonthMissing: {
            return {
                ...state,
                satimagesByParcelDico: {
                    ...state.satimagesByParcelDico,
                    [action.parcelId] : {
                        ...state.satimagesByParcelDico[action.parcelId],
                        missingMonth: action.missingMonth
                    }
                }
            }
        }

        case ActionTypeSatimage.errorUpdateSatImageDeactivationReason: {
            return {
                errorMessage: action.errorMessage 
            }
        }

        case ActionTypeSatimage.updateSatImageDeactivationReason: {
            return {
                ...state,
                satimagesByParcelDico: {
                    ...state.satimagesByParcelDico,
                    [action.parcelId] : {
                        ...state.satimagesByParcelDico[action.parcelId].satimageDico[action.satImageId],
                        deactivateStatus: action.reasonDeactivate
                    }
                }
            }
        }

        case ActionTypeSatimage.AskingReloadAllSatimageForAllParcels: {
            return {
                ...state,
                reloadingSatImages: true,
            }
        }

        case ActionTypeSatimage.ReloadAllSatimageForAllParcelsAsked: {
            return {
                ...state,
                reloadingSatImages: false,
            }
        }

        case ActionTypeSatimage.loadingImagesForWinter: {
            return {
                ...state,
                loadingImagesForWinter: true,
            }
        }

        case ActionTypeSatimage.imagesLoadedForWinter: {
            return {
                ...state,
                loadingImagesForWinter: false,
            }
        }

        case ActionTypeSatimage.setRepairStateOfParcelHistory: {
            let newState = Object.assign({}, state);
            
            //Maj de l'état de réparation de l'historique
            // Si l'arborescence n'existe pas, il va la créer avec des Object (dernier paramètre)
            lodashSetWith(newState, 
                `satimagesByParcelDico[${action.parcelId}].stateByYearMonthDico[${action.year}_${action.month}].stateRepair`, 
                action.repairState, 
                Object);

            // Vérifie si au moins un historique est en cours de réparation
            newState.repairingOldHisto = Object.values(newState.satimagesByParcelDico).some(historyByParcel =>
                // History correspond à une valeur "year_month"
                Object.values(historyByParcel.stateByYearMonthDico).some(history => history.stateRepair === RepairState.Loading)
            );

            return newState;
        }

        default:
            return state;
    }
}

export default satimageManagerAction;
