import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import chipClasses from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';

import FormControlLabel from '@mui/material/FormControlLabel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import { IsNativeHoster } from '../../../utils/platformHelper';

import { ActionGetAllLastNitrogenInputs, ActionGoToStepOfLastNitrogenInput, ActionGoToSelectParcelLastNitrogen, ActionGenerateFileOfThisLastNitrogen, ActionBuildLastNitrogenInputListPrescriptions } from '../../../redux/actions/lastNitrogenInput.js';
import { 
    TabAbstractFertilizer,
    ActionGetAllFertilizers, 
    ActionClearFertilizerValue, 
    ActionGoToShowThisFertilizer,
    ActionSelectFertilizerTab,
    ActionBuildListPrescriptions
} from '../../../redux/actions/fertilizer.js';

import { ActionGoToFertilizer } from '../../../redux/actions/contextApp.js';

import DateHelper from '../../../utils/dateHelper.js';

import lodashGet from 'lodash/get';
//import lodashIsEmpty from 'lodash/isEmpty';
import TemplateWidget from './templateWidget.jsx';

import { EnumDownloadChoiceModulation } from "../../../redux/actions/modulations";

// Theme
import getTheme from "../../../themes/index.js";
import TemplateDialogModulation from '../../templateDialogModulation.jsx';
import { IconButton } from '@mui/material';
import { enumCultureFertilizer } from '../../../models/constantEnumCulture.js';


let theme = getTheme();

function WidgetLastNitrogenInput(props) {
    const { clientId, getAllLastNitrogenInput, getAllFertilizer } = props;

    const [hasOpenDialog, setHasOpenDialog] = useState(false);
    const [selectedFertilizerId, setSelectedFertilizerId] = useState(null);
    const [showExportModulationToDownload, setShowExportModulationToDownload] = useState(true);
    const [showIncitationToUpgradeModulation, setShowIncitationToUpgradeModulation] = useState(false);
    const [hasLastNitrogenInput, setHasLastNitrogenInput] = useState(false);
    const [hasLastNitrogenInputWithModulation, setHasLastNitrogenInputWithModulation] = useState(false);

    useEffect(() => {
        if (getAllLastNitrogenInput && (clientId > 0)) {
            getAllLastNitrogenInput(clientId);
        }
        if (getAllFertilizer && (clientId > 0)) {
            getAllFertilizer(clientId);
        }
    }, [clientId, getAllLastNitrogenInput, getAllFertilizer]);

    /**
     * Fonction permettant d'être redirigé en fonction des différents élement que l'on dispose
     * @param {*} event 
     * @param {*} data 
     */

    const handleSelectFertilizerClick = (event, parcelId, isFerti = true) => {
        const {
            clearFertilizerValue,
            goToFertilizer, setValueTabForAbstractFertilizer,
            selectParcelLastNitrogenInput } = props;

        if (window.dataLayer)
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'fertilizerPage'});

        if (isFerti === true) {
                clearFertilizerValue();

            if (goToFertilizer) {
                setValueTabForAbstractFertilizer(TabAbstractFertilizer.NITROGEN_MANAGEMENT);
                goToFertilizer(true);
            }
        } else {
            if(goToFertilizer) {
                setValueTabForAbstractFertilizer(TabAbstractFertilizer.SELECT_FOR_LASTINPUT);
                selectParcelLastNitrogenInput(parcelId);
                goToFertilizer(false, true);
            }
        }
    }

    const downloadFile = (fertilizerIdToDownload, typeDownloadChoice, isLastNitrogenInputDownload) => {
        const { lastNitrogenInputDico, parcelIdSelected, downloadListPrescriptions, generateLastNitrogenInputFile, downloadLastNitrogenInputs } = props;

        if (isLastNitrogenInputDownload === true) { //on vise les données du dernier apport !
            const lastInputNSelected = lodashGet(lastNitrogenInputDico, `[${parcelIdSelected}]`, undefined);
            if (lastInputNSelected && (lastInputNSelected.id > 0)) {
                //dans le cas du dernier apport, on fait la différence d'action redux suivant le choix du 'format' souhaité :
                //Rq : pour le moment, au format pdf, 'typeDownloadChoice' ne peut valoir que 'EnumDownloadChoiceModulation.map' si on est sur le dernier apport !
                if (typeDownloadChoice === EnumDownloadChoiceModulation.map) { 
                    generateLastNitrogenInputFile(lastInputNSelected); //le pdf de la carte !
                } else {
                    downloadLastNitrogenInputs([lastInputNSelected.id], typeDownloadChoice); //la carte suivant l'un des formats gérés !
                }
            }
            //else // on ne fait rien !
        } else {
            downloadListPrescriptions([fertilizerIdToDownload], typeDownloadChoice); //pdf ou modulation de la fumure !
        }
    }
    /**
     * La fonction permet de vérifier si l'utilisateur à les droits nécessaire pour télécharger des cartes de modulation, ainsi qu'une vérification si un conseil n'est pas archivé
     * @param {*} fertiResultSelected 
     * @returns 
     */
    const authorizeDialogFertilizer = (fertiResultSelected) => { 
		const { downloadingPrescriptions, authorizeModulation } = props;
		//On vérifie : 
		// * aucun téléchargement est déjà en cours... 
		// * et données ok !

		if ((downloadingPrescriptions === true) || (!fertiResultSelected)) {
			return;
		}

		//On vérifie si on est dans l'appli mobile. Si oui, on interdit le téléchargement mais en indiquant comment faire (utiliser l'appli web sur PC).
		if (IsNativeHoster() === true) {
			this.setState({ showUseComputerForDownload: true, });
			return;
		}
		//else //sinon, on autorise le téléchargement !

		//Il faut créer chaque paramètrage (ID de prescription) associé à chaque ligne sélectionnée (celles reçues ici):
		let canExportModulationToDownload = true; //Si les fumures archivées sont affichées, c'est qu'il ne faut pas afficher la partie de télécharement de rds, isoxml
        if (fertiResultSelected && (fertiResultSelected.id > 0)) {
            setSelectedFertilizerId(fertiResultSelected.id);
            setHasLastNitrogenInput(fertiResultSelected.hasLastNitrogenInput); // On Récupère la valeur qui est déjà un boolean, afin d'afficher dans la popup un menu de navigation
            
            if (fertiResultSelected.hasLastNitrogenInput) 
            {
                const lastNitrogenInput = getLastNitrogenInputEntity();
                setHasLastNitrogenInputWithModulation(lodashGet(lastNitrogenInput, 'hasModulation', false));
            }

            if (fertiResultSelected.isArchived === true) {
                canExportModulationToDownload = false;
            }
        }

		// Si pas de droit de modulation, on affiche pas le choix de format des cartes de modulation
		// On restraint le choix aux fichiers PDF
		let showIncitationToUpgradeModulation = (authorizeModulation !== true);
		if (showIncitationToUpgradeModulation)
			canExportModulationToDownload = false;

        setHasOpenDialog(true);
        setShowExportModulationToDownload(canExportModulationToDownload);
        setShowIncitationToUpgradeModulation(showIncitationToUpgradeModulation);  
	}

    // Gestion de l'ouverture et de la fermeture du dialogue concernant cette fumure (de cette parcelle) !
    const handleOpenDialog = (fertilizer) => {
        authorizeDialogFertilizer(fertilizer);
    };

    // Permet de fermer la popup lors de l'appel à l'event
    const eventCloseDialog = () => {
        setHasOpenDialog(false);
    }
    // Appeler la fonction de téléchargement après confirmation à l'étape finale
    const confirmDownload = (typeDownloadChoice, isFromOtherTab) => {
        downloadFile(selectedFertilizerId, typeDownloadChoice, isFromOtherTab);
        eventCloseDialog();
    };

    const getLastNitrogenInputEntity = () => {
        const { lastNitrogenInputDico, parcelIdSelected } = props;

        let lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${parcelIdSelected}]`, undefined); //Rq: si 'lastNitrogenInputDico' ou 'parcelIdSelected' n'est pas ok, on aura 'undefined' !
        /*if ((parcelIdSelected > 0) && (lastNitrogenInputDico !== undefined)) {
            for (const key in lastNitrogenInputDico) {
                const lastItem = lastNitrogenInputDico[key];
                if (lastItem && (lastItem.parcelId === parcelIdSelected)) {
                    lastNitrogenInput = lastItem;
                }
            }
        }*/ //Rq: la clef du dico des derniers apports étant l'ID de la parcelle, on peut accèder en direct !

        return lastNitrogenInput;
    }

    const getContent = () => {
        const { parcelDico, parcelIdSelected, lastNitrogenInputDicoIsLoading, fertilizerDicoIsLoading, fertilizerDico } = props;

        if (parcelIdSelected <= 0) return;

        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let dataIsLoaded = null;
        let adjustment = 0;
        let renderOfPdfLastNitrogenInput = null;

        let fertilizer = undefined;
        if (fertilizerDico !== undefined) {
            for (const key in fertilizerDico) {
                const fertilizerItem = fertilizerDico[key];
                if (fertilizerItem && fertilizerItem.idParcel === parcelIdSelected) {  
                    fertilizer = fertilizerItem;
                }
            }   
        }
        const lastNitrogenInput = getLastNitrogenInputEntity();

        if ((fertilizer !== undefined) && (fertilizer.id > 0)) {
            if (fertilizer.enumOfCrop === enumCultureFertilizer.ble_hiver) {
                if (lastNitrogenInput !== undefined) {
                    adjustment = lastNitrogenInput.newNitrogenTotal - lastNitrogenInput.fertiNitrogenTotal;
                    // Permet d'afficher ou non le bouton permettant de télécharger un pdf de dernier apport
                    renderOfPdfLastNitrogenInput = (lastNitrogenInput.id !== null) ? (<FormControlLabel value={EnumDownloadChoiceModulation.map} control={<Radio color="primary" />} label={StringTranslate.lastNitrogenInput} />) : (null);            
                    dataIsLoaded = true;
                } else if (lastNitrogenInputDicoIsLoading === true) {
                    dataIsLoaded = null;
                } else {
                    dataIsLoaded = false;
                }
            } else {
                dataIsLoaded = true;
            }
        } else if (fertilizerDicoIsLoading === true) {
            dataIsLoaded = null;
        } else {
            dataIsLoaded = false;
        }

        let headTable = (
            <TableRow key="rowHead" className="widget-color">
                <TableCell align={(lastNitrogenInput === undefined) ? "center" : "left"} ><Typography fontWeight='bold'>{StringTranslate.totalNdose}</Typography></TableCell>
                {((lastNitrogenInput !== undefined) && (lastNitrogenInput.newNitrogenTotal !== null) && (lastNitrogenInput.fertiCropEnum === enumCultureFertilizer.ble_hiver)) ? ( 
                    <>
                        <TableCell><Typography fontWeight='bold'>{StringTranslate.newTotalNDose}</Typography></TableCell>
                        <TableCell><Typography fontWeight='bold'>{StringTranslate.lastInputDate}</Typography></TableCell>
                    </> 
                ) : null}
            </TableRow>
        );
       
        let bodyTable = () => {
            return (
                <TableRow key={fertilizer.id}>
                    <TableCell align={(lastNitrogenInput === undefined) ? "center" : "left"}>{fertilizer.nitrogenTotal}</TableCell>
                    {(fertilizer.enumOfCrop === enumCultureFertilizer.ble_hiver) ? (
                        (lastNitrogenInput.newNitrogenTotal !== null) ? (
                            <>
                                <TableCell>
                                    <Chip 
                                        label={(adjustment === 0) ? '0' : ((adjustment > 0) ? `+ ${adjustment}` : `- ${Math.abs(adjustment)}`)}
                                        icon={(adjustment > 5) ? <TrendingUpIcon />  : (adjustment < -5) ? <TrendingDownIcon/> : <TrendingFlatIcon/>}
                                        size="small"
                                        sx={{
                                            color: ((adjustment >= -5) && (adjustment <= 5)) ? theme.palette.common.black : theme.palette.common.white,
                                            [`& .${chipClasses.icon}`] : {color: ((adjustment >= -5) && (adjustment <= 5)) ? theme.palette.common.black : theme.palette.common.white }, // Pour changer la couleur de l'icône à l'intérieur de la Chip
                                            bgcolor: ((adjustment > 5) ? theme.palette.colorsFade[500] : (adjustment < -5) ? theme.palette.colorsFade[100] : theme.palette.colorsFade[300])
                                        }}
                                    /> 
                                {"   " + lastNitrogenInput.newNitrogenTotal + "u"}</TableCell>
                                <TableCell>{DateHelper.formati18n(new Date(lastNitrogenInput.creationDate), 'P')}</TableCell>
                            </>
                        ) : <TableCell><Button
                                aria-label="show-infos"
                                onClick={(event) => {handleSelectFertilizerClick(event, parcelIdSelected, false)}}
                                color="primary"
                                variant="contained"
                            >
                            {StringTranslate.buildLastInputNs}
                            </Button></TableCell>
                        
                    ) : ("")}
                </TableRow>
            )
        };

        let otherHeaderContent = [
            <IconButton key="ohterBtn" aria-label="settings" onClick={() => handleOpenDialog(fertilizer)}>
                <FileDownloadIcon color="action" />
            </IconButton>
        ];

        const renderOfPdfChoices = <>
            <FormControlLabel value={EnumDownloadChoiceModulation.map} control={<Radio color="primary" />} label={StringTranslate.carte} />
            <FormControlLabel value={EnumDownloadChoiceModulation.ppf} control={<Radio color="primary" />} label={StringTranslate.ppf} />
        </>;

        return (
            <>
                <TemplateWidget
                    labelButton={StringTranslate.startPPF}
                    labelLoading={StringTranslate.loadingConseilAzote}
                    titleCardHeader={StringTranslate.btnFertilizerOnSidebar}
                    dataIsLoaded={dataIsLoaded}
                    headTable={headTable}
                    bodyTable={bodyTable}
                    isTable={true}
                    generateData={(event) => {handleSelectFertilizerClick(event, parcelIdSelected, true)}}
                    otherHeaderContent={otherHeaderContent}
                />
                <TemplateDialogModulation 
                    hasOpenDownloadDialog={hasOpenDialog}
                    eventCloseDownloadDialog={eventCloseDialog}
                    showExportModulationToDownload={showExportModulationToDownload}
                    showIncitationToUpgradeModulation={showIncitationToUpgradeModulation}
                    showFirstStepForDownloading={false} //on a sélectionné qu'un seul conseil de fumure, donc pas de doute sur le type d'engrain associé !
                    defaultTab_PdfChoicesRender={renderOfPdfChoices}
                    showOtherTab={hasLastNitrogenInput}
                    showExportOtherModulation={hasLastNitrogenInputWithModulation}
                    otherTab_PdfChoicesRender={renderOfPdfLastNitrogenInput}
                    returnChoiceValueForDownload={confirmDownload}
                />   
            </>
        )
    }

    return (
        getContent()
    );
}

const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        lastNitrogenInputDicoIsLoading: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoIsLoading', false),
        lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),
        lastNitrogenInputDicoCounter: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoCounter', 0),
        fertilizerDicoIsLoading: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoading', false),
        fertilizerDico: lodashGet(state, 'fertilizerData.fertilizerDico', {}),
        fertilizerDicoCounter: lodashGet(state, 'fetilizerData.fertilizerDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}),
        authorizeModulation: lodashGet(state, 'clientUserData.clientDatas.authorizeModulation', false),
        downloadingPrescriptions: lodashGet(state, 'fertilizerData.downloadingPrescriptions', false),
    }
}
const mapDispatchToProps = dispatch => ({
    // LastNitrogenInput
    goToStepOfLastNitrogenInput: (nextStepOfLastNitrogenInput, fertiliersIds, readableMode) => dispatch(ActionGoToStepOfLastNitrogenInput(nextStepOfLastNitrogenInput, fertiliersIds, readableMode)),
    getAllLastNitrogenInput: (clientId) => dispatch(ActionGetAllLastNitrogenInputs(clientId)),
    selectParcelLastNitrogenInput : (parcelId) => dispatch(ActionGoToSelectParcelLastNitrogen(parcelId)),
    downloadLastNitrogenInputs: (selectedLniIds, formatToExport) => dispatch( ActionBuildLastNitrogenInputListPrescriptions(selectedLniIds, formatToExport) ),
    generateLastNitrogenInputFile: (lastInputNSelected) => dispatch(ActionGenerateFileOfThisLastNitrogen(lastInputNSelected)),
    // Fertilizer
    showFertilizer: (fertilizerSelectedItem) => dispatch(ActionGoToShowThisFertilizer(fertilizerSelectedItem)),
    clearFertilizerValue: () => dispatch(ActionClearFertilizerValue()),
    getAllFertilizer: (clientId) => dispatch(ActionGetAllFertilizers(clientId)),
    goToFertilizer: () => dispatch(ActionGoToFertilizer(true)),
    setValueTabForAbstractFertilizer: (tab) => dispatch( ActionSelectFertilizerTab(tab) ),
    downloadListPrescriptions: (fertiliersIds, formatToExport) => dispatch(ActionBuildListPrescriptions(fertiliersIds, formatToExport)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLastNitrogenInput);