import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { enumCultureGlobal } from '../../models/constantEnumCulture.js';
import { Grid } from "@mui/material";
/* Widget */
import WidgetParcel from './widgetPanel/widgetParcel.jsx';
import WidgetModulation from './widgetPanel/widgetModulation.jsx';
import WidgetBiomass from './widgetPanel/widgetBiomass.jsx';
import WidgetHarvest from './widgetPanel/widgetHarvest.jsx';
import WidgetFertiAndLastNitro from './widgetPanel/widgetFertiAndLastNitro.jsx';


function RenderManageOfWidget(props) {

    const { parcelIdSelected, parcelDico } = props;

    const widgetMap = { //A revoir si un jour on décide de conditionner plusieurs widgets par rapport à une même culture !
        [enumCultureGlobal.colza]: <WidgetBiomass className='leaflet-control' />,
        [enumCultureGlobal.mais]: <WidgetHarvest className='leaflet-control' />,
        [enumCultureGlobal.ble]: <WidgetFertiAndLastNitro className='leaflet-control' />
    };

    const renderWidgetByCulture = () => {
		const culture = lodashGet(parcelDico, `[${parcelIdSelected}].details.culture`, undefined);
		const checkCulture = ParcelsHelper.checkCultureIsValid(culture);
		
        const renderOfWidget = lodashGet(widgetMap, `[${checkCulture}]`, null); //Attention, ca ne fonctionne QUE par ce que l'on a un seul widget spécifique par culture !
		if (renderOfWidget) {
			return (
				<Grid item xs={12}>
					{renderOfWidget}
				</Grid>
			)
		}
		return null;
    }

    return (
        <>
            <Grid item xs={12}>
                {/* card Modulation */}
                <WidgetModulation {...props} className='leaflet-control' />
            </Grid>
            {renderWidgetByCulture()}
            <Grid item xs={12}>
                {/* card informations parcelle */}
                <WidgetParcel className='leaflet-control' />
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(RenderManageOfWidget);
