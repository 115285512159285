import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import {
    Typography, Grid, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableFooter
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import SubCard from "../subLayouts/subCard";
import MainCard from "../subLayouts/mainCard";
import StringTranslate from "../../assets/i18n/stringLanguage";
import sendError from '../../utils/errorService.js';
import { IsNativeHoster } from '../../utils/platformHelper';
import { /*ActionSelectPlan, ActionGetPlansSubscriptions, */
    ActionErrorSubscriptionProcess, ActionGetClientBilling,
    ActionBuyOnNativeAppProcess,
    ActionSubscribeStripePlan, ActionCreateStripeCustomer
} from "../../redux/actions/clientUser";

import { Elements } from 'react-stripe-elements';
import StripeCreditCardView from "../stripe/stripeCreditCardView";
import WaitBuyByStore from '../stripe/waitBuyByStore';

import '../../assets/css/ssp-stripe_subscribe_process.css'
import '../../assets/css/profil.css'
import StripeSubscriptionProcess from "../stripe/stripeSubscriptionProcess";


class AbonnementPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //newCardEntered: false,
            checkedCGV: false,
        };
        this.handleCheckedCGV = this.handleCheckedCGV.bind(this);
        this.handleSubmitSubscriptionWithCurrentCard = this.handleSubmitSubscriptionWithCurrentCard.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleNextWithStore = this.handleNextWithStore.bind(this);
        this.handleBack = this.handleBack.bind(this);

        //Cas de l'héberguement par notre application mobile => le moyen de paiement est différent !
        this.isOnNativeApp = (IsNativeHoster() === true) ? true : false;
    }



    /* fonction permettant de revenir à l'étape précèdente */
    handleBack() {
        // ↓ on passe à l'étape précèdente ↓ 
        this.props.handlePreviousStep();
    }

    /*handleNewCard() {
        this.setState({ newCardEntered: true });
    }*/

    /* fonction permettant de confirmer l'utilisation de la carte bancaire déjà enregistrer pour souscrire à un nouveau plan */
    handleSubmitSubscriptionWithCurrentCard(event) {
        const { clientId, subscriptionProcess } = this.props;

        //le composant parent connait (en principe) le plan sélectionnée et le client et donc peut dès lors lancer la souscription !
        let planId = (subscriptionProcess && subscriptionProcess.planId) ? subscriptionProcess.planId : null;
        if (!planId) {
            this.props.addErrorToSubscriptionProcess(StringTranslate.errorCreateSubcribePlanIdVoid);
            sendError('abonnementPayment.subscribeCustomer', { 'err': "retour de création du 'customer' Stripe invalide!", 'clientId': clientId });
            this.props.handleNextStep();
            return;
        }
        let idPlanDiscounted = (subscriptionProcess && subscriptionProcess.discountPlanId) ? subscriptionProcess.discountPlanId : null;

        this.props.subscribeStripePlan(planId, idPlanDiscounted)
            .then((result) => { // bool !
                //console.log('[subscribeCustomer - result]', 'souscrit !');
                this.props.handleNextStep(); // on passe à l'écran de succès - étape 4
            })
            .catch((err) => {
                sendError('abonnementPayment-subscribeStripePlan', { 'err': err, 'clientId': clientId });
                this.props.addErrorToSubscriptionProcess(StringTranslate.errorStripeSubscribePlan);
                this.props.handleNextStep();
            });
    }

    /* fonction permettant d'enregistrer le formulaire et d'aller à l'étape suivante */
    handleNext(event) {
        if (event) event.preventDefault();

        // Demande la souscription du client:
        this.handleSubmitSubscriptionWithCurrentCard();
    }

    handleNextWithStore(event) {
        //RAS ! Que le retour soit bon ou pas, le composant associé à cette méthode aura fait le boulot !

        this.props.handleNextStep();
    }

    handleCheckedCGV = e => {
        this.setState({ checkedCGV: !this.state.checkedCGV })
    }

    renderRecapSubscriptionPlan = () => {
        const {
            subscriptionProcess, planSubscriptionDico
        } = this.props;

        let idPlan = subscriptionProcess.planId;
        if ((idPlan === undefined) || (idPlan <= 0)) return null;
        let planData = Object.values(planSubscriptionDico).find(planDatas => planDatas.id === idPlan);
        if (planData === undefined) return null;

        let prixPlan = planData.amount;
        let interval = planData.interval;
        let planName = planData.name;
        // let discount = subscriptionProcess.discountPlanId;
        let discountData = planData.discountPlanDatas;

        return (
            <SubCard>
                <TableContainer>
                    <Table sx={{ minWidth: 'auto' }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{StringTranslate.resumeCommande}</TableCell>

                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{planName}</TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle1">
                                        {prixPlan} {(planData.currency === 'eur') ? '€' : '$'} / {interval}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{StringTranslate.couponReduc}</TableCell>
                                <TableCell align="right">
                                    {(discountData != null) ?
                                        <Typography variant="subtitle1">
                                            - {Math.round((prixPlan - (discountData.amountDiscount)) * 100) / 100}
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1">
                                            ---
                                        </Typography>
                                    }
                                </TableCell>
                            </TableRow>
                            {(discountData != null) ?
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle1">
                                            {discountData.labelDiscount}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                :
                                <></>
                            }

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                    <Typography variant="subtitle1">{StringTranslate.total}</Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ borderBottom: 'none' }}>
                                    <Typography variant="subtitle1">
                                        {(discountData != null) ? (discountData.amountDiscount) : (prixPlan)} {(planData.currency === 'eur') ? '€' : '$'} / {interval}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {(discountData != null) ?
                                <>
                                    {(!discountData.isForever) ?
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">
                                                    {StringTranslate.puis} {prixPlan} {(planData.currency === 'eur') ? '€' : '$'} / {interval}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <></>
                            }
                        </TableFooter>
                    </Table>
                </TableContainer>
            </SubCard>
        )
    }

    renderRecapInfoCard = () => {
        const {
            clientDatas
        } = this.props;

        let prenom = clientDatas.naming.firstName;
        let nom = clientDatas.naming.lastName;
        let phone = clientDatas.phone;
        let email = clientDatas.masterEmail;

        return (
            <SubCard>
                <Grid container spacing={2}>
                    <Grid item xs={12} alignItems="flex-start">
                        <Stack direction="row" spacing={1.5} alignItems="center">
                            <AccountCircle color="primary" />
                            <Typography variant="h3"> {prenom} {nom} </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={0.25} alignItems="flex-start">
                            <Typography variant="caption">{StringTranslate.email}</Typography>
                            <Typography variant="subtitle1">{email}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={0.25} alignItems="flex-start">
                            <Typography variant="caption">{StringTranslate.telephone}</Typography>
                            <Typography variant="subtitle1">{phone}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </SubCard>
        )
    }


    renderRecapAdresseCard = () => {
        const {
            clientDatas, completeAddress
        } = this.props;

        let societe = clientDatas.naming.compagnyName;
        let country = completeAddress.country;
        let postalCode = completeAddress.postalCode;
        let city = completeAddress.cityName;
        let adressePostale = completeAddress.label;


        return (
            <SubCard title={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1.5} alignItems="center">
                            <Typography variant="h3">{StringTranslate.adresseLabelCard}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={0.25} alignItems="flex-start">
                            <Typography
                                variant="subtitle1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {societe}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={0.25} alignItems="flex-start">
                            <Typography variant="body2">{adressePostale}</Typography>
                            <Typography variant="body2">{postalCode} {city}</Typography>
                            <Typography variant="body2">{country}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </SubCard>
        )
    }

    render() {
        // maintenant, on souhaite passer au paiement...
        // Soit en faisant saisir les infos de la CB ;
        // Soit en exploitant le Store (Apple / Android).
        return (
            <Elements locale={this.props.language}>
                <MainCard>
                    <Grid container spacing={3} sx={{ height: '65vh', overflowY: 'auto' }}>
                        <Grid item xs={12} lg={8}>
                            {/* si on est hégergé par l'appli native, alors boucle d'attente (jusqu'à ce que l'on reçoit l'info du réseultat d'achat) */}
                            {/* sinon, visuel de saisie de la CB ! */}
                            {(this.isOnNativeApp === true) ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <WaitBuyByStore
                                            handlePreviousStep={this.handleBack}
                                            handleNextStep={this.handleNextWithStore}
                                            {...this.props} />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <SubCard title={StringTranslate.titlecard}>
                                            <StripeCreditCardView
                                                handleChangeCheckedCGV={this.handleCheckedCGV}
                                                currentCardDatas={this.currentCardDatas}
                                                checkedCGV={this.state.checkedCGV}
                                                {...this.props}
                                            />
                                        </SubCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StripeSubscriptionProcess
                                            handleBack={this.handleBack}
                                            handleNext={this.handleNext}
                                            checkedCGV={this.state.checkedCGV}
                                            {...this.props}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item xs={12} lg={4} spacing={2}>
                            <Grid item xs={12}>{this.renderRecapSubscriptionPlan()}</Grid>
                            <Grid item xs={12}>{this.renderRecapInfoCard()}</Grid>
                            <Grid item xs={12}>{this.renderRecapAdresseCard()}</Grid>
                        </Grid>
                        {/* <Grid item xs={12} spacing={2}> */}
                        {/* <Stack direction="row" justifyContent="space-between">
                                <Button 
                                    type='submit'
                                    variant="text" 
                                    color="primary"
                                    onClick={this.handleBack}
                                >
                                    {`${StringTranslate.revenir}`}
                                </Button>
                                <Button 
                                    type='submit'
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.handleNext} 
                                    disabled={!this.state.checkedCGV}
                                >
                                    {`${StringTranslate.confirmersub}`}
                                </Button>
                            </Stack> */}
                        {/* </Grid> */}
                    </Grid>
                </MainCard>
            </Elements>
        );
    }
}

const mapStateToProps = state => ({
    creditCard: lodashGet(state, 'clientUserData.clientDatas.creditCard', null),
    subscriptionProcess: lodashGet(state, 'clientUserData.subscriptionProcess', {}), // aussi pour stripeSuccessFailView.jsx
    planSubscriptionDico: lodashGet(state, 'clientUserData.planSubscriptionDico', {}),
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
    language: lodashGet(state, 'settingsData.settings.language', ""),
    clientDatas: lodashGet(state, 'clientUserData.clientDatas', {}),
    codeCountry: lodashGet(state, 'settingsData.settings.codeCountry', ""),
    isNewSubscription: lodashGet(state, 'clientUserData.isNewSubscription', false),
    gettingPlansSubscriptions: lodashGet(state, 'clientUserData.gettingPlansSubscriptions', false),
    completeAddress: lodashGet(state, 'clientUserData.clientDatas.address', {}),

    subscriptionProcessStarting: lodashGet(state, 'clientUserData.subscriptionProcess.planId', '') !== "",
    showPurchaseOnStoreResult:lodashGet(state, 'clientUserData.subscriptionProcess.showPurchaseOnStoreResult', false),
    waitingWhileAskSubscribe: lodashGet(state, 'clientUserData.subscriptionProcess.asking', false),
})

const mapDispatchToProps = dispatch => ({
    addErrorToSubscriptionProcess: (error) => dispatch(ActionErrorSubscriptionProcess(error)),
    getClientBilling: (limitInvoices) => dispatch(ActionGetClientBilling(limitInvoices)),

    createStripeCustomer: (cardToken) => dispatch(ActionCreateStripeCustomer(cardToken)),
    subscribeStripePlan: (planId, idPlanDiscounted) => dispatch(ActionSubscribeStripePlan(planId, idPlanDiscounted)),
    callBuyOnNativeApp: () => dispatch(ActionBuyOnNativeAppProcess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbonnementPayment);
