import React from 'react';
import { createRoot } from 'react-dom/client'; //import React from "react"; import ReactDOM from "react-dom";
import { connect, Provider } from 'react-redux';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import format from 'date-fns/format';
import converter from '../../utils/converter.js'; //permet de transformer les données pour les adapter aux entités de l'API web et inversement
import '../../assets/css/rm-render_maps.css';
import { ChangeType, ReducerType } from '../../models/changeAction.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { SatimageHelper } from '../../utils/satimageHelper.js';
import {
    ActionGoToMapAndSelectParcel,
    ActionLoadingGlobalLayer, ActionGlobalLayerLoaded,
    ActionSetValueNewImageShowedOnMap, ActionGoToMapAndSelectObservation,
    ActionCloseMessageOfNoOpenGlobalLayer,
    ActionOpenPinDropFormDialog,
    ActionClosePinDropFormDialog,
    ActionSetDisplaySwipeablePanelForDesktop
} from '../../redux/actions/contextApp.js';

import lodashGet from 'lodash/get';
import lodashDifference from 'lodash/difference';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import ConstantsProvidersSatellite from '../../utils/constantsProvidersSatellite.js';

import MapButtonsPane from '../../components/carte/MapButtonsPane.jsx';
import country from '../../datas/country.json';
import DateHelper from '../../utils/dateHelper.js';
import { ObservationsHelper } from '../../utils/observationsHelper.js';
import '../../assets/css/observationArea.css';
import sendError from '../../utils/errorService.js';
import AlertDialog from '../../components/alertDialog';
import AlertIcon from '@mui/icons-material/ErrorOutline';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import PinDropFormPopup from '../../components/carnetDePlaine/PinDropFormPopup.jsx';
import storeManager from '../../redux/store/configureStore.js';
import { IsNativeHoster } from '../../utils/platformHelper';

import { Box, ThemeProvider } from '@mui/material';

import { ConstantsContent } from '../../utils/constantsContent.js';

import getTheme from "../../themes/index.js";
import { ChoiceProvidersSatellite } from '../../utils/constantsProvidersSatellite.js';

let theme = getTheme();

/* ↓↓ Définition des différents styles à appliquer sur un polygone ↓↓ */
const stylePolygon = {
    default: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0.3, opacity: 1 },
    selection: { fillColor: theme.palette.contour.selection, color: theme.palette.contour.selection, fillOpacity: 0.3, opacity: 1 },
    new: { fillColor: theme.palette.contour.new, color: theme.palette.contour.new, fillOpacity: 0.3, opacity: 1 },
    transparent: { fillOpacity: 0, opacity: 0 },
    defaultContour: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0, opacity: 1 },
    selectionContour: { fillColor: theme.palette.contour.selection, color: theme.palette.contour.selection, fillOpacity: 0, opacity: 1 },
    newContour: { fillColor: theme.palette.contour.new, color: theme.palette.contour.new, fillOpacity: 0, opacity: 1 },
    fill_off: { fillColor: theme.palette.primary.main, color: theme.palette.primary.main, fillOpacity: 0, opacity: 1 },
    fill_on: { fillOpacity: 0.3, opacity: 1 },
    selected: { color: theme.palette.contour.selection, opacity: 1 },
    unselected: { color: theme.palette.primary.main, opacity: 1 }
}

/* ↓↓ Définition des différentes options à appliquer lors de l'utilisation du fitBounds ↓↓ */
const fitBoundsOptions = {
    controlClosed: { paddingTopLeft: [0, 0], paddingBottomRight: [0, 70] }, // permet de déplacer l'image de la parcelle avec un padding de 70px du bas et 0px depuis la droite (boutons de controle de droite)
    controlOpened: { paddingTopLeft: [479, 0], paddingBottomRight: [0, 70] }, // prend en compte les 466px de largeur du panneau flottant et 13px de marge pour placer correctement la parcelle quand le panneau flottant est ouvert
}

const MaxZoomDefined = { // au dessus de 18, le rendu est flou
    desktopAllCrops: 18,
    desktopForSelectedCrop: 18,
    mobileAllCrops: 18,
    mobileForSelectedCrop: 16,
}

// Valeur pour la carte, se place sur la France
const defaultValue = {
    latLng: [48, 2],
    zoom: 7,
}

///////////////////////////////////////////////////////////////////////////
// Composant détenant l'affichage de notre carte.
///////////////////////////////////////////////////////////////////////////
class RenderMaps extends React.Component {
    /**
     * constructeur
     */
    constructor(props) {
        super(props);

        this.state = {
            mapIsReady: false, // donnée en lien avec les composants enfants/soeurs
        };

        //Options pour l'affichage du message invitant à sélectionner une parcelle pour exploiter les couches globales:
        this.popupMessageDialog = {
            getTitle: () => { return (<AlertIcon />); },
            description: StringTranslate.noOpenGlobalLayerWithoutParcelSelection,
            button: StringTranslate.close,
        };

        this.map = null; //object leaflet
        this.mapId = 'mainMap'; //ID de la carte

        //↓↓ LAYERS - fond de carte ↓↓
        this.currentBaseLayerOnMap = null; // object Leaflet actuellement appliqué sur la carte
        this.streetBaseMaps = null; //object leaflet représentant le layer de base street
        this.satelliteBaseMaps = null; //object leaflet représentant le layer de base satellite
        this.satelliteOverlayMaps = null; //object leaflet lors de l'ajout de layer - pas besoin d'utiliser les fonctionnalités State de React
        this.sourceProvider = null; //object leaflet représentant le fournisseur (Sentinel ou Landsat) actuel sur la carte
        this.satelliteProviderOverlayMaps = null; //object leaflet lors de l'ajout de layer - pas besoin d'utiliser les fonctionnalités State de React

        this.timeForOverlayMaps = new Date(); //date à utiliser pour obtenir une vue globale Sentinel...

        //↓↓ GROUPE LAYER - layers parcelles et images ↓↓
        this.parcelPolygonLayerGroup = null; //object leaflet qui sert à afficher les parcelles (layer)
        this.parcelImageLayerGroup = null; //object leaflet qui sert à afficher les images (ImageLayer)
        this.observationLayerGroup = null; //object leaflet qui sert à afficher les observations (point/zone)

        //↓↓ CONTEXTE - Dico de données de contexte liés seulement à la carte ↓↓
        this.parcelPolygonLayerDico = {}; // Dico contenant les couples parcelId - layer
        this.parcelImageLayerDico = {}; // Dico contenant les couples parcelId-{typeLayer,layer}
        this.observationLayerDico = {}; // Dico contenant les couples observationId-{layer}

        this.DRAWINGPARCEL = false; //TODO : A revoir pour ne plus exploiter cette prop dans les autres composants...

        //↓↓ BIND - fonctions déclarées ↓↓
        this.addNewParcelPolygonToMap = this.addNewParcelPolygonToMap.bind(this);
        this.removeParcelPolygonFromMap = this.removeParcelPolygonFromMap.bind(this);
        this.addNewParcelImageToMap = this.addNewParcelImageToMap.bind(this);
        this.removeCurrentParcelImageFromMap = this.removeCurrentParcelImageFromMap.bind(this);
        this.removeObservationLayerFromMap = this.removeObservationLayerFromMap.bind(this);
        this.addObservationLayerFromMap = this.addObservationLayerFromMap.bind(this);
        this.addFilteredParcelsToMap = this.addFilteredParcelsToMap.bind(this);
        this.selectParcel = this.selectParcel.bind(this);
        this.unselectParcel = this.unselectParcel.bind(this);
        this.zoomToParcel = this.zoomToParcel.bind(this);
        this.zoomToAllParcel = this.zoomToAllParcel.bind(this);
        this.addBaseLayerOnMap = this.addBaseLayerOnMap.bind(this);
        this.addObservationsToMap = this.addObservationsToMap.bind(this);
        this.selectObservation = this.selectObservation.bind(this);
        this.unselectObservation = this.unselectObservation.bind(this);
        this.zoomToObservation = this.zoomToObservation.bind(this);
        
    }
    
    /* Les drawer (mui) sur la carte font des flashs blancs lors des ouvertures ou fermetures.
    Pour pallier à ce problème, on réduit le maxZoom à 15.
    Septembre 2022: tente d'augmenter le zoom maxi, afin d'améliorer la vue sur les petites parcelles. =>A vérifier si les flashs reviennent */
    getMaxZoom() {
        const { parcelIdSelected } = this.props;
        
        //En cas de dessin en cours de réalisation :
        if (this.DRAWINGPARCEL) {
            return MaxZoomDefined.desktopAllCrops; //pour autoriser un zoom le plus fort !
        }

        if (IsNativeHoster() === true) { //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            if (parcelIdSelected > 0) {
                return MaxZoomDefined.mobileForSelectedCrop;
            } else {
                return MaxZoomDefined.mobileAllCrops;
            }
        } else  {
            if (parcelIdSelected > 0) {
                return MaxZoomDefined.desktopForSelectedCrop;
            } else {
                return MaxZoomDefined.desktopAllCrops;
            }
        }
        
        //return MaxZoomDefined.desktopAllCrops; //valeur par défaut, s'il fallait en mettre une !
    }

    /**
     * fonction cycle de vie 
     */
    componentDidMount() {
        const { parcelIdSelected, observationIdSelected, /*forewardLayerSelected,*/ codeCountry, isPanelOpen } = this.props;

        // ↓ récupération du code pays pour zoomer sur le pays en question lors de la vue de la carte ↓
        //Avant on recentrait sur le pays puis sur la parcelle mais cela provoquait un dézoom dans certains cas donc on recentre sur le pays si aucune parcelle n'est sélectionné
        if(parcelIdSelected < 1) this.setLatLngAndZoom(codeCountry);

        // ↓ CREATION - carte munie de son paramètrage ↓

        this.map = L.map(
            this.mapId,
            {
                crs: L.CRS.EPSG3857, //définition de la projection
                center: this.latlng,
                zoom: this.zoom,
                minZoom: 6, // en dessous de 7 => les layers Sentinel ne sont pas disponibles
                maxZoom: this.getMaxZoom(), 
                zoomControl: false, // on n'affiche pas les boutons de zoom natifs
                attributionControl: false,
            }
        );
        if (this.map.doubleClickZoom) this.map.doubleClickZoom.disable(); // ← suppression double click ←

        //↓↓ INSERTION du groupe de layer représentant les polygones ↓↓
        this.parcelPolygonLayerGroup = L.geoJSON();
        this.map.addLayer(this.parcelPolygonLayerGroup);

        //↓↓ INSERTION du groupe de layer représentant les images ↓↓
        this.parcelImageLayerGroup = L.layerGroup();

        if (isPanelOpen === false) this.props.setDisplaySwipeablePanelForDesktop(true); //Réouvre le panneau flottant s'il était fermé
        /*
        //on souhaite désormais appliquer systématiquement la couche "à la parcelle" lors de l'entrée dans l'appli !
        if ((!forewardLayerSelected) ||
            (forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {
            this.map.addLayer(this.parcelImageLayerGroup);
        } else {//C'est que l'on est en vue globale !
            this.applyGlobalLayer(forewardLayerSelected);
        }*/
        this.map.addLayer(this.parcelImageLayerGroup); //on applique donc cette couche ci !

        //↓↓ INSERTION du groupe de layer représentant les points/zones d'observation - (carnet de plaine) ↓↓
        this.observationLayerGroup = new L.geoJSON();
        if (this.props.observationShowAll)
            this.map.addLayer(this.observationLayerGroup);

        //↓↓ INSERTION des parcelles et de leur image courante respective ↓↓
        this.addFilteredParcelsToMap();
        this.addAllCurrentImageToMap();

        //↓↓ INSERTION des observations ↓↓
        this.addObservationsToMap();

        //↓↓ SELECTION parcelle/observation/parcellaire ↓↓
        if (parcelIdSelected > 0) {
            this.selectParcel(parcelIdSelected) // sélection
            this.zoomToParcel(parcelIdSelected) // + zoom
        }
        else if (observationIdSelected > 0) {
            this.selectObservation(observationIdSelected) // sélection
            this.zoomToObservation(observationIdSelected) // + zoom
        }
        else {
            this.zoomToAllParcel(); // au démarrage du composant si aucune parcelle sélectionnée, on zoome sur le parcellaire
        }

        //↓↓ CREATION ET INSERTION des différents layers de fond de carte ↓↓
        this.streetBaseMaps = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {}); // layer - vue street
        this.satelliteBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Esri-visible' }); // layer - vue satellite
        this.placesBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}', {}); // layer - délimitations des villes (en combinaision avac la vue satellite)
        this.addBaseLayerOnMap();

        //↓↓ A partir de ce point, les composants enfants/soeurs peuvent être créés 'mounted' (certains utilisent la donnée this.map au chargement => il faut que cette donnée soit disponible) ↓↓
        this.setState({ mapIsReady: true });
    }

    //Fonction asynchrone pour le zoom sur le pays
    setLatLngAndZoom(codeCountry) {
        try {
            // Récupérer les coordonnées et le zoom du pays de façon asynchrone
            this.latlng = lodashGet(country, `[${codeCountry}].geo.latlng`, defaultValue.latLng);
            this.zoom = lodashGet(country, `[${codeCountry}].geo.zoom`, defaultValue.zoom);
        } catch (error) {
            //Si une erreur survient, utiliser les coordonnées et le zoom de la France
            this.latlng = lodashGet(country, `[FR].geo.latlng`, defaultValue.latLng);
            this.zoom = lodashGet(country, `[FR].geo.zoom`, defaultValue.zoom);
        }
    }

    /**
     * fonction permettant d'appliquer le layer fond de carte sur le carte suivant les données du settings
     */
    addBaseLayerOnMap() {
        const { baseLayerSelected } = this.props;

        if (baseLayerSelected === ConstantsLayers.RoadBaseLayerName) {
            if (this.currentBaseLayerOnMap !== this.streetBaseMaps) { // => donc devrait correspondre à 'this.satelliteBaseMaps' !
                if (this.currentBaseLayerOnMap && this.map.hasLayer(this.currentBaseLayerOnMap)) {
                    this.map.removeLayer(this.currentBaseLayerOnMap); // on retire le précèdent si ce n'est pas le même que celui demandé
                }
                if (this.placesBaseMaps && this.map.hasLayer(this.placesBaseMaps)) {
                    this.map.removeLayer(this.placesBaseMaps); // on retire également (si présent) le layer de délimitation de ville
                }
                this.map.addLayer(this.streetBaseMaps); // on ajoute le nouveau layer demandé
            }
            this.currentBaseLayerOnMap = this.streetBaseMaps; // on garde l'information pour la prochaine demande 
        }
        else {//if (baseLayerSelected === ConstantsLayers.VisibleBaseLayerName) {
            if (this.currentBaseLayerOnMap !== this.satelliteBaseMaps) { // => donc devrait correspondre à 'this.streetBaseMaps' !
                if (this.currentBaseLayerOnMap && this.map.hasLayer(this.currentBaseLayerOnMap)) {
                    this.map.removeLayer(this.currentBaseLayerOnMap); // on retire le précèdent si ce n'est pas le même que celui demandé
                }
                this.map.addLayer(this.satelliteBaseMaps); // on ajoute le nouveau layer demandé
                this.map.addLayer(this.placesBaseMaps);
            }
            this.currentBaseLayerOnMap = this.satelliteBaseMaps;
        }
    }

    /**
     * fonction permettant de sélectionner une parcelle (nouveau style + zoom sur la parcelle) - second paramètre: l'ancienne sélection (parcelId)
     */
    selectParcel(parcelId, previousSelectedParcelId = -1) {
        // Actualise le zoom:
        this.map.options.maxZoom = this.getMaxZoom();

        // on retire la sélection sur la précèdente parcelle sélectionnée
        let isPreviousSelectedPolygonLayerOnMap = ((previousSelectedParcelId > 0) && this.parcelPolygonLayerDico[previousSelectedParcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[previousSelectedParcelId]));
        if (isPreviousSelectedPolygonLayerOnMap)
            this.parcelPolygonLayerDico[previousSelectedParcelId].setStyle(stylePolygon.unselected);

        let isSelectedPolygonLayerOnMap = ((parcelId > 0) && this.parcelPolygonLayerDico[parcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[parcelId]));
        if (isSelectedPolygonLayerOnMap)
            this.parcelPolygonLayerDico[parcelId].setStyle(stylePolygon.selected);
    }

    /**
     * fonction permettant de supprimer la sélection d'une parcelle actuellemnt sélectionnée
     */
    unselectParcel(parcelId = -1) {
        // Actualise le zoom:
        this.map.options.maxZoom = this.getMaxZoom();

        // on retire la sélection sur la précèdente parcelle
        let isSelectedLayerOnMap = ((parcelId > 0) && this.parcelPolygonLayerDico[parcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[parcelId]));
        if (isSelectedLayerOnMap) {
            this.parcelPolygonLayerDico[parcelId].setStyle(stylePolygon.unselected);
        }
    }

    /**
     * fonction permettant de sélectionner une observation (nouveau style + zoom sur l'observation) 
     * second paramètre: l'ancienne sélection (observationId)
     */
    selectObservation(observationId, previousSelectedObservationId = -1) {
        /* à voir plus tard s'il faut sélectionner l'observation choisie */
    }

    /**
     * fonction permettant de supprimer la sélection d'une observation actuellemnt sélectionnée
     */
    unselectObservation(observationId = -1) {
        // on retire la sélection sur la précèdente parcelle
        let isSelectedLayerOnMap = ((observationId > 0) && this.observationLayerDico[observationId] && this.observationLayerDico.hasLayer(this.observationLayerDico[observationId]));
        if (isSelectedLayerOnMap) {
            this.observationLayerDico[observationId].setStyle(stylePolygon.unselected);
        }
    }

    /**
     * fonction permettant de zoomer sur une parcelle sélectionnée - VUE PARCELLE
     */
    zoomToParcel(parcelId) {
        const {isPanelOpen} = this.props
        const layerOfPcl = this.parcelPolygonLayerDico[parcelId];
        if (layerOfPcl && this.parcelPolygonLayerGroup.hasLayer(layerOfPcl)) {
            let bounds = layerOfPcl.getBounds();
            if (bounds && bounds.isValid()) {
                if (isPanelOpen) {
                    this.map.fitBounds(bounds, fitBoundsOptions.controlOpened); //Affichage de la parcelle en prenant en compte l'ouverture du panneau flottant
                } else {
                    this.map.fitBounds(bounds, fitBoundsOptions.controlClosed); //Affichage de la parcelle classique quand le panneau est fermé
                }
            }
        }
    }

    /**
     * fonction permettant de zoomer sur une observation sélectionnée
     */
    zoomToObservation(observationId) {
        const layerOfObs = this.observationLayerDico[observationId];
        if (layerOfObs && this.observationLayerGroup.hasLayer(layerOfObs)) {
            let bounds = layerOfObs.getBounds();
            if (bounds && bounds.isValid()) {
                this.map.fitBounds(bounds, fitBoundsOptions.controlClosed);
            }
        }
    }

    /**
     * fonction permettant de zoomer sur toutes les parcelles - VUE PARCELLAIRE
     */
    zoomToAllParcel() {
        if (this.parcelPolygonLayerGroup) {
            let bounds = this.parcelPolygonLayerGroup.getBounds();
            if (bounds && bounds.isValid()) {
                this.map.fitBounds(bounds, fitBoundsOptions.controlClosed);
            }
        }
    }

    /**
     * fonction permettant d'ajouter pour chaque parcelle l'image associé
     * l'image associée est en lien avec la propriété currentSatimageId d'une entité Parcel 
     */
    addAllCurrentImageToMap() {
        const { parcelDico, parcelIdFilteredList, parcelIdFilteredListCounter, satimageByParcelDico, forewardLayerSelected } = this.props;
        if (!parcelIdFilteredList || (parcelIdFilteredListCounter <= 0)) return;

        if ((forewardLayerSelected) &&
            (forewardLayerSelected !== ConstantsLayers.VisibleParcelLayerName) && (forewardLayerSelected !== ConstantsLayers.NdviParcelLayerName)) return;

        // - pour chaque id de parcelle
        parcelIdFilteredList.forEach(parcelId => {
            // 1- récupération de la parcelle
            let parcel = parcelDico[parcelId];

            //↓↓ 2- je récupère l'image associée ↓↓ 
            const satimageId = parcel.currentSatimageId;
            const layerType = (parcel.currentLayerType) ? parcel.currentLayerType : ConstantsLayers.NdviParcelLayerName;
            let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType);

            //↓↓ 3- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
            if (imageURL) {
                /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
                let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
                // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
                let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
                //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
                const so = L.latLng(parcel.ymin, parcel.xmin);
                const ne = L.latLng(parcel.ymax, parcel.xmax);
                const bounds = L.latLngBounds(so, ne);
                const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
                if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
                if (imageLayer) {
                    this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                    this.removeCurrentParcelImageFromMap(parcelId); // si un layer image était déjà présent, on l'enlève
                    this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
                }
            }

            //↓↓ 4- CAS: est-ce une NOUVELLE IMAGE que l'utilisateur n'a pas encore été eue/vue ?  ↓↓
            /* LastSeen correspond à la date où le client a cliqué pour la dernière fois sur une parcelle.
            Si la date de l'image la plus récente < date lastSeen, le client a déjà vu cette image auparavant,
            l'image n'aura pas son encadré rose. */
            const isNewSatimage = SatimageHelper.isNewSatimage(satimageByParcelDico, parcelId, satimageId);
            if (isNewSatimage || (parcel.lastSeen === undefined) || (parcel.lastSeen === null) || (parcel.lastSeen !== null && DateHelper.getDateFromString(parcel.lastSeen) < parcel.firstSatimageDate)) {
                const layer = this.parcelPolygonLayerDico[parcelId]; // un layer est il associé ?
                if (layer) layer.setStyle(stylePolygon.newContour);
                SatimageHelper.setIsNewSatimage(this.props.parcelDico, this.props.satimageByParcelDico, parcelId, false);
                this.props.setValueNewImageShowedOnMap(true); // on met à jour la valeur qui exprime le fait qu'il y a des nouvelles images en cours de visualisation sur la carte (Mapinfo va lire cette valeur)
            }
        });
    }

    /**
     * fonction permettant d'ajouter toutes les parcelles contenues dans la liste des parcelles filtrées dans la carte
     */
    addFilteredParcelsToMap() {
        const { parcelIdFilteredList, parcelIdFilteredListCounter } = this.props;
        if (!parcelIdFilteredList || (parcelIdFilteredListCounter <= 0)) return;

        // - pour chaque id de parcelle
        parcelIdFilteredList.forEach(parcelId => this.addNewParcelPolygonToMap(parcelId));

        // 6- une fois les ajouts terminés - on recentre la carte sur le parcellaire
        if (this.parcelPolygonLayerGroup) {
            let bounds = this.parcelPolygonLayerGroup.getBounds();
            if (bounds && bounds.isValid()) {
                this.map.fitBounds(bounds, fitBoundsOptions.controlClosed);
            }
        }
    }

    /**
     * fonction permettant de forcer, pour chaque parcelle, 
     * le retour à leur dernière date d'image dispo et de leur appliquer la couche NDVI à la parcelle !
     */
    applyLastDateAndNdviLayerForAllParcels() {
        const { parcelDico, parcelDicoCounter } = this.props;
        if ((!parcelDico) || (parcelDicoCounter <= 0)) return;

        //vire les vues globales (Visible et Ndvi):
        if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
            this.map.removeLayer(this.satelliteOverlayMaps);
        }
        if (this.satelliteProviderOverlayMaps && this.map.hasLayer(this.satelliteProviderOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
            this.map.removeLayer(this.satelliteProviderOverlayMaps);
        }

        const layerType = ConstantsLayers.NdviParcelLayerName;
        // - pour chaque id de parcelle
        for (const key in parcelDico) {
            const parcel = parcelDico[key];
            if (parcel) {
                const lastDate = (parcel.firstSatimageDate) ? parcel.firstSatimageDate : undefined; // donc dernière dispo !
                this.updateImageForParcel(parcel.id, undefined, lastDate, layerType, false);
            }
        }
    }

    /**
     * fonction permettant de forcer une vue globale (Visible ou NDVI) sur la carte:
     */
    applyGlobalLayer(forewardLayertype, satimageDate = undefined) {
        const { satimageByParcelDico, parcelIdSelected, satimageIdSelectedOfParcel } = this.props;

        //Retrait du layer d'image de chaque parcelle => donc, on retire le groupe de layers directement !
        if (this.parcelImageLayerGroup && (this.map.hasLayer(this.parcelImageLayerGroup))) this.map.removeLayer(this.parcelImageLayerGroup);

        //Met en forme la date (si fournie):
        let timeValue = undefined;
        if (satimageDate) {
            if (satimageDate && (satimageDate instanceof Date)) {
                timeValue = satimageDate;
            } else if (satimageDate) {
                try {
                    timeValue = new Date(satimageDate);
                }
                catch (err) { }
            } else {
                timeValue = new Date();
            }
        }

        const satimagesDico = lodashGet(satimageByParcelDico, `[${parcelIdSelected}].satimageDico`, null);

        //on recherche l'id de l'image visée à partir de sa date:
        let useThisIdOfImag = satimageIdSelectedOfParcel;
        if ((timeValue) && (satimagesDico !== undefined) && (satimagesDico !== null) && (
            (satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel === null) || (satimageIdSelectedOfParcel <= 0))) {
            const satImage = SatimageHelper.getSatimageFromListByDate(satimagesDico, timeValue);
            if (satImage !== undefined)
                useThisIdOfImag = satImage.id;
        }

        // on détermine le fournisseur de l'image visée :
        let sourceProvider = ConstantsProvidersSatellite.SatImageSource.SentinelL2;
        if ((satimagesDico !== undefined) && (satimagesDico !== null)) {
            sourceProvider = lodashGet(satimagesDico, `[${useThisIdOfImag}].sourceProvider`, ConstantsProvidersSatellite.SatImageSource.SentinelL2);
        }

        //gestion des vues globales:
        if (forewardLayertype === ConstantsLayers.VisibleGlobalLayerName) {
            //vire la vue globale Ndvi:
            if (this.satelliteProviderOverlayMaps && this.map.hasLayer(this.satelliteProviderOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                this.map.removeLayer(this.satelliteProviderOverlayMaps);
            }

            //cré (si besoin) et affiche la vue globale Visible:
            if (!this.satelliteOverlayMaps) {
                this.createSatelliteLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.satelliteOverlayMaps);
                this.createSatelliteLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            }
            else { //si le layer existe déjà, on actualise la date...
                if (timeValue) {
                    this.satelliteOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), });
                } else {
                    this.satelliteOverlayMaps.setParams({ time: undefined, });
                }
            }

            this.map.addLayer(this.satelliteOverlayMaps);
        } else if (forewardLayertype === ConstantsLayers.NdviGlobalLayerName) {
            //vire la vue globale Visible:
            if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                this.map.removeLayer(this.satelliteOverlayMaps);
            }

            //créé (si besoin) et affiche la vue globale Ndvi:
            if (!this.satelliteProviderOverlayMaps) {
                this.createSatelliteNdviLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            }
            else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.satelliteProviderOverlayMaps);
                this.createSatelliteNdviLayer(sourceProvider, satimageDate); // si non existant alors on le crée
            }
            else { //si le layer existe déjà, on actualise la date...
                if (timeValue) {
                    this.satelliteProviderOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), });
                } else {
                    this.satelliteProviderOverlayMaps.setParams({ time: undefined, });
                }
            }

            this.map.addLayer(this.satelliteProviderOverlayMaps);
        }
        //else, ce n'est pas un layer 'global' !

        // la valeur globale du fournisseur actuel
        this.sourceProvider = sourceProvider;
    }

    /**
     * fonction permettant d'actualiser la date du cliché de la vue globale (Visible ou NDVI) sur la carte:
     */
    updateGlobalLayer(forewardLayertype, specificDate) {
        const { satimageByParcelDico, parcelIdSelected, satimageIdSelectedOfParcel } = this.props;

        //Retrait du layer d'image de chaque parcelle => donc, on retire le groupe de layers directement !
        if (this.parcelImageLayerGroup && (this.map.hasLayer(this.parcelImageLayerGroup))) this.map.removeLayer(this.parcelImageLayerGroup);

        //Met en forme la date (si fournie):
        let timeValue = undefined;
        if (specificDate) {
            if (specificDate && (specificDate instanceof Date)) {
                timeValue = specificDate;
            } else if (specificDate) {
                try {
                    timeValue = new Date(specificDate);
                }
                catch (err) { }
            } else {
                timeValue = new Date();
            }
        }

        const satimagesDico = lodashGet(satimageByParcelDico, `[${parcelIdSelected}].satimageDico`, null);

        //on recherche l'id de l'image visée à partir de sa date:
        let useThisIdOfImag = satimageIdSelectedOfParcel;
        if ((timeValue) && (satimagesDico !== undefined) && (satimagesDico !== null) && (
            (satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel === null) || (satimageIdSelectedOfParcel <= 0))) {
            const satImage = SatimageHelper.getSatimageFromListByDate(satimagesDico, timeValue);
            if (satImage !== undefined)
                useThisIdOfImag = satImage.id;
        }

        // on détermine le fournisseur de l'image visée :
        let sourceProvider = ConstantsProvidersSatellite.SatImageSource.SentinelL2;
        if ((satimagesDico !== undefined) && (satimagesDico !== null)) {
            sourceProvider = lodashGet(satimagesDico, `[${useThisIdOfImag}].sourceProvider`, ConstantsProvidersSatellite.SatImageSource.SentinelL2);
        }

        //gestion des vues globales: 
        if (forewardLayertype === ConstantsLayers.VisibleGlobalLayerName) {
            //créé (si besoin MAIS normalement il est censé déjà exister) et actualise la vue globale Visible:
            if (!this.satelliteOverlayMaps) {
                this.createSatelliteLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteOverlayMaps);
            } else if (this.sourceProvider !== sourceProvider) {
                this.map.removeLayer(this.satelliteOverlayMaps);
                this.createSatelliteLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteOverlayMaps);
            } else {
                // en rapport avec le message de chargement depuis le composant MapInfo
                if (this.props.loadingGlobalLayer) {
                    this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
                }

                this.satelliteOverlayMaps.on('load', () => {
                    if (this.props.globalLayerLoaded) {
                        this.props.globalLayerLoaded();
                    }

                    this.satelliteOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
                });

                if (timeValue)
                    this.satelliteOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), }); // '2020-02-07', }); // 
            }
        } else if (forewardLayertype === ConstantsLayers.NdviGlobalLayerName) {
            //créé (si besoin MAIS normalement il est censé déjà exister) et actualise la vue globale Ndvi:
            if (!this.satelliteProviderOverlayMaps) {
                this.createSatelliteNdviLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteProviderOverlayMaps);
            } else if (this.sourceProvider !== sourceProvider) { //il y a eu un changement de provider
                this.map.removeLayer(this.satelliteProviderOverlayMaps);
                this.createSatelliteNdviLayer(sourceProvider, specificDate); // si non existant alos on le crée
                this.map.addLayer(this.satelliteProviderOverlayMaps);
            } else {
                // en rapport avec le message de chargement depuis le composant MapInfo
                if (this.props.loadingGlobalLayer) {
                    this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
                }

                this.satelliteProviderOverlayMaps.on('load', () => {
                    if (this.props.globalLayerLoaded) {
                        this.props.globalLayerLoaded();
                    }

                    this.satelliteProviderOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
                });

                if (timeValue)
                    this.satelliteProviderOverlayMaps.setParams({ time: format(timeValue, 'yyyy-MM-dd'), }); // '2020-02-07', }); // 
            }
        }
        //else, ce n'est pas un layer 'global' !

        // Mise à jour du fournisseur (Sentinel ou Landsat) global
        this.sourceProvider = sourceProvider;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction appelée pour créer la couche satellite
    ///////////////////////////////////////////////////////////////////////////
    createSatelliteLayer(sourceProvider, specificDate = undefined) { // Création de l'objet leaflet représentant la couche Sentinel Global du visible !
        let timeValue = undefined;
        let withSpecificDate = false;
        if (specificDate && (specificDate instanceof Date)) {
            timeValue = specificDate;
            withSpecificDate = true;
        } else if (specificDate) {
            timeValue = new Date(specificDate);
            withSpecificDate = true;
        } else {
            timeValue = new Date();
        }

        let urlWms = ChoiceProvidersSatellite(sourceProvider);
        let optionsWms = {};
        if (withSpecificDate) {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 100, //100% pour être sûre d'avoir la carte à la date demandée !
                // showLogo: false,
                layers: "VISIBLE,DATE",
                //time:"2017-03-28/2018-05-09",
                time: format(timeValue, 'yyyy-MM-dd'),
                format: 'image/png',
                transparent: false,
                showDates: true
            };
        } else {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 80,
                // showLogo: false,
                layers: "VISIBLE,DATE",
                //time:"2017-03-28/2018-05-09",
                format: 'image/png',
                transparent: false,
                showDates: true
            };
        }

        this.satelliteOverlayMaps = L.tileLayer.wms(urlWms, optionsWms);

        // event trigger
        this.satelliteOverlayMaps.on('add', () => {
            // en rapport avec le message de chargement depuis le composant MapInfo
            if (this.props.loadingGlobalLayer) {
                this.props.loadingGlobalLayer(); // => StringTranslate.visiblecharge
            }

            this.satelliteOverlayMaps.on('load', () => {
                if (this.props.globalLayerLoaded) {
                    this.props.globalLayerLoaded();
                }

                this.satelliteOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
            });
        });

        this.satelliteOverlayMaps.on('remove', () => { /*RAS*/ }); //@@a voir si besoin d'y faire quelque chose...
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction appelée pour créer la couche satellite Sentinel ou LandSat
    ///////////////////////////////////////////////////////////////////////////
    createSatelliteNdviLayer(sourceProvider, specificDate = undefined) { // Création de l'objet leaflet représentant la couche Sentinel Global del'indice de végétation (NDVI) !
        let timeValue = undefined;
        let withSpecificDate = false;
        if (specificDate && (specificDate instanceof Date)) {
            timeValue = specificDate;
            withSpecificDate = true;
        } else if (specificDate) {
            timeValue = new Date(specificDate);
            withSpecificDate = true;
        } else {
            timeValue = new Date();
        }

        let urlWms = ChoiceProvidersSatellite(sourceProvider, true);
        let optionsWms = {};
        if (withSpecificDate) {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 100, //100% pour être sûre d'avoir la carte à la date demandée !
                // showLogo: false,
                layers: "INDICE-DE-VEGETATION,DATE", // ⚠️- DATE permet l'ajout de la date dans les différents clichés
                //time:"2017-03-28/2018-05-09",
                time: format(timeValue, 'yyyy-MM-dd'),
                format: 'image/png',
                transparent: false,
                showDates: true
            };
        } else {
            optionsWms = {
                tileSize: 512,
                attribution: null,
                maxcc: 80,
                // showLogo: false,
                layers: "INDICE-DE-VEGETATION,DATE", // ⚠️- DATE permet l'ajout de la date dans les différents clichés
                //time:"2017-03-28/2018-05-09",
                format: 'image/png',
                transparent: false,
                showDates: true
            };
        }

        this.satelliteProviderOverlayMaps = L.tileLayer.wms(urlWms, optionsWms);

        // event trigger
        this.satelliteProviderOverlayMaps.on('add', () => {
            // en rapport avec le message de chargement depuis le composant MapInfo
            if (this.props.loadingGlobalLayer) {
                this.props.loadingGlobalLayer(); // => StringTranslate.indvegecharge
            }

            this.satelliteProviderOverlayMaps.on('load', () => {
                if (this.props.globalLayerLoaded) {
                    this.props.globalLayerLoaded();
                }

                this.satelliteProviderOverlayMaps.off('load'); // on retire le listener à la fin du premier appel à la fonction
            });
        });

        this.satelliteProviderOverlayMaps.on('remove', () => { /*RAS*/ }); //@@a voir si besoin d'y faire quelque chose...
    }

    /**
     * fonction permettant d'ajouter l'image d'une parcelle suivant l'id de cette parcelle et les données de satImage
     */
    addNewParcelImageToMap(parcelId) {
        if (parcelId <= 0) return;

        // 1- récupération de la parcelle
        const { parcelDico, satimageByParcelDico } = this.props;
        let parcel = parcelDico[parcelId];

        //↓↓ 2- je récupère l'image associée ↓↓ 
        const satimageId = parcel.currentSatimageId;
        const layerType = (parcel.currentLayerType) ? parcel.currentLayerType : ConstantsLayers.NdviParcelLayerName;
        let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType);

        //↓↓ 3- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
        if (imageURL) {
            /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
            let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
            // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
            //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
            const so = L.latLng(parcel.ymin, parcel.xmin);
            const ne = L.latLng(parcel.ymax, parcel.xmax);
            const bounds = L.latLngBounds(so, ne);
            const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
            if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
            if (imageLayer) {
                this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                this.removeCurrentParcelImageFromMap(parcelId); // si un layer image était déjà présent, on l'enlève
                this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
            }
        }
    }

    /**
     * fonction permettant d'ajouter le contour d'une parcelle suivant l'id de cette parcelle
     */
    addNewParcelPolygonToMap(parcelId) {
        if (parcelId <= 0) return;
        const { parcelDico, satimageByParcelDico } = this.props;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        let isLayerOnMap = (this.parcelPolygonLayerDico[parcelId] && this.parcelPolygonLayerGroup.hasLayer(this.parcelPolygonLayerDico[parcelId]));

        if (!isLayerOnMap) {
            // - récupération de la parcelle
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);

            // 1- convertion d'une parcelle vers une entité geojson
            let geojson = (parcel) ? converter.databaseParcelTogeojson(parcel) : null;

            // 2- création d'un layer
            let layer = (geojson) ? L.geoJson(geojson) : null;

            // 3- customisation du layer avant insertion dans la carte
            if (layer) {
                // layer.setStyle({ className: 'rm-parcel-layer-loading' }); //ajout du style qui donne un effet zoom...
                layer.setStyle(stylePolygon.fill_off); //ajout du style qui donne un effet zoom...

                // ↓ Cablage événement ↓
                layer.on('click', (e) => {
                    let parcelId = lodashGet(e, 'layer.feature.properties.pz_id', -1);
                    if (parcelId > 0) {
                        
                        // ↓ sélection de la parcelle cliquée si la parcelle n'est pas déjà sélectionnée ↓
                        if (parcelId !== this.props.parcelIdSelected)
                            this.props.goToMapAndSelectParcel(parcelId);

                        // ↓↓ CAS: SI NOUVELLE IMAGE (couleur fushia) en cours, on la remet en simple sélection (couleur rouge) ↓↓
                        const selectedLayer = this.parcelPolygonLayerDico[parcelId]; // ajout dans la liste représentant le contexte du composant
                        if (selectedLayer) selectedLayer.setStyle(stylePolygon.selectionContour);
                        SatimageHelper.setIsNewSatimage(this.props.parcelDico, satimageByParcelDico, parcelId, false);// ⚠️- this.props.parcelDico au lieu de parcelDico car principe d'immutabilité en cours
                    }
                });

                // ↓ Cablage événement ↓
                layer.on('tooltipopen', (e) => {
                    const { forewardLayerSelected } = this.props;
                    if ((forewardLayerSelected === ConstantsLayers.NdviGlobalLayerName) || (forewardLayerSelected === ConstantsLayers.VisibleGlobalLayerName)) // non affichage de la tooltip dans ce cas d'un layer globale
                        if (e && e.target) e.target.closeTooltip();

                    // e.target.openTooltip(L.latLngs);
                    setTimeout(() => (e && e.target) ? e.target.closeTooltip() : null, 1500); // fermeture de la tooltip au bout d'une 1,5 secondes
                });

                // ↓ ajout Tooltip lors du survol sur cette parcelle ↓
                layer.bindTooltip(this.getParcelPolygonTooltip(parcelId), { className: 'rm-parcelPolygon-tooltip', sticky: true });

                // 4- ajout du layer dans le groupe de layer
                this.parcelPolygonLayerGroup.addLayer(layer);

                // 5- ajout dans un dico référençant les layers par parcelId
                this.parcelPolygonLayerDico[parcelId] = layer;
            }
        };
    }

    /**
     * Fonction permetttant de renvoyer un contenu HTML (tooltip) affichant la date de l'image en cours de survol
     */
    getParcelPolygonTooltip = (parcelId) => (layer) => {
        let parcel = ParcelsHelper.selectParcelFromDicoById(this.props.parcelDico, parcelId);
        let currentSatimageDate = (parcel.currentSatimageDate) ? new Date(parcel.currentSatimageDate) : null;
        let date = (currentSatimageDate instanceof Date) ? DateHelper.formati18n(currentSatimageDate, 'P') : '';
        return date;
    }

    /**
     * fonction permettant d'ajouter une observation dans la carte suivant l'id Parcelle attaché - ⚠️- pour l'instant on ne prend en compte que les layers déjà créés/initialisés
     */
    addObservationLayerFromMap(parcelId) {
        const { observationIdListByParcelIdDico } = this.props;

        if (Array.isArray(observationIdListByParcelIdDico[parcelId]) && (observationIdListByParcelIdDico[parcelId].length > 0)) {
            observationIdListByParcelIdDico[parcelId].forEach(observationId => {
                // - ↓ le layer existe-t-il ? et est-il déjà affiché sur la carte ? ↓
                let layer = this.observationLayerDico[observationId];
                if (layer && !this.observationLayerGroup.hasLayer(layer)) {
                    this.observationLayerGroup.addLayer(layer);
                }
            });
        }
    }

    /**
     * fonction permettant de supprimer une parcelle de la carte (polygone + image)
     */
    removeParcelPolygonFromMap(parcelId) {
        if (parcelId <= 0) return;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        const parcelLayerSelected = this.parcelPolygonLayerDico[parcelId];
        let isLayerOnMap = (parcelLayerSelected && this.parcelPolygonLayerGroup.hasLayer(parcelLayerSelected));

        if (isLayerOnMap) {
            // - si présent alors on le retire
            this.parcelPolygonLayerGroup.removeLayer(parcelLayerSelected);
            delete this.parcelPolygonLayerDico[parcelId];
        }
    }

    /**
     * fonction permettant de supprimer le layer image d'une parcelle affiché sur la carte
     */
    removeCurrentParcelImageFromMap(parcelId) {
        if (parcelId <= 0) return;

        // - un layer en lien avec le parcelId est il déjà présent sur la carte ?
        const parcelLayerSelected = this.parcelImageLayerDico[parcelId];
        let isLayerOnMap = (parcelLayerSelected && this.parcelImageLayerGroup.hasLayer(parcelLayerSelected));

        if (isLayerOnMap) {
            // - si présent alors on le retire
            this.parcelImageLayerGroup.removeLayer(parcelLayerSelected);
            delete this.parcelImageLayerDico[parcelId];
        }
    }

    /**
     * fonction permettant de supprimer une observation dans la carte suivant l'id Parcelle attaché - ⚠️- pour l'instant on ne prend en compte que les layers déjà créés/initialisés
     */
    removeObservationLayerFromMap(parcelId) {
        const { observationIdListByParcelIdDico } = this.props;

        if (Array.isArray(observationIdListByParcelIdDico[parcelId]) && (observationIdListByParcelIdDico[parcelId].length > 0)) {
            observationIdListByParcelIdDico[parcelId].forEach(observationId => {
                // - ↓ le layer existe-t-il ? et est-il déjà affiché sur la carte ? ↓
                let layer = this.observationLayerDico[observationId];
                if (layer && this.observationLayerGroup.hasLayer(layer)) {
                    this.observationLayerGroup.removeLayer(layer);
                }
            });
        }
    }

    /**
     * A commenter
     */
    updateImageForParcel(parcelId, idOfImag, dateOfImag, layerType, removeGlobalLayers = true) {
        const { satimageByParcelDico, parcelDico, } = this.props;
        if (!satimageByParcelDico) return;

        // Choix de la couche (Visible ou Ndvi):
        let newLayertype = layerType; //couche demandée OU celle définit actuellement pour la parcelle...
        if (!layerType) {
            newLayertype = lodashGet(parcelDico, `[${parcelId}].currentLayerType`, ConstantsLayers.NdviParcelLayerName);
        }

        //↓↓ 2- je récupère l'image associée (suivant la date) ↓↓ 
        let satimage = undefined;
        if (idOfImag && (idOfImag > 0)) { //id d'image spécifié !
            satimage = lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico[${idOfImag}]`, null);
        } else if (dateOfImag) { //date demandée:
            let dateInstance = dateOfImag;
            if (!(dateOfImag instanceof Date)) {
                dateInstance = new Date(dateOfImag);
            }
            const satimagesDico = lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico`, null);
            satimage = SatimageHelper.getSatimageFromListByDate(satimagesDico, dateInstance);
        } else { //sinon, la plus récente:
            const satimagesDico = lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico`, null);
            satimage = SatimageHelper.searchMostRecentSatimageFromList(satimagesDico);
        }
        let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, (satimage) ? satimage.id : -1, newLayertype);

        //↓↓ 3- on vérifie que le groupe de layers d'image est ajouté, sinon on l'ajoute ↓↓                
        if (this.parcelImageLayerGroup && (!this.map.hasLayer(this.parcelImageLayerGroup))) this.map.addLayer(this.parcelImageLayerGroup);
        //↓↓ 3(bis)- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
        if (imageURL) {
            if (removeGlobalLayers) {
                //vire les vues globales (Visible et Ndvi):
                if (this.satelliteOverlayMaps && this.map.hasLayer(this.satelliteOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                    this.map.removeLayer(this.satelliteOverlayMaps);
                }
                if (this.satelliteProviderOverlayMaps && this.map.hasLayer(this.satelliteProviderOverlayMaps)) { // si existant et en plus ajouté à la carte alors on le retire
                    this.map.removeLayer(this.satelliteProviderOverlayMaps);
                }
            }

            /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
            let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
            // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
            let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
            //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
            const so = L.latLng(parcel.ymin, parcel.xmin);
            const ne = L.latLng(parcel.ymax, parcel.xmax);
            const bounds = L.latLngBounds(so, ne);
            const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
            if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);

            const oldLayerImage = this.parcelImageLayerDico[parcelId];
            if (oldLayerImage && this.parcelImageLayerGroup.hasLayer(oldLayerImage)) this.parcelImageLayerGroup.removeLayer(oldLayerImage); // retrait, au sein groupe de layer d'images, de l'ancienne image

            if (imageLayer) {
                this.parcelImageLayerGroup.addLayer(imageLayer); // ajout, dans le groupe de layer d'images, de la nouvelle image
                this.parcelImageLayerDico[parcelId] = imageLayer; // ajout dans la liste représentant le contexte du composant
            }
        } //else 'image' peut valloir null si on lui demande d'afficher une vue Global !
    }

    /**
     * fonction permettant d'ajouter des observations (sous forme de layer) sur la carte - ⚠️ layer en concurrence (en terme de z-index) avec les layers polygon
     */
    addObservationsToMap() {
        const { observationDico } = this.props;


        if (observationDico) {

            // ↓ pour chaque observation contenue dans la liste ↓
            Object.values(observationDico).forEach(observation => {
                // ↓ on s'assure qu'elle n'est pas déjà présente sous forme de layer sur la carte ↓
                if (!this.observationLayerDico[observation.id]) {
                    this.createLayerOnMap(observation);
                }
            });
        }
    }

    deleteLayerFromMap = (layer) => {
        if (layer && this.map.hasLayer(layer)) {
            this.map.removeLayer(layer);
            this.observationLayerGroup.removeLayer(layer);
        }
    }

    createLayerOnMap = (observation) => {
        try {
            // - on récupère l'objet feature
            let geojsonFeature = JSON.parse(observation.featureStr4326); // on récupère un objet feature
            if (!geojsonFeature) return;

            // - récupération du type de feature (Point, Polygon, ...)
            let featureType = (geojsonFeature && geojsonFeature.geometry && geojsonFeature.geometry.type) ? geojsonFeature.geometry.type : null;

            // - création d'un layer suivant sa géométrie
            let layer = null;
            let pinDropLayerArea = observation.superficieHa;
            let layerType = "";
            let linkedParcelList = [{ parcelId: 0, parcelName: `${StringTranslate.noparcelname}`, area: 0 }];
            if (featureType === "Polygon") {

                layerType = "polygon";

                layer = L.geoJson(geojsonFeature, { bubblingMouseEvents: false });
                if (!layer) return;

                // ↓ Association => la zone déssinée se trouve t elle sur une ou plusieurs parcelles ? ↓
                let parcelPolygonLayerArray = this.parcelPolygonLayerGroup.getLayers();
                for (let index = 0; index < parcelPolygonLayerArray.length; index++) {
                    let linkedParcel = ParcelsHelper.getDatasFrom2PolygonsIntersection(layer, parcelPolygonLayerArray[index]);
                    if (linkedParcel) linkedParcelList.push(linkedParcel); // linkedParcel => { ...parcelInfos }
                }
                linkedParcelList.sort((a, b) => (b.area - a.area)); // tri => l'aire la plus grande en premier => en lien avec la présentation

                // ↓ customisation du layer ↓
                layer.setStyle({ className: ObservationsHelper.getObservationAreaClassFromObservationTypeId(observation.observationType) });
            }
            else if (featureType === "Point") {

                layerType = "marker";
                layer = L.geoJson(geojsonFeature, {
                    bubblingMouseEvents: false,
                    // ↓ et modification de sa mise en forme ↓
                    pointToLayer: function (geoJsonPoint, latlng) {
                        let customLeafletIcon = ObservationsHelper.getLeafletIconFromObservationTypeId(observation.observationType);
                        return L.marker(latlng, { icon: customLeafletIcon });
                    }
                })
                if (!layer) return;

                // ↓ Association => le point déssinée= se trouve t il sur une parcelles ? ↓
                let parcelPolygonLayerArray = this.parcelPolygonLayerGroup.getLayers();
                for (let index = 0; index < parcelPolygonLayerArray.length; index++) {
                    let linkedParcel = ParcelsHelper.pointsWithinPolygon(layer, parcelPolygonLayerArray[index]);
                    if (linkedParcel) linkedParcelList.unshift(linkedParcel); // linkedParcel => { ...parcelInfos }
                }

            }
            else { /* RAS */ }

            // ↓ attachement d'une popup d'informations ↓
            let popup = L.DomUtil.create('div', 'pdp-info-container');
            const root = createRoot(popup); // createRoot(container!) if you use TypeScript

            const onClick = () => {
                if (window.innerWidth > 600) {
                    //gestion du formulaire sur pc
                    if (layer.isPopupOpen()) {
                        layer.off({ popupclose: onClick }); // unbind l'event pour eviter la boucle
                        // ferme puis désassocie la popup
                        layer.closePopup();
                        layer.unbindPopup();
                        
                        if (root) {
                            root.render(<></>); // désassocie le formulaire à la popup (permet la suppression du composant)
                        }
                    } else {
                        // associe le formulaire à la popup (permet la création du composant au click)
                        if (root) {
                            root.render(
                                <ThemeProvider theme={theme}>
                                    <Provider store={storeManager.store} /*obligatoire sinon pas de connexion au store*/ >
                                        <PinDropFormPopup
                                            observationId={observation.id}
                                            pindropLayer={layer}
                                            pinDropLayerArea={pinDropLayerArea}
                                            layerType={layerType}
                                            linkedParcelList={linkedParcelList}
                                            deleteLayerFromMap={this.deleteLayerFromMap}
                                            createLayerOnMap={this.createLayerOnMap}
                                        />
                                    </Provider>
                                </ThemeProvider>);
                        }
                        layer.bindPopup(popup);
                        layer.openPopup();
                        layer.on({ popupclose: onClick });
                    }
                } else {
                    //gestion du formulaire pour les mobiles
                    this.controlFormDialog({
                        observationId: observation.id,
                        pindropLayer: layer,
                        pinDropLayerArea: pinDropLayerArea,
                        layerType: layerType,
                        linkedParcelList: linkedParcelList,
                        deleteLayerFromMap: this.deleteLayerFromMap,
                        createLayerOnMap: this.createLayerOnMap,
                    });
                }

            }

            //  - ajout d'un evenement au layer
            layer.on({ click: onClick });

            // - ajout du layer dans le groupe de layer
            this.observationLayerGroup.addLayer(layer);

            // - ajout dans un dico référençant les layers par observation.id
            this.observationLayerDico[observation.id] = layer;

        } catch (error) { }
    }

    // fonction permettant l'ouverture ou la fermeture du formulaire d'observation pour mobile
    controlFormDialog = (dataFormDialog) => {
        const { openPinDropFormDialog, closePinDropFormDialog, pinDropFormDialogOpened } = this.props;
        if (pinDropFormDialogOpened) {
            closePinDropFormDialog();
        } else {
            openPinDropFormDialog(dataFormDialog);
        }
    }

    /**
     * fonction cycle de vie 
     */
    componentDidUpdate(prevProps, prevState) {
        /* Les drawer (mui) sur la carte font des flashs blancs lors des ouvertures ou fermetures.
        Pour pallier à ce problème, on réduit le maxZoom à 15
        if (window.innerWidth < 900) {
            if (this.DRAWINGPARCEL)
                this.map.options.maxZoom = 18;
            else
                this.map.options.maxZoom = 15;
        } */
        // Actualise le zoom:
        this.map.options.maxZoom = this.getMaxZoom();

        const { parcelDico, parcelIdFilteredList, parcelIdFilteredListCounter, lastAction, satimageByParcelDico,
            parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, baseLayerSelected, forewardLayerSelected, fitMapToBounds, isPanelOpen } = this.props;

        //↓↓ CAS - AJOUT NOUVELLES PARCELLES (Import, récupération asynchrone des parcelles) ↓↓
        if (((!prevProps.parcelIdFilteredList) || (prevProps.parcelIdFilteredListCounter <= 0)) && (parcelIdFilteredList && (parcelIdFilteredListCounter > 0))) {
            this.addFilteredParcelsToMap();
        }

        //↓↓ CAS - MODIFICATION LISTE DES PARCELLES  (suite à un ajout, suppression, filtre, ...) ↓↓
        if (prevProps.parcelIdFilteredList !== parcelIdFilteredList) {

            let differenceListToDelete = lodashDifference(prevProps.parcelIdFilteredList, parcelIdFilteredList); //⚠️- SUPPRESSION - Pour tous parcelId non présents dans la nouvelle liste de parcelId
            differenceListToDelete.forEach(parcelId => {
                this.removeParcelPolygonFromMap(parcelId);
                this.removeCurrentParcelImageFromMap(parcelId);
                this.removeObservationLayerFromMap(parcelId);
            });

            let differenceListToAdd = lodashDifference(parcelIdFilteredList, prevProps.parcelIdFilteredList);//⚠️- AJOUT - Pour tous parcelId non présents dans l'ancienne liste de parcelId
            differenceListToAdd.forEach(parcelId => {
                this.addNewParcelPolygonToMap(parcelId);
                this.addNewParcelImageToMap(parcelId);
                this.addObservationLayerFromMap(parcelId);
            });

            // - on recentre la carte sur le parcellaire modifié (AllParcel = les parcelles actuellement visibles sur la carte)
            if (!this.DRAWINGPARCEL) // ← CAS - un dessin est il en cours ? si NON, on peut zoomer sur le parcellaire
                this.zoomToAllParcel();
        }

        //↓↓ CAS - OPERATIONS faites sur les différents Reducer ↓↓
        if ((prevProps.lastAction !== lastAction) && lastAction) { // pour toute nouvelle action
            if (lastAction.reducer === ReducerType.PARCEL) { // reducer parcels
                if (lastAction.type === ChangeType.ADD) { // type ajout
                    //↓↓ 1-je récupère l'image suivant le type demandé (ndvi, visible) ↓↓ 
                    const satimageId = lastAction.params.satimageId;
                    const parcelId = lastAction.params.parcelId;
                    const layerType = lastAction.params.layerType;
                    let imageURL = SatimageHelper.getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType);

                    let parcel = undefined;

                    //↓↓ 2- on vérifie que le la parcelle fait partie de notre liste filtrée ↓↓ 
                    if (parcelId && parcelIdFilteredList && parcelIdFilteredList.includes(parcelId)) {
                        //↓↓ 3- on vérifie que le groupe de layers d'image est ajouté, sinon on l'ajoute ↓↓ 
                        if ((forewardLayerSelected) && (
                            (forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) &&
                            this.parcelImageLayerGroup && (!this.map.hasLayer(this.parcelImageLayerGroup))) this.map.addLayer(this.parcelImageLayerGroup);
                        //↓↓ 3(bis)- je l'affiche sur la carte (si une image est déjà présente je la supprime) ↓↓
                        if (imageURL) {
                            /*const parcelLayer = this.parcelPolygonLayerDico[parcelId]; // récupération du layer parcelle
                            let bounds = (parcelLayer) ? parcelLayer.getBounds() : null; // pour récupération du bound */ //Depuis la mise en place d'une BBox carré, on ne se base plus sur la BBox déterminée par Leaflet !
                            // - récupération de la parcelle, puis définition de la bounds à partir de la BBox:
                            parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);
                            //const bounds = { _northEast: { lat:parcel.ymax, lng:parcel.xmax, }, _southWest: { lat:parcel.ymin, lng:parcel.xmin, }, }; //l'objet est plus complexe...
                            const so = L.latLng(parcel.ymin, parcel.xmin);
                            const ne = L.latLng(parcel.ymax, parcel.xmax);
                            const bounds = L.latLngBounds(so, ne);
                            const imageLayer = (bounds && bounds.isValid()) ? L.imageOverlay(imageURL, bounds) : console.log(`bounds are not valid. ParcelId ${parcelId}`); // création layer image
                            if (bounds && !bounds.isValid()) sendError('errorBoundingBox', `Problème de bounding box avant affichage sur la carte. ParcelId ${parcelId}, client: ${(this.props.clientDatas) ? this.props.clientDatas.id : null}`);
                            if (imageLayer) {
                                this.parcelImageLayerGroup.addLayer(imageLayer); // ajout dans le groupe de layer d'images
                                this.removeCurrentParcelImageFromMap(parcelId); // si un layer image était déjà présent, on l'enlève
                                this.parcelImageLayerDico[parcelId] = imageLayer; // ajout de la nouvelle image dans la liste représentant le contexte du composant
                            }
                        }
                    }

                    //↓↓ 4- CAS: est-ce une NOUVELLE IMAGE que l'utilisateur n'a pas encore été eue/vue ?  ↓↓
                    const isNewSatimage = SatimageHelper.isNewSatimage(satimageByParcelDico, parcelId, satimageId);
                    if (isNewSatimage) {
                        const layer = this.parcelPolygonLayerDico[parcelId]; // un layer est il associé ?
                        // A la création d'une parcelle, l'image la plus proche du jour actuel est forcément vue
                        if (layer && ((parcel.lastSeen === undefined) || (parcel.lastSeen === null) || (parcel.lastSeen !== null && DateHelper.getDateFromString(parcel.lastSeen) < parcel.firstSatimageDate))) layer.setStyle(stylePolygon.newContour);
                        SatimageHelper.setIsNewSatimage(parcelDico, this.props.satimageByParcelDico, parcelId, false); // on remet la valeur a false
                        this.props.setValueNewImageShowedOnMap(true); // on met à jour la valeur qui exprime le fait qu'il y a des nouvelles images en cours de visualisation sur la carte (Mapinfo va lire cette valeur)
                    }
                }
            }
        }

        //↓↓ CAS - CHANGEMENT de parcelle sélectionnée ↓↓
        if (prevProps.parcelIdSelected !== parcelIdSelected) {
            // Soit SELECTION (dit 'vue parcelle') ; Soit PAS DE SELECTION (dit 'vue parcellaire')

            if (parcelIdSelected > 0) { //on passe de la vue parcellaire à la vue d'une parcelle OU changement de parcelle sélectionnée !
                this.selectParcel(parcelIdSelected, prevProps.parcelIdSelected);
                if (isPanelOpen === false) this.props.setDisplaySwipeablePanelForDesktop(true); //Réouvre le panneau flottant lors du changement de parcelle s'il était fermé
                this.zoomToParcel(parcelIdSelected);
            } else { //on passe de la vue d'une parcelle à la vue parcellaire !
                this.unselectParcel(prevProps.parcelIdSelected); // on déselectionne l'ancienne parcelle si sélectionnée
                if (!this.DRAWINGPARCEL) {// ← CAS - un dessin est il en cours ? si NON, on peut zoomer sur le parcellaire
                    if (this.props.parcelIdZoomed <= 0) { //Si on veut désélectionner la parcelle mais rester zommé dessus
                        this.zoomToAllParcel(); // on zoome sur le parcellaire
                    }
                }
            }
        }

        //↓↓ CAS - CHANGEMENT d'image sur la parcelle couramment sélectionnée ↓↓
        if (parcelIdSelected && (parcelIdSelected > 0) && (
            (prevProps.satimageIdSelectedOfParcel !== satimageIdSelectedOfParcel) || (prevProps.satimageDateSelectedOfParcel !== satimageDateSelectedOfParcel))) {
            // Soit on est sur un layer 'à la parcelle' => on actualise l'image Visible ou Ndvi sur le dessin de celle-ci:
            // Soit on est sur une vue 'globale' => on actualise la date de la carte entière en respectant la date du cliché de cette parcelle:
            if ((forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {
                // utilise l'id et la date de l'image sélectionnée, MAISle layer défini sur cette parcelle (pas le layer défini sur le panneau des layers)
                this.updateImageForParcel(parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, undefined);
            } else {
                this.updateGlobalLayer(forewardLayerSelected, satimageDateSelectedOfParcel);
            }
        }
        //else //On ne peut pas changer de date si on n'a pas une parcelle de sélectionnée !

        //↓↓ CAS - CHANGEMENT LAYER FOND DE CARTE ↓↓
        if (prevProps.baseLayerSelected !== baseLayerSelected) {
            this.addBaseLayerOnMap()
        }

        //↓↓ CAS - CHANGEMENT LAYER visuel... ↓↓
        if (prevProps.forewardLayerSelected !== forewardLayerSelected) {
            // Soit on est sur un layer 'à la parcelle' => 
            //   cas1 - une parcelle est sélectionnée: on actualise l'image Visible ou Ndvi sur le dessin de celle-ci (cette parcelle).
            //   cas2 - aucune parcelle sélectionnée: on force la dernière image Ndvi sur chaque parcelle.
            if ((forewardLayerSelected === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerSelected === ConstantsLayers.NdviParcelLayerName)) {
                if (parcelIdSelected && (parcelIdSelected > 0)) {
                    // utilise l'id et la date de l'image sélectionnée, ET on peut utiliser le layer défini car = à celle sur la parcelle
                    this.updateImageForParcel(parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, forewardLayerSelected);
                } else {
                    this.applyLastDateAndNdviLayerForAllParcels();
                }
            }
            // Soit on est sur une vue 'globale' => on actualise la date de la carte entière en utilisant la date  
            //   cas1 - une parcelle est sélectionnée: date de l'image actuellement sélectionnée sur cette parcelle.
            //   cas2 - aucune parcelle sélectionnée: date du jour. 
            else { //qu'il y ai une parcelle de sélectionnée ou pas !
                if (parcelIdSelected && (parcelIdSelected > 0)) {
                    this.applyGlobalLayer(forewardLayerSelected, satimageDateSelectedOfParcel);
                } else {
                    this.applyGlobalLayer(forewardLayerSelected);
                }
            }
        }

        //↓↓ CAS - EVENEMENT BOUTON - RECENTRAGE CARTE PARCELLE/PARECLLAIRE ↓↓
        if (prevProps.fitMapToBounds !== fitMapToBounds) {
            (parcelIdSelected > 0) ? this.zoomToParcel(parcelIdSelected) : this.zoomToAllParcel();
        }

        //↓↓ CAS - AJOUT DES OBSERVATIONS ↓↓
        // tant que la popup de création est ouverte on n'ajoute pas l'observation à la map
        if (((prevProps.lastObservationIdSaved !== this.props.lastObservationIdSaved) && (this.props.lastObservationIdSaved === undefined))) {
            this.addObservationsToMap();
        }

        //↓↓ CAS - AFFICHAGE OU NON DE L'ENSEMBLE DES OBSERVATIONS ↓↓
        if (prevProps.observationShowAll !== this.props.observationShowAll) {
            if (this.props.observationShowAll) {
                if (this.observationLayerGroup && (!this.map.hasLayer(this.observationLayerGroup))) this.map.addLayer(this.observationLayerGroup);
            }
            else {
                if (this.observationLayerGroup && this.map.hasLayer(this.observationLayerGroup)) this.map.removeLayer(this.observationLayerGroup);
            }
        }

        //↓↓ CAS - OUVERTURE OU FERMETURE DU PANNEAU FLOTTANT ↓↓
        //Recentre la parcelle correctement si on ouvre ou ferme le panneau
        if(prevProps.isPanelOpen !== isPanelOpen){
            if (parcelIdSelected > 0) {
                this.zoomToParcel(parcelIdSelected)   
            }
        }
    }

    /* Ferme le dialog indiquant la non-sélection d'une parcelle pour l'accès aux couches globales */
    closeMessageDialog = () => {
        if (this.props.closeMessageOfNoOpenGlobalLayer) {
            this.props.closeMessageOfNoOpenGlobalLayer();
        }
    }

    /**
     * fonction cycle de vie 
     */
    render() {
        const { mapIsReady } = this.state;
        const { openDialogOfNoOpenGlobalLayer, isOpenMenuBurgerDesktop } = this.props;

        return (
            <React.Fragment>
                <Box className="contentAbstractMap"
                    sx={{
                        [theme.breakpoints.up('md')]: { 
                            left: isOpenMenuBurgerDesktop ? ConstantsContent.DrawerWidth : ConstantsContent.MarginCard,
                            width: isOpenMenuBurgerDesktop ? `calc(100% - ${ConstantsContent.DrawerWidth + ConstantsContent.MarginCard}px)` : `calc(100% - ${ConstantsContent.MarginCard * 2}px)`
                        }
                    }}><div className="mapMain" id={this.mapId}></div></Box>

                {/*<div className="actionsOverTheMap">*/}
                {(mapIsReady) && <MapButtonsPane renderMapsCmp={this} />}

                {/* Dialog des messages d'erreur */}
                {(mapIsReady) && (openDialogOfNoOpenGlobalLayer === true) && (
                    <AlertDialog popup={this.popupMessageDialog} handleChangePopup={this.closeMessageDialog} />
                )}
                {/*</div>*/}
            </React.Fragment>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        clientDatas: lodashGet(state, 'clientUserData.clientDatas', undefined),

        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        lastAction: lodashGet(state, 'parcelsData.lastParcelDicoAction', null),
        parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', []),
        parcelIdFilteredListCounter: lodashGet(state, 'parcelsData.parcelIdFilteredListCounter', 0),

        satimageByParcelDico: lodashGet(state, 'satimageData.satimagesByParcelDico', {}),

        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        satimageIdSelectedOfParcel: lodashGet(state, 'contextAppData.satimageIdSelectedOfParcel', undefined),
        satimageDateSelectedOfParcel: lodashGet(state, 'contextAppData.satimageDateSelectedOfParcel', undefined),
        observationIdSelected: lodashGet(state, 'contextAppData.observationIdSelected', -1),
        showInviteToPremium: lodashGet(state, 'contextAppData.showInviteToPremium', false),
        parcelIdZoomed: lodashGet(state, 'contextAppData.parcelIdZoomed', -1),
        isPanelOpen: lodashGet(state, 'contextAppData.displaySwipeablePanelForDesktop', true),

        baseLayerSelected: lodashGet(state, 'settingsData.baseLayerSelected', ConstantsLayers.VisibleBaseLayerName),
        forewardLayerSelected: lodashGet(state, 'settingsData.forewardLayerSelected', ConstantsLayers.NdviParcelLayerName),
        codeCountry: lodashGet(state, 'settingsData.settings.codeCountry', 'FR'),

        fitMapToBounds: lodashGet(state, 'contextAppData.fitMapToBounds', null),
        openDialogOfNoOpenGlobalLayer: lodashGet(state, 'contextAppData.openDialogOfNoOpenGlobalLayer', false),
        isOpenMenuBurgerDesktop: lodashGet(state, 'contextAppData.isOpenMenuBurgerDesktop', false),

        observationDico: lodashGet(state, 'observationsData.observationDico', {}),
        observationIdListByParcelIdDico: lodashGet(state, 'observationsData.observationIdListByParcelIdDico', []),
        observationShowAll: lodashGet(state, 'observationsData.showAll', true),
        lastObservationIdSaved: lodashGet(state, 'observationsData.lastObservationIdSaved', undefined),

        pinDropFormDialogOpened: lodashGet(state, 'contextAppData.pinDropFormDialogOpened', false), // état de la fenêtre de dialogue utilisée dans la gestion d'observation pour mobile
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    goToMapAndSelectObservation: (observationId) => dispatch(ActionGoToMapAndSelectObservation(observationId)),
    loadingGlobalLayer: () => dispatch(ActionLoadingGlobalLayer()),
    globalLayerLoaded: () => dispatch(ActionGlobalLayerLoaded()),
    setValueNewImageShowedOnMap: (bool) => dispatch(ActionSetValueNewImageShowedOnMap(bool)),
    closeMessageOfNoOpenGlobalLayer: () => dispatch(ActionCloseMessageOfNoOpenGlobalLayer()),
    setDisplaySwipeablePanelForDesktop: (mapPanelExpanded) => dispatch(ActionSetDisplaySwipeablePanelForDesktop(mapPanelExpanded)),

    // gestion de la fenetre de dialogue utilisée dans la gestion d'observation pour mobile
    openPinDropFormDialog: (obj) => dispatch(ActionOpenPinDropFormDialog(obj)),
    closePinDropFormDialog: () => dispatch(ActionClosePinDropFormDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderMaps);