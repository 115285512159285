import { get as lodashGet } from 'lodash';
import biomassByDepartment from "../datas/biomassByDepartment.json";
// import biomassDate from "../datas/biomassDate.json";
import biomassDate from "../datas/biomassDate.json";
import StringTranslate from '../assets/i18n/stringLanguage';

export const BiomassesHelper = {

    /**
     *  fonction permettant de calculer le nombre total de pesées de bioamsse colza se trouvant dans le dico
     */
    count(weightingBiomassDico) {
        if (!weightingBiomassDico) return 0;
        return Object.entries(weightingBiomassDico).length;
    },

    /* fonction permettant d'adapter l'item de pesée de biomasse colza reçu - le concept de normalisation des données est ici utilisé */
    convertItem(weightingBiomassItem) {
        if (!weightingBiomassItem) {
            return {};
        }

        //transforme la date (caîne en entité 'Date'):
        weightingBiomassItem.creationDate = (weightingBiomassItem.creationDate && (!isNaN(Date.parse(weightingBiomassItem.creationDate)))) ?
            new Date(weightingBiomassItem.creationDate) : new Date();
        weightingBiomassItem.DateImagSource = (weightingBiomassItem.DateImagSource && (!isNaN(Date.parse(weightingBiomassItem.DateImagSource)))) ?
            new Date(weightingBiomassItem.DateImagSource) : undefined;
        weightingBiomassItem.imagSourceProvider = (weightingBiomassItem.imagSourceProvider && (!isNaN(Date.parse(weightingBiomassItem.imagSourceProvider)))) ?
            new Date(weightingBiomassItem.imagSourceProvider) : new Date();
     
        return weightingBiomassItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(weightingBiomassList) {
        if ((!weightingBiomassList) || (!Array.isArray(weightingBiomassList))) {
            return {};
        }

        let dicoWeightingBiomass = {};
        weightingBiomassList.forEach(weightingBiomassItem => {
            dicoWeightingBiomass[weightingBiomassItem.id] = BiomassesHelper.convertItem(weightingBiomassItem);
        });

        return dicoWeightingBiomass;
    },

    /* fonction permettant de lister les biomasses par parcel id */
    sortByParcel(biomassesList) {
        if (biomassesList !== undefined && biomassesList !== null) {
            biomassesList = biomassesList.sort((a, b) => (a.parcelId >= b.parcelId) ? 1 : -1);
        }
        return biomassesList;
    },

    /**
     * Fonction permettant de récupérer le code département d'une parcelle
     * @param {Parcel} parcel parcel pour laquelle on veut le code département
     * @returns {string} code du département 
     */
    getCodeDepartementByParcel(parcel) {
        const codeCommune = lodashGet(parcel, 'localisation.codeCommune', undefined);

        // Vérifie que le code commune est renseigné
        if (!codeCommune) return undefined;
        if (!(typeof codeCommune === 'string' || codeCommune instanceof String)) return undefined;

        // Récupère le code du département à partir du code commune
        const codeDpt = codeCommune.slice(0,2);

        return codeDpt;
    },

    /**
     * Fonction permettant de récupérer le message à afficher pour l'entrée d'hiver en fonction du département de la parcelle
     * @param {Parcel} parcel parcel pour laquelle on veut récupérer le message
     * @returns {string} Nom de propriété StringTranslate du message à afficher
     */
    getRangeDateMsgKeyByDepartementForBeginningWinter(parcel) {
        const codeDpt = this.getCodeDepartementByParcel(parcel);
        if (!codeDpt) return undefined;

        // Vérifie si on dispose d'information pour ce département
        if (!Object.keys(biomassByDepartment).includes(codeDpt)) return undefined;

        // Récupération du type de date pour l'entrée d'hiver de ce département
        const type = lodashGet(biomassByDepartment, `[${codeDpt}].entree`, undefined);
        
        // Vérification de la validiter de la donnée
        if ((!type) || !(typeof type === 'number' || type instanceof Number)) return undefined;

        return `biomassRangeDateBeginningWinterHelper${type}`;
    },

    /**
     * Fonction permettant de récupérer le message à afficher pour la sortie d'hiver en fonction du département de la parcelle
     * @param {Parcel} parcel parcel pour laquelle on veut récupérer le message
     * @returns {string} Nom de propriété StringTranslate du message à afficher
     */
    getRangeDateMsgKeyByDepartementForEndingWinter(parcel) {
        const codeDpt = this.getCodeDepartementByParcel(parcel)
        if (!codeDpt) return undefined;

        // Vérifie si on dispose d'information pour ce département
        if (!Object.keys(biomassByDepartment).includes(codeDpt)) return undefined;

        // Récupération du type de date pour la sortie d'hiver de ce département
        const type = lodashGet(biomassByDepartment, `[${codeDpt}].sortie`, undefined);
        
        // Vérification de la validiter de la donnée
        if ((!type) || !(typeof type === 'number' || type instanceof Number)) return undefined;

        return `biomassRangeDateEndingWinterHelper${type}`;
    },

    /**
     * Fonction permettant de récupérer l'interval d'entrée d'hiver correspondant à une parcelle
     * @param {Parcel} parcel parcelle pour laquelle on veut récuperer l'interval d'entré d'hiver
     * @returns {Object} l'interval de date
     */
    getRangeDateByParcelForBeginningWinter(parcel, limitDate) {
        //Récupération du département de la parcelle
        const codeDpt = this.getCodeDepartementByParcel(parcel);
        if (!codeDpt) return undefined;
        //Détermination du type de biomasse en fonction du département
        const type = lodashGet(biomassByDepartment, `[${codeDpt}].entree`);
        if (!type) return undefined;

        const today = new Date();
        const actualYear = today.getFullYear();

        //Détermination des intervalles en fonction du type de biomasse de la parcelle
        const begin = lodashGet(biomassDate, `winterBegin.[${type}].begin`);
        const end = lodashGet(biomassDate, `winterBegin.[${type}].end`);
        const beginExtra = lodashGet(biomassDate, `winterBegin.[${type}].begin_extra`);
        const endExtra = lodashGet(biomassDate, `winterBegin.[${type}].end_extra`);
        if ((!begin) || (!end) || (!beginExtra) || (!endExtra)) return undefined;

        const beginDate = new Date(actualYear, begin.month - 1, begin.day);
        const endDate = new Date(actualYear, end.month - 1, end.day);
        const beginExtraDate = new Date(actualYear, beginExtra.month - 1, beginExtra.day);
        const endExtraDate = new Date(actualYear, endExtra.month - 1, endExtra.day);
        /** si l'interval se trouve dans le futur, on charge celui de l'année précedente si on n'est pas dans l'intervalle, 
         * qu'on a dépassé l'intervalle où qu'on a dépassé la date limite pour charger l'année précedente */
        if ((today < beginExtraDate) && (today < limitDate)) {
            beginDate.setFullYear(actualYear - 1);
            endDate.setFullYear(actualYear - 1);
            beginExtraDate.setFullYear(actualYear - 1);
            endExtraDate.setFullYear(actualYear - 1);
        }

        return { beginDate, endDate, beginExtraDate, endExtraDate, isBeginningWinter: true, };
    },

    /**
     * Fonction permettant de récupérer l'interval de sortie d'hiver correspondant à une parcelle
     * @param {Parcel} parcel parcelle pour laquelle on veut récuperer l'interval de sortie d'hiver
     * @param {String} beginWinterImgDate optionnel - date de l'image choisie en entrée d'hiver pour la parcelle
     * @returns {Object} l'interval de date
     */
    getRangeDateByParcelForEndingWinter(parcel, beginWinterImgDate = undefined) {
        const codeDpt = this.getCodeDepartementByParcel(parcel);
        if (!codeDpt) return undefined;
        const type = lodashGet(biomassByDepartment, `[${codeDpt}].sortie`);
        if (!type) return undefined;

        const biomassBeginDate = new Date(beginWinterImgDate);
        let year = new Date().getFullYear();
        if (beginWinterImgDate !== undefined) {
            year = biomassBeginDate.getFullYear() + 1;   
        }

        const begin = lodashGet(biomassDate, `winterEnd.[${type}].begin`);
        const end = lodashGet(biomassDate, `winterEnd.[${type}].end`);
        const beginExtra = lodashGet(biomassDate, `winterEnd.[${type}].begin_extra`);
        const endExtra = lodashGet(biomassDate, `winterEnd.[${type}].end_extra`);
        if ((!begin) || (!end) || (!beginExtra) || (!endExtra)) return undefined;

        const beginDate = new Date(year, begin.month - 1, begin.day);
        const endDate = new Date(year, end.month - 1, end.day);
        const beginExtraDate = new Date(year, beginExtra.month - 1, beginExtra.day);
        const endExtraDate = new Date(year, endExtra.month - 1, endExtra.day);
        //Rq: en sortie d'hiver, la plage de date est sur la même année, en principe !

        return { beginDate, endDate, beginExtraDate, endExtraDate, isBeginningWinter: false, };
    },

    /**
     * Fonction permettant d'obtenir le texte de l'interval principal
     * @param {Parcel} parcel la parcelle correspondant
     * @param {Boolean} isBeginningWinter true, si nous sommes dans le cas d'entrée d'hiver, sinon false
     * @returns {String} texte présentant l'interval principal
     */
    getRangeDatesText(parcel, isBeginningWinter) {
        let range;
        let result;

        if (isBeginningWinter) {
            range = this.getRangeDateByParcelForBeginningWinter(parcel);
        } else {
            //On n'envoie la date d'entrée d'hiver donc on va recevoir une date de l'année en cours
            //Ce n'est pas génant car le texte retourné ne mentionne pas l'année
            range = this.getRangeDateByParcelForEndingWinter(parcel);
        }
        
        if (!range) return undefined;//aucun texte affiché !

        result = StringTranslate.biomassRangeDates
        result = result.replace('{0}', `${range.beginDate.getDate()}/${range.beginDate.getMonth() + 1}`);
        result = result.replace('{1}', `${range.endDate.getDate()}/${range.endDate.getMonth() + 1}`);

        return result;
    },

    /**
     * Fonction permettant d'obtenir le texte de l'interval élargi
     * @param {Parcel} parcel la parcelle correspondant
     * @param {Boolean} isBeginningWinter true, si nous sommes dans le cas d'entrée d'hiver, sinon false
     * @returns {String} texte présentant l'interval élargi
     */
    getExtraRangeDatesText(parcel, isBeginningWinter) {
        let range;
        let result;

        if (isBeginningWinter) {
            range = this.getRangeDateByParcelForBeginningWinter(parcel);
        } else {
            //On n'envoie la date d'entrée d'hiver donc on va recevoir une date de l'année en cours
            //Ce n'est pas génant car le texte retourné ne mentionne pas l'année
            range = this.getRangeDateByParcelForEndingWinter(parcel);
        }
        
        if (!range) return undefined;//aucun texte affiché !

        result = StringTranslate.biomassExtraRangeDates
        result = result.replace('{0}', `${range.beginExtraDate.getDate()}/${range.beginExtraDate.getMonth() + 1}`);
        result = result.replace('{1}', `${range.beginDate.getDate()}/${range.beginDate.getMonth() + 1}`);
        result = result.replace('{2}', `${range.endDate.getDate()}/${range.endDate.getMonth() + 1}`);
        result = result.replace('{3}', `${range.endExtraDate.getDate()}/${range.endExtraDate.getMonth() + 1}`);

        return result;
    }
}