import { useEffect } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import numberHelper from '../../../utils/numberHelper.js';

import DateHelper from '../../../utils/dateHelper.js';

import {BiomassStep, ActionGoToShowThisBiomass, ActionClearBiomassValue, ActionGenerateFileOfThisBiomass, ActionGetAllWeightingBiomasses, ActionGoToNextStepOfBiomass, ActionClearNewBiomassCounter, ActionGoToTabOfBiomass } from '../../../redux/actions/biomass';

import {ActionGoToBiomass} from '../../../redux/actions/contextApp.js';

import lodashGet from 'lodash/get';

import TemplateWidget from './templateWidget.jsx';


//////////////////////////////////////////////////////////////////////////////////////////
// fonction de rendu visuel pour la fonctionnalité widget d'une pesée de colza de parcelle 
//////////////////////////////////////////////////////////////////////////////////////////
function WidgetBiomass(props) {
    const { clientId, getAllBiomasses } = props;

    //Au clic d'une parcelle, on charge TOUTES les pesées de colza du client
    useEffect(() => {
        if (getAllBiomasses && (clientId > 0)) {
            getAllBiomasses(clientId);
        }
    }, [clientId, getAllBiomasses]);

    //Redirection -> affichage biomasse
    const handleShowBiomass = (biomass) => {
        const { showBiomass, clearBiomassValue } = props;

        //si une pesée de colza a déjà été sélectionnée auparavant, vider les valeurs :
        if (clearBiomassValue) { 
            clearBiomassValue();
        }

        if (showBiomass) { 
            showBiomass(biomass);
        }
    };

    //Génération générale de pdf pour les pesées de colza (E/S)
    const generatePdf = (biomasses) => {
        const { generateBiomassFile } = props;

        if (generateBiomassFile) { 
            generateBiomassFile(biomasses[0]);
        }
    };

    const handleSelectBiomassPageClick = (event, data) => {
        const {clearNewBiomassCounter, goToBiomass, goToNextStepOfBiomass, goToBiomassTab } = props;
        clearNewBiomassCounter();
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'biomassPage' });

        let readableMode = false;
        let newParcelIdsSelected = [data];
        
        if (goToBiomass) {
            goToBiomassTab()
            goToNextStepOfBiomass(BiomassStep.CHOIX_PARCELS, newParcelIdsSelected, readableMode);
            goToBiomass(true,newParcelIdsSelected, false );
        }
    };

    const getContent = () => {
        const { parcelDico, parcelIdSelected, biomassDico, biomassDicoLoading } = props;

        if (parcelIdSelected <= 0) return;

        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let biomasses = undefined;
        let dataIsLoaded = null;
        //Récupérer toutes les pesées de colza correspondant à la parcelle
        if (biomassDico !== undefined) {
            for (const key in biomassDico) {
                const biomItem = biomassDico[key];
                if (biomItem && biomItem.parameter && (biomItem.parameter.parcelId === parcelIdSelected)) {
                    if (biomasses === undefined) {
                        biomasses = [];
                    }
                    biomasses.push(biomItem);
                }
            }
        }

        let labelButton = StringTranslate.newBiomassWidget;
        let labelLoading = StringTranslate.loadingBiomassRecords;
        let titleCardHeader = StringTranslate.mybiomasses;

        // Si on a une biomass correspondant à l'id de la parcelle sélectionner
        if (biomasses !== undefined) {
            dataIsLoaded = true;
        } else if (biomassDicoLoading === true) { // Si biomass en cours de chargement
            dataIsLoaded = null;
        } else { // Si aucune biomass n'a été trouver alors on affichera un bouton afin de créer une pesée de colza
            dataIsLoaded = false;
        }

        let headTable = (
            <TableRow key="rowHead" className="widget-color">
                {/* ↓ cellules concernant le nom de la parcelle ↓ */}
                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioBeginningWinter + " / " + StringTranslate.ColumnBioEndingWinter}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioDateWinter}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioWeighting}</Typography></TableCell>
            </TableRow>
        );
        let bodyTable = () => (
            biomasses.map(biomass => (
                <TableRow key={`row[${biomass.id}]`} onClick={() => handleShowBiomass(biomass)} className="widget-color" hover>
                    <TableCell>{(biomass.parameter.isBeginningWinter === true) ? StringTranslate.ColumnBioBeginningWinter.substring(0, 1) : StringTranslate.ColumnBioEndingWinter.substring(0, 1)}</TableCell>
                    <TableCell>{DateHelper.formati18n(new Date(biomass.parameter.dateImagSource), 'P')}</TableCell>
                    <TableCell>{numberHelper.fixeDecimal(biomass.averageQuantity)}</TableCell>
                </TableRow>
            ))
        );
        
        let generateData = (event) => {handleSelectBiomassPageClick(event, parcelIdSelected)};
        let menuItemsList = [
            <MenuItem key={2} onClick={() => generatePdf(biomasses)}>
                <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadWeightingPdf}</Typography>
            </MenuItem>
        ];

        return (
            <TemplateWidget 
                labelButton={labelButton} 
                labelLoading={labelLoading}
                titleCardHeader={titleCardHeader}
                dataIsLoaded={dataIsLoaded}
                headTable={headTable}
                bodyTable={bodyTable}
                isTable={true}
                generateData={generateData}
                menuItemsList={menuItemsList}
            />
        );
    };

    return (
        getContent()
    );
};

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        biomassDicoLoading: lodashGet(state, 'biomassData.loading', false),
        biomassDico: lodashGet(state, 'biomassData.biomassDico', {}),
        biomassDicoCounter: lodashGet(state, 'biomassData.biomassDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = dispatch => ({
    showBiomass: (biomassSelectedItem) => dispatch( ActionGoToShowThisBiomass(biomassSelectedItem, true) ),
    clearBiomassValue: () => dispatch( ActionClearBiomassValue() ),
    generateBiomassFile: (biomass) => dispatch( ActionGenerateFileOfThisBiomass(biomass) ),
    getAllBiomasses: (clientId) => dispatch( ActionGetAllWeightingBiomasses(clientId) ),
    goToNextStepOfBiomass: (nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfBiomass(nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue)),
    clearNewBiomassCounter: () => dispatch(ActionClearNewBiomassCounter()),
    goToBiomass: () => dispatch(ActionGoToBiomass(true)),
    goToBiomassTab: () => dispatch(ActionGoToTabOfBiomass(0)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBiomass);