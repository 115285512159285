import React from 'react';
import { connect } from 'react-redux';

import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import ImageSwiper from '../imageSwiper';
import DisplayNdviCharts from '../histogramme/displayNdviCharts.jsx';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Box, Typography, Fade, IconButton, SwipeableDrawer } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import { setDisplaySwipeablePanelForMobile } from '../../redux/actions/contextApp';

import '../../assets/css/swipeablePanel.css';

import getTheme from "../../themes/index.js";
import StringTranslate from '../../assets/i18n/stringLanguage';
import lodashGet from 'lodash/get';
import RenderManageOfWidget from './renderManageOfWidget';


const theme = getTheme();
const drawerBleeding = 85; //hauteur du bandeau supperieur du panneau flottant (zone permettant de l'ouvrir).
const headerHeight = 70; //hauteur de l'entête de l'appli (de façon à ce que le panneau flottant ne vienne pas la recouvrir)
const margeBetween = 10; // pour laisser un fin filet de carto... 
                         //RQ: si le contenu à afficher est suffisant pour monter jusqu'en haut; Sans quoi, il prends une taille juste suffisante à partir du bas !

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.grey[100],
}));

/* Fonction Swipeable drawer pour le mobile */
function SwipeablePanelForMobile(props) { 

	const dateSemi = (props && props.parcelDico && (props.parcelIdSelected > 0)) ? lodashGet(props, `parcelDico[${props.parcelIdSelected}].details.dateSemi`, undefined) : undefined;

	const [open, setOpen] = React.useState(false); /* Ouverture/fermeture du swiper mobile */

	/* En ce qui concerne les IOS, pour éviter que le swiper interfère avec le swiper de l'IOS, rajouter 
	cette ligne : */
	const iOS = (typeof navigator !== 'undefined') && (/iPad|iPhone|iPod/.test(navigator.userAgent)); //TODO : faudrait utiliser la version présente sous platformHelper !

	/* Fonction d'ouverture et de fermeture du panneau flottant */
	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const toggleSwipeablePanel = (display) => () => {
		if (props && (props.parcelIdSelected > 0) && props.setDisplaySwipeablePanelForMobile) {
			props.setDisplaySwipeablePanelForMobile(display, props.parcelIdSelected);
		}
	}

	let eventDateSemi = undefined;
	try {
		if (dateSemi !== undefined) {
			eventDateSemi = new Date(Date.UTC(new Date(dateSemi).getFullYear(), new Date(dateSemi).getMonth(), new Date(dateSemi).getDate(), 0, 0));
		}
	}
	catch(errDate) {
		//Tampis !
	}
	const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	// This is used only for the example
	//const container = window !== undefined ? () => window().document.body : undefined; //avec SwipeableDrawer container={container} ...

	return (
    <Root>
		<CssBaseline />
		<Global styles={{
			'.MuiDrawer-root > .MuiPaper-root': {
				height: `calc(100% - ${drawerBleeding}px - ${(headerHeight + margeBetween)}px)`, /* 70px correspond à la hauteur du header */
				overflow: 'visible',
			},
			}}/>

      	<SwipeableDrawer 
			anchor="bottom"
			open={open}
			onClose={toggleDrawer(false)}
			onOpen={toggleDrawer(true)}
			SwipeAreaProps={{
				onClick: toggleDrawer(true), 
				onTouchStart: toggleDrawer(true),
				onMouseDown: toggleDrawer(true),
			}}
			disableDiscovery={iOS}
			disableBackdropTransition={!iOS}
			swipeAreaWidth={drawerBleeding}
			disableSwipeToOpen={false}
			/* hideBackdrop={true} */
			BackdropProps={{ invisible: true }}
			ModalProps={{
				keepMounted: true,
			}}>
			{/*<Box id='root-bx-swiper-mobile' sx={{
				backgroundColor: theme.palette.background.paper,
				maxHeight: `calc(100% - ${headerHeight}px - ${margeBetween}px)`,
				minHeight: '50%'
			}}>*/}
			<Box id='stlbx-hd'
				sx={{
					backgroundColor: theme.palette.background.paper,
					position: 'absolute',
					top: `-${drawerBleeding}px`,
					right: 0,
					left: 0,
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					visibility: 'visible',
					borderBottom: '1px solid',
					borderColor: theme.palette.grey[200]
				}}>
				<Fade
					in={!open}
					timeout={{
					appear: 500,
					enter: 600,
					exit: 400
					}}>
					<Box sx={{
						position: "absolute",
						bottom: `${(drawerBleeding + 8)}px`
						}}>
						<Grid container justifyContent="center" >
							<Grid item xs={12}>
							<ImageSwiper />
							</Grid>
						</Grid>
					</Box>
				</Fade>
				<Box
					id="Puller"
					sx={{
					width: 30,
					height: 6,
					backgroundColor: theme.palette.grey[300],
					borderRadius: 3,
					position: 'absolute',//pourquoi en absolu ?
					top: 8,
					left: 'calc(50% - 15px)',
					}}/>
				<IconButton
					aria-label="close"
					onClick={toggleSwipeablePanel(false)}
					sx={{
					pointerEvents: "auto",
					position: 'absolute',
					right: 3,
					top: 3,
					color: theme.palette.grey[400],
					}}>
					<CloseIcon fontSize="small" />
				</IconButton>

				<Grid id="parcel-infos-hd" container columnSpacing={1} sx={{ pt: 2, pl: 1.2, height: drawerBleeding }}>
					<Grid item xs={12}>
						<Typography variant="h4">{(props && props.parcelDico && (props.parcelIdSelected > 0)) ? lodashGet(props, `parcelDico[${props.parcelIdSelected}].name`, "") : ""}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{StringTranslate.libelecolumnculture} : {(props && props.parcelDico && (props.parcelIdSelected > 0)) ? lodashGet(props, `parcelDico[${props.parcelIdSelected}].details.culture`, "---") : "---"}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{StringTranslate.libelecolumnsemis} : {(eventDateSemi !== undefined) ? eventDateSemi.toLocaleDateString(StringTranslate.getLanguage(), options) : "---"}</Typography>
					</Grid>
				</Grid>
			</Box>

			<Box id='stlbx-body'
				sx={{
					backgroundColor: theme.palette.grey[100],
					px: 1,
					pb: 1,
					pt: 3,
					height: '100%',
					overflow: 'scroll',
				}}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
					{/* composant analyse de l'image */}
					<DisplayNdviCharts {...props} />
					</Grid>
					<RenderManageOfWidget {...props} />
				</Grid>
			</Box>
			{/* </Box> */}
        </SwipeableDrawer>
    </Root>
  );
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
  setDisplaySwipeablePanelForMobile: (open, parcelIdZoomed) => dispatch(setDisplaySwipeablePanelForMobile(open, parcelIdZoomed)),
})

export default connect(null, mapDispatchToProps)(SwipeablePanelForMobile);
